export const productsModulesConstants = {
  GETALLPRODUCTSMODULES_REQUEST: 'GETALLPRODUCTSMODULES_REQUEST',
  GETALLPRODUCTSMODULES_SUCCESS: 'GETALLPRODUCTSMODULES_SUCCESS',
  GETALLPRODUCTSMODULES_FAILURE: 'GETALLPRODUCTSMODULES_FAILURE',

  GETPRODUCTSMODULES_REQUEST: 'GETPRODUCTSMODULES_REQUEST',
  GETPRODUCTSMODULES_SUCCESS: 'GETPRODUCTSMODULES_SUCCESS',
  GETPRODUCTSMODULES_FAILURE: 'GETPRODUCTSMODULES_FAILURE',

  CREATEPRODUCTSMODULES_REQUEST: 'CREATEPRODUCTSMODULES_REQUEST',
  CREATEPRODUCTSMODULES_SUCCESS: 'CREATEPRODUCTSMODULES_SUCCESS',
  CREATEPRODUCTSMODULES_FAILURE: 'CREATEPRODUCTSMODULES_FAILURE',

  UPDATEPRODUCTSMODULES_REQUEST: 'UPDATEPRODUCTSMODULES_REQUEST',
  UPDATEPRODUCTSMODULES_SUCCESS: 'UPDATEPRODUCTSMODULES_SUCCESS',
  UPDATEPRODUCTSMODULES_FAILURE: 'UPDATEPRODUCTSMODULES_FAILURE',

  DELETEPRODUCTSMODULES_REQUEST: 'DELETEPRODUCTSMODULES_REQUEST',
  DELETEPRODUCTSMODULES_SUCCESS: 'DELETEPRODUCTSMODULES_SUCCESS',
  DELETEPRODUCTSMODULES_FAILURE: 'DELETEPRODUCTSMODULES_FAILURE',
};
