import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { teamActions } from '../../actions/team.actions';

class TeamScreen extends Component {
  componentDidMount() {
    this.props.dispatch(teamActions.getAll());
  }

  render() {
    const { teams, loading } = this.props;

    if (!teams.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Times</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/teams/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`teams/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;

              dispatch(teamActions.getAll(`page=${page}`));
            }}
            header={['Nome', 'Descrição']}
            search={{
              name: 'Nome',
              description: 'Descrição',
            }}
            columns={['name', 'description']}
            data={teams.data}
            totalPages={teams.lastPage}
            page={teams.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { teams } = state;
  return {
    teams,
    loading: teams.loading,
  };
}

const connectedTeamScreen = connect(mapStateToProps)(TeamScreen);
export { connectedTeamScreen as TeamScreen };
