import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { contactActions } from '../../actions/contact.actions';

class ContactScreen extends Component {
  componentDidMount() {
    this.props.dispatch(contactActions.getAll());
  }

  render() {
    const { contact, loading } = this.props;

    if (!contact.data || loading) {
      return <Loader active={loading} />;
    }
    return (
      <Card fluid>
        <Grid>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="left">
            <Button primary onClick={() => this.props.history.push('/contact/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>

        <DynamicTable
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;
            dispatch(contactActions.getAll(`page=${page}`));
          }}
          header={['Nome', 'E-Mail', 'Telefone Principal', 'Telefone Celular', 'Notas']}
          columns={[
            {
              name: 'name',
              format: (item) => item.display_name,
            },
            {
              name: 'email',
              format: (item) => item.email,
            },
            {
              name: 'phone_main',
              format: (item) => item.phone_main,
            },
            {
              name: 'notes',
              format: (item) => item.notes,
            },
          ]}
          data={contact.data}
          totalPages={contact.lastPage}
          page={contact.page}
        />
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { contact } = state;
  return {
    contact,
    loading: contact.loading || false,
  };
}

const connectedClientScreen = connect(mapStateToProps)(ContactScreen);
export { connectedClientScreen as ClientCardList };
