import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { projectActions } from '../../actions/project.actions';
import { expenseActions } from '../../actions/expense.actions';
import CurrencyInput from 'react-currency-input';

class ExpenseEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type_id: null,
      center_id: null,
      description: '',
      refundable: true,
      date: '',
      amount: '0.00',
      kilometer_fare: null,
      kilometer: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(expenseActions.getById(match.params.id)).then(() => {
      const { expenses } = this.props;

      dispatch(projectActions.getAll());
      dispatch(expenseActions.getTypes());
      dispatch(expenseActions.getCenters());

      this.setState({
        type_id: expenses.item.type_id,
        center_id: expenses.item.center_id,
        description: expenses.item.description,
        date: moment(expenses.item.date).format('DD/MM/YYYY'),
        amount: expenses.item.amount,
        kilometer_fare: expenses.item.kilometer,
        kilometer: expenses.item.kilometer,
        refundable: expenses.item.refundable,
      });
    });
  }

  handleChange(e, data) {
    if (data && data.name == 'refundable') {
      this.setState({ refundable: data.checked });
    } else if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { type_id, center_id, description, date, amount, refundable, kilometer } = this.state;
    const { dispatch, match } = this.props;
    if (type_id && center_id && description && date && amount) {
      dispatch(
        expenseActions.update(
          match.params.id,
          {
            type_id,
            center_id,
            description,
            refundable,
            date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            amount: amount,
            kilometers: kilometer,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const {
      type_id,
      center_id,
      description,
      date,
      amount,
      kilometer_fare,
      kilometer,
      refundable,
      submitted,
    } = this.state;
    const { centers, types, loading } = this.props;
    let typeOptions = [],
      centerOptions = [];

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (centers && centers.data !== undefined) {
      centerOptions = centers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.abbrev };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Despesa</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              name="type_id"
              options={typeOptions}
              onChange={(event, data) => {
                const { dispatch } = this.props;
                dispatch(expenseActions.getTypeById(data.value)).then(() => {
                  const { type } = this.props;

                  this.setState({
                    kilometer_fare: type.kilometer_fare,
                  });
                });
                this.handleChange(true, data);
              }}
              value={type_id}
            />
            {submitted && !type_id && (
              <Label basic color="red" pointing>
                Tipo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Centro de Custo</label>
            <Dropdown
              placeholder="Centro de Custo"
              fluid
              search
              selection
              name="center_id"
              options={centerOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={center_id}
            />
            {submitted && !center_id && (
              <Label basic color="red" pointing>
                Centro de custo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <input
              name="description"
              placeholder="Descrição"
              value={description}
              onChange={this.handleChange}
            />
            {(submitted && !description) ||
              (description && description.length <= 3 && (
                <Label basic color="red" pointing>
                  Descrição é requerido (Mínimo 4 caracteres)
                </Label>
              ))}
          </Form.Field>
          <Form.Field>
            <label>Data</label>
            <DateInput
              closable
              clearable
              dateFormat="DD/MM/YYYY"
              name="date"
              placeholder="Data"
              onChange={(event, data) => this.handleChange(true, data)}
              value={date}
              iconPosition="left"
            />
            {submitted && !date && (
              <Label basic color="red" pointing>
                Data é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            {!kilometer_fare && (
              <>
                <label>Valor (R$)</label>
                <CurrencyInput
                  name="amount"
                  placeholder="Valor (R$)"
                  value={amount}
                  displayType={'text'}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={'R$ '}
                  onChangeEvent={(event, maskedvalue, floatvalue) =>
                    this.handleChange(true, {
                      name: 'amount',
                      value: floatvalue,
                    })
                  }
                />
              </>
            )}
            {!kilometer_fare && submitted && !amount && (
              <Label basic color="red" pointing>
                Valor é requerido
              </Label>
            )}

            {kilometer_fare && kilometer && (
              <>
                <label>Distância (km)</label>
                <Form.Input
                  name="kilometer"
                  type="number"
                  onChange={this.handleChange}
                  placeholder="Distância (km)"
                  value={kilometer}
                />
              </>
            )}
            {kilometer_fare && kilometer && submitted && !kilometer_fare && (
              <Label basic color="red" pointing>
                Distância é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="refundable"
              label="Reembolsável"
              checked={refundable}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, expenses } = state;
  return {
    types: expenses.types,
    centers: expenses.centers,
    type: expenses.type,
    projects,
    expenses,
    loading: expenses.loading || projects.loading || false,
  };
}

const connectedExpenseEditScreen = connect(mapStateToProps)(ExpenseEditScreen);
export { connectedExpenseEditScreen as ExpenseEditScreen };
