import client from '../helpers/HttpClient';

class BaseService {
  /**
   * The base path for the service on the API
   * @returns {string}
   */
  get basePath() {
    throw 'Must override basePath getter';
  }

  async create(obj) {
    try {
      const result = await client.post(this.basePath, obj);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  async getAll(params) {
    try {
      let path = this.basePath;
      let config = {};
      if (params && typeof params === 'string') {
        path += `?${params}`;
      } else if (params) {
        config.params = params;
      }
      const result = await client.get(path, config);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`${this.basePath}/${id}`);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`${this.basePath}/${id}`);
      return result.data;
    } catch (error) {
      const { response } = error;
      if (
        response &&
        response.data &&
        response.data.code &&
        response.data.code === 'ER_ROW_IS_REFERENCED_2'
      ) {
        return Promise.reject(
          'Este registro não pode ser removido porque tem outros registros relacionados',
        );
      }
      return Promise.reject(this.handleError(error));
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`${this.basePath}/${id}`, obj);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  handleError(error) {
    if (error && error.response && error.response.data.length) {
      const messages = error.response.data.map((item) => item.message);
      return messages.join('<br/>');
    }

    return error;
  }
}

export default BaseService;
