import client from '../helpers/HttpClient';

class leadsService {
  async create(obj) {
    try {
      const result = await client.post('leads', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`leads/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      const result = await client.get(`leads`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;
      return Promise.reject(returnMessage);
    }
  }

  async getStatus(params) {
    try {
      const result = await client.get(`lead/status`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id, params) {
    try {
      const result = await client.get(`leads/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`leads/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new leadsService();
