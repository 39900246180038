import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Dropdown, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import { withRouter } from 'react-router-dom';

const NavBarChildren = ({ children }) => <Container fluid>{children}</Container>;

class MenuBand extends Component {
  state = {
    activeItem: 'timer',
    visible: false,
    menu: [
      {
        as: 'a',
        name: 'timer',
        content: 'Timer',
        key: 'timer',
        icon: 'clock',
        permission: 'activities.timer',
      },
      {
        as: 'a',
        name: 'tickets',
        key: 'tickets',
        content: 'Tickets',
        icon: 'clipboard list',
        permission: 'tickets.index',
      },
      {
        as: 'a',
        name: 'expenses',
        key: 'expenses',
        content: 'Despesas',
        icon: 'dollar',
        permission: 'expenses.index',
      },
      {
        as: 'a',
        name: 'orders',
        key: 'orders',
        content: 'Pedidos',
        icon: 'shop',
        permission: 'service.orders.index',
      },
      {
        as: 'a',
        name: 'sales',
        key: 'sales',
        content: 'Vendas',
        icon: 'tags',
        permission: 'sale.index',
      },
      {
        as: 'a',
        name: 'opportunity',
        key: 'opportunity',
        content: 'Oportunidades',
        icon: 'list alternate outline',
        permission: 'opportunities.index',
      },
      {
        as: 'a',
        name: 'transaction',
        key: 'transaction',
        content: 'Transações',
        icon: 'sync',
        permission: 'transactions.index',
      },
      {
        as: 'a',
        name: 'leads',
        key: 'leads',
        content: 'Leads',
        icon: 'id badge outline',
        permission: 'leads.index',
      },
      {
        content: 'Atividades',
        permission: 'activities.index',
        submenus: [
          {
            as: 'a',
            name: 'activities',
            content: 'Relatório',
            key: 'activities',
            icon: 'file alternate',
            permission: 'activities.index',
          },
          {
            as: 'a',
            name: 'activities-bank',
            content: 'Banco de Horas',
            key: 'activities-bank',
            icon: 'time',
            permission: 'activities.bank.index',
          },
          {
            as: 'a',
            name: 'activities/per-user',
            content: 'Por Usuário',
            key: 'activities/per-user',
            icon: 'user circle',
            permission: 'activities.timer',
          },
        ],
      },
      {
        content: 'Tickets',
        permission: 'tickets.index',
        submenus: [
          {
            as: 'a',
            name: 'tickets-report',
            key: 'tickets-report',
            content: 'Relatório',
            icon: 'file alternate',
            permission: 'tickets.index',
          },
        ],
      },
      {
        content: 'Despesas',
        permission: 'expenses.permissions',
        submenus: [
          {
            as: 'a',
            name: 'expenses-activity',
            key: 'expenses-activity',
            content: 'Relatório',
            icon: 'file alternate',
            permission: 'expenses.index',
          },
        ],
      },
      {
        content: 'Financeiro',
        permission: 'financial.accounts.index',
        submenus: [
          {
            as: 'a',
            name: 'finance/accounts',
            key: 'finance/accounts',
            content: 'Contas Financeiras',
            icon: 'list alternate outline',
            permission: 'financial.accounts.index',
          },
          {
            as: 'a',
            name: 'finance/categories',
            key: 'finance/categories',
            content: 'Categorias',
            icon: 'list alternate outline',
            permission: 'financial.sub.categories.index',
          },
        ],
      },
      {
        content: 'Configurações',
        permission: 'projects.index',
        submenus: [
          {
            as: 'a',
            name: 'projects',
            key: 'projects',
            content: 'Projetos',
            icon: 'list alternate outline',
            permission: 'projects.store',
          },
          {
            as: 'a',
            name: 'clients',
            key: 'clients',
            content: 'Clientes',
            icon: 'address book',
            permission: 'clients.store',
          },
          {
            as: 'a',
            name: 'users',
            key: 'users',
            content: 'Usuários',
            icon: 'user circle',
            permission: 'users.store',
          },
          {
            as: 'a',
            name: 'managers',
            key: 'managers',
            content: 'Gerentes de Conta',
            icon: 'user',
            permission: 'account.managers.store',
          },
          {
            as: 'a',
            name: 'teams',
            key: 'teams',
            content: 'Times',
            icon: 'users',
            permission: 'teams.store',
          },
          {
            as: 'a',
            name: 'contracts',
            key: 'contracts',
            content: 'Contratos',
            icon: 'list',
            permission: 'contracts.index',
          },
          {
            as: 'a',
            name: 'expense-types',
            content: 'Tipo Despesa',
            key: 'expense-types',
            icon: 'list alternate',
            permission: 'expense.type.store',
          },
          {
            as: 'a',
            name: 'activitytype',
            content: 'Tipo Atividade',
            key: 'activitytype',
            icon: 'list alternate',
            permission: 'activity.types.store',
          },
          {
            as: 'a',
            name: 'products',
            content: 'Produtos',
            key: 'products',
            icon: 'boxes',
            permission: 'products.index',
          },
          {
            as: 'a',
            name: 'product/modules',
            content: 'Módulos de Produtos',
            key: 'product/modules',
            icon: 'archive',
            permission: 'product.module.store',
          },
          {
            as: 'a',
            name: 'licenses',
            content: 'Licenças',
            key: 'licenses',
            icon: 'drivers license',
            permission: 'licenses.store',
          },
          {
            as: 'a',
            name: 'providers',
            content: 'Fornecedores',
            key: 'providers',
            icon: 'truck',
            permission: 'providers.store',
          },
          {
            as: 'a',
            name: 'companies',
            content: 'Empresas',
            key: 'companies',
            icon: 'building outline',
            permission: 'companies.store',
          },
          {
            as: 'a',
            name: 'payment',
            content: 'Método de Pagamento',
            key: 'payment',
            icon: 'money',
            permission: 'payment.methods.store',
          },
          {
            as: 'a',
            name: 'provider-types',
            content: 'Tipo de Fornecedor',
            key: 'provider-types',
            icon: 'truck',
            permission: 'providers.store',
          },
          {
            as: 'a',
            name: 'mails',
            content: 'E-mails',
            key: 'mails',
            icon: 'mail',
            permission: 'email.texts.update',
          },
          {
            as: 'a',
            name: 'pdf',
            content: 'PDF',
            key: 'pdf',
            icon: 'file pdf',
            permission: 'pdf.templates.update',
          },
        ],
      },
    ],
  };

  componentDidMount() {
    this.setState({
      activeItem: this.props.history.location.pathname.split('/')[1],
    });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name, visible: false });
    this.props.history.push(`/${name}`);
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  logout = () => {
    const { dispatch } = this.props;

    localStorage.removeItem('user');
    localStorage.removeItem('me');
    dispatch(userActions.logout());
    window.location.href = `/login`;
  };

  render() {
    const { children, me, company } = this.props;

    if (!me) return <Fragment>{children}</Fragment>;

    const { menu, activeItem } = this.state;
    const leftItems = _.filter(
      menu,
      (item) => me && me.role_permissions.some((r) => item.permission.split('|').indexOf(r) >= 0),
    );

    const companyData = company ? company : null;
    const authAction = (
      <Fragment>
        <Icon name="user" />
        <Dropdown text={me ? me.email : ''} loading={me && me.email ? false : true}>
          <Dropdown.Menu>
            <Dropdown.Item
              icon="user outline"
              text="Perfil"
              onClick={() => this.props.history.push('/profile')}
            />
            <Dropdown.Divider />
            <Dropdown.Item icon="sign-out" text="Logout" onClick={this.logout} />
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );

    return (
      <div>
        <Menu fixed="left" style={{ marginTop: '4.7em' }}>
          <Sidebar.Pushable as={Segment} className="full-height">
            <div className="navslide">
              <Sidebar
                className="main-menu"
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible
                borderless
                width="thin">
                {_.map(leftItems, (item, i) => {
                  if (item.submenus) {
                    return (
                      <Menu.Item className="menu-divider" key={i}>
                        <div className="menu-divider-label">{item.content}</div>
                        <Menu.Menu>
                          {_.filter(
                            item.submenus,
                            (item) =>
                              me &&
                              me.role_permissions.some(
                                (r) => item.permission.split('|').indexOf(r) >= 0,
                              ),
                          ).map((subitem) => {
                            return (
                              <Menu.Item
                                {...subitem}
                                key={subitem.key}
                                active={activeItem === subitem.name}
                                onClick={this.handleItemClick}
                              />
                            );
                          })}
                        </Menu.Menu>
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <Menu.Item
                        {...item}
                        active={activeItem === item.name}
                        onClick={this.handleItemClick}
                      />
                    );
                  }
                })}
              </Sidebar>
            </div>
          </Sidebar.Pushable>
        </Menu>
        <Menu fixed="top">
          <Menu.Item
            className="main-menu"
            style={{ width: '190px' }}
            onClick={() => this.props.history.push('/')}>
            {companyData && companyData.menu_logo_url != null ? (
              <Image style={{ width: '190px', padding: '10px' }} src={companyData.menu_logo_url} />
            ) : (
              <div className="content-logo">
                <Image
                  size="mini"
                  src={companyData ? companyData.logo_url : 'imgs/logo-icon.png'}
                />
                <span className="logo-text">{companyData ? companyData.name : 'BandTrack'}</span>
              </div>
            )}
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>{authAction}</Menu.Item>
          </Menu.Menu>
        </Menu>
        <NavBarChildren>{children}</NavBarChildren>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  return {
    me: users.me || null,
    company: companies.item || null,
  };
}

const connectedMenuBand = withRouter(connect(mapStateToProps)(MenuBand));
export { connectedMenuBand as MenuBand };
