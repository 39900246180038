import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Input } from 'semantic-ui-react';
import CurrencyInput from 'react-currency-input';
import './styles.css';

class InputCoins extends Component {
  state = {
    currency: '',
    amount: 0,
  };

  handleChange(e, data) {
    if (data.value) {
      this.props.onChange(true, { name: 'currency', value: data.value });
    } else {
      this.props.onChange(true, {
        name: this.props.name || 'amount',
        value: data,
      });
    }
  }

  render() {
    const { amount, currency } = this.props;

    const options = [
      { key: 'BRL', text: 'BRL', value: 'BRL' },
      { key: 'EUR ', text: 'EUR ', value: 'EUR' },
      { key: 'USD', text: 'USD', value: 'USD' },
    ];

    return (
      <div>
        <Input focus placeholder="Search..." className="imput">
          <Dropdown
            className="coins"
            defaultValue="BRL"
            options={options}
            value={currency}
            onChange={(event, data) => this.handleChange(event, data)}
          />
          <CurrencyInput
            style={{ paddingLeft: '57px', zIndex: 1, marginLeft: '-44px' }}
            decimalSeparator=","
            thousandSeparator="."
            name="amount"
            value={amount}
            onChange={(event, data) => this.handleChange(event, data)}
          />
        </Input>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedInputCoins = connect(mapStateToProps)(InputCoins);
export { connectedInputCoins as InputCoins };
