import { paymentConstants } from '../constants/payment.constants';
import paymentService from '../services/payment.service';
import { alertActions } from './alert.actions';

export const paymentActions = {
  create,
  deleteById,
  getAll,
  getById,
  update,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const payment = await paymentService.create(obj);

      dispatch(success(payment));
      dispatch(alertActions.success('Método de Pagamento Criado'));
      history.push('/payment');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: paymentConstants.CREATE_PAYMENT_REQUEST,
    };
  }

  function success(payment) {
    return {
      type: paymentConstants.CREATE_PAYMENT_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: paymentConstants.CREATE_PAYMENT_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const payment = await paymentService.deleteById(id);

      dispatch(success(payment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(payment) {
    return {
      type: paymentConstants.DELETE_PAYMENT_REQUEST,
      payment,
    };
  }

  function success(payment) {
    return {
      type: paymentConstants.DELETE_PAYMENT_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: paymentConstants.DELETE_PAYMENT_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const payment = await paymentService.getAll(filter);

      dispatch(success(payment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: paymentConstants.GETALL_PAYMENT_REQUEST,
    };
  }

  function success(payment) {
    return {
      type: paymentConstants.GETALL_PAYMENT_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: paymentConstants.GETALL_PAYMENT_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const payment = await paymentService.getById(id);

      dispatch(success(payment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(payment) {
    return {
      type: paymentConstants.GET_PAYMENT_REQUEST,
      payment,
    };
  }

  function success(payment) {
    return {
      type: paymentConstants.GET_PAYMENT_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: paymentConstants.GET_PAYMENT_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const payment = await paymentService.update(id, obj);

      dispatch(success(payment));
      dispatch(alertActions.success('Método de Pagamento Atualizado'));
      history.push('/payment');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: paymentConstants.UPDATE_PAYMENT_REQUEST,
    };
  }

  function success(payment) {
    return {
      type: paymentConstants.UPDATE_PAYMENT_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: paymentConstants.UPDATE_PAYMENT_FAILURE,
      error,
    };
  }
}
