import { teamConstants } from '../constants/team.constants';
import teamService from '../services/team.service';
import { alertActions } from './alert.actions';

export const teamActions = {
  create,
  update,
  deleteById,
  getAll,
  getById,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const team = await teamService.create(obj);

      dispatch(success(team));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: teamConstants.CREATETEAM_REQUEST };
  }
  function success(team) {
    return { type: teamConstants.CREATETEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.CREATETEAM_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const team = await teamService.update(id, obj);

      dispatch(success(team));
      dispatch(alertActions.success('Time Atualizado'));
      history.push('/teams');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: teamConstants.UPDATETEAM_REQUEST };
  }
  function success(team) {
    return { type: teamConstants.UPDATETEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.UPDATETEAM_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const teams = await teamService.getAll(filter);

      dispatch(success(teams));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: teamConstants.GETALLTEAM_REQUEST };
  }
  function success(teams) {
    return { type: teamConstants.GETALLTEAM_SUCCESS, teams };
  }
  function failure(error) {
    return { type: teamConstants.GETALLTEAM_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const team = await teamService.getById(id);

      dispatch(success(team));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(team) {
    return { type: teamConstants.GETTEAM_REQUEST, team };
  }
  function success(team) {
    return { type: teamConstants.GETTEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.GETTEAM_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const team = await teamService.deleteById(id);

      dispatch(success(team));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(team) {
    return { type: teamConstants.DELETETEAM_REQUEST, team };
  }
  function success(team) {
    return { type: teamConstants.DELETETEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.DELETETEAM_FAILURE, error };
  }
}
