import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { mailsActions } from '../../actions/mails.actions';

class MailsShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(mailsActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  editById() {
    const { match } = this.props;
    this.props.history.push(`/mails/${match.params.id}/edit`);
  }

  format(date) {
    const d = moment(date);
    return d.isValid() ? d.format('DD/MM/YYYY HH:mm:ss') : '-';
  }

  render() {
    const { mails, loading } = this.props;

    if (!mails)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{mails.subject || '-'}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Assunto: </h4>
          {mails.subject || '-'}
          <h4>Slug: </h4>
          {mails.slug || '-'}
          <h4>Última Alteração: </h4>
          {this.format(mails.updated_at)}
          <h4>Texto</h4>
          <div
            style={{ border: '1px solid #CCC', padding: '4px' }}
            dangerouslySetInnerHTML={{ __html: mails.body }}></div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { mails } = state;

  return {
    mails: mails.item,
    loading: mails.loading || false,
  };
}

const connectedMailsShowScreen = connect(mapStateToProps)(MailsShowScreen);
export { connectedMailsShowScreen as MailsShowScreen };
