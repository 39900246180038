import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { financialCategoryActions } from '../../actions/financial-category.actions';

class FinancialCategoryCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      category_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(financialCategoryActions.getGroups());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, description, category_id } = this.state;
    const { dispatch } = this.props;
    if (name && category_id) {
      dispatch(
        financialCategoryActions.create(
          {
            name,
            description,
            category_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { name, description, category_id, submitted } = this.state;

    const { groups, loading } = this.props;
    let groupOptions = [];

    if (groups && groups.data !== undefined) {
      groupOptions = groups.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Nova Categoria</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <input
              name="description"
              placeholder="Descrição"
              value={description}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Grupo</label>
            <Dropdown
              placeholder="Grupo"
              fluid
              search
              selection
              name="category_id"
              options={groupOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={category_id}
            />
            {submitted && !category_id && (
              <Label basic color="red" pointing>
                Grupo é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { financialCategory } = state;
  return {
    groups: financialCategory.groups,
    loading: financialCategory.loading || false,
  };
}

const connectedCategoryCreateScreen = connect(mapStateToProps)(FinancialCategoryCreateScreen);
export { connectedCategoryCreateScreen as FinancialCategoryCreateScreen };
