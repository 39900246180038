import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import { pdfActions } from '../../actions/pdf.actions';

class PdfEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: RichTextEditor.createEmptyValue(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(pdfActions.getById(match.params.id)).then(() => {
      const { pdf } = this.props;

      this.setState({
        description: RichTextEditor.createValueFromString(pdf.description, 'html'),
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { description } = this.state;
    const { dispatch, match } = this.props;
    if (description) {
      dispatch(
        pdfActions.update(
          match.params.id,
          {
            description: description.toString('html'),
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { name, description, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Texto PDF</h1>
          </Grid.Column>
        </Grid>
        <h4>{name}</h4>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Texto</label>
            <RichTextEditor
              value={description}
              onChange={(value) => this.handleChange(true, { name: 'description', value })}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Texto é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { pdf } = state;
  return {
    pdf: pdf.item,
    loading: pdf.loading || false,
  };
}

const connectedPdfEditScreen = connect(mapStateToProps)(PdfEditScreen);
export { connectedPdfEditScreen as PdfEditScreen };
