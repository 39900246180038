import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { activityActions } from '../../actions/activity.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class ActivityTypeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(activityActions.getTypes()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { types, loading } = this.props;
    const { refresh } = this.state;
    const data = types.data;

    if (!data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Tipos de Atividade</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/activitytype/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`activitytype/${item.id}/show`)}
            refresh={refresh}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(activityActions.getTypes(`page=${page}`));
            }}
            header={['Nome', 'Estado', 'Status']}
            search={{
              name: 'Nome',
              status: 'Estado',
              active: 'Status',
            }}
            columns={[
              'name',
              'status.name',
              {
                name: 'active',
                type: 'boolean',
              },
            ]}
            data={data}
            totalPages={types.lastPage}
            page={types.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { activities } = state;
  return {
    types: activities.types || {},
    loading: activities.loading || false,
  };
}

const connectedActivityTypeScreen = connect(mapStateToProps)(ActivityTypeScreen);
export { connectedActivityTypeScreen as ActivityTypeScreen };
