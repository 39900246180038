import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';

class ProviderTypeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(providersActions.getTypes()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { types, loading } = this.props;
    const { refresh } = this.state;

    if (!types || !types.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Tipo de Fornecedor</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/provider-types/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`/provider-types/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;

            dispatch(providersActions.getTypes(`page=${page}`));
          }}
          refresh={refresh}
          header={['Código', 'Nome']}
          search={{
            name: 'Nome',
            code: 'Código',
          }}
          columns={['id', 'name']}
          data={types.data}
          totalPages={types.lastPage}
          page={types.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;
  return {
    types: providers.types,
    loading: providers.loading || false,
  };
}

const connectedProviderTypeScreen = connect(mapStateToProps)(ProviderTypeScreen);
export { connectedProviderTypeScreen as ProviderTypeScreen };
