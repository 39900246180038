import React, { Component } from 'react';
import { connect } from 'react-redux';
import { salesActions } from '../../actions/sales.actions';
import { Form, Modal, Button, Label, Dropdown, FormGroup } from 'semantic-ui-react';
import { InputCoins } from '../InputCoins/InputCoins';
import { SearchBar } from '../SearchBar/SearchBar';

class ModalEditItensSales extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      name: '',
      type: '',
      unit_price: 0,
      unit_cost_price: 0,
      amount_cost_price: 0,
      currency: 'BRL',
      quantity: 1,
      amount: 25,
      part_number: '',
      openItemsEdit: false,
      submitted: false,
    };
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { sales, dispatch, itemsId } = this.props;
    const {
      name,
      unit_price,
      quantity,
      amount,
      part_number,
      currency,
      unit_cost_price,
      amount_cost_price,
      type,
      submitted,
    } = this.state;

    if (amount && quantity && name) {
      let price = null;
      let costPrice = null;

      if (unit_price.toString().match(/(\.)/g)) {
        price = unit_price
          .toFixed(2)
          .toString()
          .replace(/(\.|\,)/g, '');
        price = parseInt(price);
      } else {
        price = unit_price * 100;
      }

      if (unit_cost_price && unit_cost_price.toString().match(/(\.)/g)) {
        costPrice = unit_cost_price
          .toFixed(2)
          .toString()
          .replace(/(\.|\,)/g, '');
        costPrice = parseInt(costPrice);
      } else {
        costPrice = unit_cost_price * 100;
      }

      let payload = {
        name,
        type,
        sale_id: sales.id,
        unit_cost_price: costPrice,
        amount_cost_price: costPrice * quantity,
        unit_price: price,
        quantity,
        currency,
        part_number,
        amount: price * quantity,
      };

      dispatch(salesActions.updateItems(sales.id, itemsId, payload)).then(() => {
        this.onClose();
        this.setState({
          submitted: false,
          openItemsEdit: false,
          refresh: true,
          page: 1,
        });
        dispatch(salesActions.getItems(sales.id));
      });
    }
  }

  onClose() {
    this.setState({ openItemsEdit: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { sales, dispatch, itemsId } = this.props;

    if (this.props.openItemsEdit !== prevProps.openItemsEdit || this.props.id != prevProps.id) {
      this.setState({ openItemsEdit: true });
      dispatch(salesActions.getByIdItems(sales.id, itemsId)).then(() => {
        this.setState({
          name: this.props.items.name,
          currency: this.props.items.currency,
          amount: this.props.items.amount / 100,
          unit_price: this.props.items.unit_price / 100,
          part_number: this.props.items.part_number,
          quantity: this.props.items.quantity,
          unit_cost_price: this.props.items.unit_cost_price / 100,
          amount_cost_price: this.props.items.amount_cost_price / 100,
          type: this.props.items.type,
        });
      });
    }
  }

  render() {
    const { loading, items } = this.props;
    const {
      name,
      unit_price,
      currency,
      quantity,
      part_number,
      unit_cost_price,
      type,
      amount,
      openItemsEdit,
      submitted,
    } = this.state;

    const typeOptions = [
      { key: 'P', value: 'P', text: 'Produto' },
      { key: 'S', value: 'S', text: 'Serviço' },
    ];

    return (
      <Modal size="small" open={openItemsEdit} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Editar Item</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Field>
              <label>Produto</label>
              <SearchBar
                onSelect={(item) =>
                  this.setState({ part_number: item.part_number, name: item.name })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Tipo</label>
              <Dropdown
                placeholder="Tipo"
                fluid
                search
                selection
                name="type"
                options={typeOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={type}
              />
              {submitted && !type && (
                <Label basic color="red" pointing>
                  Tipo é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Part Number</label>
              <input
                name="part_number"
                placeholder="Nº do Produto"
                value={part_number}
                onChange={this.handleChange}
              />
            </Form.Field>
            {submitted && !part_number && (
              <Label basic color="red" pointing>
                Part Number é requerido
              </Label>
            )}
            <Form.Field>
              <label>Descrição</label>
              <input
                name="name"
                placeholder="Descrição"
                value={name}
                onChange={this.handleChange}
              />
              {submitted && !name && (
                <Label basic color="red" pointing>
                  Descrição é requerido
                </Label>
              )}
            </Form.Field>
            <FormGroup>
              <Form.Field width={6}>
                <label>Preço Custo</label>
                <InputCoins
                  name={'unit_cost_price'}
                  amount={unit_cost_price}
                  currency={currency}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field width={6}>
                <label>Preço Venda</label>
                <InputCoins
                  name={'unit_price'}
                  amount={unit_price}
                  currency={currency}
                  onChange={this.handleChange}
                />
                {submitted && !unit_price && (
                  <Label basic color="red" pointing>
                    Preço é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Quantidade</label>
                <input
                  name="quantity"
                  placeholder="Quantidade"
                  type="number"
                  value={quantity}
                  onChange={this.handleChange}
                />
                {submitted && !quantity && (
                  <Label basic color="red" pointing>
                    Quantidade é requerido
                  </Label>
                )}
              </Form.Field>
            </FormGroup>
            <Button
              primary
              type="submit"
              disabled={!unit_price && !quantity && !name && !part_number ? true : false}>
              Salvar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { sales } = state;
  return {
    sales: sales.item,
    items: sales.saleItem || null,
    loading: sales.loading || false,
  };
}

const connectedModalEditItensSales = connect(mapStateToProps)(ModalEditItensSales);
export { connectedModalEditItensSales as ModalEditItensSales };
