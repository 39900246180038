import { providersConstants } from '../constants/providers.constants';
import providersService from '../services/provider.service';
import providerTypeService from '../services/provider-type.service';
import { alertActions } from './alert.actions';

export const providersActions = {
  create,
  update,
  deleteById,
  getAll,
  getById,
  getTypes,
  getTypeById,
  updateType,
  deleteTypeById,
  createType,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const providers = await providersService.create(obj);
      dispatch(success(providers));
      history.push('/providers');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return { type: providersConstants.CREATEPROVIDERS_REQUEST };
  }
  function success(providers) {
    return { type: providersConstants.CREATEPROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: providersConstants.CREATEPROVIDERS_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const providers = await providersService.deleteById(id);

      dispatch(success(providers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(providers) {
    return { type: providersConstants.DELETEPROVIDERS_REQUEST, providers };
  }
  function success(providers) {
    return { type: providersConstants.DELETEPROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: providersConstants.DELETEPROVIDERS_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const providers = await providersService.update(id, obj);

      dispatch(success(providers));
      history.push('/providers');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: providersConstants.UPDATEPROVIDERS_REQUEST };
  }
  function success(providers) {
    return { type: providersConstants.UPDATEPROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: providersConstants.UPDATEPROVIDERS_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const providers = await providersService.getAll(filter);

      dispatch(success(providers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: providersConstants.GETALLPROVIDERS_REQUEST };
  }
  function success(providers) {
    return { type: providersConstants.GETALLPROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: providersConstants.GETALLPROVIDERS_FAILURE, error };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const types = await providerTypeService.getAll(filter);

      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: providersConstants.GETALLTYPEPROVIDERS_REQUEST };
  }
  function success(types) {
    return { type: providersConstants.GETALLTYPEPROVIDERS_SUCCESS, types };
  }
  function failure(error) {
    return { type: providersConstants.GETALLTYPEPROVIDERS_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const providers = await providersService.getById(id);

      dispatch(success(providers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(providers) {
    return { type: providersConstants.GETPROVIDERS_REQUEST, providers };
  }
  function success(providers) {
    return { type: providersConstants.GETPROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: providersConstants.GETPROVIDERS_FAILURE, error };
  }
}

function createType(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await providerTypeService.create(obj);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: providersConstants.CREATEPROVIDERS_REQUEST };
  }
  function success(type) {
    return { type: providersConstants.CREATEPROVIDERS_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: providersConstants.CREATEPROVIDERS_FAILURE, error };
  }
}

function deleteTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await providerTypeService.deleteById(id);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return { type: providersConstants.DELETEPROVIDERS_REQUEST, item: type };
  }
  function success(type) {
    return { type: providersConstants.DELETEPROVIDERS_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: providersConstants.DELETETYPEPROVIDERS_FAILURE, error };
  }
}

function updateType(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await providerTypeService.update(id, obj);

      dispatch(success(type));
      history.push('/provider-types');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: providersConstants.UPDATEPROVIDERS_REQUEST };
  }
  function success(type) {
    return { type: providersConstants.UPDATEPROVIDERS_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: providersConstants.UPDATEPROVIDERS_FAILURE, error };
  }
}

function getTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await providerTypeService.getById(id);
      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return { type: providersConstants.GETTYPEPROVIDERS_REQUEST, item: type };
  }
  function success(type) {
    return { type: providersConstants.GETTYPEPROVIDERS_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: providersConstants.GETTYPEPROVIDERS_FAILURE, error };
  }
}
