import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';

class UserEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      username: '',
      email: '',
      password: '',
      role_id: null,
      client_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(userActions.getRoles());
    dispatch(clientActions.getAll());
    dispatch(userActions.getById(match.params.id)).then(() => {
      const { users } = this.props;

      this.setState({
        name: users.name,
        username: users.username,
        email: users.email,
        active: users.active,
        password: '',
        client_id: users.client_id,
        role_id: users.role_id,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, username, email, password, active, role_id, client_id } = this.state;
    const { dispatch, match } = this.props;

    if (name && email && role_id) {
      let param = {
        name,
        username,
        email,
        role_id,
        active,
        client_id,
      };

      if (password) {
        param.password = password;
      }

      dispatch(userActions.update(match.params.id, param, this.props.history));
    }
  }

  render() {
    const { name, username, email, password, role_id, active, client_id, submitted } = this.state;
    const { roles, loading, clients } = this.props;
    let roleOptions,
      clientOptions,
      activeOptions = [];

    activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];

    if (roles && roles.data !== undefined) {
      roleOptions = roles.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Usuário</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input
              name="name"
              placeholder="Nome do Usuário"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input
              name="email"
              type="email"
              placeholder="Email do usuário"
              value={email}
              onChange={this.handleChange}
            />
            {submitted && !email && (
              <Label basic color="red" pointing>
                Email é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Username</label>
            <input
              name="username"
              placeholder="Username"
              value={username}
              onChange={this.handleChange}
            />
            {submitted && !username && (
              <Label basic color="red" pointing>
                Username é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Senha</label>
            <input
              name="password"
              type="password"
              placeholder="Senha do usuário"
              value={password}
              onChange={this.handleChange}
            />
            {submitted && password && password.length < 6 && (
              <Label basic color="red" pointing>
                Senha deve ter no mínimo 6 caracteres
              </Label>
            )}
          </Form.Field>
          <Label basic pointing>
            Deixe em branco para manter a senha atual
          </Label>
          <Form.Field>
            <label>Perfil</label>
            <Dropdown
              placeholder="Perfil"
              fluid
              search
              selection
              name="role_id"
              options={roleOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={role_id}
            />
            {submitted && !role_id && (
              <Label basic color="red" pointing>
                Perfil é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
            <Label basic pointing>
              Deixe em branco para usuários internos
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              name="active"
              options={activeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={active}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { users, clients } = state;
  return {
    users: users.item,
    loading: users.loading,
    clients,
    roles: users.roles,
  };
}

const connectedUserEditScreen = connect(mapStateToProps)(UserEditScreen);
export { connectedUserEditScreen as UserEditScreen };
