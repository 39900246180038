import { financesReceivablesConstants } from '../constants/finances-receivables.constants';

export function financesReceivables(state = {}, action) {
  switch (action.type) {
    case financesReceivablesConstants.GETALLFINANCESRECEIVABLES_REQUEST:
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLES_REQUEST:
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLESTYPE_REQUEST:
    case financesReceivablesConstants.UPDATEFINANCESRECEIVABLESTYPE_REQUEST:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLESTYPE_REQUEST:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLES_REQUEST:
    case financesReceivablesConstants.GETFINANCESRECEIVABLESTYPE_REQUEST:
    case financesReceivablesConstants.GETMEFINANCESRECEIVABLES_REQUEST:
    case financesReceivablesConstants.GETTEAMFINANCESRECEIVABLES_REQUEST:
    case financesReceivablesConstants.GETTYPEFINANCESRECEIVABLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLESTYPE_SUCCESS:
    case financesReceivablesConstants.UPDATEFINANCESRECEIVABLESTYPE_SUCCESS:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLESTYPE_SUCCESS:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLES_SUCCESS:
    case financesReceivablesConstants.GETFINANCESRECEIVABLESTYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLES_SUCCESS:
    case financesReceivablesConstants.GETFINANCESRECEIVABLES_SUCCESS:
      return {
        ...state,
        item: action.FINANCESRECEIVABLES,
        loading: false,
      };
    case financesReceivablesConstants.GETALLFINANCESRECEIVABLES_SUCCESS:
    case financesReceivablesConstants.GETMEFINANCESRECEIVABLES_SUCCESS:
    case financesReceivablesConstants.GETTEAMFINANCESRECEIVABLES_SUCCESS:
      return {
        ...state,
        ...action.FINANCESRECEIVABLES,
        loading: false,
      };
    case financesReceivablesConstants.GETTYPEFINANCESRECEIVABLES_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case financesReceivablesConstants.GETCENTERFINANCESRECEIVABLES_SUCCESS:
      return {
        ...state,
        centers: action.centers,
        loading: false,
      };
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLES_FAILURE:
    case financesReceivablesConstants.CREATEFINANCESRECEIVABLESTYPE_FAILURE:
    case financesReceivablesConstants.GETALLFINANCESRECEIVABLES_FAILURE:
    case financesReceivablesConstants.GETFINANCESRECEIVABLESTYPE_FAILURE:
    case financesReceivablesConstants.UPDATEFINANCESRECEIVABLESTYPE_FAILURE:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLESTYPE_FAILURE:
    case financesReceivablesConstants.DELETEFINANCESRECEIVABLES_FAILURE:
    case financesReceivablesConstants.GETMEFINANCESRECEIVABLES_FAILURE:
    case financesReceivablesConstants.GETTEAMFINANCESRECEIVABLES_FAILURE:
    case financesReceivablesConstants.GETTYPEFINANCESRECEIVABLES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
