import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { financesAccountsActions } from '../../actions/finances-accounts.actions';

class FinancialAccountScreen extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(financesAccountsActions.getAll());
  }

  render() {
    const { financesAccounts, loading } = this.props;

    if (!financesAccounts.data || loading) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Contas Financeiras</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/finance/accounts/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`/finance/accounts/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;
            dispatch(financesAccountsActions.getAll(`page=${page}`));
          }}
          header={['Nome', 'Tipo', 'Status']}
          search={{
            name: 'Nome',
            'type.name': 'Tipo',
            active: 'Status',
          }}
          columns={[
            'name',
            'type.name',
            {
              name: 'status',
              format: (item) => (item.active ? 'Ativo' : 'Inativo'),
            },
          ]}
          data={financesAccounts.data}
          totalPages={financesAccounts.lastPage}
          page={financesAccounts.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { financesAccounts } = state;
  return {
    financesAccounts,
    loading: financesAccounts.loading || false,
  };
}

const connectedFinancialAccountScreen = connect(mapStateToProps)(FinancialAccountScreen);
export { connectedFinancialAccountScreen as FinancialAccountScreen };
