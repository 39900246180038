import { transactionCategoryConstants } from '../constants/transaction-category.constants';

export function transactionCategory(state = {}, action) {
  switch (action.type) {
    case transactionCategoryConstants.GETALLTRANSACTIONCATEGORY_REQUEST:
    case transactionCategoryConstants.CREATETRANSACTIONCATEGORY_REQUEST:
    case transactionCategoryConstants.GETALLTRANSACTIONCATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
        loading: false,
      };
    case transactionCategoryConstants.DELETETRANSACTIONCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case transactionCategoryConstants.DELETETRANSACTIONCATEGORY_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case transactionCategoryConstants.CREATETRANSACTIONCATEGORY_SUCCESS:
    case transactionCategoryConstants.GETTRANSACTIONCATEGORY_SUCCESS:
      return {
        ...state,
        item: action.TRANSACTIONCATEGORY,
        loading: false,
      };
    case transactionCategoryConstants.CREATETRANSACTIONCATEGORY_FAILURE:
    case transactionCategoryConstants.GETALLTRANSACTIONCATEGORY_FAILURE:
    case transactionCategoryConstants.DELETETRANSACTIONCATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
