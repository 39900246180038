import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Input, Loader, Label } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import './styles.css';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getUserMe());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { old_password, new_password, new_password_confirmation } = this.state;
    const { dispatch } = this.props;
    if (
      old_password &&
      new_password &&
      new_password_confirmation &&
      new_password === new_password_confirmation
    ) {
      dispatch(
        userActions.editMe({
          old_password,
          new_password,
          new_password_confirmation,
        }),
      );
    }
  }

  render() {
    const { me } = this.props;
    const { old_password, new_password, new_password_confirmation, submitted } = this.state;

    if (!me)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <h1>Meu Perfil</h1>
        <div className="container-inner">
          <h4>Nome: </h4> {me.name}
          <h4>Email: </h4> {me.email}
          <h4></h4>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <label>Senha Atual</label>
              <Input
                name="old_password"
                type="password"
                placeholder="Senha Atual"
                value={old_password}
                onChange={this.handleChange}
              />
              {submitted && !old_password && (
                <Label basic color="red" pointing>
                  Senha Atual é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Nova Senha</label>
              <Input
                name="new_password"
                type="password"
                placeholder="Nova Senha"
                value={new_password}
                onChange={this.handleChange}
              />
              {submitted && !new_password && (
                <Label basic color="red" pointing>
                  Nova Senha é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Confirmação Nova Senha</label>
              <Input
                name="new_password_confirmation"
                type="password"
                placeholder="Confirmação Nova Senha"
                value={new_password_confirmation}
                onChange={this.handleChange}
              />
              {submitted && !new_password_confirmation && (
                <Label basic color="red" pointing>
                  Confirmação Nova Senha é requerido
                </Label>
              )}
            </Form.Field>
            <Button color="secondary" type="submit">
              Atualizar
            </Button>
          </Form>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    me: users.me || null,
  };
}

const connectedProfileScreen = connect(mapStateToProps)(ProfileScreen);
export { connectedProfileScreen as ProfileScreen };
