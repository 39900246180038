export const licensesConstants = {
  GETALL_LICENSES_REQUEST: 'GETALL_LICENSES_REQUEST',
  GETALL_LICENSES_SUCCESS: 'GETALL_LICENSES_SUCCESS',
  GETALL_LICENSES_FAILURE: 'GETALL_LICENSES_FAILURE',

  GET_LICENSES_REQUEST: 'GET_LICENSES_REQUEST',
  GET_LICENSES_SUCCESS: 'GET_LICENSES_SUCCESS',
  GET_LICENSES_FAILURE: 'GET_LICENSES_FAILURE',

  GETBASE64_LICENSES_REQUEST: 'GETBASE64_LICENSES_REQUEST',
  GETBASE64_LICENSES_SUCCESS: 'GETBASE64_LICENSES_SUCCESS',
  GETBASE64_LICENSES_FAILURE: 'GETBASE64_LICENSES_FAILURE',

  CREATE_LICENSES_REQUEST: 'CREATE_LICENSES_REQUEST',
  CREATE_LICENSES_SUCCESS: 'CREATE_LICENSES_SUCCESS',
  CREATE_LICENSES_FAILURE: 'CREATE_LICENSES_FAILURE',

  DELETE_LICENSES_REQUEST: 'DELETE_LICENSES_REQUEST',
  DELETE_LICENSES_SUCCESS: 'DELETE_LICENSES_SUCCESS',
  DELETE_LICENSES_FAILURE: 'DELETE_LICENSES_FAILURE',
};
