import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { ordersActions } from '../../actions/orders.actions';
import { companyActions } from '../../actions/company.actions';
import { providersActions } from '../../actions/providers.actions';

class OrdersCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      user_id: [],
      billing_company_id: null,
      payment_method_id: null,
      provider_id: null,
      submitted: false,
      invoice: null,
      type: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
    dispatch(ordersActions.getAll());
    dispatch(ordersActions.getUser());
    dispatch(ordersActions.getPayment());
    dispatch(companyActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, user_id, billing_company_id, provider_id, payment_method_id, invoice, type } =
      this.state;
    const { dispatch } = this.props;

    if (name && user_id && payment_method_id && billing_company_id) {
      dispatch(
        ordersActions.create({
          name,
          user_id,
          billing_company_id,
          provider_id,
          payment_method_id,
          invoice,
          type,
        }),
      ).then(() => {
        this.props.history.push('/orders');
      });
    }
  }

  render() {
    const {
      name,
      user_id,
      billing_company_id,
      provider_id,
      payment_method_id,
      invoice,
      type,
      submitted,
    } = this.state;
    const { loading, providers, companies, users, payment } = this.props;
    let companyOptions,
      userOptions,
      paymentOptions,
      providersOptions = [];

    const typeOptions = [
      { key: 'I', value: 'I', text: 'Interno' },
      { key: 'E', value: 'E', text: 'Externo' },
    ];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (companies && companies.data !== undefined) {
      companyOptions = companies.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (payment && payment.data !== undefined) {
      paymentOptions = payment.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Criar Pedido</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              name="type"
              options={typeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type}
            />
            {submitted && !type && (
              <Label basic color="red" pointing>
                Tipo é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Nome</label>
            <input name="name" onChange={this.handleChange} placeholder="Nome" value={name} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Vendedor </label>
            <Dropdown
              placeholder="Vendedor "
              fluid
              search
              selection
              name="user_id"
              options={userOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={user_id}
            />
            {submitted && !user_id && (
              <Label basic color="red" pointing>
                Vendedor é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Fornecedor</label>
            <Dropdown
              placeholder="Fornecedor"
              fluid
              search
              selection
              name="provider_id"
              options={providersOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={provider_id}
            />
            {submitted && !provider_id && (
              <Label basic color="red" pointing>
                Fornecedor é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Método Pagamento</label>
            <Dropdown
              placeholder="Método Pagamento"
              fluid
              search
              selection
              name="payment_method_id"
              options={paymentOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={payment_method_id}
            />
            {submitted && !payment_method_id && (
              <Label basic color="red" pointing>
                Método Pagamento é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Empresa Faturamento</label>
            <Dropdown
              placeholder="Empresa Faturamento"
              fluid
              search
              selection
              name="billing_company_id"
              options={companyOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={billing_company_id}
            />
            {submitted && !billing_company_id && (
              <Label basic color="red" pointing>
                Empresa Faturamento é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Nota Fiscal</label>
            <input
              name="invoice"
              onChange={this.handleChange}
              placeholder="Nota Fiscal"
              value={invoice}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { orders, companies, providers } = state;

  return {
    orders,
    companies,
    providers,
    users: orders.users,
    payment: orders.payment,
    loading: orders?.loading || false,
  };
}

const connectedOrdersCreateScreen = connect(mapStateToProps)(OrdersCreateScreen);
export { connectedOrdersCreateScreen as OrdersCreateScreen };
