export const financesReceivablesConstants = {
  GETALLFINANCESRECEIVABLES_REQUEST: 'GETALLFINANCESRECEIVABLES_REQUEST',
  GETALLFINANCESRECEIVABLES_SUCCESS: 'GETALLFINANCESRECEIVABLES_SUCCESS',
  GETALLFINANCESRECEIVABLES_FAILURE: 'GETALLFINANCESRECEIVABLES_FAILURE',

  GETMEFINANCESRECEIVABLES_REQUEST: 'GETMEFINANCESRECEIVABLES_REQUEST',
  GETMEFINANCESRECEIVABLES_SUCCESS: 'GETMEFINANCESRECEIVABLES_SUCCESS',
  GETMEFINANCESRECEIVABLES_FAILURE: 'GETMEFINANCESRECEIVABLES_FAILURE',

  GETFINANCESRECEIVABLES_REQUEST: 'GETFINANCESRECEIVABLES_REQUEST',
  GETFINANCESRECEIVABLES_SUCCESS: 'GETFINANCESRECEIVABLES_SUCCESS',
  GETFINANCESRECEIVABLES_FAILURE: 'GETFINANCESRECEIVABLES_FAILURE',

  UPDATEFINANCESRECEIVABLES_REQUEST: 'UPDATEFINANCESRECEIVABLES_REQUEST',
  UPDATEFINANCESRECEIVABLES_SUCCESS: 'UPDATEFINANCESRECEIVABLES_SUCCESS',
  UPDATEFINANCESRECEIVABLES_FAILURE: 'UPDATEFINANCESRECEIVABLES_FAILURE',

  CREATEFINANCESRECEIVABLES_REQUEST: 'CREATEFINANCESRECEIVABLES_REQUEST',
  CREATEFINANCESRECEIVABLES_SUCCESS: 'CREATEFINANCESRECEIVABLES_SUCCESS',
  CREATEFINANCESRECEIVABLES_FAILURE: 'CREATEFINANCESRECEIVABLES_FAILURE',

  CREATEFINANCESRECEIVABLESTYPE_REQUEST: 'CREATEFINANCESRECEIVABLESTYPE_REQUEST',
  CREATEFINANCESRECEIVABLESTYPE_SUCCESS: 'CREATEFINANCESRECEIVABLESTYPE_SUCCESS',
  CREATEFINANCESRECEIVABLESTYPE_FAILURE: 'CREATEFINANCESRECEIVABLES_FAILURE',

  GETTYPEFINANCESRECEIVABLES_REQUEST: 'GETTYPEFINANCESRECEIVABLES_REQUEST',
  GETTYPEFINANCESRECEIVABLES_SUCCESS: 'GETTYPEFINANCESRECEIVABLES_SUCCESS',
  GETTYPEFINANCESRECEIVABLES_FAILURE: 'GETTYPEFINANCESRECEIVABLES_FAILURE',

  GETFINANCESRECEIVABLESTYPE_REQUEST: 'GETFINANCESRECEIVABLESTYPE_REQUEST',
  GETFINANCESRECEIVABLESTYPE_SUCCESS: 'GETFINANCESRECEIVABLESTYPE_SUCCESS',
  GETFINANCESRECEIVABLESTYPE_FAILURE: 'GETFINANCESRECEIVABLESTYPE_FAILURE',

  UPDATEFINANCESRECEIVABLESTYPE_REQUEST: 'UPDATEFINANCESRECEIVABLESTYPE_REQUEST',
  UPDATEFINANCESRECEIVABLESTYPE_SUCCESS: 'UPDATEFINANCESRECEIVABLESTYPE_SUCCESS',
  UPDATEFINANCESRECEIVABLESTYPE_FAILURE: 'UPDATEFINANCESRECEIVABLESTYPE_FAILURE',

  DELETEFINANCESRECEIVABLESTYPE_REQUEST: 'DELETEFINANCESRECEIVABLESTYPE_REQUEST',
  DELETEFINANCESRECEIVABLESTYPE_SUCCESS: 'DELETEFINANCESRECEIVABLESTYPE_SUCCESS',
  DELETEFINANCESRECEIVABLESTYPE_FAILURE: 'DELETEFINANCESRECEIVABLESTYPE_FAILURE',

  DELETEFINANCESRECEIVABLES_REQUEST: 'DELETEFINANCESRECEIVABLES_REQUEST',
  DELETEFINANCESRECEIVABLES_SUCCESS: 'DELETEFINANCESRECEIVABLES_SUCCESS',
  DELETEFINANCESRECEIVABLES_FAILURE: 'DELETEFINANCESRECEIVABLES_FAILURE',
};
