export const leadsConstants = {
  GETALL_LEADS_REQUEST: 'GETALL_LEADS_REQUEST',
  GETALL_LEADS_SUCCESS: 'GETALL_LEADS_SUCCESS',
  GETALL_LEADS_FAILURE: 'GETALL_LEADS_FAILURE',

  GET_LEADS_REQUEST: 'GET_LEADS_REQUEST',
  GET_LEADS_SUCCESS: 'GET_LEADS_SUCCESS',
  GET_LEADS_FAILURE: 'GET_LEADS_FAILURE',

  GET_LEADSSTATUS_REQUEST: 'GET_LEADSSTATUS_REQUEST',
  GET_LEADSSTATUS_SUCCESS: 'GET_LEADSSTATUS_SUCCESS',
  GET_LEADSSTATUS_FAILURE: 'GET_LEADSSTATUS_FAILURE',

  CREATE_LEADS_REQUEST: 'CREATE_LEADS_REQUEST',
  CREATE_LEADS_SUCCESS: 'CREATE_LEADS_SUCCESS',
  CREATE_LEADS_FAILURE: 'CREATE_LEADS_FAILURE',

  UPDATE_LEADS_REQUEST: 'UPDATE_LEADS_REQUEST',
  UPDATE_LEADS_SUCCESS: 'UPDATE_LEADS_SUCCESS',
  UPDATE_LEADS_FAILURE: 'UPDATE_LEADS_FAILURE',

  DELETE_LEADS_REQUEST: 'DELETE_LEADS_REQUEST',
  DELETE_LEADS_SUCCESS: 'DELETE_LEADS_SUCCESS',
  DELETE_LEADS_FAILURE: 'DELETE_LEADS_FAILURE',
};
