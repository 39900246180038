import { ordersConstants } from '../constants/orders.constants';
import ordersService from '../services/orders.service';
import { alertActions } from './alert.actions';

export const ordersActions = {
  create,
  createParticipant,
  getParticipantsType,
  update,
  updateStatus,
  getStatus,
  updateParticipant,
  deleteById,
  deleteParticipantById,
  getAll,
  getById,
  getParticipantById,
  timer,
  getParticipants,
  createInteraction,
  getInteraction,
  getUser,
  getPayment,
  createItems,
  getItems,
  getByIdItems,
  createAttachment,
  getAttachment,
  getEmailText,
  sendOrderEmail,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.create(obj);

      dispatch(success(orders));
      dispatch(alertActions.success('Pedido Criado'));
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return {
      type: ordersConstants.CREATEORDERS_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.CREATEORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.CREATEORDERS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const orders = await ordersService.deleteById(id);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(orders) {
    return {
      type: ordersConstants.DELETEORDERS_REQUEST,
      orders,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.DELETEORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.DELETEORDERS_FAILURE,
      error,
    };
  }
}

function deleteParticipantById(orderId, id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const orders = await ordersService.deleteParticipantById(id, orderId);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(orders) {
    return {
      type: ordersConstants.DELETEORDERSPARTICIPANT_REQUEST,
      orders,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.DELETEORDERSPARTICIPANT_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.DELETEORDERSPARTICIPANT_FAILURE,
      error,
    };
  }
}

function createAttachment(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const attachment = await ordersService.createAttachment(id, obj);

      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: ordersConstants.CREATE_ATTACHMENT_ORDERS_REQUEST };
  }
  function success(attachment) {
    return {
      type: ordersConstants.CREATE_ATTACHMENT_ORDERS_SUCCESS,
      attachment,
    };
  }
  function failure(error) {
    return { type: ordersConstants.CREATE_ATTACHMENT_ORDERS_FAILURE, error };
  }
}

function createParticipant(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const participant = await ordersService.createParticipant(id, obj);

      dispatch(success(participant));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: ordersConstants.CREATEORDERSPARTICIPANT_REQUEST,
    };
  }

  function success(participant) {
    return {
      type: ordersConstants.CREATEORDERSPARTICIPANT_SUCCESS,
      participant,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.CREATEORDERSPARTICIPANT_FAILURE,
      error,
    };
  }
}

function getStatus() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const statusOrder = await ordersService.getStatus();
      dispatch(success(statusOrder));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GET_ORDERSSTATUS_REQUEST,
    };
  }

  function success(statusOrder) {
    return {
      type: ordersConstants.GET_ORDERSSTATUS_SUCCESS,
      statusOrder,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GET_ORDERSSTATUS_FAILURE,
      error,
    };
  }
}

function getParticipantsType(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const types = await ordersService.getParticipantsType(filter);
      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETORDERSPARTICIPANTTYPE_REQUEST,
    };
  }

  function success(types) {
    return {
      type: ordersConstants.GETORDERSPARTICIPANTTYPE_SUCCESS,
      types,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETORDERSPARTICIPANTTYPE_FAILURE,
      error,
    };
  }
}

function getAttachment(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const attachment = await ordersService.getAttachment(id, params);
      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ordersConstants.GET_ATTACHMENT_ORDERS_REQUEST };
  }
  function success(attachment) {
    return { type: ordersConstants.GET_ATTACHMENT_ORDERS_SUCCESS, attachment };
  }
  function failure(error) {
    return { type: ordersConstants.GET_ATTACHMENT_ORDERS_FAILURE, error };
  }
}

function getItems(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const items = await ordersService.getItems(id, params);
      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GET_ORDERSITEMS_REQUEST,
    };
  }

  function success(items) {
    return {
      type: ordersConstants.GET_ORDERSITEMS_SUCCESS,
      items,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GET_ORDERSITEMS_FAILURE,
      error,
    };
  }
}

function getByIdItems(order_id, id) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const orderItem = await ordersService.getByIdItems(order_id, id);
      dispatch(success(orderItem));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETID_ORDERSITEMS_REQUEST,
    };
  }

  function success(orderItem) {
    return {
      type: ordersConstants.GETID_ORDERSITEMS_SUCCESS,
      orderItem,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETID_ORDERSITEMS_FAILURE,
      error,
    };
  }
}

function createItems(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const items = await ordersService.createItems(id, obj);

      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: ordersConstants.CREATEORDERSITEMS_REQUEST,
    };
  }

  function success(items) {
    return {
      type: ordersConstants.CREATEORDERSITEMS_SUCCESS,
      items,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.CREATEORDERSITEMS_FAILURE,
      error,
    };
  }
}

function createInteraction(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interaction = await ordersService.createInteraction(id, obj);

      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: ordersConstants.CREATE_ORDERSINTERACTION_REQUEST,
    };
  }

  function success(interaction) {
    return {
      type: ordersConstants.CREATE_ORDERSINTERACTION_SUCCESS,
      interaction,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.CREATE_ORDERSINTERACTION_FAILURE,
      error,
    };
  }
}

function update(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.update(id, obj);

      dispatch(success(orders));
      dispatch(alertActions.success('Pedido Atualizado'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: ordersConstants.UPDATEORDERS_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.UPDATEORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.UPDATEORDERS_FAILURE,
      error,
    };
  }
}

function updateStatus(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const status = await ordersService.updateStatus(id, obj);

      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.UPDATEORDERSSTATUS_REQUEST,
    };
  }

  function success(status) {
    return {
      type: ordersConstants.UPDATEORDERSSTATUS_SUCCESS,
      status,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.UPDATEORDERSSTATUS_FAILURE,
      error,
    };
  }
}

function updateParticipant(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.updateParticipant(id, obj);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.UPDATEORDERSPARTICIPANT_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.UPDATEORDERSPARTICIPANT_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.UPDATEORDERSPARTICIPANT_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.getAll(filter);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETALLORDERS_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.GETALLORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETALLORDERS_FAILURE,
      error,
    };
  }
}

function getInteraction(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const interaction = await ordersService.getInteraction(id, params);
      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GET_ORDERSINTERACTION_REQUEST,
    };
  }

  function success(interaction) {
    return {
      type: ordersConstants.GET_ORDERSINTERACTION_SUCCESS,
      interaction,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GET_ORDERSINTERACTION_FAILURE,
      error,
    };
  }
}

function timer(start_date, end_date) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.timer(start_date, end_date);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETMEORDERS_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.GETMEORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETMEORDERS_FAILURE,
      error,
    };
  }
}

function getParticipants(orderId, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const participants = await ordersService.getParticipants(orderId, params);

      dispatch(success(participants));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETALLORDERSPARTICIPANT_REQUEST,
    };
  }

  function success(participants) {
    return {
      type: ordersConstants.GETALLORDERSPARTICIPANT_SUCCESS,
      participants,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETALLORDERSPARTICIPANT_FAILURE,
      error,
    };
  }
}

function getUser(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const users = await ordersService.getUser(filter);

      dispatch(success(users));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETUSERORDERS_REQUEST,
    };
  }

  function success(users) {
    return {
      type: ordersConstants.GETUSERORDERS_SUCCESS,
      users,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETUSERORDERS_FAILURE,
      error,
    };
  }
}

function getPayment(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const payment = await ordersService.getPayment(filter);

      dispatch(success(payment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETPAYMENTORDERS_REQUEST,
    };
  }

  function success(payment) {
    return {
      type: ordersConstants.GETPAYMENTORDERS_SUCCESS,
      payment,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETPAYMENTORDERS_FAILURE,
      error,
    };
  }
}

function getEmailText(orderId) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const orderEmailText = await ordersService.getOrSendEmailText(orderId);
      dispatch(success(orderEmailText));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GET_EMAILTEXTORDER_REQUEST,
    };
  }

  function success(orderEmailText) {
    return {
      type: ordersConstants.GET_EMAILTEXTORDER_SUCCESS,
      orderEmailText,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GET_EMAILTEXTORDER_FAILURE,
      error,
    };
  }
}

function sendOrderEmail(orderId, params) {
  return async (dispatch) => {
    try {
      const success = await ordersService.getOrSendEmailText(orderId, params);
      if (success) dispatch(alertActions.success('Email Enviado com Sucesso'));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await ordersService.getById(id);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: ordersConstants.GETORDERS_REQUEST,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.GETORDERS_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETORDERS_FAILURE,
      error,
    };
  }
}

function getParticipantById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const orders = await ordersService.getParticipantById(id);

      dispatch(success(orders));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(orders) {
    return {
      type: ordersConstants.GETORDERSPARTICIPANT_REQUEST,
      orders,
    };
  }

  function success(orders) {
    return {
      type: ordersConstants.GETORDERSPARTICIPANT_SUCCESS,
      orders,
    };
  }

  function failure(error) {
    return {
      type: ordersConstants.GETORDERSPARTICIPANT_FAILURE,
      error,
    };
  }
}
