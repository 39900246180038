import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader, Form } from 'semantic-ui-react';
import moment from 'moment';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { contractActions } from '../../actions/contract.actions';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class ContractScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: '',
    };
  }

  async componentDidMount() {
    let start = moment();
    let end = moment();

    start = start.startOf('month').format(DATE_FORMAT);
    end = end.endOf('month').format(DATE_FORMAT);
    await this.setState({ period: `${start} - ${end}` });
    await this.fetchContracts();
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => {
      var [start_date, end_date] = period.split(' - ');
      if (start_date && end_date) this.fetchContracts();
    });
  }

  fetchContracts() {
    const { dispatch } = this.props;
    const { period } = this.state;
    if (period) {
      var [start_date, end_date] = period.split(' - ');
      if (start_date && end_date) {
        start_date = `${moment(start_date, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
        end_date = `${moment(end_date, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      } else {
        start_date = null;
        end_date = null;
      }
    } else {
      start_date = null;
      end_date = null;
    }
    dispatch(
      contractActions.getAll({
        start_date,
        end_date,
      }),
    );
  }

  render() {
    const { contracts, loading } = this.props;
    const { period } = this.state;

    if (!contracts.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Contratos</h1>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/contracts/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>

        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Selecione o período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período (Contratos mensais)"
              closable={true}
              width={4}
              clearable
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />
          </Form.Group>
        </Form>

        <Loader active={loading} />

        <DynamicTable
          onClick={(item) => this.props.history.push(`contracts/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;
            dispatch(contractActions.getAll(`page=${page}`));
          }}
          header={[
            'Cliente',
            'Tipo',
            'Data Início',
            'Data Fim',
            'Quantidade Horas',
            'Horas Gastas',
            'Periodicidade',
          ]}
          search={{
            'client.name': 'Cliente',
            start_date: 'Data Início',
            finish_date: 'Data Fim',
            hours_qty: 'Quantidade Horas',
            period: 'Periodicidade',
          }}
          columns={[
            'client.name',
            'type.name',
            {
              name: 'start_date',
              format: (item) => moment(item.start_date).format('DD/MM/YYYY'),
            },
            {
              name: 'finish_date',
              format: (item) => moment(item.finish_date).format('DD/MM/YYYY'),
            },
            {
              name: 'hours_qty',
              format: (item) =>
                moment.duration(item.hours_qty, 'hour').format('HH:mm', { trim: false }),
            },
            {
              name: 'hours_spent',
              format: (item) =>
                moment.duration(item.hours_spent, 'hour').format('HH:mm', { trim: false }),
            },
            {
              name: 'period',
              format: (item) => {
                switch (item.period) {
                  case 'C':
                    return 'Contrato';
                  case 'A':
                    return 'Anual';
                  case 'M':
                    return 'Mensal';
                  default:
                    return '';
                }
              },
            },
          ]}
          data={contracts.data}
          totalPages={contracts.lastPage}
          page={contracts.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { contracts } = state;
  return {
    contracts,
    loading: contracts.loading || false,
  };
}

const connectedContractScreen = connect(mapStateToProps)(ContractScreen);
export { connectedContractScreen as ContractScreen };
