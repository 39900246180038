import { combineReducers } from 'redux';
import { activities } from './activities.reducer';
import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { clients } from './clients.reducer';
import { companies } from './companies.reducer';
import { contracts } from './contracts.reducer';
import { expenses } from './expenses.reducer';
import { financesAccounts } from './finances-accounts.reducer';
import { financesPayables } from './finances-payables.reducer';
import { financesLabel } from './finances-label.reducer';
import { mails } from './mails.reducer';
import { orders } from './orders.reducer';
import { pdf } from './pdf.reducer';
import { products } from './products.reducer';
import { productsModules } from './products-modules.reducer';
import { licenses } from './licenses.reducer';
import { projects } from './projects.reducer';
import { providers } from './providers.reducer';
import { reports } from './reports.reducer';
import { contact } from './contact.reducer';
import { teams } from './teams.reducer';
import { tickets } from './tickets.reducer';
import { users } from './users.reducer';
import { financesReceivables } from './finances-receivables.reducer';
import { opportunity } from './opportunity.reducer';
import { sales } from './sales.reducer';
import { payment } from './payment.reducer';
import { transaction } from './transaction.reducer';
import { search } from './search.reducer';
import { managers } from './managers.reducer';
import { financialCategory } from './financial-category.reducer';

import { leads } from './leads.reducer';
import { transactionCategory } from './transaction-category.reducer';

const rootReducer = combineReducers({
  activities,
  alert,
  authentication,
  clients,
  companies,
  contracts,
  search,
  expenses,
  financesAccounts,
  financialCategory,
  financesPayables,
  financesReceivables,
  transaction,
  transactionCategory,
  mails,
  orders,
  pdf,
  products,
  productsModules,
  licenses,
  projects,
  providers,
  reports,
  teams,
  tickets,
  users,
  opportunity,
  sales,
  payment,
  managers,
  leads,
  contact,
  financesLabel,
});

export default rootReducer;
