import { companyConstants } from '../constants/company.constants';
import companyService from '../services/company.service';
import { alertActions } from './alert.actions';

export const companyActions = {
  create,
  update,
  getAll,
  getById,
  deleteById,
  getOne,
  createOne,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const company = await companyService.create(obj);

      dispatch(success(company));
      history.push('/companies');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: companyConstants.CREATECOMPANY_REQUEST };
  }
  function success(company) {
    return { type: companyConstants.CREATECOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.CREATECOMPANY_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const company = await companyService.update(id, obj);

      dispatch(success(company));
      history.push('/companies');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: companyConstants.UPDATECOMPANY_REQUEST };
  }
  function success(company) {
    return { type: companyConstants.UPDATECOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.UPDATECOMPANY_FAILURE, error };
  }
}

function getAll() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const companies = await companyService.getAll();

      dispatch(success(companies));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: companyConstants.GETALLCOMPANY_REQUEST };
  }
  function success(companies) {
    return { type: companyConstants.GETALLCOMPANY_SUCCESS, companies };
  }
  function failure(error) {
    return { type: companyConstants.GETALLCOMPANY_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const company = await companyService.deleteById(id);

      dispatch(success(company));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(company) {
    return { type: companyConstants.DELETECOMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: companyConstants.DELETECOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.DELETECOMPANY_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const company = await companyService.getById(id);

      dispatch(success(company));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(company) {
    return { type: companyConstants.GETCOMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: companyConstants.GETCOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.GETCOMPANY_FAILURE, error };
  }
}

function getOne(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const company = await companyService.getById(id);

      dispatch(success(company));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(company) {
    return { type: companyConstants.GETONECOMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: companyConstants.GETONECOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.GETONECOMPANY_FAILURE, error };
  }
}

function createOne(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const company = await companyService.create(obj);

      dispatch(success(company));
      history.push('/companies');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: companyConstants.CREATEONECOMPANY_REQUEST };
  }
  function success(company) {
    return { type: companyConstants.CREATEONECOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.CREATEONECOMPANY_FAILURE, error };
  }
}
