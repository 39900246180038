import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { providersActions } from '../../actions/providers.actions';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';

class ModalCreateMappings extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      provider_id: null,
      code: null,
      date: null,
      expires_at: null,
      attachment_url: null,
      openCreateMappings: false,
      submitted: false,
      files: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onChangeUpload(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);
    formData.append('public', 1);
    formData.append('dir', 'opportunity/mappings');
    this.setState({ files: formData });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { opportunity, dispatch } = this.props;
    const { provider_id, code, date, expires_at, attachment_url } = this.state;
    if (provider_id && date && code) {
      let payload = {
        provider_id,
        code,
        date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        attachment_url,
        opportunity_id: opportunity.id,
      };
      expires_at
        ? (payload.expires_at = moment(expires_at, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        : null;

      if (this.state.files)
        await dispatch(opportunityActions.uploadFile(this.state.files)).then((res) => {
          const { file } = res;
          payload.attachment_url = file.url;
        });

      await dispatch(opportunityActions.createMappings(opportunity.id, payload)).then(() => {
        this.setState({
          submitted: false,
          openCreateMappings: false,
        });
        dispatch(opportunityActions.getMappings(opportunity.id));
        dispatch(opportunityActions.getInteraction(opportunity.id));
        this.onClose();
      });
    }
  };

  onClose() {
    const initialState = {
      provider_id: null,
      code: null,
      date: null,
      expires_at: null,
      attachment_url: null,
      openCreateMappings: false,
      submitted: false,
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openCreateMappings &&
      (this.props.openCreateMappings !== prevProps.openCreateMappings ||
        this.props.id != prevProps.id) &&
      !this.state.openCreateMappings
    ) {
      this.setState({ openCreateMappings: true });
    }
  }

  render() {
    const { provider_id, code, date, expires_at, attachment_url, openCreateMappings, submitted } =
      this.state;
    const { loading, providers } = this.props;
    let providersOptions = [];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="small" open={openCreateMappings} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Cadastro de Mapeamento</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Field>
              <label>Fornecedores</label>
              <Dropdown
                placeholder="Fornecedores"
                fluid
                search
                selection
                name="provider_id"
                options={providersOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={provider_id}
              />
              {submitted && !provider_id && (
                <Label basic color="red" pointing>
                  Fornecedor é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Group>
              <Form.Field width={10}>
                <label>Número</label>
                <input name="code" placeholder="Numero" value={code} onChange={this.handleChange} />
                {submitted && !code && (
                  <Label basic color="red" pointing>
                    Número é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={10}>
                <label>Data de Mapeamento</label>
                <DateInput
                  closable
                  clearable
                  name="date"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={date}
                  iconPosition="left"
                />
                {submitted && !date && (
                  <Label basic color="red" pointing>
                    Data é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginTop: '20px' }}>
              <Form.Field width={10}>
                <label>Data Expiração</label>
                <DateInput
                  closable
                  clearable
                  name="expires_at"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={expires_at}
                  iconPosition="left"
                />
              </Form.Field>
              <Form.Field width={10}>
                <label>Anexo</label>
                <input
                  style={{ padding: '7px 0px', border: 'none' }}
                  name="attachment_url"
                  type="file"
                  placeholder="Anexo"
                  value={attachment_url}
                  onChange={(e) => this.onChangeUpload(e)}
                />
              </Form.Field>
            </Form.Group>
            <Button primary type="submit" disabled={!provider_id && !code && !date ? true : false}>
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity, providers } = state;
  return {
    opportunity: opportunity.item,
    mappings: opportunity.mappings,
    providers,
    loading: opportunity.loading || false,
  };
}

const connectedModalCreateMappings = connect(mapStateToProps)(ModalCreateMappings);
export { connectedModalCreateMappings as ModalCreateMappings };
