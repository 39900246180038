import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Loader, Input, Grid, Button, GridRow, GridColumn } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { userActions } from '../../actions/user.actions';
import _ from 'lodash';
import { clientActions } from '../../actions/client.actions';

class UserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        client_id: null,
        active: '1',
        role_id: null,
        search: null,
      },
      refresh: false,
      page: 1,
      perPage: 20,
      sort: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value || null;

    this.setState({ ...filter }, () => this.getData());
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clientActions.getAll());
    dispatch(userActions.getRoles());
    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);

    this.getData();
  }

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  getData() {
    const { dispatch } = this.props;

    dispatch(userActions.getAll(this.filterParams())).then(() => {
      this.setState({ refresh: true });
    });
  }

  filterParams() {
    const { filter, page, sort, perPage } = this.state;

    const params = filter;
    params.page = page;
    params.sort = sort;
    params.perPage = perPage;

    return params;
  }

  render() {
    const { clients, users, roles, loading } = this.props;
    const { filter, refresh } = this.state;
    let clientOptions,
      roleOptions,
      activeOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }
    if (roles && roles.data !== undefined) {
      roleOptions = roles.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }
    activeOptions = [
      {
        key: 1,
        value: '1',
        text: 'Ativo',
      },
      {
        key: 2,
        value: '0',
        text: 'Inativo',
      },
    ];

    if (!users.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <h1>Usuários</h1>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
              <Button primary onClick={() => this.props.history.push('/users/create')}>
                Novo
              </Button>
            </Grid.Column>
          </Grid.Row>
          <GridRow>
            <GridColumn>
              <Input
                fluid
                icon="search"
                value={filter.search}
                onChange={(e, data) => this.handleSearch(data.value)}
                placeholder="Procurar..."
                loading={loading}
              />
            </GridColumn>
          </GridRow>
        </Grid>

        <Form>
          <Form.Group>
            <Form.Select
              name="active"
              label="Status"
              clearable
              search
              selection
              width={4}
              value={filter['active']}
              options={activeOptions}
              onChange={(e, data) => this.handleChange(data)}
            />
            <Form.Select
              name="users.client_id"
              label="Cliente"
              clearable
              search
              selection
              width={4}
              value={filter['users.client_id']}
              options={clientOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="users.role_id"
              label="Perfil"
              clearable
              search
              selection
              width={4}
              value={filter['users.role_id']}
              options={roleOptions}
              onChange={(e, data) => this.handleChange(data)}
            />
          </Form.Group>
        </Form>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`users/${item.id}/show`)}
            handleSort={(column) => {
              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}|desc`, sorted: 'desc', page: 1 }, () => {
                  this.getData();
                });
              } else {
                this.setState({ sort: `${column}|asc`, sorted: 'asc', page: 1 }, () => {
                  this.getData();
                });
              }
            }}
            refresh={refresh}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={['Nome', 'Email', 'Cliente', 'Perfil', 'Status']}
            columns={[
              'name',
              'email',
              {
                name: 'client',
                format: (item) => item.client?.name,
              },
              {
                name: 'role',
                format: (item) => item.role?.name,
              },
              {
                name: 'active',
                type: 'boolean',
              },
            ]}
            data={users.data}
            totalPages={users.lastPage}
            page={users.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { clients, users } = state;
  return {
    clients,
    users,
    roles: users.roles,
    loading: users.loading || false,
  };
}

const connectedUserScreen = connect(mapStateToProps)(UserScreen);
export { connectedUserScreen as UserScreen };
