export const financesAccountsConstants = {
  GETALLFINANCESACCOUNTS_REQUEST: 'GETALLFINANCESACCOUNTS_REQUEST',
  GETALLFINANCESACCOUNTS_SUCCESS: 'GETALLFINANCESACCOUNTS_SUCCESS',
  GETALLFINANCESACCOUNTS_FAILURE: 'GETALLFINANCESACCOUNTS_FAILURE',

  GETMEFINANCESACCOUNTS_REQUEST: 'GETMEFINANCESACCOUNTS_REQUEST',
  GETMEFINANCESACCOUNTS_SUCCESS: 'GETMEFINANCESACCOUNTS_SUCCESS',
  GETMEFINANCESACCOUNTS_FAILURE: 'GETMEFINANCESACCOUNTS_FAILURE',

  GETFINANCESACCOUNTS_REQUEST: 'GETFINANCESACCOUNTS_REQUEST',
  GETFINANCESACCOUNTS_SUCCESS: 'GETFINANCESACCOUNTS_SUCCESS',
  GETFINANCESACCOUNTS_FAILURE: 'GETFINANCESACCOUNTS_FAILURE',

  UPDATEFINANCESACCOUNTS_REQUEST: 'UPDATEFINANCESACCOUNTS_REQUEST',
  UPDATEFINANCESACCOUNTS_SUCCESS: 'UPDATEFINANCESACCOUNTS_SUCCESS',
  UPDATEFINANCESACCOUNTS_FAILURE: 'UPDATEFINANCESACCOUNTS_FAILURE',

  CREATEFINANCESACCOUNTS_REQUEST: 'CREATEFINANCESACCOUNTS_REQUEST',
  CREATEFINANCESACCOUNTS_SUCCESS: 'CREATEFINANCESACCOUNTS_SUCCESS',
  CREATEFINANCESACCOUNTS_FAILURE: 'CREATEFINANCESACCOUNTS_FAILURE',

  CREATEFINANCESACCOUNTSTYPE_REQUEST: 'CREATEFINANCESACCOUNTSTYPE_REQUEST',
  CREATEFINANCESACCOUNTSTYPE_SUCCESS: 'CREATEFINANCESACCOUNTSTYPE_SUCCESS',
  CREATEFINANCESACCOUNTSTYPE_FAILURE: 'CREATEFINANCESACCOUNTS_FAILURE',

  GETTYPEFINANCESACCOUNTS_REQUEST: 'GETTYPEFINANCESACCOUNTS_REQUEST',
  GETTYPEFINANCESACCOUNTS_SUCCESS: 'GETTYPEFINANCESACCOUNTS_SUCCESS',
  GETTYPEFINANCESACCOUNTS_FAILURE: 'GETTYPEFINANCESACCOUNTS_FAILURE',

  GETFINANCESACCOUNTSTYPE_REQUEST: 'GETFINANCESACCOUNTSTYPE_REQUEST',
  GETFINANCESACCOUNTSTYPE_SUCCESS: 'GETFINANCESACCOUNTSTYPE_SUCCESS',
  GETFINANCESACCOUNTSTYPE_FAILURE: 'GETFINANCESACCOUNTSTYPE_FAILURE',

  UPDATEFINANCESACCOUNTSTYPE_REQUEST: 'UPDATEFINANCESACCOUNTSTYPE_REQUEST',
  UPDATEFINANCESACCOUNTSTYPE_SUCCESS: 'UPDATEFINANCESACCOUNTSTYPE_SUCCESS',
  UPDATEFINANCESACCOUNTSTYPE_FAILURE: 'UPDATEFINANCESACCOUNTSTYPE_FAILURE',

  DELETEFINANCESACCOUNTSTYPE_REQUEST: 'DELETEFINANCESACCOUNTSTYPE_REQUEST',
  DELETEFINANCESACCOUNTSTYPE_SUCCESS: 'DELETEFINANCESACCOUNTSTYPE_SUCCESS',
  DELETEFINANCESACCOUNTSTYPE_FAILURE: 'DELETEFINANCESACCOUNTSTYPE_FAILURE',

  DELETEFINANCESACCOUNTS_REQUEST: 'DELETEFINANCESACCOUNTS_REQUEST',
  DELETEFINANCESACCOUNTS_SUCCESS: 'DELETEFINANCESACCOUNTS_SUCCESS',
  DELETEFINANCESACCOUNTS_FAILURE: 'DELETEFINANCESACCOUNTS_FAILURE',
};
