import client from '../helpers/HttpClient';

class reportService {
  async getActivities(params) {
    if (!params) {
      params = { sort: 'activities.timestamp_start:desc' };
    }

    try {
      const result = await client.get('activities', { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getActivitiesBank(params) {
    if (!params) {
      params = { sort: 'activities.timestamp_start:desc' };
    }

    try {
      const result = await client.get('activities/bank', { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getActivitiesReport(filter) {
    try {
      const result = await client.post('activities/report', filter);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getActivitiesPerUserReport(params) {
    try {
      const result = await client.get('activities/per-user', { params });
      return result.data;
    } catch (error) {
      const msg = (error && error.message) || error.response.statusText;
      return Promise.reject(msg);
    }
  }

  async getAll(obj) {
    try {
      const result = await client.post('activities/report', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getExpenses(filter) {
    try {
      const result = await client.post('expenses/report', filter);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new reportService();
