import { financesPayablesConstants } from '../constants/finances-payables.constants';

export function financesPayables(state = {}, action) {
  switch (action.type) {
    case financesPayablesConstants.GETALLFINANCESPAYABLES_REQUEST:
    case financesPayablesConstants.CREATEFINANCESPAYABLES_REQUEST:
    case financesPayablesConstants.CREATEFINANCESPAYABLESTYPE_REQUEST:
    case financesPayablesConstants.UPDATEFINANCESPAYABLESTYPE_REQUEST:
    case financesPayablesConstants.DELETEFINANCESPAYABLESTYPE_REQUEST:
    case financesPayablesConstants.DELETEFINANCESPAYABLES_REQUEST:
    case financesPayablesConstants.GETFINANCESPAYABLESTYPE_REQUEST:
    case financesPayablesConstants.GETMEFINANCESPAYABLES_REQUEST:
    case financesPayablesConstants.GETTEAMFINANCESPAYABLES_REQUEST:
    case financesPayablesConstants.GETTYPEFINANCESPAYABLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case financesPayablesConstants.CREATEFINANCESPAYABLESTYPE_SUCCESS:
    case financesPayablesConstants.UPDATEFINANCESPAYABLESTYPE_SUCCESS:
    case financesPayablesConstants.DELETEFINANCESPAYABLESTYPE_SUCCESS:
    case financesPayablesConstants.DELETEFINANCESPAYABLES_SUCCESS:
    case financesPayablesConstants.GETFINANCESPAYABLESTYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case financesPayablesConstants.CREATEFINANCESPAYABLES_SUCCESS:
    case financesPayablesConstants.GETFINANCESPAYABLES_SUCCESS:
      return {
        ...state,
        item: action.financesPayables,
        loading: false,
      };
    case financesPayablesConstants.GETALLFINANCESPAYABLES_SUCCESS:
    case financesPayablesConstants.GETMEFINANCESPAYABLES_SUCCESS:
    case financesPayablesConstants.GETTEAMFINANCESPAYABLES_SUCCESS:
      return {
        ...state,
        ...action.financesPayables,
        loading: false,
      };
    case financesPayablesConstants.GETTYPEFINANCESPAYABLES_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case financesPayablesConstants.GETCENTERFINANCESPAYABLES_SUCCESS:
      return {
        ...state,
        centers: action.centers,
        loading: false,
      };
    case financesPayablesConstants.CREATEFINANCESPAYABLES_FAILURE:
    case financesPayablesConstants.CREATEFINANCESPAYABLESTYPE_FAILURE:
    case financesPayablesConstants.GETALLFINANCESPAYABLES_FAILURE:
    case financesPayablesConstants.GETFINANCESPAYABLESTYPE_FAILURE:
    case financesPayablesConstants.UPDATEFINANCESPAYABLESTYPE_FAILURE:
    case financesPayablesConstants.DELETEFINANCESPAYABLESTYPE_FAILURE:
    case financesPayablesConstants.DELETEFINANCESPAYABLES_FAILURE:
    case financesPayablesConstants.GETMEFINANCESPAYABLES_FAILURE:
    case financesPayablesConstants.GETTEAMFINANCESPAYABLES_FAILURE:
    case financesPayablesConstants.GETTYPEFINANCESPAYABLES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
