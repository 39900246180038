import { productsConstants } from '../constants/products.constants';
import productsService from '../services/product.service';
import { alertActions } from './alert.actions';

export const productsActions = {
  create,
  createType,
  update,
  updateType,
  deleteById,
  deleteTypeById,
  getAll,
  getById,
  getTypeById,
  timer,
  getTypes,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.create(obj);

      dispatch(success(products));
      dispatch(alertActions.success('Produto Criado'));
      history.push('/products');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return {
      type: productsConstants.CREATEPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.CREATEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.CREATEPRODUCTS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const products = await productsService.deleteById(id);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(products) {
    return {
      type: productsConstants.DELETEPRODUCTS_REQUEST,
      products,
    };
  }

  function success(products) {
    return {
      type: productsConstants.DELETEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.DELETEPRODUCTS_FAILURE,
      error,
    };
  }
}

function deleteTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const products = await productsService.deleteTypeById(id);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(products) {
    return {
      type: productsConstants.DELETETYPEPRODUCTS_REQUEST,
      products,
    };
  }

  function success(products) {
    return {
      type: productsConstants.DELETETYPEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.DELETETYPEPRODUCTS_FAILURE,
      error,
    };
  }
}

function createType(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.createType(obj);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: productsConstants.CREATETYPEPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.CREATETYPEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.CREATETYPEPRODUCTS_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.update(id, obj);

      dispatch(success(products));
      dispatch(alertActions.success('Produto Atualizado'));
      history.push('/products');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: productsConstants.UPDATEPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.UPDATEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.UPDATEPRODUCTS_FAILURE,
      error,
    };
  }
}

function updateType(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.updateType(id, obj);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: productsConstants.UPDATETYPEPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.UPDATETYPEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.UPDATETYPEPRODUCTS_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.getAll(filter);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: productsConstants.GETALLPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.GETALLPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.GETALLPRODUCTS_FAILURE,
      error,
    };
  }
}

function timer(start_date, end_date) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const products = await productsService.timer(start_date, end_date);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: productsConstants.GETMEPRODUCTS_REQUEST,
    };
  }

  function success(products) {
    return {
      type: productsConstants.GETMEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.GETMEPRODUCTS_FAILURE,
      error,
    };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const types = await productsService.getTypes(filter);

      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: productsConstants.GETALLTYPEPRODUCTS_REQUEST,
    };
  }

  function success(types) {
    return {
      type: productsConstants.GETALLTYPEPRODUCTS_SUCCESS,
      types,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.GETALLTYPEPRODUCTS_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const products = await productsService.getById(id);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(products) {
    return {
      type: productsConstants.GETPRODUCTS_REQUEST,
      products,
    };
  }

  function success(products) {
    return {
      type: productsConstants.GETPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.GETPRODUCTS_FAILURE,
      error,
    };
  }
}

function getTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const products = await productsService.getTypeById(id);

      dispatch(success(products));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(products) {
    return {
      type: productsConstants.GETTYPEPRODUCTS_REQUEST,
      products,
    };
  }

  function success(products) {
    return {
      type: productsConstants.GETTYPEPRODUCTS_SUCCESS,
      products,
    };
  }

  function failure(error) {
    return {
      type: productsConstants.GETTYPEPRODUCTS_FAILURE,
      error,
    };
  }
}
