import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';

import { ordersActions } from '../../actions/orders.actions';
import { salesActions } from '../../actions/sales.actions';
import { clientActions } from '../../actions/client.actions';

class SalesEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      sales_force_id: '',
      status_id: null,
      author_id: null,
      client_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(clientActions.getAll());
    dispatch(ordersActions.getUser());
    dispatch(salesActions.getStatus());

    dispatch(salesActions.getById(match.params.id)).then(() => {
      const { sales } = this.props;

      this.setState({
        title: sales.title,
        description: sales.description,
        sales_force_id: sales.sales_force_id,
        status_id: sales.status_id,
        author_id: sales.author_id,
        client_id: sales.client_id,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { title, description, sales_force_id, status_id, author_id, client_id } = this.state;
    const { dispatch, match } = this.props;

    if (title && client_id && status_id && author_id && description) {
      dispatch(
        salesActions.update(
          match.params.id,
          {
            title,
            description,
            sales_force_id,
            status_id,
            author_id,
            client_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { title, description, sales_force_id, status_id, author_id, client_id, submitted } =
      this.state;
    const { clients, loading, status, users } = this.props;
    let clientOptions,
      statusOptions,
      userOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Venda</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Título</label>
            <input name="title" onChange={this.handleChange} placeholder="Título" value={title} />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Título é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Observações</label>
            <TextArea
              name="description"
              onChange={this.handleChange}
              placeholder="Observações"
              value={description}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Observações é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>ID SalesForce</label>
            <input
              name="sales_force_id"
              onChange={this.handleChange}
              placeholder="ID SalesForce"
              value={sales_force_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              clearable
              name="status_id"
              options={statusOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={status_id}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Vendedor</label>
            <Dropdown
              placeholder="Vendedor"
              fluid
              search
              selection
              name="author_id"
              options={userOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={author_id}
            />
            {submitted && !author_id && (
              <Label basic color="red" pointing>
                Vendedor é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
            {submitted && !client_id && (
              <Label basic color="red" pointing>
                Cliente é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { sales, clients, orders } = state;

  return {
    clients,
    users: orders.users,
    sales: sales.item,
    status: sales.status,
    loading: sales.loading || false,
  };
}

const connectedSalesEditScreen = connect(mapStateToProps)(SalesEditScreen);
export { connectedSalesEditScreen as SalesEditScreen };
