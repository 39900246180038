import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Icon, List, Loader, Popup, Table } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { activityActions } from '../../actions/activity.actions';
import { ModalExpense } from '../../components/Modals/ModalExpense';
import { ActivityCreate } from '../../components/ActivityCreate/ActivityCreate';
import './styles.css';
import _ from 'lodash';

class TimerScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start_date: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment().subtract().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      activity_id: null,
      project_text: null,
      working: false,
      loading: false,
      activityExpense: {},
      openExpense: false,
      time_manual_start: '',
      time_manual_end: '',
      closeProject: false,
      timestamp_start: null,
      timestamp_end: null,
      description: '',
      mode: 'auto',
      type_id: null,
      status_id: null,
      project_id: null,
      submitted: false,
      timeStart: 0,
      open: false,
      deleteId: null,
    };
  }

  handleCancel = () => this.setState({ open: false, deleteId: null });

  deleteShow = (id) => this.setState({ open: true, deleteId: id });

  deleteById() {
    const { dispatch } = this.props;
    const { deleteId, start_date, end_date } = this.state;

    dispatch(activityActions.deleteById(deleteId)).then(() => {
      dispatch(activityActions.timer(start_date, end_date));

      this.setState({
        open: false,
        deleteId: null,
      });
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { start_date, end_date } = this.state;

    dispatch(activityActions.timer(start_date, end_date)).then(() => {
      const { activities } = this.props;

      if (activities && activities.current_activity) {
        const activityTemp = activities.current_activity;

        dispatch(activityActions.getById(activityTemp.id)).then(() => {
          const { activity } = this.props;

          const ms = moment().diff(moment(activity.timestamp_start, 'YYYY-MM-DD HH:mm:ss'));
          let payload = {
            submitted: false,
            project_id: activity.project_id,
            mode: 'auto',
            type_id: activity.type_id,
            description: activity.description,
            timeStart: ms,
            working: true,
          };
          this.setState({ recreate: payload });
        });
      }
    });
  }

  loadMore() {
    const { dispatch } = this.props;
    let { start_date, end_date } = this.state;
    const new_start_date = moment(start_date).subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss');
    this.setState({ start_date: new_start_date });
    dispatch(activityActions.timer(new_start_date, end_date));
  }

  componentDidUpdate() {
    const { activities } = this.props;

    if (this.state.recreate && activities && !activities.current_activity) {
      this.setState({ recreate: null });
    }
  }

  initialState() {
    this.setState({
      activity_id: null,
      project_text: null,
      closeProject: false,
      working: false,
      openExpense: false,
      time_manual_start: null,
      time_manual_end: null,
      timestamp_start: null,
      timestamp_end: null,
      description: '',
      mode: 'auto',
      type_id: null,
      status_id: null,
      project_id: null,
      submitted: false,
      timeStart: 0,
      open: false,
      activityExpense: {},
      deleteId: null,
    });

    this.description = '';
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  reCreate(item) {
    const { dispatch } = this.props;
    dispatch(activityActions.getById(item.id)).then(() => {
      const { activity } = this.props;

      let payload = {
        submitted: false,
        project_id: activity.project_id,
        mode: 'auto',
        type_id: activity.type_id,
        description: activity.description,
        working: true,
      };

      dispatch(activityActions.create(payload)).then(() => {
        this.setState({ working: true });
        this.setState({ recreate: payload });
      });
    });
  }

  openListExpenses(item) {
    this.setState({ openExpense: true, activityExpense: item });
  }

  rowItemContent(activities) {
    return _.map(activities, (item) => {
      return (
        <Table.Row key={item.id}>
          <Table.Cell width={6} className="timer-list-description">
            <span title={item.description}>{item.description}</span>
          </Table.Cell>
          <Table.Cell width={2} className="timer-list-project">
            <span title={item.project}>
              <i>{item.project_code}</i> {item.project}
            </span>
          </Table.Cell>
          <Table.Cell width={2} className="timer-list-client">
            <span title={item.client}>{item.client}</span>
          </Table.Cell>
          <Table.Cell width={2} className="timer-list-type">
            <span title={item.type}>{item.type}</span>
          </Table.Cell>
          <Table.Cell width={2} className="timer-list-time">
            {moment(item.timestamp_start).format('HH:mm')} -{' '}
            {moment(item.timestamp_end).format('HH:mm')}
          </Table.Cell>
          <Table.Cell width={2} className="timer-list-action">
            <Button icon onClick={() => this.openListExpenses(item)}>
              <Icon name="dollar" />
            </Button>
            <Button icon onClick={() => this.reCreate(item)}>
              <Icon name="play" />
            </Button>
            <Popup
              trigger={
                <Button icon>
                  <Icon name="ellipsis vertical" />
                </Button>
              }
              content={
                <List animated verticalAlign="middle">
                  <List.Item
                    className="item-link"
                    onClick={() => this.props.history.push(`/activities/${item.id}/edit`)}>
                    <List.Icon name="pencil" />
                    <List.Content>Editar</List.Content>
                  </List.Item>

                  <List.Item
                    className="item-link color-red"
                    onClick={() => {
                      if (!item.locked) this.deleteShow(item.id);
                    }}>
                    <List.Icon name="trash" />
                    <List.Content className="error">Remover</List.Content>
                  </List.Item>
                </List>
              }
              on="click"
              position="left center"
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  rowContent(activities) {
    if (!activities.activities) {
      return null;
    }

    if (!activities.activities.length) {
      return <h4>Nenhum Resultado Encontrado</h4>;
    }

    return Array.from(activities.activities, (item) => (
      <Fragment key={item.date}>
        <Grid columns={2}>
          <Grid.Row className="timer-date">
            <Grid.Column className="label-info">
              {moment(item.date).format('dddd, DD MMMM')}
            </Grid.Column>
            <Grid.Column textAlign="right" className="label-info">
              {moment.duration(item.total_time, 'ms').format('HH:mm', { trim: false })}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table fixed>
          <Table.Body>{this.rowItemContent(item.activities)}</Table.Body>
        </Table>
      </Fragment>
    ));
  }

  render() {
    const { open, openExpense, activityExpense, loading, start_date, end_date } = this.state;

    let { activities } = this.props;

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />

        <ModalExpense openExpense={openExpense} activityExpense={activityExpense} />
        <ActivityCreate recreate={this.state.recreate} startDate={start_date} endDate={end_date} />

        <Loader active={loading} />
        {this.rowContent(activities)}
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <p>
            <small>
              Mostrando atividades de {moment(start_date).format('DD/MM/YYYY')} até{' '}
              {moment(end_date).format('DD/MM/YYYY')}
            </small>
          </p>
          <Button size={'small'} secondary onClick={() => this.loadMore()}>
            Ver Mais
          </Button>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { activities, projects } = state;
  return {
    activities,
    types: activities.types,
    activity: activities.item || null,
    projects,
    projectsAll: projects,
    project: projects.item || null,
    status: projects.status || null,
  };
}

const connectedTimerScreen = connect(mapStateToProps)(TimerScreen);
export { connectedTimerScreen as TimerScreen };
