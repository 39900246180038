export const contactConstants = {
  CREATECONTACT_REQUEST: 'CREATECONTACT_REQUEST',
  CREATECONTACT_SUCCESS: 'CREATECONTACT_SUCCESS',
  CREATECONTACT_FAILURE: 'CREATECONTACT_FAILURE',

  GETALLCONTACT_REQUEST: 'GETALLCONTACT_REQUEST',
  GETALLCONTACT_SUCCESS: 'GETALLCONTACT_SUCCESS',
  GETALLCONTACT_FAILURE: 'GETALLCONTACT_FAILURE',

  UPDATECONTACT_REQUEST: 'UPDATECONTACT_REQUEST',
  UPDATECONTACT_SUCCESS: 'UPDATECONTACT_SUCCESS',
  UPDATECONTACT_FAILURE: 'UPDATECONTACT_FAILURE',

  GETCONTACT_REQUEST: 'GETCONTACT_REQUEST',
  GETCONTACT_SUCCESS: 'GETCONTACT_SUCCESS',
  GETCONTACT_FAILURE: 'GETCONTACT_FAILURE',

  DELETECONTACT_REQUEST: 'DELETECONTACT_REQUEST',
  DELETECONTACT_SUCCESS: 'DELETECONTACT_SUCCESS',
  DELETECONTACT_FAILURE: 'DELETECONTACT_FAILURE',
};
