import { leadsConstants } from '../constants/leads.constants';
import leadsService from '../services/leads.service';
import { alertActions } from './alert.actions';

export const leadsActions = {
  create,
  update,
  deleteById,
  getAll,
  getById,
  getStatus,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const leads = await leadsService.create(obj);

      dispatch(success(leads));
      dispatch(alertActions.success('Lead Criado'));
      history.push('/leads');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return {
      type: leadsConstants.CREATE_LEADS_REQUEST,
    };
  }

  function success(leads) {
    return {
      type: leadsConstants.CREATE_LEADS_SUCCESS,
      leads,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.CREATE_LEADS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const leads = await leadsService.deleteById(id);

      dispatch(success(leads));
      dispatch(alertActions.success('Lead Removido'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(leads) {
    return {
      type: leadsConstants.DELETE_LEADS_REQUEST,
      leads,
    };
  }

  function success(leads) {
    return {
      type: leadsConstants.DELETE_LEADS_SUCCESS,
      leads,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.DELETE_LEADS_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const leads = await leadsService.update(id, obj);
      dispatch(success(leads));
      dispatch(alertActions.success('Lead Atualizado'));
      history.push('/leads');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: leadsConstants.UPDATE_LEADS_REQUEST,
    };
  }

  function success(leads) {
    return {
      type: leadsConstants.UPDATE_LEADS_SUCCESS,
      leads,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.UPDATE_LEADS_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const leads = await leadsService.getAll(filter);

      dispatch(success(leads));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: leadsConstants.GETALL_LEADS_REQUEST,
    };
  }

  function success(leads) {
    return {
      type: leadsConstants.GETALL_LEADS_SUCCESS,
      leads,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.GETALL_LEADS_FAILURE,
      error,
    };
  }
}

function getStatus(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const status = await leadsService.getStatus(filter);

      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: leadsConstants.GET_LEADSSTATUS_REQUEST,
    };
  }

  function success(status) {
    return {
      type: leadsConstants.GET_LEADSSTATUS_SUCCESS,
      status,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.GET_LEADSSTATUS_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const leads = await leadsService.getById(id);

      dispatch(success(leads));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: leadsConstants.GET_LEADS_REQUEST,
    };
  }

  function success(leads) {
    return {
      type: leadsConstants.GET_LEADS_SUCCESS,
      leads,
    };
  }

  function failure(error) {
    return {
      type: leadsConstants.GET_LEADS_FAILURE,
      error,
    };
  }
}
