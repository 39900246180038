export const teamConstants = {
  GETALLTEAM_REQUEST: 'GETALLTEAM_REQUEST',
  GETALLTEAM_SUCCESS: 'GETALLTEAM_SUCCESS',
  GETALLTEAM_FAILURE: 'GETALLTEAM_FAILURE',

  GETTEAM_REQUEST: 'GETTEAM_REQUEST',
  GETTEAM_SUCCESS: 'GETTEAM_SUCCESS',
  GETTEAM_FAILURE: 'GETTEAM_FAILURE',

  CREATETEAM_REQUEST: 'CREATETEAM_REQUEST',
  CREATETEAM_SUCCESS: 'CREATETEAM_SUCCESS',
  CREATETEAM_FAILURE: 'CREATETEAM_FAILURE',

  UPDATETEAM_REQUEST: 'UPDATETEAM_REQUEST',
  UPDATETEAM_SUCCESS: 'UPDATETEAM_SUCCESS',
  UPDATETEAM_FAILURE: 'UPDATETEAM_FAILURE',

  DELETETEAM_REQUEST: 'DELETETEAM_REQUEST',
  DELETETEAM_SUCCESS: 'DELETETEAM_SUCCESS',
  DELETETEAM_FAILURE: 'DELETETEAM_FAILURE',
};
