export const managersConstants = {
  GETALL_MANAGERS_REQUEST: 'GETALL_MANAGERS_REQUEST',
  GETALL_MANAGERS_SUCCESS: 'GETALL_MANAGERS_SUCCESS',
  GETALL_MANAGERS_FAILURE: 'GETALL_MANAGERS_FAILURE',

  GET_MANAGERS_REQUEST: 'GET_MANAGERS_REQUEST',
  GET_MANAGERS_SUCCESS: 'GET_MANAGERS_SUCCESS',
  GET_MANAGERS_FAILURE: 'GET_MANAGERS_FAILURE',

  GET_MANAGERSUSER_REQUEST: 'GET_MANAGERSUSER_REQUEST',
  GET_MANAGERSUSER_SUCCESS: 'GET_MANAGERSUSER_SUCCESS',
  GET_MANAGERSUSER_FAILURE: 'GET_MANAGERSUSER_FAILURE',

  UPDATE_MANAGERS_REQUEST: 'UPDATE_MANAGERS_REQUEST',
  UPDATE_MANAGERS_SUCCESS: 'UPDATE_MANAGERS_SUCCESS',
  UPDATE_MANAGERS_FAILURE: 'UPDATE_MANAGERS_FAILURE',

  CREATE_MANAGERS_REQUEST: 'CREATE_MANAGERS_REQUEST',
  CREATE_MANAGERS_SUCCESS: 'CREATE_MANAGERS_SUCCESS',
  CREATE_MANAGERS_FAILURE: 'CREATE_MANAGERS_FAILURE',

  DELETE_MANAGERS_REQUEST: 'DELETE_MANAGERS_REQUEST',
  DELETE_MANAGERS_SUCCESS: 'DELETE_MANAGERS_SUCCESS',
  DELETE_MANAGERS_FAILURE: 'DELETE_MANAGERS_FAILURE',
};
