import { paymentConstants } from '../constants/payment.constants';

export function payment(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GETALL_PAYMENT_REQUEST:
    case paymentConstants.CREATE_PAYMENT_REQUEST:
    case paymentConstants.DELETE_PAYMENT_REQUEST:
    case paymentConstants.UPDATE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case paymentConstants.CREATE_PAYMENT_SUCCESS:
    case paymentConstants.GET_PAYMENT_SUCCESS:
    case paymentConstants.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        item: action.payment,
        loading: false,
      };
    case paymentConstants.GETALL_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payment,
        loading: false,
      };
    case paymentConstants.GETALL_PAYMENT_FAILURE:
    case paymentConstants.CREATE_PAYMENT_FAILURE:
    case paymentConstants.DELETE_PAYMENT_FAILURE:
    case paymentConstants.UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
