import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';
import { clientActions } from '../../actions/client.actions';
import './styles.css';
import { AddressForm } from '../../components/AddressForm/AddressForm';
import { maskCnpj } from '../../helpers/mask-cnpj';
import { cnpjValidation } from '../../helpers/cnpj-validation';

class ClientEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      active: 1,
      trading_name: '',
      cnpj: null,
      notes: '',
      email_domains: '',
      submitted: false,
    };

    this.addressForm = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCnpj = this.handleCnpj.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(clientActions.getById(match.params.id)).then(() => {
      const { clients } = this.props;
      this.setState({
        name: clients.name,
        trading_name: clients.trading_name,
        address: clients.address,
        active: clients.active,
        cnpj: clients.cnpj,
        email_domains: clients.email_domains,
        state_registration_number: clients.state_registration_number,
        city_registration_number: clients.city_registration_number,
        notes: clients.notes,
      });
      this.addressForm.current.setAddress(clients.address);
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      name,
      trading_name,
      cnpj,
      active,
      notes,
      email_domains,
      state_registration_number,
      city_registration_number,
    } = this.state;
    const { dispatch, match } = this.props;

    if (!cnpjValidation(cnpj, false)) {
      return;
    }

    const address = await this.addressForm.current.save();
    if (name) {
      dispatch(
        clientActions.update(
          match.params.id,
          {
            name,
            trading_name,
            state_registration_number,
            city_registration_number,
            cnpj,
            email_domains,
            notes,
            main_address_id: address.id,
            active,
          },
          this.props.history,
        ),
      );
    }
  }

  handleCnpj(e) {
    this.setState({ cnpj: maskCnpj(e.target.value) });
  }

  render() {
    const {
      name,
      trading_name,
      cnpj,
      active,
      notes,
      state_registration_number,
      city_registration_number,
      email_domains,
      submitted,
    } = this.state;
    const { loading } = this.props;
    let activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];
    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Cliente</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input
              name="name"
              placeholder="Nome do Cliente"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Nome Fantasia</label>
            <input
              name="trading_name"
              placeholder="Nome Fantasia"
              value={trading_name}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>CNPJ</label>
            <input
              name="cnpj"
              placeholder="00.000.000/0000-00"
              value={cnpj}
              maxLength="18"
              onChange={this.handleCnpj}
            />
            {!cnpjValidation(cnpj, false) && submitted && (
              <Label basic color="red" pointing>
                CNPJ inválido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Inscrição Estadual</label>
            <input
              name="state_registration_number"
              placeholder="Inscrição Estadual"
              value={state_registration_number}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Inscrição Municipal</label>
            <input
              name="city_registration_number"
              placeholder="Inscrição Municipal"
              value={city_registration_number}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Dominios E-Mail (separados por virgula)</label>
            <input
              name="email_domains"
              placeholder="Dominios"
              value={email_domains}
              onChange={this.handleChange}
            />
            <small>Os e-mails destes dominios serao automaticamente aceitos em tickets</small>
          </Form.Field>
          <AddressForm ref={this.addressForm} />
          <Form.Field>
            <label>Situação</label>
            <Dropdown
              placeholder="Situação"
              name="active"
              value={active}
              onChange={(event, data) => this.handleChange(true, data)}
              options={activeOptions}
            />
          </Form.Field>
          <Form.Field>
            <label>Observações</label>
            <TextArea
              style={{ height: '100px', width: '100%' }}
              placeholder="Observações"
              name="notes"
              maxLength="1024"
              onChange={this.handleChange}
              value={notes}
            />
          </Form.Field>

          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  return {
    clients,
    loading: clients.loading || false,
  };
}

const connectedClientEditScreen = connect(mapStateToProps)(ClientEditScreen);
export { connectedClientEditScreen as ClientEditScreen };
