export const mailsConstants = {
  GETALLMAILS_REQUEST: 'GETALLMAILS_REQUEST',
  GETALLMAILS_SUCCESS: 'GETALLMAILS_SUCCESS',
  GETALLMAILS_FAILURE: 'GETALLMAILS_FAILURE',

  GETMEMAILS_REQUEST: 'GETMEMAILS_REQUEST',
  GETMEMAILS_SUCCESS: 'GETMEMAILS_SUCCESS',
  GETMEMAILS_FAILURE: 'GETMEMAILS_FAILURE',

  GETMAILS_REQUEST: 'GETMAILS_REQUEST',
  GETMAILS_SUCCESS: 'GETMAILS_SUCCESS',
  GETMAILS_FAILURE: 'GETMAILS_FAILURE',

  UPDATEMAILS_REQUEST: 'UPDATEMAILS_REQUEST',
  UPDATEMAILS_SUCCESS: 'UPDATEMAILS_SUCCESS',
  UPDATEMAILS_FAILURE: 'UPDATEMAILS_FAILURE',
};
