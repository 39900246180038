export const activityConstants = {
  GETALLACTIVITY_REQUEST: 'GETALLACTIVITY_REQUEST',
  GETALLACTIVITY_SUCCESS: 'GETALLACTIVITY_SUCCESS',
  GETALLACTIVITY_FAILURE: 'GETALLACTIVITY_FAILURE',

  GETMEACTIVITY_REQUEST: 'GETMEACTIVITY_REQUEST',
  GETMEACTIVITY_SUCCESS: 'GETMEACTIVITY_SUCCESS',
  GETMEACTIVITY_FAILURE: 'GETMEACTIVITY_FAILURE',

  GETALLTYPEACTIVITY_REQUEST: 'GETALLTYPEACTIVITY_REQUEST',
  GETALLTYPEACTIVITY_SUCCESS: 'GETALLTYPEACTIVITY_SUCCESS',
  GETALLTYPEACTIVITY_FAILURE: 'GETALLTYPEACTIVITY_FAILURE',

  GETTYPEACTIVITY_REQUEST: 'GETTYPEACTIVITY_REQUEST',
  GETTYPEACTIVITY_SUCCESS: 'GETTYPEACTIVITY_SUCCESS',
  GETTYPEACTIVITY_FAILURE: 'GETTYPEACTIVITY_FAILURE',

  GETACTIVITY_REQUEST: 'GETACTIVITY_REQUEST',
  GETACTIVITY_SUCCESS: 'GETACTIVITY_SUCCESS',
  GETACTIVITY_FAILURE: 'GETACTIVITY_FAILURE',

  CREATEACTIVITY_REQUEST: 'CREATEACTIVITY_REQUEST',
  CREATEACTIVITY_SUCCESS: 'CREATEACTIVITY_SUCCESS',
  CREATEACTIVITY_FAILURE: 'CREATEACTIVITY_FAILURE',

  CREATETYPEACTIVITY_REQUEST: 'CREATETYPEACTIVITY_REQUEST',
  CREATETYPEACTIVITY_SUCCESS: 'CREATETYPEACTIVITY_SUCCESS',
  CREATETYPEACTIVITY_FAILURE: 'CREATETYPEACTIVITY_FAILURE',

  UPDATEACTIVITY_REQUEST: 'UPDATEACTIVITY_REQUEST',
  UPDATEACTIVITY_SUCCESS: 'UPDATEACTIVITY_SUCCESS',
  UPDATEACTIVITY_FAILURE: 'UPDATEACTIVITY_FAILURE',

  UPDATETYPEACTIVITY_REQUEST: 'UPDATETYPEACTIVITY_REQUEST',
  UPDATETYPEACTIVITY_SUCCESS: 'UPDATETYPEACTIVITY_SUCCESS',
  UPDATETYPEACTIVITY_FAILURE: 'UPDATETYPEACTIVITY_FAILURE',

  DELETEACTIVITY_REQUEST: 'DELETEACTIVITY_REQUEST',
  DELETEACTIVITY_SUCCESS: 'DELETEACTIVITY_SUCCESS',
  DELETEACTIVITY_FAILURE: 'DELETEACTIVITY_FAILURE',

  DELETETYPEACTIVITY_REQUEST: 'DELETETYPEACTIVITY_REQUEST',
  DELETETYPEACTIVITY_SUCCESS: 'DELETETYPEACTIVITY_SUCCESS',
  DELETETYPEACTIVITY_FAILURE: 'DELETEACTIVITYTYPE_FAILURE',

  GETALLROLES_REQUEST: 'GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'GETALLROLES_FAILURE',
};
