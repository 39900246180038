import { managersConstants } from '../constants/managers.constants';

export function managers(state = {}, action) {
  switch (action.type) {
    case managersConstants.GETALL_MANAGERS_REQUEST:
    case managersConstants.CREATE_MANAGERS_REQUEST:
    case managersConstants.DELETE_MANAGERS_REQUEST:
    case managersConstants.UPDATE_MANAGERS_REQUEST:
    case managersConstants.GET_MANAGERSUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case managersConstants.DELETE_MANAGERS_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case managersConstants.CREATE_MANAGERS_SUCCESS:
    case managersConstants.UPDATE_MANAGERS_SUCCESS:
      return {
        ...state,
        item: action.managers,
        loading: false,
      };
    case managersConstants.GET_MANAGERS_SUCCESS:
    case managersConstants.GETALL_MANAGERS_SUCCESS:
      return {
        ...state,
        ...action.managers,
        loading: false,
      };
    case managersConstants.GET_MANAGERSUSER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
      };
    case managersConstants.CREATE_MANAGERS_FAILURE:
    case managersConstants.GETALL_MANAGERS_FAILURE:
    case managersConstants.DELETE_MANAGERS_FAILURE:
    case managersConstants.UPDATE_MANAGERS_FAILURE:
    case managersConstants.GET_MANAGERSUSER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
