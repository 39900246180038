import { leadsConstants } from '../constants/leads.constants';

export function leads(state = {}, action) {
  switch (action.type) {
    case leadsConstants.GETALL_LEADS_REQUEST:
    case leadsConstants.CREATE_LEADS_REQUEST:
    case leadsConstants.UPDATE_LEADS_REQUEST:
    case leadsConstants.DELETE_LEADS_REQUEST:
    case leadsConstants.GET_LEADSSTATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadsConstants.DELETE_LEADS_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case leadsConstants.CREATE_LEADS_SUCCESS:
    case leadsConstants.UPDATE_LEADS_SUCCESS:
      return {
        ...state,
        item: action.leads,
        loading: false,
      };
    case leadsConstants.GET_LEADS_SUCCESS:
    case leadsConstants.GETALL_LEADS_SUCCESS:
      return {
        ...state,
        ...action.leads,
        loading: false,
      };
    case leadsConstants.GET_LEADSSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case leadsConstants.CREATE_LEADS_FAILURE:
    case leadsConstants.GETALL_LEADS_FAILURE:
    case leadsConstants.UPDATE_LEADS_FAILURE:
    case leadsConstants.DELETE_LEADS_FAILURE:
    case leadsConstants.GET_LEADSSTATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
