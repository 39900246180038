export const pdfConstants = {
  GETALLPDF_REQUEST: 'GETALLPDF_REQUEST',
  GETALLPDF_SUCCESS: 'GETALLPDF_SUCCESS',
  GETALLPDF_FAILURE: 'GETALLPDF_FAILURE',

  GETMEPDF_REQUEST: 'GETMEPDF_REQUEST',
  GETMEPDF_SUCCESS: 'GETMEPDF_SUCCESS',
  GETMEPDF_FAILURE: 'GETMEPDF_FAILURE',

  GETPDF_REQUEST: 'GETPDF_REQUEST',
  GETPDF_SUCCESS: 'GETPDF_SUCCESS',
  GETPDF_FAILURE: 'GETPDF_FAILURE',

  UPDATEPDF_REQUEST: 'UPDATEPDF_REQUEST',
  UPDATEPDF_SUCCESS: 'UPDATEPDF_SUCCESS',
  UPDATEPDF_FAILURE: 'UPDATEPDF_FAILURE',
};
