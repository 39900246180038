import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { managersActions } from '../../actions/managers.actions';

class ManagersShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      name: '',
      submitted: false,
    };
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(managersActions.getById(match.params.id));
  }

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(managersActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/managers'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/managers/${match.params.id}/edit`);
  }

  render() {
    const { managers, loading } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Loader active={loading} />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{managers.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" title="Editar" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" title="Remover" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <div className="container-inner">
          <h4>Nome: </h4>
          {managers.user?.name}
          <h4>Cliente: </h4>
          {managers.client?.name}
          <h4>Criado em: </h4>
          {moment(managers.created_at).format('DD/MM/YYYY') || '-'}
          <h4>Atualizado em: </h4>
          {moment(managers.updated_at).format('DD/MM/YYYY') || '-'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { managers } = state;
  return {
    managers,
    loading: managers.loading || false,
  };
}

const connectedManagersShowScreen = connect(mapStateToProps)(ManagersShowScreen);
export { connectedManagersShowScreen as ManagersShowScreen };
