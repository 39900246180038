export const currencyLabelHelper = (currency) => {
  switch (currency) {
    case 'BRL':
      return 'R$ ';

    case 'USD':
      return 'U$$ ';

    case 'EUR':
      return 'Є ';

    default:
      return 'R$ ';
  }
};
