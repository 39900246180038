import client from '../helpers/HttpClient';

class salesService {
  async update(id, obj) {
    try {
      const result = await client.put(`sales/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getItems(id, params) {
    try {
      const result = await client.get(`sale/${id}/items`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteItems(sale_id, id) {
    try {
      const result = await client.delete(`sale/${sale_id}/items/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateItems(sale_id, id, payload) {
    try {
      const result = await client.put(`sale/${sale_id}/items/${id}`, payload);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getByIdItems(sale_id, id, params) {
    try {
      const result = await client.get(`sale/${sale_id}/items/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatus(params) {
    try {
      const result = await client.get(`sale/status`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getOrders(id, params) {
    try {
      const result = await client.get(`sale/${id}/service-orders`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      let endpoint = 'sales';

      const result = await client.get(endpoint, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async synchronizeOpportunitiesFromSalesForce() {
    try {
      let endpoint = 'integration/salesforce/sync/opportunities';
      const result = await client.post(endpoint);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;
      return Promise.reject(returnMessage);
    }
  }

  async getById(id, params) {
    try {
      const result = await client.get(`sales/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async create(obj) {
    try {
      const result = await client.post('sales', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createItems(id, obj) {
    try {
      const result = await client.post(`sale/${id}/items`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createOrders(id, obj) {
    try {
      const result = await client.post(`service/orders`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`sales/${id}`);

      return result.data;
    } catch (error) {
      const { response } = error;
      if (
        response?.data?.code === 'ER_ROW_IS_REFERENCED_2' ||
        response?.data?.error?.message.includes('ER_ROW_IS_REFERENCED_2')
      ) {
        return Promise.reject(
          'Este registro não pode ser removido porque tem outros registros relacionados',
        );
      }

      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new salesService();
