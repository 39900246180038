import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { expenseActions } from '../../actions/expense.actions';
import CurrencyFormat from 'react-currency-format';

class ExpenseShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(expenseActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(expenseActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/expenses'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/expenses/${match.params.id}/edit`);
  }

  render() {
    const { item, loading } = this.props;
    const { open } = this.state;

    if (!item) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esta despesa?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page" loading={loading}>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{item.description}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <div className="container-inner">
          <h4>Tipo: </h4>
          {item.type ? item.type[0].name : ''}
          <h4>Descrição: </h4>
          {item.description}
          <h4>Projeto: </h4>
          {item.project ? item.project[0].name : '-'}
          <h4>Ticket: </h4>
          {item.ticket ? `#${item.ticket[0].id} - ${item.ticket[0].title}` : '-'}
          <h4>Data: </h4>
          {moment(item.date).format('DD/MM/YYYY')}
          <h4>Reembolsável: </h4>
          {item.refundable ? 'Sim' : 'Não'}
          <h4>{item.kilometer_fare ? 'Distância' : 'Valor'}: </h4>
          {item.kilometer_fare ? (
            item.amount
          ) : (
            <CurrencyFormat
              value={item.amount}
              displayType={'text'}
              decimalSeparator=","
              thousandSeparator="."
              prefix={'R$ '}
            />
          )}
          {item.activity && <h4>Atividade: </h4>} {item.activity ? item.activity.description : ''}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { expenses } = state;
  return {
    item: expenses.item || null,
    loading: expenses.loading || false,
  };
}

const connectedExpenseShowScreen = connect(mapStateToProps)(ExpenseShowScreen);
export { connectedExpenseShowScreen as ExpenseShowScreen };
