export const financesLabelConstants = {
  GETALLFINANCELABEL_REQUEST: 'GETALLFINANCELABEL_REQUEST',
  GETALLFINANCELABEL_SUCCESS: 'GETALLFINANCELABEL_SUCCESS',
  GETALLFINANCELABEL_FAILURE: 'GETALLFINANCELABEL_FAILURE',

  GETMEFINANCELABEL_REQUEST: 'GETMEFINANCELABEL_REQUEST',
  GETMEFINANCELABEL_SUCCESS: 'GETMEFINANCELABEL_SUCCESS',
  GETMEFINANCELABEL_FAILURE: 'GETMEFINANCELABEL_FAILURE',

  GETFINANCELABEL_REQUEST: 'GETFINANCELABEL_REQUEST',
  GETFINANCELABEL_SUCCESS: 'GETFINANCELABEL_SUCCESS',
  GETFINANCELABEL_FAILURE: 'GETFINANCELABEL_FAILURE',

  UPDATEFINANCELABEL_REQUEST: 'UPDATEFINANCELABEL_REQUEST',
  UPDATEFINANCELABEL_SUCCESS: 'UPDATEFINANCELABEL_SUCCESS',
  UPDATEFINANCELABEL_FAILURE: 'UPDATEFINANCELABEL_FAILURE',

  CREATEFINANCELABEL_REQUEST: 'CREATEFINANCELABEL_REQUEST',
  CREATEFINANCELABEL_SUCCESS: 'CREATEFINANCELABEL_SUCCESS',
  CREATEFINANCELABEL_FAILURE: 'CREATEFINANCELABEL_FAILURE',

  GETTYPEFINANCELABEL_REQUEST: 'GETTYPEFINANCELABEL_REQUEST',
  GETTYPEFINANCELABEL_SUCCESS: 'GETTYPEFINANCELABEL_SUCCESS',
  GETTYPEFINANCELABEL_FAILURE: 'GETTYPEFINANCELABEL_FAILURE',

  GETFINANCELABELTYPE_REQUEST: 'GETFINANCELABELTYPE_REQUEST',
  GETFINANCELABELTYPE_SUCCESS: 'GETFINANCELABELTYPE_SUCCESS',
  GETFINANCELABELTYPE_FAILURE: 'GETFINANCELABELTYPE_FAILURE',

  UPDATEFINANCELABELTYPE_REQUEST: 'UPDATEFINANCELABELTYPE_REQUEST',
  UPDATEFINANCELABELTYPE_SUCCESS: 'UPDATEFINANCELABELTYPE_SUCCESS',
  UPDATEFINANCELABELTYPE_FAILURE: 'UPDATEFINANCELABELTYPE_FAILURE',

  DELETEFINANCELABELTYPE_REQUEST: 'DELETEFINANCELABELTYPE_REQUEST',
  DELETEFINANCELABELTYPE_SUCCESS: 'DELETEFINANCELABELTYPE_SUCCESS',
  DELETEFINANCELABELTYPE_FAILURE: 'DELETEFINANCELABELTYPE_FAILURE',

  DELETEFINANCELABEL_REQUEST: 'DELETEFINANCELABEL_REQUEST',
  DELETEFINANCELABEL_SUCCESS: 'DELETEFINANCELABEL_SUCCESS',
  DELETEFINANCELABEL_FAILURE: 'DELETEFINANCELABEL_FAILURE',
};
