import { mailsConstants } from '../constants/mails.constants';

export function mails(state = {}, action) {
  switch (action.type) {
    case mailsConstants.GETALLMAILS_REQUEST:
    case mailsConstants.UPDATEMAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case mailsConstants.UPDATEMAILS_SUCCESS:
    case mailsConstants.GETMAILS_SUCCESS:
      return {
        ...state,
        item: action.mails,
        loading: false,
      };
    case mailsConstants.GETALLMAILS_SUCCESS:
    case mailsConstants.GETMEMAILS_SUCCESS:
      return {
        ...state,
        ...action.mails,
        loading: false,
      };
    case mailsConstants.UPDATEMAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
