import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, List } from 'semantic-ui-react';
import { ticketActions } from '../../actions/ticket.actions';

class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticketId: null,
      deleteTeamId: null,
      openConfirm: false,
    };
  }

  deleteTeams(ticket_id, id) {
    const { dispatch } = this.props;

    dispatch(ticketActions.deleteTeams(ticket_id, id)).then(() => {
      dispatch(ticketActions.getById(ticket_id)).then(() => {
        dispatch(ticketActions.getAllTeams(ticket_id));
        this.setState({
          ticketId: null,
          deleteTeamId: null,
          openConfirm: false,
        });
      });
    });
  }

  render() {
    const { teams } = this.props;

    const { openConfirm } = this.state;

    return (
      <Fragment>
        <Confirm
          key={'teams'}
          content="Deseja remover este time?"
          open={openConfirm}
          onCancel={() => this.setState({ openConfirm: false })}
          onConfirm={() => this.deleteTeams(this.state.ticketId, this.state.deleteTeamId)}
        />
        {teams && teams.total === 0 && (
          <p textAlign="center" style={{ textStyle: 'italic' }}>
            Nenhum time
          </p>
        )}
        {teams && teams.total > 0 && (
          <List divided verticalAlign="middle" style={{ maxHeight: '150px', overflow: 'auto' }}>
            {teams?.data?.map((teams) => (
              <List.Item key={teams.id}>
                <List.Content>{teams?.team?.name || '-'}</List.Content>
                <List.Content floated="right">
                  <Button
                    negative
                    size="small"
                    icon="close"
                    onClick={() =>
                      this.setState({
                        ticketId: teams.ticket_id,
                        deleteTeamId: teams.id,
                        openConfirm: true,
                      })
                    }
                  />
                </List.Content>
                <List.Description>{teams?.team?.description}</List.Description>
              </List.Item>
            ))}
          </List>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    tickets,
  };
}

const connectedTeams = connect(mapStateToProps)(Teams);
export { connectedTeams as Teams };
