import BaseService from './base.service';
import client from '../helpers/HttpClient';

class LicensesService extends BaseService {
  get basePath() {
    return 'licenses';
  }

  async getBase64ById(id) {
    try {
      const result = await client.get(`${this.basePath}/${id}/data`);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }
}

export default new LicensesService();
