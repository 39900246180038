import { licensesConstants } from '../constants/licenses.constants';
import licensesService from '../services/licenses.service';
import { alertActions } from './alert.actions';

export const licensesActions = {
  create,
  deleteById,
  getAll,
  getById,
  getBase64ById,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const licenses = await licensesService.create(obj);

      dispatch(success(licenses));
      dispatch(alertActions.success('Licença Criada'));
      history.push('/licenses');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return {
      type: licensesConstants.CREATE_LICENSES_REQUEST,
    };
  }

  function success(licenses) {
    return {
      type: licensesConstants.CREATE_LICENSES_SUCCESS,
      licenses,
    };
  }

  function failure(error) {
    return {
      type: licensesConstants.CREATE_LICENSES_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const licenses = await licensesService.deleteById(id);

      dispatch(success(licenses));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(licenses) {
    return {
      type: licensesConstants.DELETE_LICENSES_REQUEST,
      licenses,
    };
  }

  function success(licenses) {
    return {
      type: licensesConstants.DELETE_LICENSES_SUCCESS,
      licenses,
    };
  }

  function failure(error) {
    return {
      type: licensesConstants.DELETE_LICENSES_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const licenses = await licensesService.getAll(filter);

      dispatch(success(licenses));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: licensesConstants.GETALL_LICENSES_REQUEST,
    };
  }

  function success(licenses) {
    return {
      type: licensesConstants.GETALL_LICENSES_SUCCESS,
      licenses,
    };
  }

  function failure(error) {
    return {
      type: licensesConstants.GETALL_LICENSES_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const licenses = await licensesService.getById(id);

      dispatch(success(licenses));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(licenses) {
    return {
      type: licensesConstants.GET_LICENSES_REQUEST,
      licenses,
    };
  }

  function success(licenses) {
    return {
      type: licensesConstants.GET_LICENSES_SUCCESS,
      licenses,
    };
  }

  function failure(error) {
    return {
      type: licensesConstants.GET_LICENSES_FAILURE,
      error,
    };
  }
}

function getBase64ById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const base64 = await licensesService.getBase64ById(id);

      dispatch(success(base64));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(base64) {
    return {
      type: licensesConstants.GETBASE64_LICENSES_REQUEST,
      base64,
    };
  }

  function success(base64) {
    return {
      type: licensesConstants.GETBASE64_LICENSES_SUCCESS,
      base64,
    };
  }

  function failure(error) {
    return {
      type: licensesConstants.GETBASE64_LICENSES_FAILURE,
      error,
    };
  }
}
