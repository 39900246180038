import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import { mailsActions } from '../../actions/mails.actions';

class MailsEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      body: RichTextEditor.createEmptyValue(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(mailsActions.getById(match.params.id)).then(() => {
      const { mails } = this.props;

      this.setState({
        subject: mails.subject,
        body: RichTextEditor.createValueFromString(mails.body, 'html'),
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { subject, body } = this.state;
    const { dispatch, match } = this.props;
    if (subject && body) {
      dispatch(
        mailsActions.update(
          match.params.id,
          {
            subject,
            body: body.toString('html'),
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { subject, body, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar E-mail</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Assunto</label>
            <input
              name="subject"
              placeholder="Assunto"
              value={subject}
              onChange={this.handleChange}
            />
            {submitted && !subject && (
              <Label basic color="red" pointing>
                Assunto é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Corpo</label>
            <RichTextEditor
              value={body}
              onChange={(value) => this.handleChange(true, { name: 'body', value })}
            />
            {submitted && !body && (
              <Label basic color="red" pointing>
                Corpo é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { mails } = state;
  return {
    mails: mails.item,
    loading: mails.loading || false,
  };
}

const connectedMailsEditScreen = connect(mapStateToProps)(MailsEditScreen);
export { connectedMailsEditScreen as MailsEditScreen };
