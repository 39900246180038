import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { licensesActions } from '../../actions/licenses.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class LicensesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(licensesActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { licenses, loading } = this.props;
    const { refresh } = this.state;

    if (!licenses.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Licenças</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/licenses/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`/licenses/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(licensesActions.getAll(`page=${page}`));
            }}
            header={['Produto', 'Módulo', 'Cliente', 'Expiração', 'Qtd. Items']}
            search={{
              'product.name': 'Produto',
              'module.name': 'Módulo',
              'client.name': 'Cliente',
              expires_at: 'Expiração',
              max_itens: 'Qtd. Itens',
            }}
            columns={[
              'product.name',
              'module.name',
              'client.name',
              {
                name: 'expires_at',
                format: (item) =>
                  item.expires_at
                    ? moment(item.expires_at).format('DD/MM/YYYY', { trim: false })
                    : 'Perpétua',
              },
              'max_itens',
            ]}
            data={licenses.data}
            totalPages={licenses.lastPage}
            page={licenses.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { licenses } = state;
  return {
    licenses,
    loading: licenses.loading || false,
  };
}

const connectedLicensesScreen = connect(mapStateToProps)(LicensesScreen);
export { connectedLicensesScreen as LicensesScreen };
