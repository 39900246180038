import { teamConstants } from '../constants/team.constants';

export function teams(state = {}, action) {
  switch (action.type) {
    case teamConstants.GETALLTEAM_REQUEST:
    case teamConstants.CREATETEAM_REQUEST:
    case teamConstants.UPDATETEAM_REQUEST:
    case teamConstants.DELETETEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teamConstants.CREATETEAM_SUCCESS:
    case teamConstants.UPDATETEAM_SUCCESS:
    case teamConstants.DELETETEAM_SUCCESS:
    case teamConstants.GETTEAM_SUCCESS:
      return {
        ...state,
        item: action.team,
        loading: false,
      };
    case teamConstants.GETALLTEAM_SUCCESS:
    case teamConstants.GETMETEAM_SUCCESS:
      return {
        ...state,
        ...action.teams,
        loading: false,
      };
    case teamConstants.CREATETEAM_FAILURE:
    case teamConstants.GETALLTEAM_FAILURE:
    case teamConstants.DELETETEAM_FAILURE:
    case teamConstants.UPDATETEAM_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
