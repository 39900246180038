import { mailsConstants } from '../constants/mails.constants';
import mailsService from '../services/email-text.service';
import { alertActions } from './alert.actions';

export const mailsActions = {
  update,
  getAll,
  getById,
};

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const mails = await mailsService.update(id, obj);

      dispatch(success(mails));
      history.push('/mails');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: mailsConstants.UPDATEMAILS_REQUEST };
  }
  function success(mails) {
    return { type: mailsConstants.UPDATEMAILS_SUCCESS, mails };
  }
  function failure(error) {
    return { type: mailsConstants.UPDATEMAILS_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const mails = await mailsService.getAll(filter);

      dispatch(success(mails));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: mailsConstants.GETALLMAILS_REQUEST };
  }
  function success(mails) {
    return { type: mailsConstants.GETALLMAILS_SUCCESS, mails };
  }
  function failure(error) {
    return { type: mailsConstants.GETALLMAILS_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const mails = await mailsService.getById(id);

      dispatch(success(mails));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(mails) {
    return { type: mailsConstants.GETMAILS_REQUEST, mails };
  }
  function success(mails) {
    return { type: mailsConstants.GETMAILS_SUCCESS, mails };
  }
  function failure(error) {
    return { type: mailsConstants.GETMAILS_FAILURE, error };
  }
}
