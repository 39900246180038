import { pdfConstants } from '../constants/pdf.constants';

export function pdf(state = {}, action) {
  switch (action.type) {
    case pdfConstants.GETALLPDF_REQUEST:
    case pdfConstants.UPDATEPDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pdfConstants.UPDATEPDF_SUCCESS:
    case pdfConstants.GETPDF_SUCCESS:
      return {
        ...state,
        item: action.pdf,
        loading: false,
      };
    case pdfConstants.GETALLPDF_SUCCESS:
    case pdfConstants.GETMEPDF_SUCCESS:
      return {
        ...state,
        ...action.pdf,
        loading: false,
      };
    case pdfConstants.UPDATEPDF_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
