import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ticketActions } from '../../actions/ticket.actions';
import { userActions } from '../../actions/user.actions';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';

class ModalExecutors extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      user_id: null,
      openModalExecutor: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getCompanyUsers());
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { ticket_id, dispatch } = this.props;
    const { user_id } = this.state;

    if (user_id) {
      let payload = {
        user_id,
        ticket_id: ticket_id,
      };

      dispatch(ticketActions.createExecutors(ticket_id, payload)).then(() => {
        const { ticket_id } = this.props;
        dispatch(ticketActions.getAllExecutors(ticket_id));
        this.onClose();
        this.setState({
          user_id: '',
          ticket_id: ticket_id,
          submitted: false,
        });
        this.setState({
          refresh: true,
          page: 1,
        });
      });
    }
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onClose() {
    this.setState({ openModalExecutor: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openModalExecutor &&
      (this.props.openModalExecutor !== prevProps.openModalExecutor ||
        this.props.id != prevProps.id) &&
      !this.state.openModalExecutor
    ) {
      this.setState({ openModalExecutor: true });
    }
  }

  render() {
    const { submitted, openModalExecutor, user_id } = this.state;
    const { users } = this.props;

    let userOptions = [];

    if (users && users.companyUsers && users.companyUsers.data !== undefined) {
      userOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="small" open={openModalExecutor} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Adicionar Executor</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Dropdown
                name="user_id"
                onChange={(event, data) => this.handleChange(true, data)}
                placeholder="Executor"
                fluid
                search
                selection
                options={userOptions}
                value={user_id}
              />
              {submitted && !user_id && (
                <Label basic color="red" pointing>
                  Executor é requerido
                </Label>
              )}
            </Form.Field>
            <Button content="Adicionar" labelPosition="left" icon="edit" primary />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { users, executors } = state;
  return {
    users,
    executors,
  };
}

const connectedModalExecutors = connect(mapStateToProps)(ModalExecutors);
export { connectedModalExecutors as ModalExecutors };
