import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';

import addressService from '../../services/address.service';

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      zip_code: '',
      city: '',
      neighborhood: '',
      state: '',
      number: '',
      complement: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ ...this.props });
  }

  setAddress(address) {
    this.setState({ ...address });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async onBlurCep(event) {
    const { value } = event.target;
    const cep = value.replace(/\D/g, '');
    let checkCep = /^[0-9]{8}$/;

    if (!checkCep.test(cep)) {
      return;
    }
    const zip_code = cep.substring(0, 2) + '.' + cep.substring(2, 5) + '-' + cep.substring(5);
    await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          neighborhood: data.bairro,
          city: data.localidade,
          street: data.logradouro,
          state: data.uf,
          zip_code: zip_code,
        });
      });
  }

  async save() {
    const id = this.state.id;
    const data = {
      zip_code: this.state.zip_code,
      street: this.state.street,
      neighborhood: this.state.neighborhood,
      city: this.state.city,
      state: this.state.state,
      number: this.state.number,
      complement: this.state.complement,
    };

    if (id) {
      return addressService.update(id, data);
    } else {
      return addressService.create(data);
    }
  }

  render() {
    const { city, state, street, neighborhood, number, zip_code, complement } = this.state;

    return (
      <Grid style={{ marginBottom: 10 }}>
        <Grid.Column mobile={16} verticalAlign="middle">
          <Form>
            <Form.Field>
              <label>CEP</label>
              <input
                name="zip_code"
                value={zip_code}
                onClick={(e) => e.preventDefault()}
                onBlur={(event) => this.onBlurCep(event)}
                onChange={this.handleChange}
                placeholder="CEP"
              />
            </Form.Field>
            <Form.Field>
              <label>Rua</label>
              <input name="street" placeholder="Rua" value={street} onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Número</label>
              <input
                name="number"
                placeholder="Número"
                value={number}
                onChange={this.handleChange}
              />
            </Form.Field>

            <Form.Field>
              <label>Complemento</label>
              <input
                name="complement"
                placeholder="Complemento"
                value={complement}
                onChange={this.handleChange}
              />
            </Form.Field>

            <Form.Field>
              <label>Bairro</label>
              <input
                name="neighborhood"
                placeholder="Bairro"
                value={neighborhood}
                onChange={this.handleChange}
              />
            </Form.Field>

            <Form.Field>
              <label>Cidade</label>
              <input name="city" placeholder="Cidade" value={city} onChange={this.handleChange} />
            </Form.Field>

            <Form.Field>
              <label>Estado</label>
              <input name="state" placeholder="UF" value={state} onChange={this.handleChange} />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

AddressForm.propTypes = {};
export { AddressForm };
