import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ticketActions } from '../../actions/ticket.actions';
import { teamActions } from '../../actions/team.actions';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';

class ModalTeams extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      team_id: null,
      openModalTeam: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(teamActions.getAll());
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { ticket_id, dispatch } = this.props;
    const { team_id } = this.state;

    if (team_id) {
      let payload = {
        team_id,
        ticket_id: ticket_id,
      };

      dispatch(ticketActions.createTeams(ticket_id, payload)).then(() => {
        const { ticket_id } = this.props;
        dispatch(ticketActions.getAllTeams(ticket_id));
        this.onClose();
        this.setState({
          team_id: '',
          ticket_id: ticket_id,
          submitted: false,
        });
        this.setState({
          refresh: true,
          page: 1,
        });
      });
    }
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onClose() {
    this.setState({ openModalTeam: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openModalTeam &&
      (this.props.openModalTeam !== prevProps.openModalTeam || this.props.id != prevProps.id) &&
      !this.state.openModalTeam
    ) {
      this.setState({ openModalTeam: true });
    }
  }

  render() {
    const { submitted, openModalTeam, team_id } = this.state;
    const { teams } = this.props;

    let teamOptions = [];

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="small" open={openModalTeam} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Adicionar Time</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Dropdown
                name="team_id"
                onChange={(event, data) => this.handleChange(true, data)}
                placeholder="Time"
                fluid
                search
                selection
                options={teamOptions}
                value={team_id}
              />
              {submitted && !team_id && (
                <Label basic color="red" pointing>
                  Time é requerido
                </Label>
              )}
            </Form.Field>
            <Button content="Adicionar" labelPosition="left" icon="edit" primary />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { teams } = state;
  return {
    teams,
  };
}

const connectedModalTeams = connect(mapStateToProps)(ModalTeams);
export { connectedModalTeams as ModalTeams };
