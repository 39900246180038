import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';
import { AddressForm } from '../../components/AddressForm/AddressForm';
import { maskCnpj } from '../../helpers/mask-cnpj';
import { cnpjValidation } from '../../helpers/cnpj-validation';

class ProvidersEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      submitted: false,
    };

    this.addressForm = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCnpj = this.handleCnpj.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(providersActions.getTypes());
    dispatch(providersActions.getById(match.params.id)).then(() => {
      const { providers } = this.props;

      this.setState({
        name: providers.name,
        accept_quote: providers.accept_quote,
        accept_mapping: providers.accept_mapping,
        cnpj: providers.cnpj,
        stateRegistration: providers.state_registration_number,
        type_id: providers.type_id,
        notes: providers.notes,
      });
      this.addressForm.current.setAddress(providers.address);
    });
    dispatch(providersActions.getTypes());
  }

  handleChange(e, data) {
    if (data) {
      const value = data.checked !== undefined ? data.checked : data.value;
      this.setState({ [data.name]: value === '' ? null : value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, accept_quote, type_id, accept_mapping, cnpj, stateRegistration, notes } =
      this.state;
    const { dispatch, match } = this.props;

    if (!cnpjValidation(cnpj, false)) {
      return;
    }

    const address = await this.addressForm.current.save();
    if (name) {
      dispatch(
        providersActions.update(
          match.params.id,
          {
            name,
            type_id,
            accept_quote: !!accept_quote,
            accept_mapping: !!accept_mapping,
            main_address_id: address.id,
            cnpj,
            state_registration_number: stateRegistration,
            notes,
          },
          this.props.history,
        ),
      );
    }
  }

  handleCnpj(e) {
    this.setState({ cnpj: maskCnpj(e.target.value) });
  }

  render() {
    const {
      name,
      accept_quote,
      type_id,
      accept_mapping,
      cnpj,
      stateRegistration,
      submitted,
      notes,
    } = this.state;
    const { loading, types } = this.props;
    let typesOptions = [];
    if (types && types.data !== undefined) {
      typesOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }
    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Fornecedor</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Tipo"
              name="type_id"
              options={typesOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type_id}
            />
          </Form.Field>
          <Form.Field>
            <label>CNPJ</label>
            <input
              name="cnpj"
              placeholder="00.000.000/0000-00"
              value={cnpj}
              onChange={this.handleCnpj}
              maxLength="18"
            />
            {!cnpjValidation(cnpj, false) && submitted && (
              <Label basic color="red" pointing>
                CNPJ inválido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Inscrição Estadual</label>
            <input
              name="stateRegistration"
              placeholder={stateRegistration}
              value={stateRegistration}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="accept_quote"
              label="Aceita Cotações"
              checked={accept_quote}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="accept_mapping"
              label="Aceita Mapeamento"
              checked={accept_mapping}
              onChange={this.handleChange}
            />
          </Form.Field>
          <AddressForm ref={this.addressForm} />
          <Form.Field>
            <label>Observações</label>
            <TextArea
              style={{ height: '100px', width: '100%' }}
              placeholder="Observações"
              name="notes"
              maxLength="1024"
              onChange={this.handleChange}
              value={notes}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;
  return {
    providers: providers.item,
    types: providers.types,
    loading: providers.loading || false,
  };
}

const connectedProvidersEditScreen = connect(mapStateToProps)(ProvidersEditScreen);
export { connectedProvidersEditScreen as ProvidersEditScreen };
