import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import moment from 'moment';

class ProvidersScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(providersActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { providers, loading } = this.props;
    const { refresh } = this.state;

    if (!providers.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Fornecedores</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/providers/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`providers/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;

              dispatch(providersActions.getAll(`page=${page}`));
            }}
            header={[
              'Nome',
              'Tipo',
              'Cnpj',
              'Última Alteração',
              'Aceita Mapeamento',
              'Aceita Cotações',
            ]}
            search={{
              name: 'Nome',
              'type.name': 'Tipo',
            }}
            columns={[
              'name',
              'type.name',
              'cnpj',
              {
                name: 'updated_at',
                format: (item) => {
                  const date = moment(item.updated_at);
                  return date.isValid() ? date.format('DD/MM/YYYY HH:mm:ss') : '-';
                },
              },
              {
                name: 'accept_mapping',
                format: (item) => {
                  return item.accept_mapping ? 'Sim' : 'Não ';
                },
              },
              {
                name: 'accept_quote',
                format: (item) => {
                  return item.accept_quote ? 'Sim' : 'Não ';
                },
              },
            ]}
            data={providers.data}
            totalPages={providers.lastPage}
            page={providers.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;
  return {
    providers,
    loading: providers.loading || false,
  };
}

const connectedProvidersScreen = connect(mapStateToProps)(ProvidersScreen);
export { connectedProvidersScreen as ProvidersScreen };
