export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALLUSER_REQUEST: 'USERS_GETALLUSER_REQUEST',
  GETALLUSER_SUCCESS: 'USERS_GETALLUSER_SUCCESS',
  GETALLUSER_FAILURE: 'USERS_GETALLUSER_FAILURE',

  GETCLIENTUSER_REQUEST: 'USERS_GETCLIENTUSER_REQUEST',
  GETCLIENTUSER_SUCCESS: 'USERS_GETCLIENTUSER_SUCCESS',
  GETCLIENTUSER_FAILURE: 'USERS_GETCLIENTUSER_FAILURE',

  GETCOMPANYUSER_REQUEST: 'USERS_GETCOMPANYUSER_REQUEST',
  GETCOMPANYUSER_SUCCESS: 'USERS_GETCOMPANYUSER_SUCCESS',
  GETCOMPANYUSER_FAILURE: 'USERS_GETCOMPANYUSER_FAILURE',

  GETUSER_REQUEST: 'GETUSER_REQUEST',
  GETUSER_SUCCESS: 'GETUSER_SUCCESS',
  GETUSER_FAILURE: 'GETUSER_FAILURE',

  GETUSERME_REQUEST: 'GETUSERME_REQUEST',
  GETUSERME_SUCCESS: 'GETUSERME_SUCCESS',
  GETUSERME_FAILURE: 'GETUSERME_FAILURE',

  CREATEUSER_REQUEST: 'CREATEUSER_REQUEST',
  CREATEUSER_SUCCESS: 'CREATEUSER_SUCCESS',
  CREATEUSER_FAILURE: 'CREATEUSER_FAILURE',

  UPDATEUSER_REQUEST: 'UPDATEUSER_REQUEST',
  UPDATEUSER_SUCCESS: 'UPDATEUSER_SUCCESS',
  UPDATEUSER_FAILURE: 'UPDATEUSER_FAILURE',

  DELETEUSER_REQUEST: 'DELETEUSER_REQUEST',
  DELETEUSER_SUCCESS: 'DELETEUSER_SUCCESS',
  DELETEUSER_FAILURE: 'DELETEUSER_FAILURE',

  UPDATEUSERME_REQUEST: 'UPDATEUSERME_REQUEST',
  UPDATEUSERME_SUCCESS: 'UPDATEUSERME_SUCCESS',
  UPDATEUSERME_FAILURE: 'UPDATEUSERME_FAILURE',

  GETALLROLES_REQUEST: 'USERS_GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'USERS_GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'USERS_GETALLROLES_FAILURE',
};
