import { contractConstants } from '../constants/contract.constants';
import contractService from '../services/contract.service';
import { alertActions } from './alert.actions';

export const contractActions = {
  create,
  update,
  deleteById,
  getAll,
  getTypes,
  getById,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const contract = await contractService.create(obj);

      dispatch(success(contract));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: contractConstants.CREATECONTRACT_REQUEST };
  }
  function success(contract) {
    return { type: contractConstants.CREATECONTRACT_SUCCESS, contract };
  }
  function failure(error) {
    return { type: contractConstants.CREATECONTRACT_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const contract = await contractService.deleteById(id);

      dispatch(success(contract));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(contract) {
    return { type: contractConstants.DELETECONTRACT_REQUEST, item: contract };
  }
  function success(contract) {
    return { type: contractConstants.DELETECONTRACT_SUCCESS, item: contract };
  }
  function failure(error) {
    return { type: contractConstants.DELETECONTRACT_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const contract = await contractService.update(id, obj);

      dispatch(success(contract));
      history.push('/contracts');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: contractConstants.UPDATECONTRACT_REQUEST };
  }
  function success(contract) {
    return { type: contractConstants.UPDATECONTRACT_SUCCESS, contract };
  }
  function failure(error) {
    return { type: contractConstants.UPDATECONTRACT_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const contracts = await contractService.getAll(filter);
      dispatch(success(contracts));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: contractConstants.GETALLCONTRACT_REQUEST };
  }
  function success(contracts) {
    return { type: contractConstants.GETALLCONTRACT_SUCCESS, contracts };
  }
  function failure(error) {
    return { type: contractConstants.GETALLCONTRACT_FAILURE, error };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const types = await contractService.getTypes();
      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: contractConstants.GETTYPECONTRACT_REQUEST };
  }
  function success(contractTypes) {
    return { type: contractConstants.GETTYPECONTRACT_SUCCESS, contractTypes };
  }
  function failure(error) {
    return { type: contractConstants.GETTYPECONTRACT_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const contract = await contractService.getById(id);

      dispatch(success(contract));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(contract) {
    return { type: contractConstants.GETCONTRACT_REQUEST, contract };
  }
  function success(contract) {
    return { type: contractConstants.GETCONTRACT_SUCCESS, contract };
  }
  function failure(error) {
    return { type: contractConstants.GETCONTRACT_FAILURE, error };
  }
}
