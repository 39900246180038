import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { projectActions } from '../../actions/project.actions';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { activityActions } from '../../actions/activity.actions';
import _ from 'lodash';

class ActivityEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      project_id: null,
      project_text: null,
      closeProject: false,
      mode: 'manual',
      type_id: null,
      description: '',
      timeStart: 0,
      working: true,
      timestamp_end: '',
      timestamp_start: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    Promise.all([
      dispatch(activityActions.getById(match.params.id)),
      dispatch(activityActions.getTypes()),
      dispatch(projectActions.getAll()),
    ]).then(() => {
      const { activity } = this.props;
      const ms = moment().diff(moment(activity.timestamp_start, 'YYYY-MM-DD HH:mm:ss'));

      let payload = {
        submitted: false,
        project_id: activity.project_id,
        mode: 'manual',
        type_id: activity.type_id,
        description: activity.description,
        timestamp_start: moment(activity.timestamp_start).format('DD/MM/YYYY HH:mm:ss'),
        timestamp_end: moment(activity.timestamp_end).format('DD/MM/YYYY HH:mm:ss'),
        timeStart: ms,
      };
      this.setState(payload);
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { description, project_id, type_id, timestamp_start, timestamp_end } = this.state;
    const { dispatch, match } = this.props;

    if (description && project_id && type_id && timestamp_start && timestamp_end) {
      dispatch(
        activityActions.update(match.params.id, {
          description,
          project_id,
          type_id,
          timestamp_start: moment(timestamp_start, 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          timestamp_end: moment(timestamp_end, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        }),
      ).then(() => {
        this.setState({ submitted: false });
        this.props.history.goBack();
      });
    }
  }

  getProjectOptions() {
    const { projects } = this.props;
    let projectOptions = [];

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
          status_id: item.status_id,
          item: item,
          content: (
            <div className="item-flex">
              <h4>
                <i>{item.code}</i> {item.name}
              </h4>
              <span>{item.client.name}</span>
              <Label>{item.status.name}</Label>
            </div>
          ),
        };
      });
    }

    return projectOptions;
  }

  searchProject(items, search) {
    return _.filter(items, (item) => {
      const tokens = search.toLowerCase().split(' ');
      let match = false;
      for (const token of tokens) {
        if (
          item.item.client?.name.toLowerCase().indexOf(token) > -1 ||
          item.item.status?.name.toLowerCase().indexOf(token) > -1 ||
          item.item.name?.toLowerCase().indexOf(token) > -1
        ) {
          match = true;
          break;
        }
      }

      return match;
    });
  }

  getTypeOptions() {
    const { types } = this.props;
    let typeOptions = [];

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return typeOptions;
  }

  render() {
    const { description, submitted, timestamp_start, timestamp_end, project_id, type_id } =
      this.state;
    const { activity, loading, dispatch } = this.props;
    const projectOptions = this.getProjectOptions();
    const typeOptions = this.getTypeOptions();

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Alterar Atividade</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Projeto</label>
            <Dropdown
              placeholder="Projeto"
              fluid
              selection
              floating
              name="project_id"
              search={this.searchProject}
              error={submitted && !activity.project_id ? true : false}
              options={projectOptions}
              onChange={(event, data) => {
                this.handleChange(true, data);
                let statusId = null;
                for (let option of projectOptions) {
                  if (option.value == data.value) {
                    statusId = option.status_id;
                  }
                }
                dispatch(activityActions.getTypes(`status_id=${statusId}`));
              }}
              value={project_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              name="type_id"
              disabled={!project_id}
              style={{ marginTop: '5px' }}
              error={submitted && !type_id ? true : false}
              options={typeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <input
              name="description"
              placeholder="Descrição"
              value={description}
              onChange={this.handleChange}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Início</label>
            <div>
              <DateTimeInput
                closable
                clearable
                error={submitted && !timestamp_start ? true : false}
                dateTimeFormat="DD/MM/YYYY HH:mm:ss"
                name="timestamp_start"
                placeholder="Data Início"
                onChange={(event, data) => this.handleChange(true, data)}
                value={timestamp_start}
                iconPosition="left"
              />
              {submitted && !timestamp_start && (
                <Label basic color="red" pointing>
                  Data e Hora de Início é requerido
                </Label>
              )}
            </div>
          </Form.Field>
          <Form.Field>
            <label>Fim</label>
            <div>
              <DateTimeInput
                closable
                clearable
                error={submitted && !timestamp_end ? true : false}
                dateTimeFormat="DD/MM/YYYY HH:mm:ss"
                name="timestamp_end"
                placeholder="Data Fim"
                onChange={(event, data) => this.handleChange(true, data)}
                value={timestamp_end}
                iconPosition="left"
              />
              {submitted && !timestamp_end && (
                <Label basic color="red" pointing>
                  Data e Hora de Fim é requerido
                </Label>
              )}
            </div>
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, activities } = state;
  return {
    activity: activities.item || null,
    projects,
    types: activities.types,
    loading: projects.loading || activities.loading || false,
  };
}

const connectedActivityEditScreen = connect(mapStateToProps)(ActivityEditScreen);
export { connectedActivityEditScreen as ActivityEditScreen };
