import { productsConstants } from '../constants/products.constants';

export function products(state = {}, action) {
  switch (action.type) {
    case productsConstants.GETALLPRODUCTS_REQUEST:
    case productsConstants.CREATEPRODUCTS_REQUEST:
    case productsConstants.UPDATEPRODUCTS_REQUEST:
    case productsConstants.UPDATETYPEPRODUCTS_REQUEST:
    case productsConstants.DELETEPRODUCTS_REQUEST:
    case productsConstants.DELETETYPEPRODUCTS_REQUEST:
    case productsConstants.CREATETYPEPRODUCTS_REQUEST:
    case productsConstants.GETTYPEPRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsConstants.CREATETYPEPRODUCTS_SUCCESS:
    case productsConstants.GETTYPEPRODUCTS_SUCCESS:
    case productsConstants.DELETETYPEPRODUCTS_SUCCESS:
    case productsConstants.UPDATETYPEPRODUCTS_SUCCESS:
      return {
        ...state,
        itemType: action.products,
        loading: false,
      };
    case productsConstants.CREATEPRODUCTS_SUCCESS:
    case productsConstants.UPDATEPRODUCTS_SUCCESS:
    case productsConstants.DELETEPRODUCTS_SUCCESS:
    case productsConstants.GETPRODUCTS_SUCCESS:
      return {
        ...state,
        item: action.products,
        loading: false,
      };
    case productsConstants.GETALLPRODUCTS_SUCCESS:
    case productsConstants.GETMEPRODUCTS_SUCCESS:
      return {
        ...state,
        ...action.products,
        loading: false,
      };
    case productsConstants.GETALLTYPEPRODUCTS_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case productsConstants.CREATEPRODUCTS_FAILURE:
    case productsConstants.UPDATEPRODUCTS_FAILURE:
    case productsConstants.UPDATETYPEPRODUCTS_FAILURE:
    case productsConstants.CREATETYPEPRODUCTS_FAILURE:
    case productsConstants.DELETEPRODUCTS_FAILURE:
    case productsConstants.DELETETYPEPRODUCTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
