import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { managersActions } from '../../actions/managers.actions';

class ManagersScreen extends Component {
  componentDidMount() {
    this.props.dispatch(managersActions.getAll());
  }

  render() {
    const { managers, loading } = this.props;

    if (!managers.data || loading) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Gerentes de Conta</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/managers/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`managers/${item.id}/show`)}
          header={['Nome', 'Cliente', 'Atualizado em']}
          search={{
            user: 'Nome',
            client: 'Cliente',
            updated_at: 'Atualizado em',
          }}
          columns={[
            {
              name: 'user',
              format: (item) => item.user?.name,
            },
            {
              name: 'client',
              format: (item) => item.client?.name,
            },
            {
              name: 'updated_at',
              format: (item) => moment(item.updated_at).format('DD/MM/YYYY'),
            },
          ]}
          data={managers.data}
          totalPages={managers.lastPage}
          page={managers.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { managers } = state;
  return {
    managers,
    loading: managers.loading || false,
  };
}

const connectedManagersScreen = connect(mapStateToProps)(ManagersScreen);
export { connectedManagersScreen as ManagersScreen };
