import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon, Loader } from 'semantic-ui-react';
import { salesActions } from '../../actions/sales.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getData() {
    const { dispatch } = this.props;
    const params = {};
    const { page, sort } = this.state;
    params.page = page;
    params.sort = sort;
    dispatch(salesActions.getAll(params));
  }

  componentDidMount() {
    this.props.dispatch(salesActions.getAll());
  }

  async synSalesForce() {
    const { dispatch } = this.props;
    this.setState({ loading_sync: true });
    await dispatch(salesActions.synchronizeOpportunitiesFromSalesForce()).then(() => {
      this.setState({ loading_sync: false });
      dispatch(salesActions.getAll());
    });
  }

  render() {
    const { sales, loading } = this.props;
    const { loading_sync } = this.state;

    if (!sales.data || loading) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Vendas</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/sales/create')}>
              Novo
            </Button>
            <Button loading={loading_sync} onClick={() => this.synSalesForce()}>
              <Icon name="sync" />
              Sincronizar
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`sales/${item.id}/show`)}
          handlePaginationChange={(page) => {
            this.setState({ page }, () => {
              this.getData();
            });
          }}
          header={['ID SalesForce', 'Título', 'Cliente', 'Status', 'Vendedor', 'Última Alteração']}
          search={{
            sales_force_opportunity_number: 'ID SalesForce',
            title: 'Título',
            client: 'Cliente',
            status: 'Status',
            author: 'Vendedor',
            update_at: 'Última Alteração',
          }}
          columns={[
            'sales_force_opportunity_number',
            'title',
            {
              name: 'client',
              format: (item) => item.client?.name,
            },
            {
              name: 'status',
              format: (item) => item.status?.name,
            },
            {
              name: 'author',
              format: (item) => item.author?.name,
            },
            {
              name: 'update_at',
              format: (item) => moment(item.update_at).format('DD/MM/YYYY'),
            },
          ]}
          data={sales.data}
          totalPages={sales.lastPage}
          page={sales.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { sales } = state;
  return {
    sales,
    loading: sales.loading || false,
  };
}

const connectedSalesScreen = connect(mapStateToProps)(SalesScreen);
export { connectedSalesScreen as SalesScreen };
