import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader, Icon } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { clientActions } from '../../actions/client.actions';
import './styles.css';

class ClientScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(clientActions.getAll());
  }

  exportFile() {
    const { dispatch } = this.props;
    dispatch(clientActions.exportFile('export=xlsx'));
  }

  async synSalesForce() {
    const { dispatch } = this.props;
    this.setState({ loading_sync: true });
    await dispatch(clientActions.synchronizeClientsFromSalesForce()).then(() => {
      this.setState({ loading_sync: false });
      dispatch(clientActions.getAll());
    });
  }

  render() {
    const { clients, loading } = this.props;
    const { loading_sync } = this.state;

    if (!clients.data || loading) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Clientes</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/clients/create')}>
              Novo
            </Button>
            <Button onClick={() => this.exportFile()}>
              <Icon name="file excel" />
              Exportar
            </Button>
            <Button loading={loading_sync} onClick={() => this.synSalesForce()}>
              <Icon name="sync" />
              Sincronizar
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`clients/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;
            dispatch(clientActions.getAll(`page=${page}`));
          }}
          header={['Nome', 'Nome Fantasia', 'CNPJ', 'Situação']}
          search={{
            name: 'Nome',
            active: 'Situação',
            trading_name: 'Nome Fantasia',
          }}
          columns={[
            'name',
            'trading_name',
            'cnpj',
            {
              name: 'situation',
              format: (item) => (item.active ? 'Ativo' : 'Inativo'),
            },
          ]}
          data={clients.data}
          totalPages={clients.lastPage}
          page={clients.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  return {
    clients,
    loading: clients.loading || false,
  };
}

const connectedClientScreen = connect(mapStateToProps)(ClientScreen);
export { connectedClientScreen as ClientScreen };
