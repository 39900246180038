import { managersConstants } from '../constants/managers.constants';
import managersService from '../services/managers.service';
import { alertActions } from './alert.actions';

export const managersActions = {
  create,
  update,
  deleteById,
  getAll,
  getById,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const managers = await managersService.create(obj);

      dispatch(success(managers));
      history.push('/managers');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return { type: managersConstants.CREATE_MANAGERS_REQUEST };
  }
  function success(managers) {
    return { type: managersConstants.CREATE_MANAGERS_SUCCESS, managers };
  }
  function failure(error) {
    return { type: managersConstants.CREATE_MANAGERS_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const managers = await managersService.deleteById(id);

      dispatch(success(managers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(managers) {
    return { type: managersConstants.DELETE_MANAGERS_REQUEST, managers };
  }
  function success(managers) {
    return { type: managersConstants.DELETE_MANAGERS_SUCCESS, managers };
  }
  function failure(error) {
    return { type: managersConstants.DELETE_MANAGERS_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const managers = await managersService.update(id, obj);

      dispatch(success(managers));
      history.push('/managers');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: managersConstants.UPDATE_MANAGERS_REQUEST };
  }
  function success(managers) {
    return { type: managersConstants.UPDATE_MANAGERS_SUCCESS, managers };
  }
  function failure(error) {
    return { type: managersConstants.UPDATE_MANAGERS_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const managers = await managersService.getAll(filter);

      dispatch(success(managers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: managersConstants.GETALL_MANAGERS_REQUEST };
  }
  function success(managers) {
    return { type: managersConstants.GETALL_MANAGERS_SUCCESS, managers };
  }
  function failure(error) {
    return { type: managersConstants.GETALL_MANAGERS_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const managers = await managersService.getById(id);

      dispatch(success(managers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: managersConstants.GET_MANAGERS_REQUEST };
  }
  function success(managers) {
    return { type: managersConstants.GET_MANAGERS_SUCCESS, managers };
  }
  function failure(error) {
    return { type: managersConstants.GET_MANAGERS_FAILURE, error };
  }
}
