import { financesLabelConstants } from '../constants/finances-label.constants';

export function financesLabel(state = {}, action) {
  switch (action.type) {
    case financesLabelConstants.GETALLFINANCELABEL_REQUEST:
    case financesLabelConstants.CREATEFINANCELABEL_REQUEST:
    case financesLabelConstants.UPDATEFINANCELABEL_REQUEST:
    case financesLabelConstants.DELETEFINANCELABELTYPE_REQUEST:
    case financesLabelConstants.DELETEFINANCELABEL_REQUEST:
    case financesLabelConstants.GETMEFINANCELABEL_REQUEST:
    case financesLabelConstants.GETTYPEFINANCELABEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case financesLabelConstants.DELETEFINANCELABEL_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case financesLabelConstants.CREATEFINANCELABEL_SUCCESS:
    case financesLabelConstants.GETFINANCELABEL_SUCCESS:
    case financesLabelConstants.UPDATEFINANCELABEL_SUCCESS:
      return {
        ...state,
        item: action.financesLabel,
        loading: false,
      };
    case financesLabelConstants.GETALLFINANCELABEL_SUCCESS:
    case financesLabelConstants.GETMEFINANCELABEL_SUCCESS:
      return {
        ...state,
        ...action.financesLabel,
        loading: false,
      };
    case financesLabelConstants.GETTYPEFINANCELABEL_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case financesLabelConstants.GETALLFINANCELABEL_FAILURE:
    case financesLabelConstants.CREATEFINANCELABEL_FAILURE:
    case financesLabelConstants.UPDATEFINANCELABEL_FAILURE:
    case financesLabelConstants.DELETEFINANCELABELTYPE_FAILURE:
    case financesLabelConstants.DELETEFINANCELABEL_FAILURE:
    case financesLabelConstants.GETMEFINANCELABEL_FAILURE:
    case financesLabelConstants.GETTYPEFINANCELABEL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
