import client from '../helpers/HttpClient';

class financialCategoryService {
  async update(id, obj) {
    try {
      const result = await client.put(`finance/sub-categories/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      const result = await client.get('finance/sub-categories', params);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id, params) {
    try {
      const result = await client.get(`finance/sub-categories/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async create(obj) {
    try {
      const result = await client.post('finance/sub-categories', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`finance/sub-categories/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new financialCategoryService();
