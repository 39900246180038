import React, { Component, Fragment } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Image, Label, List } from 'semantic-ui-react';
import moment from 'moment';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';
import { teamActions } from '../../actions/team.actions';
import { ticketActions } from '../../actions/ticket.actions';
import RichTextEditor from 'react-rte';
import './styles.css';
import { productsActions } from '../../actions/products.actions';
import { contractActions } from '../../actions/contract.actions';
import ContracsTable from '../../components/ContracsTable/ContracsTable';

class TicketEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: RichTextEditor.createEmptyValue(),
      status_id: '',
      user_id: '',
      assignee_id: '',
      user: {},
      client_id: '',
      priority_id: '',
      product_id: '',
      contract_id: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.initGetter();
  }

  initGetter() {
    const { dispatch, match } = this.props;

    dispatch(ticketActions.getById(match.params.id)).then(() => {
      dispatch(userActions.getClientUsers());
      dispatch(userActions.getCompanyUsers());
      dispatch(clientActions.getAll());
      dispatch(teamActions.getAll());
      dispatch(productsActions.getAll());
      dispatch(ticketActions.getStatusTickets());
      dispatch(ticketActions.getPriorities());

      const { ticket } = this.props;
      this.getContracts(ticket.client_id);
      this.setState({
        title: ticket.title,
        description: RichTextEditor.createValueFromString(ticket.description, 'html'),
        status_id: ticket.status_id,
        user_id: ticket.user_id,
        assignee_id: ticket.assignee_id,
        team_id: ticket.team_id,
        user: ticket.user,
        client_id: ticket.client_id,
        priority_id: ticket.priority_id,
        product_id: ticket.product_id,
        attachments: ticket.attachment,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  getContracts(client_id) {
    const { dispatch } = this.props;

    const now = moment();
    const start_date = now.startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end_date = now.endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');

    dispatch(
      contractActions.getAll({
        start_date,
        end_date,
        client_id,
      }),
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      title,
      description,
      status_id,
      user_id,
      team_id,
      client_id,
      priority_id,
      product_id,
      assignee_id,
    } = this.state;

    const { dispatch, match } = this.props;
    if (title && description.toString('html') && status_id && user_id && client_id && priority_id) {
      dispatch(
        ticketActions.update(
          match.params.id,
          {
            title,
            description: description.toString('html'),
            status_id,
            user_id,
            product_id,
            assignee_id: assignee_id === '' ? null : assignee_id,
            team_id: team_id === '' ? null : team_id,
            client_id,
            priority_id,
          },
          this.props.history,
        ),
      );
    }
  }

  onChangeUpload(e) {
    const { dispatch, match } = this.props;

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);

    dispatch(ticketActions.uploadAttachment(match.params.id, formData)).then(() => {
      this.initGetter();
    });
  }

  removeAttachment(e, id) {
    const { dispatch, match } = this.props;

    e.preventDefault();

    dispatch(ticketActions.deleteAttachmentById(match.params.id, id)).then(() => {
      this.initGetter();
    });
  }

  render() {
    const {
      priorities,
      status,
      clients,
      teams,
      clientUsers,
      companyUsers,
      products,
      contracts,
      loading,
      dispatch,
    } = this.props;
    const {
      title,
      team_id,
      description,
      status_id,
      assignee_id,
      attachments,
      user_id,
      user,
      client_id,
      priority_id,
      product_id,
      submitted,
    } = this.state;

    let prioritiesOptions,
      userOptions,
      statusOptions,
      clientOptions,
      teamOptions,
      productOptions,
      companyUsersOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (products && products.data !== undefined) {
      productOptions = products.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clientUsers && clientUsers.data !== undefined) {
      userOptions = clientUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (companyUsers && companyUsers.data !== undefined) {
      companyUsersOptions = companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (user && userOptions) {
      userOptions.push({ key: user.id, value: user.id, text: user.name });
    }

    if (priorities && priorities.data !== undefined) {
      prioritiesOptions = priorities.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Ticket</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Titulo</label>
            <input
              name="title"
              placeholder="Titulo do Ticket"
              value={title}
              onChange={this.handleChange}
            />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Titulo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <RichTextEditor
              value={description}
              onChange={(value) => this.handleChange(true, { name: 'description', value })}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <h4>Anexos</h4>
            <label className="ui button" htmlFor="file">
              Anexar novo arquivo
            </label>
            <input
              name="file"
              id="file"
              className="input hidden"
              type="file"
              onChange={(e) => this.onChangeUpload(e)}
            />
            <List divided verticalAlign="middle">
              {attachments &&
                attachments.map((attachment, key) => (
                  <List.Item key={attachment.id + key}>
                    <List.Content floated="right">
                      <Button onClick={(e) => this.removeAttachment(e, attachment.id)}>
                        Remover
                      </Button>
                    </List.Content>
                    <Image src="imgs/square-image.png" />
                    <List.Content>
                      <List.Header>{_.last(attachment.url.split('/'))}</List.Header>
                      {moment(attachment.created_at).format('DD/MM/YYYY HH:mm')}
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          </Form.Field>
          <Form.Field>
            <label>Prioridade</label>
            <Dropdown
              placeholder="Prioridade"
              name="priority_id"
              value={priority_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={prioritiesOptions}
            />
            {submitted && !priority_id && (
              <Label basic color="red" pointing>
                Prioridade é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => {
                this.handleChange(true, data);
                dispatch(
                  userActions.getClientUsers({
                    client: data.value === '' ? null : data.value,
                  }),
                );
                if (data.value) {
                  this.getContracts(data.value);
                }
              }}
              value={client_id}
            />
            {submitted && !client_id && (
              <Label basic color="red" pointing>
                Cliente é requerido
              </Label>
            )}
            {client_id && <ContracsTable contracts={contracts} />}
          </Form.Field>

          <Form.Field>
            <label>Solicitante</label>
            <Dropdown
              name="user_id"
              onChange={(event, data) => this.handleChange(true, data)}
              placeholder="Usuário"
              fluid
              search
              selection
              options={userOptions}
              value={user_id}
            />
            {submitted && !user_id && (
              <Label basic color="red" pointing>
                Solicitante é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Produto</label>
            <Dropdown
              placeholder="Produto"
              name="product_id"
              value={product_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={productOptions}
            />
          </Form.Field>

          <Form.Field>
            <label>Status Ticket</label>
            <Dropdown
              placeholder="Status Ticket"
              name="status_id"
              value={status_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={statusOptions}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { teams, tickets, clients, users, products, contracts } = state;

  return {
    status: tickets.status,
    priorities: tickets.priorities,
    clients,
    users,
    teams,
    products,
    contracts: contracts?.data || [],
    clientUsers: users.clientUsers,
    companyUsers: users.companyUsers,
    ticket: tickets.item,
    loading:
      clients.loading ||
      users.loading ||
      teams.loading ||
      tickets.loading ||
      contracts.loading ||
      false,
  };
}

const connectedTicketEditScreen = connect(mapStateToProps)(TicketEditScreen);
export { connectedTicketEditScreen as TicketEditScreen };
