export const opportunityConstants = {
  GETALL_OPPORTUNITY_REQUEST: 'GETALL_OPPORTUNITY_REQUEST',
  GETALL_OPPORTUNITY_SUCCESS: 'GETALL_OPPORTUNITY_SUCCESS',
  GETALL_OPPORTUNITY_FAILURE: 'GETALL_OPPORTUNITY_FAILURE',

  GET_OPPORTUNITYSTATUS_REQUEST: 'GET_OPPORTUNITYSTATUS_REQUEST',
  GET_OPPORTUNITYSTATUS_SUCCESS: 'GET_OPPORTUNITYSTATUS_SUCCESS',
  GET_OPPORTUNITYSTATUS_FAILURE: 'GET_OPPORTUNITYSTATUS_FAILURE',

  GET_OPPORTUNITYSTATUSBOARD_REQUEST: 'GET_OPPORTUNITYSTATUSBOARD_REQUEST',
  GET_OPPORTUNITYSTATUSBOARD_SUCCESS: 'GET_OPPORTUNITYSTATUSBOARD_SUCCESS',
  GET_OPPORTUNITYSTATUSBOARD_FAILURE: 'GET_OPPORTUNITYSTATUSBOARD_FAILURE',

  GET_OPPORTUNITYSTATUSHISTORY_REQUEST: 'GET_OPPORTUNITYSTATUSHISTORY_REQUEST',
  GET_OPPORTUNITYSTATUSHISTORY_SUCCESS: 'GET_OPPORTUNITYSTATUSHISTORY_SUCCESS',
  GET_OPPORTUNITYSTATUSHISTORY_FAILURE: 'GET_OPPORTUNITYSTATUSHISTORY_FAILURE',

  GET_OPPORTUNITYQUOTES_REQUEST: 'GET_OPPORTUNITYQUOTES_REQUEST',
  GET_OPPORTUNITYQUOTES_SUCCESS: 'GET_OPPORTUNITYQUOTES_SUCCESS',
  GET_OPPORTUNITYQUOTES_FAILURE: 'GET_OPPORTUNITYQUOTES_FAILURE',

  GET_OPPORTUNITYQUOTESITENS_REQUEST: 'GET_OPPORTUNITYQUOTESITENS_REQUEST',
  GET_OPPORTUNITYQUOTESITENS_SUCCESS: 'GET_OPPORTUNITYQUOTESITENS_SUCCESS',
  GET_OPPORTUNITYQUOTESITENS_FAILURE: 'GET_OPPORTUNITYQUOTESITENS_FAILURE',

  GET_OPPORTUNITYQUOTESITENSTYPE_REQUEST: 'GET_OPPORTUNITYQUOTESITENSTYPE_REQUEST',
  GET_OPPORTUNITYQUOTESITENSTYPE_SUCCESS: 'GET_OPPORTUNITYQUOTESITENSTYPE_SUCCESS',
  GET_OPPORTUNITYQUOTESITENSTYPE_FAILURE: 'GET_OPPORTUNITYQUOTESITENSTYPE_FAILURE',

  GET_OPPORTUNITYPARTICIPANT_REQUEST: 'GET_OPPORTUNITYPARTICIPANT_REQUEST',
  GET_OPPORTUNITYPARTICIPANT_SUCCESS: 'GET_OPPORTUNITYPARTICIPANT_SUCCESS',
  GET_OPPORTUNITYPARTICIPANT_FAILURE: 'GET_OPPORTUNITYPARTICIPANT_FAILURE',

  GET_OPPORTUNITYPARTICIPANTTYPE_REQUEST: 'GET_OPPORTUNITYPARTICIPANTTYPE_REQUEST',
  GET_OPPORTUNITYPARTICIPANTTYPE_SUCCESS: 'GET_OPPORTUNITYPARTICIPANTTYPE_SUCCESS',
  GET_OPPORTUNITYPARTICIPANTTYPE_FAILURE: 'GET_OPPORTUNITYPARTICIPANTTYPE_FAILURE',

  GET_OPPORTUNITYPARTICIPANTUSER_REQUEST: 'GET_OPPORTUNITYPARTICIPANTUSER_REQUEST',
  GET_OPPORTUNITYPARTICIPANTUSER_SUCCESS: 'GET_OPPORTUNITYPARTICIPANTUSER_SUCCESS',
  GET_OPPORTUNITYPARTICIPANTUSER_FAILURE: 'GET_OPPORTUNITYPARTICIPANTUSER_FAILURE',

  GET_OPPORTUNITY_REQUEST: 'GET_OPPORTUNITY_REQUEST',
  GET_OPPORTUNITY_SUCCESS: 'GET_OPPORTUNITY_SUCCESS',
  GET_OPPORTUNITY_FAILURE: 'GET_OPPORTUNITY_FAILURE',

  UPDATE_OPPORTUNITYSTATUSCHANGE_REQUEST: 'UPDATE_OPPORTUNITYSTATUSCHANGE_REQUEST',
  UPDATE_OPPORTUNITYSTATUSCHANGE_SUCCESS: 'UPDATE_OPPORTUNITYSTATUSCHANGE_SUCCESS',
  UPDATE_OPPORTUNITYSTATUSCHANGE_FAILURE: 'UPDATE_OPPORTUNITYSTATUSCHANGE_FAILURE',

  GET_OPPORTUNITYINTERACTION_REQUEST: 'GET_OPPORTUNITYINTERACTION_REQUEST',
  GET_OPPORTUNITYINTERACTION_SUCCESS: 'GET_OPPORTUNITYINTERACTION_SUCCESS',
  GET_OPPORTUNITYINTERACTION_FAILURE: 'GET_OPPORTUNITYINTERACTION_FAILURE',

  GET_OPPORTUNITYITENS_REQUEST: 'GET_OPPORTUNITYITENS_REQUEST',
  GET_OPPORTUNITYITENS_SUCCESS: 'GET_OPPORTUNITYITENS_SUCCESS',
  GET_OPPORTUNITYITENS_FAILURE: 'GET_OPPORTUNITYITENS_FAILURE',

  GETID_OPPORTUNITYITENS_REQUEST: 'GETID_OPPORTUNITYITENS_REQUEST',
  GETID_OPPORTUNITYITENS_SUCCESS: 'GETID_OPPORTUNITYITENS_SUCCESS',
  GETID_OPPORTUNITYITENS_FAILURE: 'GETID_OPPORTUNITYITENS_FAILURE',

  GETID_OPPORTUNITYQUOTESITENS_REQUEST: 'GETID_OPPORTUNITYQUOTESITENS_REQUEST',
  GETID_OPPORTUNITYQUOTESITENS_SUCCESS: 'GETID_OPPORTUNITYQUOTESITENS_SUCCESS',
  GETID_OPPORTUNITYQUOTESITENS_FAILURE: 'GETID_OPPORTUNITYQUOTESITENS_FAILURE',

  GET_OPPORTUNITYMAPPINGS_REQUEST: 'GET_OPPORTUNITYMAPPINGS_REQUEST',
  GET_OPPORTUNITYMAPPINGS_SUCCESS: 'GET_OPPORTUNITYMAPPINGS_SUCCESS',
  GET_OPPORTUNITYMAPPINGS_FAILURE: 'GET_OPPORTUNITYMAPPINGS_FAILURE',

  UPDATE_OPPORTUNITYITENS_REQUEST: 'UPDATE_OPPORTUNITYITENS_REQUEST',
  UPDATE_OPPORTUNITYITENS_SUCCESS: 'UPDATE_OPPORTUNITYITENS_SUCCESS',
  UPDATE_OPPORTUNITYITENS_FAILURE: 'UPDATE_OPPORTUNITYITENS_FAILURE',

  UPDATE_OPPORTUNITY_REQUEST: 'UPDATE_OPPORTUNITY_REQUEST',
  UPDATE_OPPORTUNITY_SUCCESS: 'UPDATE_OPPORTUNITY_SUCCESS',
  UPDATE_OPPORTUNITY_FAILURE: 'UPDATE_OPPORTUNITY_FAILURE',

  UPDATE_OPPORTUNITYSTATUSSTATE_REQUEST: 'UPDATE_OPPORTUNITYSTATUSSTATE_REQUEST',
  UPDATE_OPPORTUNITYSTATUSSTATE_SUCCESS: 'UPDATE_OPPORTUNITYSTATUSSTATE_SUCCESS',
  UPDATE_OPPORTUNITYSTATUSSTATE_FAILURE: 'UPDATE_OPPORTUNITYSTATUSSTATE_FAILURE',

  UPDATE_OPPORTUNITYQUOTESITENS_REQUEST: 'UPDATE_OPPORTUNITYQUOTESITENS_REQUEST',
  UPDATE_OPPORTUNITYQUOTESITENS_SUCCESS: 'UPDATE_OPPORTUNITYQUOTESITENS_SUCCESS',
  UPDATE_OPPORTUNITYQUOTESITENS_FAILURE: 'UPDATE_OPPORTUNITYQUOTESITENS_FAILURE',

  CREATE_OPPORTUNITY_REQUEST: 'CREATE_OPPORTUNITY_REQUEST',
  CREATE_OPPORTUNITY_SUCCESS: 'CREATE_OPPORTUNITY_SUCCESS',
  CREATE_OPPORTUNITY_FAILURE: 'CREATE_OPPORTUNITY_FAILURE',

  CREATE_OPPORTUNITYINTERACTION_REQUEST: 'CREATE_OPPORTUNITYINTERACTION_REQUEST',
  CREATE_OPPORTUNITYINTERACTION_SUCCESS: 'CREATE_OPPORTUNITYINTERACTION_SUCCESS',
  CREATE_OPPORTUNITYINTERACTION_FAILURE: 'CREATE_OPPORTUNITYINTERACTION_FAILURE',

  CREATE_OPPORTUNITYITENS_REQUEST: 'CREATE_OPPORTUNITYITENS_REQUEST',
  CREATE_OPPORTUNITYITENS_SUCCESS: 'CREATE_OPPORTUNITYITENS_SUCCESS',
  CREATE_OPPORTUNITYITENS_FAILURE: 'CREATE_OPPORTUNITYITENS_FAILURE',

  CREATE_OPPORTUNITYMAPPINGS_REQUEST: 'CREATE_OPPORTUNITYMAPPINGS_REQUEST',
  CREATE_OPPORTUNITYMAPPINGS_SUCCESS: 'CREATE_OPPORTUNITYMAPPINGS_SUCCESS',
  CREATE_OPPORTUNITYMAPPINGS_FAILURE: 'CREATE_OPPORTUNITYMAPPINGS_FAILURE',

  CREATE_OPPORTUNITYPARTICIPANT_REQUEST: 'CREATE_OPPORTUNITYPARTICIPANT_REQUEST',
  CREATE_OPPORTUNITYPARTICIPANT_SUCCESS: 'CREATE_OPPORTUNITYPARTICIPANT_SUCCESS',
  CREATE_OPPORTUNITYPARTICIPANT_FAILURE: 'CREATE_OPPORTUNITYPARTICIPANT_FAILURE',

  CREATE_OPPORTUNITYQUOTES_REQUEST: 'CREATE_OPPORTUNITYQUOTES_REQUEST',
  CREATE_OPPORTUNITYQUOTES_SUCCESS: 'CREATE_OPPORTUNITYQUOTES_SUCCESS',
  CREATE_OPPORTUNITYQUOTES_FAILURE: 'CREATE_OPPORTUNITYQUOTES_FAILURE',

  CREATE_OPPORTUNITYQUOTESITENS_REQUEST: 'CREATE_OPPORTUNITYQUOTESITENS_REQUEST',
  CREATE_OPPORTUNITYQUOTESITENS_SUCCESS: 'CREATE_OPPORTUNITYQUOTESITENS_SUCCESS',
  CREATE_OPPORTUNITYQUOTESITENS_FAILURE: 'CREATE_OPPORTUNITYQUOTESITENS_FAILURE',

  DELETE_OPPORTUNITY_REQUEST: 'DELETE_OPPORTUNITY_REQUEST',
  DELETE_OPPORTUNITY_SUCCESS: 'DELETE_OPPORTUNITY_SUCCESS',
  DELETE_OPPORTUNITY_FAILURE: 'DELETE_OPPORTUNITY_FAILURE',

  DELETE_OPPORTUNITYPARTICIPANT_REQUEST: 'DELETE_OPPORTUNITYPARTICIPANT_REQUEST',
  DELETE_OPPORTUNITYPARTICIPANT_SUCCESS: 'DELETE_OPPORTUNITYPARTICIPANT_SUCCESS',
  DELETE_OPPORTUNITYPARTICIPANT_FAILURE: 'DELETE_OPPORTUNITYPARTICIPANT_FAILURE',

  DELETE_OPPORTUNITYMAPPINGS_REQUEST: 'DELETE_OPPORTUNITYMAPPINGS_REQUEST',
  DELETE_OPPORTUNITYMAPPINGS_SUCCESS: 'DELETE_OPPORTUNITYMAPPINGS_SUCCESS',
  DELETE_OPPORTUNITYMAPPINGS_FAILURE: 'DELETE_OPPORTUNITYMAPPINGS_FAILURE',

  DELETE_OPPORTUNITYITENS_REQUEST: 'DELETE_OPPORTUNITYITENS_REQUEST',
  DELETE_OPPORTUNITYITENS_SUCCESS: 'DELETE_OPPORTUNITYITENS_SUCCESS',
  DELETE_OPPORTUNITYITENS_FAILURE: 'DELETE_OPPORTUNITYITENS_FAILURE',

  DELETE_OPPORTUNITYQUOTESITENS_REQUEST: 'DELETE_OPPORTUNITYQUOTESITENS_REQUEST',
  DELETE_OPPORTUNITYQUOTESITENS_SUCCESS: 'DELETE_OPPORTUNITYQUOTESITENS_SUCCESS',
  DELETE_OPPORTUNITYQUOTESITENS_FAILURE: 'DELETE_OPPORTUNITYQUOTESITENS_FAILURE',

  DELETE_OPPORTUNITYQUOTES_REQUEST: 'DELETE_OPPORTUNITYQUOTES_REQUEST',
  DELETE_OPPORTUNITYQUOTES_SUCCESS: 'DELETE_OPPORTUNITYQUOTES_SUCCESS',
  DELETE_OPPORTUNITYQUOTES_FAILURE: 'DELETE_OPPORTUNITYQUOTES_FAILURE',

  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILUE',
  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
};
