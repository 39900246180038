import client from '../helpers/HttpClient';
import BaseService from './base.service';

class UserService extends BaseService {
  get basePath() {
    return 'users';
  }

  async updateMe(obj) {
    try {
      const result = await client.put(`me/edit`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async login(email, password) {
    try {
      const result = await client.post('auth/login', {
        email,
        password,
      });

      if (!result || !result.data || !result.data.token) {
        throw new Error('Falha no Login');
      } else {
        localStorage.setItem('user', JSON.stringify(result.data));
        return result.data;
      }
    } catch (error) {
      const returnMessage = 'Falha no Login. Usuário ou Senha Inválido';

      return Promise.reject(returnMessage);
    }
  }

  async logout() {
    try {
      const result = await client.post('auth/logout');

      return result;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getRoles() {
    try {
      const result = await client.get('auth/roles');

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getUserMe() {
    try {
      const result = await client.get('me');

      localStorage.setItem('me', JSON.stringify(result.data));
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new UserService();
