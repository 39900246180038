import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Dimmer, Grid, Loader, Image } from 'semantic-ui-react';
import { companyActions } from '../../actions/company.actions';

class CompanyShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(companyActions.getOne(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(companyActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/companies'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/companies/${match.params.id}/edit`);
  }

  render() {
    const { company, loading } = this.props;
    const { open } = this.state;

    if (!company)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{company.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <Grid>
            <Grid.Column computer={3}>
              <h4>Logo</h4>
              <Image src={company?.logo_url} />
            </Grid.Column>
            <Grid.Column computer={2} />
            <Grid.Column computer={3}>
              <h4>Logo Login</h4>
              <Image src={company?.login_logo_url} />
            </Grid.Column>
            <Grid.Column computer={2} />
            <Grid.Column computer={3}>
              <h4>Logo Menu</h4>
              <Image src={company?.menu_logo_url} />
            </Grid.Column>
          </Grid>

          <h4>Título: </h4>
          {company.title}

          <h4>CNPJ: </h4>
          {company.cnpj}

          <h4>Cor Primária: </h4>
          {company.primary_color}

          <h4>Cor Secundária: </h4>
          {company.secondary_color}

          <h4>Cor Terciária: </h4>
          {company.tertiary_color}

          <h4>Endereço: </h4>
          {company.address
            ? `${company.address?.street ? company.address?.street + ',' : ''} ${
                company.address?.number ? company.address?.number + ',' : ''
              } ${company.address?.complement ? company.address?.complement + '-' : ''} ${
                company.address?.neighborhood ? company.address?.neighborhood + '-' : ''
              } ${company.address?.city ? company.address?.city + ' -' : ''} ${
                company.address?.state
              }`
            : ''}

          <h4>Status: </h4>
          {company.active ? 'Ativo' : 'Inativo'}
          <h4>Data de criação: </h4>
          {moment(company.created_at).format('DD/MM/YYYY') || '-'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { companies } = state;
  return {
    company: companies.company,
    loading: companies.loading || false,
  };
}

const connectedCompanyShowScreen = connect(mapStateToProps)(CompanyShowScreen);
export { connectedCompanyShowScreen as CompanyShowScreen };
