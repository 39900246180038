import { expenseConstants } from '../constants/expense.constants';
import expenseService from '../services/expense.service';
import expenseTypeService from '../services/expense-type.service';
import { alertActions } from './alert.actions';

export const expenseActions = {
  create,
  createType,
  update,
  updateType,
  deleteTypeById,
  getAll,
  getById,
  deleteById,
  getTypeById,
  getTypes,
  getCenters,
  exportFile,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const expense = await expenseService.create(obj);

      dispatch(success(expense));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: expenseConstants.CREATEEXPENSE_REQUEST };
  }
  function success(expense) {
    return { type: expenseConstants.CREATEEXPENSE_SUCCESS, expense };
  }
  function failure(error) {
    return { type: expenseConstants.CREATEEXPENSE_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const expense = await expenseService.deleteById(id);

      dispatch(success(expense));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(expense) {
    return { type: expenseConstants.DELETEEXPENSE_REQUEST, expense };
  }
  function success(expense) {
    return { type: expenseConstants.DELETEEXPENSE_SUCCESS, expense };
  }
  function failure(error) {
    return { type: expenseConstants.DELETEEXPENSE_FAILURE, error };
  }
}

function createType(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await expenseTypeService.create(obj);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: expenseConstants.CREATEEXPENSETYPE_REQUEST };
  }
  function success(type) {
    return { type: expenseConstants.CREATEEXPENSETYPE_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: expenseConstants.CREATEEXPENSE_FAILURE, error };
  }
}

function deleteTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await expenseTypeService.deleteById(id);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return { type: expenseConstants.DELETEEXPENSETYPE_REQUEST, item: type };
  }
  function success(type) {
    return { type: expenseConstants.DELETEEXPENSETYPE_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: expenseConstants.DELETEEXPENSETYPE_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const expense = await expenseService.update(id, obj);

      dispatch(success(expense));
      history.push('/expenses');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: expenseConstants.UPDATEEXPENSE_REQUEST };
  }
  function success(expense) {
    return { type: expenseConstants.UPDATEEXPENSE_SUCCESS, expense };
  }
  function failure(error) {
    return { type: expenseConstants.UPDATEEXPENSE_FAILURE, error };
  }
}

function updateType(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await expenseTypeService.update(id, obj);

      dispatch(success(type));
      history.push('/expense-types');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: expenseConstants.UPDATEEXPENSETYPE_REQUEST };
  }
  function success(type) {
    return { type: expenseConstants.UPDATEEXPENSETYPE_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: expenseConstants.UPDATEEXPENSETYPE_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const expenses = await expenseService.getAll(filter);

      dispatch(success(expenses));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: expenseConstants.GETALLEXPENSE_REQUEST };
  }
  function success(expenses) {
    return { type: expenseConstants.GETALLEXPENSE_SUCCESS, expenses };
  }
  function failure(error) {
    return { type: expenseConstants.GETALLEXPENSE_FAILURE, error };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const types = await expenseTypeService.getAll(filter);
      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: expenseConstants.GETTYPEEXPENSE_REQUEST };
  }
  function success(types) {
    return { type: expenseConstants.GETTYPEEXPENSE_SUCCESS, types };
  }
  function failure(error) {
    return { type: expenseConstants.GETTYPEEXPENSE_FAILURE, error };
  }
}

function getCenters(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const centers = await expenseService.getCenters(filter);
      dispatch(success(centers));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: expenseConstants.GETCENTEREXPENSE_REQUEST };
  }
  function success(centers) {
    return { type: expenseConstants.GETCENTEREXPENSE_SUCCESS, centers };
  }
  function failure(error) {
    return { type: expenseConstants.GETCENTEREXPENSE_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const expense = await expenseService.getById(id);

      dispatch(success(expense));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(expense) {
    return { type: expenseConstants.GETEXPENSE_REQUEST, expense };
  }
  function success(expense) {
    return { type: expenseConstants.GETEXPENSE_SUCCESS, expense };
  }
  function failure(error) {
    return { type: expenseConstants.GETEXPENSE_FAILURE, error };
  }
}

function getTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await expenseTypeService.getById(id);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return { type: expenseConstants.GETEXPENSETYPE_REQUEST, item: type };
  }
  function success(type) {
    return { type: expenseConstants.GETEXPENSETYPE_SUCCESS, item: type };
  }
  function failure(error) {
    return { type: expenseConstants.GETEXPENSETYPE_FAILURE, error };
  }
}

function exportFile(filter, fileType) {
  return async (dispatch) => {
    try {
      const expenses = await expenseService.getAll(filter + '&export=' + fileType);
      window.open(expenses.url, '_blank');
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}
