import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { providersActions } from '../../actions/providers.actions';
import { Button, Dropdown, Form, Label, Modal, Table } from 'semantic-ui-react';
import { InputCoins } from '../InputCoins/InputCoins';
import { SearchBar } from '../SearchBar/SearchBar';

class ModalEditQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCurrency = this.handleCurrency.bind(this);
  }

  getInitialState() {
    return {
      provider_id: null,
      name: '',
      product_id: null,
      description: null,
      type_id: '',
      part_number: '',
      item_amount: 0,
      tax_iss: null,
      ncm: null,
      tax_icms: null,
      tax_ipi: '15',
      tax_cofins: '9,25',
      amount: 0,
      item_currency: 'BRL',
      quantity: null,
      openItensEdit: false,
      submitted: false,
      itensList: [],
      quote_id: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
    dispatch(opportunityActions.getQuoteItemType());
    dispatch(opportunityActions.getQuotesItens());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
    if (e.target && e.target.name == 'quantity')
      this.handleTotal(e, { value: e.target.value, name: e.target.name });
  }

  handleProduct(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
    if (e.target.name == 'quantity')
      this.handleTotal(e, { value: e.target.value, name: e.target.name });
  }

  handleComplet(e, data) {
    const res = data.options.find((element) => element.key == data.value);
    this.setState({
      [data.name]: data.value,
      description: res.descriptions,
      ncm: res.ncm,
    });
  }

  handleTotal(e, data) {
    this.setState({ [data.name]: data.value });

    const { quantity, item_amount } = this.state;
    if ((item_amount, quantity)) {
      const { quantity, item_amount } = this.state;
      const sum = data.name == 'item_amount' ? data.value * quantity : item_amount * data.value;
      this.setState({ amount: sum });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { opportunity, dispatch, itensId, quoteId } = this.props;
    const {
      name,
      ncm,
      description,
      type_id,
      part_number,
      item_amount,
      tax_iss,
      tax_icms,
      tax_ipi,
      tax_cofins,
      amount,
      item_currency,
      quantity,
      provider_id,
    } = this.state;

    let payload = {
      quote_id: quoteId,
      name,
      product_id: 1,
      description,
      type_id,
      part_number,
      item_amount: item_amount * 100,
      tax_iss: this.formatDecimal(tax_iss.toString()),
      tax_icms: this.formatDecimal(tax_icms.toString()),
      ncm,
      tax_ipi: this.formatDecimal(tax_ipi.toString()),
      tax_cofins: this.formatDecimal(tax_cofins.toString()),
      amount: amount * 100,
      item_currency,
      quantity: Number(quantity),
      provider_id,
    };
    dispatch(opportunityActions.updateQuotesItens(opportunity.id, quoteId, itensId, payload)).then(
      () => {
        this.setState({
          submitted: false,
          openItensEdit: false,
          refresh: true,
          page: 1,
        });
        dispatch(opportunityActions.getQuotesItens(opportunity.id, quoteId));
        this.onClose();
      },
    );
  }

  handleCurrency(e) {
    const { value } = e.target;
    const formatValue = parseInt(value.replace(/[\D]+/g, ''));
    var tmp = formatValue + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

    const { name } = e.target;

    if (typeof tmp !== Number) this.setState({ [name]: 0 });

    this.setState({ [name]: tmp });
  }

  formatDecimal(value) {
    var retorno = value.replace('.', '');
    return parseFloat(retorno).toFixed(2);
  }

  onClose() {
    const { dispatch, opportunity, quoteId } = this.props;

    const initialState = {
      provider_id: null,
      quote_id: null,
      name: '',
      product_id: null,
      description: null,
      type_id: '',
      item_amount: 0,
      tax_iss: null,
      ncm: null,
      tax_icms: null,
      tax_ipi: null,
      tax_cofins: null,
      amount: null,
      item_currency: 'BRL',
      quantity: null,
      openItensEdit: false,
      submitted: false,
      itensList: [],
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      dispatch(opportunityActions.getQuotesItens(opportunity.id, quoteId));
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { opportunity, dispatch, itensId, quoteId } = this.props;

    if (
      this.props.openItensEdit &&
      (this.props.openItensEdit !== prevProps.openItensEdit || this.props.id != prevProps.id) &&
      !this.state.openItensEdit
    ) {
      this.setState({ openItensEdit: true });

      dispatch(opportunityActions.getByIdItensQuote(opportunity.id, quoteId, itensId)).then(() => {
        this.setState({
          provider_id: this.props.quotesItens.provider_id,
          part_number: this.props.quotesItens.part_number,
          description: this.props.quotesItens.description,
          ncm: this.props.quotesItens.ncm,
          type_id: this.props.quotesItens.type_id,
          quantity: this.props.quotesItens.quantity,
          item_amount: this.props.quotesItens.item_amount / 100,
          amount: this.props.quotesItens.amount / 100,
          tax_iss: this.props.quotesItens.tax_iss,
          tax_icms: this.props.quotesItens.tax_icms,
          tax_ipi: this.props.quotesItens.tax_ipi,
          tax_cofins: this.props.quotesItens.tax_cofins,
        });
      });
    }
  }

  rowContent() {
    const { quotesItens } = this.props;

    if (!quotesItens?.data || !quotesItens?.data?.length) {
      return (
        <Table.Row key={0}>
          <Table.Cell colSpan="15" textAlign="center">
            Nenhum Resultado Encontrado
          </Table.Cell>
        </Table.Row>
      );
    }
  }

  render() {
    const {
      provider_id,
      ncm,
      description,
      type_id,
      part_number,
      item_amount,
      item_currency,
      tax_iss,
      tax_icms,
      tax_ipi,
      tax_cofins,
      amount,
      quantity,
      openItensEdit,
      submitted,
    } = this.state;
    const { loading, providers, quotesItensType } = this.props;
    let providersOptions,
      quotesItensTypeOptions = [];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (quotesItensType && quotesItensType.data !== undefined) {
      quotesItensTypeOptions = quotesItensType.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal
        size="large"
        open={openItensEdit}
        closeIcon
        onClose={() => this.onClose()}
        style={{ marginTop: '100px' }}>
        <Modal.Header>Edição de Itens de Cotação</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading} style={{ marginBottom: '20px' }}>
            <Form.Group>
              <Form.Field width={4}>
                <label>Fornecedor</label>
                <Dropdown
                  placeholder="Fornecedor"
                  fluid
                  search
                  selection
                  name="provider_id"
                  options={providersOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={provider_id}
                />
                {submitted && !provider_id && (
                  <Label basic color="red" pointing>
                    Fornecedor é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Tipo</label>
                <Dropdown
                  placeholder="Tipo"
                  fluid
                  search
                  selection
                  name="type_id"
                  options={quotesItensTypeOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={type_id}
                />
                {submitted && !type_id && (
                  <Label basic color="red" pointing>
                    Tipo é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={5}>
                <label>Produto</label>
                <SearchBar
                  onSelect={(item) =>
                    this.setState({
                      part_number: item.part_number,
                      description: item.name,
                      ncm: item.ncm,
                    })
                  }
                />
              </Form.Field>
              <Form.Field width={5}>
                <label>Part Number</label>
                <input
                  name="part_number"
                  placeholder="Part Number"
                  value={part_number}
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field width={5}>
                <label>Descrição</label>
                <input
                  name="description"
                  placeholder="Descrição"
                  value={description}
                  type="text"
                  onChange={this.handleChange}
                />
                {submitted && !description && (
                  <Label basic color="red" pointing>
                    Descrição é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={5}>
                <label>NCM</label>
                <input
                  name="ncm"
                  placeholder="Ncm"
                  value={ncm}
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={3}>
                <label>Qtde</label>
                <input
                  name="quantity"
                  placeholder="Qtde"
                  type="number"
                  value={quantity}
                  onChange={this.handleChange}
                />
                {submitted && !quantity && (
                  <Label basic color="red" pointing>
                    Quantidade é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={5}>
                <label>Valor Unitário</label>
                <InputCoins
                  name={'item_amount'}
                  amount={item_amount}
                  currency={item_currency}
                  onChange={(event, data) => this.handleTotal(event, data)}
                />
                {submitted && !item_amount && (
                  <Label basic color="red" pointing>
                    Valor é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={3}>
                <label>ISS </label>
                <input
                  name="tax_iss"
                  placeholder="ISS"
                  value={tax_iss}
                  onChange={this.handleCurrency}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>ICMS </label>
                <input
                  name="tax_icms"
                  value={tax_icms}
                  placeholder="ICMS"
                  onChange={this.handleCurrency}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>IPI </label>
                <input
                  name="tax_ipi"
                  placeholder="ISS"
                  value={tax_ipi}
                  onChange={this.handleCurrency}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>COFINS </label>
                <input
                  name="tax_cofins"
                  placeholder="COFINS"
                  value={tax_cofins}
                  onChange={this.handleCurrency}
                />
              </Form.Field>
              <Form.Field width={5}>
                <label>Valor Total</label>
                <InputCoins amount={amount} currency={item_currency} onChange={this.handleChange} />
              </Form.Field>
              <Button style={{ float: 'right', marginTop: 20 }} className="secondary" type="submit">
                Criar
              </Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity, products, providers } = state;
  return {
    opportunity: opportunity.item,
    quotes: opportunity.quotes,
    quotesItens: opportunity.quotesItens,
    quotesItensType: opportunity.quotesItensType,
    products,
    providers,
    loading: opportunity.loading || false,
  };
}

const connectedModalEditQuotes = connect(mapStateToProps)(ModalEditQuotes);
export { connectedModalEditQuotes as ModalEditQuotes };
