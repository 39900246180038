import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Loader, Input, Grid, Button, GridRow, GridColumn } from 'semantic-ui-react';
import moment from 'moment';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import _ from 'lodash';
import { projectActions } from '../../actions/project.actions';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class ProjectScreen extends Component {
  STORAGE_KEY = 'ProjectScreenState';

  constructor(props) {
    super(props);

    this.state = {
      period: '',
      filter: {
        client_id: null,
        status_id: null,
        owner_id: null,
        start_date: null,
        end_date: null,
        all: 1,
        search: null,
      },
      refresh: false,
      page: 1,
      perPage: 20,
      sort: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value || null;

    this.setState({ ...filter }, () => this.getData());
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => this.getData());
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);

    dispatch(clientActions.getAll());
    dispatch(projectActions.getStatusProjects());
    dispatch(userActions.getCompanyUsers());

    let start = moment();
    let end = moment();
    if (moment().date() < 15) {
      start = start.subtract(1, 'month');
      end = end.subtract(1, 'month');
    }

    // Load previous filter from storage, if any
    const state = JSON.parse(await localStorage.getItem(this.STORAGE_KEY));

    if (state) {
      if (state.filter.start_date) {
        start = moment(state.filter.start_date).format(DATE_FORMAT);
        end = moment(state.filter.end_date).format(DATE_FORMAT);
      } else {
        start = start.startOf('month').format(DATE_FORMAT);
        end = end.endOf('month').format(DATE_FORMAT);
      }

      this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

      this.setState(state, () => this.getData());
    } else {
      start = start.startOf('month').format(DATE_FORMAT);
      end = end.endOf('month').format(DATE_FORMAT);
      this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

      this.getData();
    }
  }

  persistStateToStorage() {
    const { filter } = this.state;
    const state = { filter };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  getData() {
    const { dispatch } = this.props;

    dispatch(projectActions.getAll(this.filterParams())).then(() => {
      this.setState({ refresh: true });
      this.persistStateToStorage();
    });
  }

  filterParams() {
    const { filter, period, page, perPage, sort } = this.state;

    if (period) {
      var [startDate, endDate] = period.split(' - ');
      if (startDate) {
        filter.start_date = `${moment(startDate, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
      } else {
        filter.start_date = null;
      }

      if (endDate) {
        filter.end_date = `${moment(endDate, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      } else {
        filter.end_date = null;
      }
    } else {
      filter.start_date = null;
      filter.end_date = null;
    }

    const params = filter;

    delete params.export;
    params.page = page;
    params.sort = sort;
    params.perPage = perPage;

    return params;
  }

  downloadFile() {
    const { dispatch } = this.props;
    const filter = this.filterParams();
    filter.export = 'xlsx';
    dispatch(projectActions.exportFile(filter));
  }

  render() {
    const { projects, clients, users, status, loading } = this.props;
    const { period, filter, refresh } = this.state;
    let clientOptions,
      userOptions,
      statusOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (!projects.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <h1>Projetos</h1>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
              <Button primary onClick={() => this.props.history.push('/projects/create')}>
                Novo
              </Button>
              <Button onClick={() => this.downloadFile()}>
                <Icon name="file excel" />
                Exportar
              </Button>
            </Grid.Column>
          </Grid.Row>
          <GridRow>
            <GridColumn>
              <Input
                fluid
                icon="search"
                value={filter.search}
                onChange={(e, data) => this.handleSearch(data.value)}
                placeholder="Procurar..."
                loading={loading}
              />
            </GridColumn>
          </GridRow>
        </Grid>

        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período"
              closable={true}
              width={4}
              clearable
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />

            <Form.Select
              name="projects.client_id"
              label="Cliente"
              clearable
              search
              selection
              value={filter['projects.client_id']}
              width={4}
              options={clientOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="projects.owner_id"
              label="Responsável"
              clearable
              search
              selection
              value={filter['projects.owner_id']}
              width={4}
              options={userOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="projects.status_id"
              label="Estado"
              clearable
              search
              selection
              width={4}
              value={filter['projects.status_id']}
              options={statusOptions}
              onChange={(e, data) => this.handleChange(data)}
            />
          </Form.Group>
        </Form>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`projects/${item.id}/show`)}
            handleSort={(column) => {
              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}|desc`, sorted: 'desc', page: 1 }, () => {
                  this.getData();
                });
              } else {
                this.setState({ sort: `${column}|asc`, sorted: 'asc', page: 1 }, () => {
                  this.getData();
                });
              }
            }}
            refresh={refresh}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={['Código', 'Nome', 'Cliente', 'Responsável', 'Estado', 'Criado em']}
            columns={[
              'code',
              'name',
              'client.name',
              'owner.name',
              'status.name',
              {
                name: 'created_at',
                format: (item) =>
                  moment(item.created_at).format('DD/MM/YYYY HH:mm', { trim: false }),
              },
            ]}
            data={projects.data}
            totalPages={projects.lastPage}
            page={projects.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, clients, users } = state;
  return {
    clients,
    users: users.companyUsers,
    status: projects.status,
    projects,
    loading: projects.loading || false,
  };
}

const connectedProjectScreen = connect(mapStateToProps)(ProjectScreen);
export { connectedProjectScreen as ProjectScreen };
