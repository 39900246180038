import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import { mailsActions } from '../../actions/mails.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class MailsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(mailsActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { mails, loading } = this.props;
    const { refresh } = this.state;

    if (!mails.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>E-mails</h1>
          </Grid.Column>
        </Grid>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`mails/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(mailsActions.getAll(`page=${page}`));
            }}
            header={['Slug', 'Assunto', 'Última Alteração']}
            search={{
              slug: 'Slug',
              subject: 'Assunto',
            }}
            columns={[
              'slug',
              'subject',
              {
                name: 'updated_at',
                format: (item) => {
                  const date = moment(item.updated_at);
                  return date.isValid() ? date.format('DD/MM/YYYY HH:mm') : '-';
                },
              },
            ]}
            data={mails.data}
            totalPages={mails.lastPage}
            page={mails.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { mails } = state;

  return {
    mails,
    loading: mails.loading || false,
  };
}

const connectedMailsScreen = connect(mapStateToProps)(MailsScreen);
export { connectedMailsScreen as MailsScreen };
