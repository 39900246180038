import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';

class UserShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  handleCancel = () => this.setState({ open: false });

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(userActions.getById(match.params.id));
  }

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(userActions.deleteById(match.params.id)).then(() => this.props.history.push('/users'));
  }

  editById() {
    const { match } = this.props;
    this.props.history.push(`/users/${match.params.id}/edit`);
  }

  render() {
    const { users, loading } = this.props;
    const { open } = this.state;

    if (!users) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{users.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Nome: </h4>
          {users.name}
          <h4>Username: </h4>
          {users.username}
          <h4>Email: </h4>
          {users.email}
          <h4>Perfil: </h4>
          {users.role?.name || '-'}
          <h4>Cliente: </h4>
          {users.client?.name || ' - '}
          <h4>Status: </h4>
          {users.active ? 'Ativo' : 'Inativo'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users: users.item,
    loading: users.loading,
  };
}

const connectedUserShowScreen = connect(mapStateToProps)(UserShowScreen);
export { connectedUserShowScreen as UserShowScreen };
