import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Form, Header, Icon, Loader } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { reportActions } from '../../actions/report.actions';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';
import { projectActions } from '../../actions/project.actions';
import { teamActions } from '../../actions/team.actions';
import { activityActions } from '../../actions/activity.actions';
import CurrencyFormat from 'react-currency-format';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class ActivityScreen extends Component {
  STORAGE_KEY = 'ActivityScreenState';

  constructor(props) {
    super(props);

    this.state = {
      period: '',
      filter: {
        timestamp_start: null,
        timestamp_end: null,
      },
      page: 1,
      sort: 'timestamp_start|desc',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    const filterOptions = 'perPage=10000';

    dispatch(userActions.getCompanyUsers());
    dispatch(clientActions.getAll());
    dispatch(teamActions.getAll());
    dispatch(activityActions.getTypes(filterOptions));

    let start = moment();
    let end = moment();
    if (moment().date() < 15) {
      start = start.subtract(1, 'month');
      end = end.subtract(1, 'month');
    }

    start = start.startOf('month').format(DATE_FORMAT);
    end = end.endOf('month').format(DATE_FORMAT);
    this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

    // Load previous filter from storage, if any
    const state = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (state) {
      delete state.filter.export;
      this.setState(state, () => this.fetchActivities());
      dispatch(
        projectActions.getAll(
          state.filter && state.filter.client_id
            ? `projects.client_id=${state.filter.client_id}`
            : null,
        ),
      );
    } else {
      this.fetchActivities();
      dispatch(projectActions.getAll());
    }
  }

  persistStateToStorage() {
    const { filter } = this.state;
    const state = { filter };
    delete state.filter.export;
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  fetchActivities() {
    const { dispatch } = this.props;
    const params = this.filterParams();

    dispatch(reportActions.getActivities(params)).then(() => {
      this.persistStateToStorage();
    });
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value || null;
    this.setState({ filter }, () => this.fetchActivities());
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => this.fetchActivities());
  }

  filterParams() {
    const { filter, period, page, sort } = this.state;

    if (period) {
      var [startDate, endDate] = period.split(' - ');
      if (startDate) {
        filter.timestamp_start = `${moment(startDate, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
      } else {
        filter.timestamp_start = null;
      }

      if (endDate) {
        filter.timestamp_end = `${moment(endDate, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      } else {
        filter.timestamp_end = null;
      }
    } else {
      filter.timestamp_start = null;
      filter.timestamp_end = null;
    }

    const params = filter;
    params.page = page;
    params.sort = sort;

    return params;
  }

  getProjectOptions() {
    const { projects } = this.props;
    let projectOptions = [];

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
          content: (
            <div className="item-flex">
              <Header as="h4" content={item.name} subheader={item.client} />
            </div>
          ),
        };
      });
    }

    return projectOptions;
  }

  downloadFile() {
    const { dispatch } = this.props;
    dispatch(reportActions.exportFile(this.filterParams()));
  }

  render() {
    const { filter, period } = this.state;
    const { reports, loading } = this.props;
    const data = reports.data;

    if (!data) {
      return <Loader active={loading} />;
    }

    const { users, clients, projects, activityType, teams } = this.props;
    let userOptions,
      clientOptions,
      projectOptions,
      activityTypeOptions,
      teamOptions = [];

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (activityType && activityType.data !== undefined) {
      activityTypeOptions = activityType.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = this.getProjectOptions();
    }

    return (
      <Fragment>
        <Header as="h1">Atividades</Header>
        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período"
              closable={true}
              width={4}
              clearable
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />

            <Form.Select
              name="projects.client_id"
              label="Cliente"
              clearable
              search
              selection
              value={filter['projects.client_id']}
              width={4}
              options={clientOptions}
              onChange={(e, data) => {
                const { dispatch } = this.props;
                if (data.value) {
                  dispatch(projectActions.getAll(`all=true&projects.client_id=${data.value}`));
                } else {
                  dispatch(projectActions.getAll('all=true'));
                }
                this.handleChange(data);
              }}
            />

            <Form.Select
              name="activities.project_id"
              label="Projeto"
              clearable
              search
              selection
              value={filter['activities.project_id']}
              width={4}
              options={projectOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="team_id"
              label="Time"
              clearable
              search
              selection
              width={4}
              value={filter['team_id']}
              options={teamOptions}
              onChange={(e, data) => this.handleChange(data)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              name="activities.user_id"
              label="Usuário"
              clearable
              search
              selection
              width={4}
              value={filter['activities.user_id']}
              options={userOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="activities.type_id"
              label="Tipo Atividade"
              clearable
              search
              selection
              value={filter['activities.type_id']}
              width={4}
              options={activityTypeOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Button onClick={() => this.downloadFile()} width="4" label="&nbsp;">
              <Icon name="file excel" />
              Exportar
            </Form.Button>
          </Form.Group>
        </Form>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`activities/${item.id}/show`)}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.fetchActivities();
              });
            }}
            handleSort={(column) => {
              if (column == 'expenses') return false;

              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}|desc`, sorted: 'desc', page: 1 }, () => {
                  this.fetchActivities();
                });
              } else {
                this.setState({ sort: `${column}|asc`, sorted: 'asc', page: 1 }, () => {
                  this.fetchActivities();
                });
              }
            }}
            header={[
              'Cliente',
              'Projeto',
              'Tipo Atividade',
              'Descrição',
              'Usuário',
              'Início',
              'Fim',
              'Tempo',
              'BH',
              'HE',
              'Total',
              'Despesas',
            ]}
            columns={[
              {
                name: 'clients.name',
                format: (item) => (item.client ? item.client.name : '-'),
              },
              {
                name: 'projects.name',
                format: (item) => item.project.name,
              },
              {
                name: 'types.name',
                format: (item) => item.type.name,
              },
              {
                name: 'activities.description',
                format: (item) => item.description,
              },
              {
                name: 'users.name',
                format: (item) => item.user.name,
              },
              {
                name: 'timestamp_start',
                format: (item) => moment(item.timestamp_start).format('DD/MM/YYYY HH:mm'),
              },
              {
                name: 'timestamp_end',
                format: (item) => moment(item.timestamp_end).format('DD/MM/YYYY HH:mm'),
              },
              {
                name: 'time_total',
                format: (item) =>
                  moment.duration(item.time_total, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_over',
                format: (item) =>
                  moment.duration(item.time_over, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_extra',
                format: (item) =>
                  moment.duration(item.time_extra, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'total',
                format: (item) =>
                  moment.duration(item.total, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'expenses_total',
                format: (item) => {
                  return (
                    <CurrencyFormat
                      value={item.expenses_total}
                      displayType={'text'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  );
                },
              },
            ]}
            data={data}
            totalPages={reports.lastPage}
            page={reports.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { reports, users, activities, projects, teams, clients } = state;
  return {
    reports,
    clients,
    teams,
    users: users.companyUsers,
    me: users.me,
    projects,
    activityType: activities.types,
    loading: activities.loading || false,
  };
}

const connectedActivityScreen = connect(mapStateToProps)(ActivityScreen);
export { connectedActivityScreen as ActivityScreen };
