export const financialCategoryConstants = {
  GETALLFINANCIALCATEGORY_REQUEST: 'GETALLFINANCIALCATEGORY_REQUEST',
  GETALLFINANCIALCATEGORY_SUCCESS: 'GETALLFINANCIALCATEGORY_SUCCESS',
  GETALLFINANCIALCATEGORY_FAILURE: 'GETALLFINANCIALCATEGORY_FAILURE',

  GETFINANCIALCATEGORY_REQUEST: 'GETFINANCIALCATEGORY_REQUEST',
  GETFINANCIALCATEGORY_SUCCESS: 'GETFINANCIALCATEGORY_SUCCESS',
  GETFINANCIALCATEGORY_FAILURE: 'GETFINANCIALCATEGORY_FAILURE',

  UPDATEFINANCIALCATEGORY_REQUEST: 'UPDATEFINANCIALCATEGORY_REQUEST',
  UPDATEFINANCIALCATEGORY_SUCCESS: 'UPDATEFINANCIALCATEGORY_SUCCESS',
  UPDATEFINANCIALCATEGORY_FAILURE: 'UPDATEFINANCIALCATEGORY_FAILURE',

  CREATEFINANCIALCATEGORY_REQUEST: 'CREATEFINANCIALCATEGORY_REQUEST',
  CREATEFINANCIALCATEGORY_SUCCESS: 'CREATEFINANCIALCATEGORY_SUCCESS',
  CREATEFINANCIALCATEGORY_FAILURE: 'CREATEFINANCIALCATEGORY_FAILURE',

  DELETEFINANCIALCATEGORY_REQUEST: 'DELETEFINANCIALCATEGORY_REQUEST',
  DELETEFINANCIALCATEGORY_SUCCESS: 'DELETEFINANCIALCATEGORY_SUCCESS',
  DELETEFINANCIALCATEGORY_FAILURE: 'DELETEFINANCIALCATEGORY_FAILURE',

  GETGROUPS_FAILURE: 'GETGROUPS_FAILURE',
  GETGROUPS_REQUEST: 'GETGROUPS_REQUEST',
  GETGROUPS_SUCCESS: 'GETGROUPS_SUCCESS',
};
