import { expenseConstants } from '../constants/expense.constants';

export function expenses(state = {}, action) {
  switch (action.type) {
    case expenseConstants.GETALLEXPENSES_REQUEST:
    case expenseConstants.CREATEEXPENSE_REQUEST:
    case expenseConstants.CREATEEXPENSETYPE_REQUEST:
    case expenseConstants.UPDATEEXPENSETYPE_REQUEST:
    case expenseConstants.DELETEEXPENSETYPE_REQUEST:
    case expenseConstants.DELETEEXPENSE_REQUEST:
    case expenseConstants.GETEXPENSETYPE_REQUEST:
    case expenseConstants.GETMEEXPENSE_REQUEST:
    case expenseConstants.GETTEAMEXPENSE_REQUEST:
    case expenseConstants.GETTYPEEXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case expenseConstants.CREATEEXPENSETYPE_SUCCESS:
    case expenseConstants.UPDATEEXPENSETYPE_SUCCESS:
    case expenseConstants.DELETEEXPENSETYPE_SUCCESS:
    case expenseConstants.DELETEEXPENSE_SUCCESS:
    case expenseConstants.GETEXPENSETYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case expenseConstants.CREATEEXPENSE_SUCCESS:
    case expenseConstants.GETEXPENSE_SUCCESS:
      return {
        ...state,
        item: action.expense,
        loading: false,
      };
    case expenseConstants.GETALLEXPENSE_SUCCESS:
    case expenseConstants.GETMEEXPENSE_SUCCESS:
    case expenseConstants.GETTEAMEXPENSE_SUCCESS:
      return {
        ...state,
        ...action.expenses,
        loading: false,
      };
    case expenseConstants.GETTYPEEXPENSE_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case expenseConstants.GETCENTEREXPENSE_SUCCESS:
      return {
        ...state,
        centers: action.centers,
        loading: false,
      };
    case expenseConstants.CREATEEXPENSE_FAILURE:
    case expenseConstants.CREATEEXPENSETYPE_FAILURE:
    case expenseConstants.GETALLEXPENSE_FAILURE:
    case expenseConstants.GETEXPENSETYPE_FAILURE:
    case expenseConstants.UPDATEEXPENSETYPE_FAILURE:
    case expenseConstants.DELETEEXPENSETYPE_FAILURE:
    case expenseConstants.DELETEEXPENSE_FAILURE:
    case expenseConstants.GETMEEXPENSES_FAILURE:
    case expenseConstants.GETTEAMEXPENSES_FAILURE:
    case expenseConstants.GETTYPEEXPENSES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
