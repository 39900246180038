import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal } from 'semantic-ui-react';

import { licensesActions } from '../../actions/licenses.actions';
import { alertActions } from '../../actions/alert.actions';

class ModalLicense extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleLicenseDownload = this.handleLicenseDownload.bind(this);
    this.handleLicenseCopy = this.handleLicenseCopy.bind(this);
  }

  getInitialState() {
    return {
      license_id: null,
      openModalLicense: false,
    };
  }

  componentDidMount() {
    const { dispatch, license_id } = this.props;

    dispatch(licensesActions.getBase64ById(license_id));
  }

  onClose() {
    this.setState({ openModalLicense: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openModalLicense &&
      (this.props.openModalLicense !== prevProps.openModalLicense ||
        this.props.license_id != prevProps.license_id) &&
      !this.state.openModalLicense
    ) {
      this.setState({ openModalLicense: true });
    }
  }

  handleLicenseDownload() {
    const { base64 } = this.props;
    const contentType = 'text/plain';

    const linkSource = `data:${contentType};text,${base64.data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'license.lic';
    downloadLink.click();
  }

  handleLicenseCopy() {
    const { base64, dispatch } = this.props;

    navigator.clipboard
      .writeText(base64.data)
      .then(() => dispatch(alertActions.success('Licença copiada com sucesso!')))
      .catch(() => dispatch(alertActions.error('Erro ao copiar licença, tente novamente.')));
  }

  render() {
    const { openModalLicense } = this.state;
    const { base64 } = this.props;

    return (
      <Modal size="small" open={openModalLicense} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Licença</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <textarea name="base64" value={base64?.data} contentEditable={false}></textarea>
            </Form.Field>
            <Button type="button" onClick={this.handleLicenseCopy}>
              Copiar
            </Button>
            <Button primary type="button" onClick={this.handleLicenseDownload}>
              Download
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { licenses } = state;
  return {
    base64: licenses.base64,
  };
}

const connectedModalLicense = connect(mapStateToProps)(ModalLicense);
export { connectedModalLicense as ModalLicense };
