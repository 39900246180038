import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, GridColumn, GridRow, Input, Icon, Loader } from 'semantic-ui-react';
import CurrencyFormat from 'react-currency-format';
import { opportunityActions } from '../../actions/opportunity.actions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './styles.css';

moment.locale('pt-br');

class OpportunityKanbanScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      filter: {
        mine: true,
      },
    };

    this.clickListener = this.clickListener.bind(this);
  }

  componentDidMount() {
    const filterOptions = 'perPage=10000';
    this.props.dispatch(opportunityActions.getAll(filterOptions));
    this.props.dispatch(opportunityActions.getStatusBoard());

    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);
  }

  changeFilter(mineStatus) {
    const { filter } = this.state;
    filter.mine = mineStatus;
    this.setState({ filter }, () => this.getData());
  }

  persistStateToStorage() {
    const { filter } = this.state;
    const state = { filter };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  getData() {
    const { dispatch } = this.props;

    const { filter } = this.state;
    const params = {};
    _.forEach(filter, (value, key) => {
      if (value == true) {
        key = 'mine';
      }
      params[key] = value === '' ? null : value;
    });

    dispatch(opportunityActions.getAll(params)).then(() => {
      this.persistStateToStorage();
    });
  }

  onDragEnd(result, columns, items) {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const idOpp = items[source.index];
      const { dispatch } = this.props;

      let payload = {
        current_status_id: destColumn.statusId,
      };

      dispatch(opportunityActions.updateStatusChange(idOpp.id, payload)).then(() => {
        const { dispatch } = this.props;

        const filterOptions = 'perPage=10000';
        this.props.dispatch(opportunityActions.getAll(filterOptions));
        this.props.dispatch(opportunityActions.getStatusBoard());
      });
    }
  }

  clickListener(id) {
    window.addEventListener('dblclick', (event) => {
      this.props.history.push(`/opportunity/${id}/show`);
    });
  }

  render() {
    const { opportunity, loading, statusBoard } = this.props;
    const { refresh, filter } = this.state;

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>BOARDS</h1>
          </Grid.Column>
          <Grid.Row>
            <Grid.Column width={4}>
              <Button.Group fluid>
                <Button positive={filter.mine === true} onClick={() => this.changeFilter(true)}>
                  Minhas Oportunidades
                </Button>
                <Button.Or text="ou" />
                <Button positive={filter.mine === null} onClick={() => this.changeFilter(null)}>
                  Todos
                </Button>
              </Button.Group>
            </Grid.Column>
            <Grid.Column textAlign="center" width={8}>
              <Input
                fluid
                icon="search"
                value={filter.search}
                onChange={(e, data) => this.handleSearch(data.value)}
                placeholder="Procurar..."
                loading={loading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Loader active={loading} />
        {!loading && (
          <div style={{ display: 'flex', height: '100%', marginTop: '30px' }}>
            <DragDropContext
              onDragEnd={(result) => this.onDragEnd(result, statusBoard, opportunity?.data)}>
              {statusBoard?.map((columns) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#f5f5f5 ',
                      border: 'solid 1px #456C86',
                      margin: 8,
                      flexDirection: 'column',
                    }}
                    key={columns.statusId}>
                    <div className="card-tittle">
                      <h2>{columns.status}</h2>
                      <div className="card-value">
                        <div>
                          <Icon name="sticky note outline" />
                          {columns.count}
                        </div>
                        <div>
                          <Icon name="dollar sign" />
                          <CurrencyFormat
                            displayType={'text'}
                            value={columns.amountPreferential / 100 || '-'}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <Droppable
                        droppableId={(columns.statusId - 1).toString()}
                        key={columns.statusId}
                        style={{ textAlign: 'left' }}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                padding: 4,
                                width: 300,
                                minHeight: 500,
                              }}>
                              {opportunity?.data?.map((item, index) => {
                                if (item.current_status_id == columns.statusId) {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id.toString()}
                                      index={index}>
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onDoubleClick={() => this.clickListener(item.id)}
                                            style={{
                                              userSelect: 'none',
                                              padding: 16,
                                              margin: '0 0 8px 0',
                                              minHeight: '50px',
                                              backgroundColor: 'lightgrey',
                                              boxShadow: '-1px 8px 8px -7px rgba(0,0,0,0.59)',
                                              borderLeft: snapshot.isDragging
                                                ? 'solid 4px #263B4A'
                                                : 'solid 4px #456C86',
                                              ...provided.draggableProps.style,
                                            }}>
                                            <GridColumn>
                                              <GridRow>
                                                <b>{item.client?.name || '-'}</b>
                                              </GridRow>
                                              <GridRow style={{ margin: '5px 0px' }}>
                                                <p>{item.notes || '-'}</p>
                                              </GridRow>
                                              <GridRow style={{ fontSize: '12px' }}>
                                                <p>
                                                  <span style={{ fontWeight: 'bold' }}>
                                                    Código:
                                                  </span>{' '}
                                                  {item.code || '-'}
                                                </p>
                                              </GridRow>
                                            </GridColumn>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                }
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity } = state;

  return {
    opportunity,
    statusBoard: opportunity.statusBoard,
    loading: opportunity.loading || false,
  };
}

const connectedOpportunityKanbanScreen = connect(mapStateToProps)(OpportunityKanbanScreen);
export { connectedOpportunityKanbanScreen as OpportunityKanbanScreen };
