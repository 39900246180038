import moment from 'moment';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Confirm,
  CardContent,
  Dimmer,
  Grid,
  GridColumn,
  Icon,
  GridRow,
  Dropdown,
  Label,
  List,
  Loader,
} from 'semantic-ui-react';
import { opportunityActions } from '../../actions/opportunity.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Interactions } from '../../components/Interactions/Interactions';
import { ModalItens } from '../../components/Modals/ModalItens';
import { ModalEditItens } from '../../components/Modals/ModalEditItens';
import { ModalParticipants } from '../../components/Modals/ModalParticipants';
import { ModalShowQuotes } from '../../components/Modals/ModalShowQuotes';
import { ModalCreateQuotes } from '../../components/Modals/ModalCreateQuotes';
import { ModalCreateMappings } from '../../components/Modals/ModalCreateMappings';
import { ModalHistoryStatus } from '../../components/Modals/ModalHistoryStatus';
import './styles.css';

class OpportunityShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      open: false,
      openParticipants: false,
      deleteParticipantsId: null,
      openMappings: false,
      deleteMappingsId: null,
      openItensId: false,
      deleteItensId: null,
      openItens: false,
      openItensEdit: false,
      itensId: null,
      openShowQuotes: false,
      openDeleteQuotes: false,
      quoteId: null,
      openParticipantsModal: false,
      openCreateQuote: false,
      openCreateMappings: false,
      openHistoryStatus: false,
      openCreateMappingsZ: false,
      statusId: null,
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.getById(match.params.id)).then((result) => {
      const { opportunity } = this.props;

      this.setState({
        statusId: opportunity.current_status_id,
      });
    });
    dispatch(opportunityActions.getInteraction(match.params.id));
    dispatch(opportunityActions.getItens(match.params.id));
    dispatch(opportunityActions.getParticipants(match.params.id));
    dispatch(opportunityActions.getQuotes(match.params.id));
    dispatch(opportunityActions.getMappings(match.params.id));
    dispatch(opportunityActions.getStatusHistory(match.params.id));
    dispatch(opportunityActions.getStatus());
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/opportunity'),
    );
  }

  deleteParticipants(id) {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.deleteParticipants(match.params.id, id)).then(() => {
      dispatch(opportunityActions.getParticipants(match.params.id));
      dispatch(opportunityActions.getInteraction(match.params.id));
      this.setState({ deleteParticipantsId: null, openParticipants: false });
    });
  }

  deleteMappings(id) {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.deleteMappings(match.params.id, id)).then(() => {
      dispatch(opportunityActions.getMappings(match.params.id));
      dispatch(opportunityActions.getInteraction(match.params.id));
      this.setState({ deleteMappingsId: null, openMappings: false });
    });
  }

  deleteItens(id) {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.deleteItens(match.params.id, id)).then(() => {
      dispatch(opportunityActions.getItens(match.params.id));
      dispatch(opportunityActions.getInteraction(match.params.id));
      this.setState({ deleteItensId: null, openItensId: false });
    });
  }

  deleteQuotes(id) {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.deleteQuotes(match.params.id, id)).then(() => {
      dispatch(opportunityActions.getQuotes(match.params.id));
      dispatch(opportunityActions.getInteraction(match.params.id));
      this.setState({ quoteId: null, openDeleteQuotes: false });
    });
  }

  quoteItensClick(id) {
    const { match, dispatch } = this.props;

    dispatch(opportunityActions.getQuotesItens(match.params.id, id)).then(() => {
      this.setState({ openShowQuotes: true });
    });
  }

  editById() {
    const { match } = this.props;
    this.props.history.push(`/opportunity/${match.params.id}/edit`);
  }

  format(date) {
    const d = moment(date);
    return d.isValid() ? d.format('DD/MM/YYYY HH:mm:ss') : '-';
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  changeStatus(e, data) {
    const { match, dispatch } = this.props;
    dispatch(
      opportunityActions.updateStatusChange(match.params.id, { current_status_id: data.value }),
    ).then(() => {
      const { match } = this.props;
      dispatch(opportunityActions.getById(match.params.id)).then((result) => {
        const { opportunity } = this.props;

        this.setState({
          statusId: opportunity.current_status_id,
        });
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { opportunity, dispatch } = this.props;
    const { description } = this.state;

    if (description) {
      let payload = {
        text: description,
        type_id: 2,
        opportunity_id: opportunity.id,
      };

      dispatch(opportunityActions.createInteraction(opportunity.id, payload)).then(() => {
        const { match } = this.props;
        dispatch(opportunityActions.getInteraction(match.params.id));
        this.setState({
          description: '',
          type_id: 2,
          opportunity_id: opportunity.id,
          refresh: true,
          page: 1,
        });
      });
    }
  }

  render() {
    const {
      opportunity,
      address,
      loading,
      interactions,
      itens,
      participants,
      quotes,
      mappings,
      statusHistory,
      status,
    } = this.props;
    const {
      open,
      description,
      openItens,
      openParticipants,
      openParticipantsModal,
      openShowQuotes,
      openCreateQuote,
      openMappings,
      openCreateMappings,
      openHistoryStatus,
      openItensId,
      openItensEdit,
      itensId,
      openDeleteQuotes,
      statusId,
    } = this.state;

    let statusOptions = [];

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (!opportunity)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <div id="page-opportunity-show">
        <Fragment>
          <Confirm
            key={'opportunity'}
            content="Deseja remover esta oportunidade?"
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.deleteById()}
          />
          <Confirm
            key={'participant'}
            content="Deseja remover este participante?"
            open={openParticipants}
            onCancel={() => this.setState({ openParticipants: false })}
            onConfirm={() => this.deleteParticipants(this.state.deleteParticipantsId)}
          />
          <Confirm
            key={'mappings'}
            content="Deseja remover este mapeamento?"
            open={openMappings}
            onCancel={() => this.setState({ openMappings: false })}
            onConfirm={() => this.deleteMappings(this.state.deleteMappingsId)}
          />
          <Confirm
            key={'itens'}
            content="Deseja remover esta proposta?"
            open={openItensId}
            onCancel={() => this.setState({ openItensId: false })}
            onConfirm={() => this.deleteItens(this.state.deleteItensId)}
          />
          <Confirm
            key={'quote'}
            content="Deseja remover esta cotação?"
            open={openDeleteQuotes}
            onCancel={() => this.setState({ openDeleteQuotes: false })}
            onConfirm={() => this.deleteQuotes(this.state.quoteId)}
          />

          <Grid className="header-page">
            <GridColumn className="header-column-left">
              <h1>Oportunidade</h1>
              <Label className="opportunity-number">{opportunity?.code || '-'}</Label>
            </GridColumn>
            <Grid.Column className="header-column-right">
              <Dropdown
                className="btn-status"
                options={statusOptions}
                value={statusId}
                onChange={(event, data) => this.changeStatus(true, data)}
              />
              <Button icon="edit outline" onClick={() => this.editById()} />
              <Button icon="trash alternate outline" onClick={this.deleteShow} />
              <Button
                content="Histórico"
                onClick={() => this.setState({ openHistoryStatus: true })}
              />
            </Grid.Column>
          </Grid>
          <Loader active={loading} />
          <ModalItens openItens={openItens} onClose={() => this.setState({ openItens: false })} />
          <ModalEditItens
            itensId={itensId}
            openItensEdit={openItensEdit}
            onClose={() => this.setState({ openItensEdit: false })}
          />
          <ModalParticipants
            openParticipants={openParticipantsModal}
            onClose={() => this.setState({ openParticipantsModal: false })}
          />
          <ModalShowQuotes
            openShowQuotes={openShowQuotes}
            onClose={() => this.setState({ openShowQuotes: false })}
          />
          <ModalCreateQuotes
            openCreateQuote={openCreateQuote}
            onClose={() => this.setState({ openCreateQuote: false })}
          />
          <ModalCreateMappings
            openCreateMappings={openCreateMappings}
            onClose={() => this.setState({ openCreateMappings: false })}
          />
          <ModalHistoryStatus
            statusHistory={statusHistory}
            openHistoryStatus={openHistoryStatus}
            onClose={() => this.setState({ openHistoryStatus: false })}
          />

          {!loading && (
            <>
              <div className="container-inner">
                <Grid>
                  <GridRow columns={1}>
                    <GridColumn>
                      <Card fluid>
                        <Card.Content>
                          <Grid columns={2}>
                            <GridRow>
                              <GridColumn>
                                <GridRow>
                                  <b>Título:</b>
                                  <span>{opportunity.title || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Cliente:</b>
                                  <span>{opportunity.client?.name || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Status:</b>
                                  <span>{opportunity.status?.name || '-'}</span>
                                </GridRow>
                              </GridColumn>

                              <GridColumn>
                                <GridRow>
                                  <GridColumn floated="right" style={{ textAlign: 'center' }}>
                                    <b>Data</b>
                                    <br />
                                    <span>
                                      {moment(opportunity.datetime).format('DD/MM/YYYY') || '-'}
                                    </span>
                                  </GridColumn>
                                </GridRow>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>
                  </GridRow>

                  <GridRow>
                    <GridColumn width={10}>
                      <Card fluid>
                        <Card.Content>
                          <Grid columns={2}>
                            <GridColumn>
                              <GridRow columns={2}>
                                <GridColumn>
                                  <b>CNPJ:</b>
                                  <span>{opportunity.client?.cnpj || '-'}</span>
                                </GridColumn>
                              </GridRow>
                              <GridRow>
                                <b>Endereço:</b>
                                <span>
                                  {address
                                    ? `${address.street ? address.street + ', ' : ' '} ${
                                        address.number ? address.number + ', ' : ' '
                                      } ${address.complement ? address.complement + ' - ' : ' '}  ${
                                        address.city ? address.city + ' - ' : ' '
                                      } ${address.state}`
                                    : ''}
                                </span>
                              </GridRow>
                            </GridColumn>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>

                    <GridColumn width={6}>
                      <Card fluid className="card-payment">
                        <CardContent>
                          <Grid>
                            <GridRow columns={2}>
                              <GridColumn>
                                <p className="card-payment-label">Gerente</p>
                                <p className="card-payment-value">
                                  {opportunity.manager?.user.name || '-'}
                                </p>
                              </GridColumn>
                              <GridColumn>
                                <p className="card-payment-label">Previsão</p>
                                <p className="card-payment-value">
                                  {opportunity.expected_date
                                    ? moment(opportunity.expected_date).format('DD/MM/YYYY')
                                    : '-'}
                                </p>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </CardContent>
                      </Card>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </div>

              <div className="all-content">
                <div className="container-wrapper container-participants container-left">
                  <Button
                    icon="plus square"
                    title="Cadastro de participantes"
                    onClick={() => this.setState({ openParticipantsModal: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />
                  <div className="container-title">
                    <h1>Participantes</h1>
                  </div>

                  <div className="list-content">
                    <DynamicTable
                      header={['Nome', 'Tipo', '']}
                      columns={[
                        {
                          name: 'name',
                          format: (data) => (
                            <>
                              <Icon name="user" className="user-icon" />
                              {data.user?.name}
                            </>
                          ),
                        },
                        {
                          name: 'type',
                          format: (data) => <>{data.type?.name}</>,
                        },
                        {
                          name: 'Delete',
                          format: (data) => (
                            <>
                              <Button
                                icon="trash alternate outline"
                                floated="right"
                                onClick={() =>
                                  this.setState({
                                    deleteParticipantsId: data.id,
                                    openParticipants: true,
                                  })
                                }
                              />
                            </>
                          ),
                        },
                      ]}
                      data={participants || []}
                      totalPages={1}
                      handlePaginationChange={() => {}}
                      style="basic"
                      celled={false}
                      sortable={false}
                      showPagination={false}
                    />
                  </div>
                </div>
                <div className="container-wrapper">
                  <Button
                    icon="plus square"
                    title="Cadastro de mapeamento"
                    onClick={() => this.setState({ openCreateMappings: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />
                  <div className="container-title">
                    <h1>Mapeamento </h1>
                  </div>

                  <div className="list-content">
                    <DynamicTable
                      header={[
                        'Fornecedor',
                        'Número',
                        'Data Mapeamento',
                        'Mapeado por',
                        'Data Expiração',
                        '',
                      ]}
                      columns={[
                        {
                          name: 'provider',
                          format: (data) => <>{data.provider?.name}</>,
                        },
                        {
                          name: 'number',
                          format: (data) => <>{data.code || '-'}</>,
                        },
                        {
                          name: 'date',
                          format: (data) => (
                            <>
                              <div style={{ fontSize: '13px' }}>
                                {data.created_at == null
                                  ? '-'
                                  : moment(data.created_at).format('DD/MM/YY')}
                              </div>
                            </>
                          ),
                        },
                        {
                          name: 'mappedBy',
                          format: (data) => <>{data.mappedBy?.name}</>,
                        },
                        {
                          name: 'expiresAt',
                          format: (data) => (
                            <>
                              <div style={{ fontSize: '13px' }}>
                                {data.expires_at == null
                                  ? '-'
                                  : moment(data.expires_at).format('DD/MM/YY')}
                              </div>
                            </>
                          ),
                        },
                        {
                          name: 'Delete',
                          format: (data) => (
                            <>
                              {data.attachment_url ? (
                                <Button
                                  icon="arrow alternate circle down outline"
                                  floated="right"
                                  style={{ marginBottom: '5px' }}
                                  as="a"
                                  target="_blank"
                                  href={data.attachment_url}></Button>
                              ) : (
                                ''
                              )}
                              <Button
                                icon="trash alternate outline"
                                floated="right"
                                onClick={() =>
                                  this.setState({ deleteMappingsId: data.id, openMappings: true })
                                }
                              />
                            </>
                          ),
                        },
                      ]}
                      data={mappings || []}
                      totalPages={1}
                      handlePaginationChange={() => {}}
                      style="basic"
                      celled={false}
                      sortable={false}
                      showPagination={false}
                    />
                  </div>
                </div>
              </div>

              <div className="all-content">
                <div className="container-wrapper container-left">
                  <Button
                    icon="plus square"
                    title="Cadastro de item"
                    onClick={() => this.setState({ openCreateQuote: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />

                  <div className="container-title">
                    <h1>Cotações </h1>
                  </div>
                  <div className="list-content">
                    <DynamicTable
                      header={['Código', 'Data de criação', '']}
                      columns={[
                        {
                          name: 'Código',
                          format: (data) => (
                            <div
                              onClick={() =>
                                this.quoteItensClick(data.id) &&
                                this.setState({ openShowQuotes: true })
                              }
                              style={{ cursor: 'pointer' }}>
                              {data.code || '-'}
                            </div>
                          ),
                        },
                        {
                          name: 'Data de criação',
                          format: (data) => (
                            <div
                              onClick={() =>
                                this.quoteItensClick(data.id) &&
                                this.setState({ openShowQuotes: true })
                              }
                              style={{ cursor: 'pointer' }}>
                              {data.created_at == null
                                ? '-'
                                : moment(data.created_at).format('DD/MM/YY')}
                            </div>
                          ),
                        },
                        {
                          name: 'Delete',
                          format: (data) => (
                            <>
                              <Button
                                icon="trash alternate outline"
                                floated="right"
                                onClick={() =>
                                  this.setState({ quoteId: data.id, openDeleteQuotes: true })
                                }
                              />
                            </>
                          ),
                        },
                      ]}
                      data={quotes || []}
                      totalPages={1}
                      handlePaginationChange={() => {}}
                      style="basic"
                      celled={false}
                      sortable={false}
                      showPagination={false}
                    />
                  </div>
                </div>

                <div className="container-wrapper">
                  <Button
                    icon="plus square"
                    title="Cadastro de item"
                    onClick={() => this.setState({ openItens: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />
                  <div className="container-title">
                    <h1>Propostas</h1>
                  </div>

                  <List divided verticalAlign="middle">
                    <div className="list-content">
                      {itens?.data?.map((item) => (
                        <List.Item
                          className="list-item"
                          key={item.id}
                          style={
                            item.preferential == true
                              ? { backgroundColor: 'rgb(255 143 110)' }
                              : { backgroundColor: 'none' }
                          }>
                          <div className="list-item-icon">
                            <span>
                              <Icon name="box" className="list-item-icon-icon" />
                            </span>
                          </div>

                          <List.Content className="list-content">
                            <List.Header as="h1">{item.name || '-'}</List.Header>

                            <List.Description>
                              <div style={{ marginLeft: 5 }}>
                                <span>Data: {moment(item.date).format('DD/MM/YYYY') || '-'}</span>
                              </div>
                            </List.Description>
                          </List.Content>

                          <div className="list-item-right">
                            <h1>
                              <CurrencyFormat
                                displayType={'text'}
                                value={item.amount / 100 || '-'}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'R$ '}
                              />
                            </h1>
                          </div>
                          <div className="button-itens">
                            <Button
                              icon="edit outline"
                              floated="right"
                              style={{ marginBottom: '3px' }}
                              onClick={() =>
                                this.setState({ itensId: item.id, openItensEdit: true })
                              }
                            />
                            <Button
                              icon="trash alternate outline"
                              floated="right"
                              onClick={() =>
                                this.setState({ deleteItensId: item.id, openItensId: true })
                              }
                            />
                          </div>
                          {item.file_url ? (
                            <Button
                              style={{ padding: '30px 10px' }}
                              icon="arrow alternate circle down outline"
                              floated="right"
                              as="a"
                              target="_blank"
                              href={item.file_url}></Button>
                          ) : (
                            ''
                          )}
                        </List.Item>
                      ))}
                    </div>
                  </List>
                </div>
              </div>

              <div className="all-content">
                <div className="container-wrapper container-left">
                  <Interactions
                    interactions={interactions}
                    valueContent={description}
                    onSubmit={this.handleSubmit}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="container-wrapper">
                  <div className="container-title">
                    <h1>Descrição</h1>
                  </div>

                  <div>
                    <p>{opportunity.notes || '-'}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity } = state;
  const participants = opportunity.participants;
  const mappings = opportunity.mappings;
  const quotes = opportunity.quotes;
  const statusHistory = opportunity.statusHistory;

  return {
    opportunity: opportunity.item,
    interactions: opportunity.interaction || null,
    statusHistory: statusHistory?.data,
    status: opportunity.status,
    itens: opportunity.itens || null,
    participants: participants?.data,
    quotes: quotes?.data,
    mappings: mappings?.data,
    address: opportunity.item?.client?.address || null,
    loading: opportunity.loading || false,
  };
}

const connectedOpportunityShowScreen = connect(mapStateToProps)(OpportunityShowScreen);
export { connectedOpportunityShowScreen as OpportunityShowScreen };
