import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { productsModulesActions } from '../../actions/products-modules.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class ProductsModulesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(productsModulesActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { productsModules, loading } = this.props;
    const { refresh } = this.state;

    if (!productsModules.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Módulos de Produtos</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/product/modules/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`/product/modules/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(productsModulesActions.getAll(`page=${page}`));
            }}
            header={['Produto', 'Módulo', 'Criado em']}
            search={{
              'product.name': 'Produto',
              name: 'Módulo',
              created_at: 'Criado em',
            }}
            columns={[
              'product.name',
              'name',
              {
                name: 'created_at',
                format: (item) => moment(item.created_at).format('DD/MM/YYYY', { trim: false }),
              },
            ]}
            data={productsModules.data}
            totalPages={productsModules.lastPage}
            page={productsModules.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { productsModules } = state;
  return {
    productsModules,
    loading: productsModules.loading || false,
  };
}

const connectedProductsModulesScreen = connect(mapStateToProps)(ProductsModulesScreen);
export { connectedProductsModulesScreen as ProductsModulesScreen };
