import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Header, Table } from 'semantic-ui-react';

import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import 'moment/locale/pt-br';
import _ from 'lodash';

import { reportActions } from '../../actions/report.actions';
import { userActions } from '../../actions/user.actions';
import { teamActions } from '../../actions/team.actions';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class ActivitiesPerUserScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: '',
      filter: {
        timestamp_start: null,
        timestamp_finish: null,
        team_ids: [],
        user_ids: [],
      },
    };
  }

  fetchRegisters() {
    const { dispatch } = this.props;
    const { period, filter } = this.state;

    if (period) {
      var [startDate, endDate] = period.split(' - ');
      if (startDate) {
        filter.timestamp_start = `${moment(startDate, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
      }

      if (endDate) {
        filter.timestamp_finish = `${moment(endDate, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      }
    }

    dispatch(reportActions.getActivitiesPerUserReport(filter));
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getCompanyUsers());
    dispatch(teamActions.getAll());

    let start = moment();
    let end = moment();
    if (moment().date() < 15) {
      start = start.subtract(1, 'month');
      end = end.subtract(1, 'month');
    }

    start = start.startOf('month').format(DATE_FORMAT);
    end = end.endOf('month').format(DATE_FORMAT);
    this.setState({ period: `${start} - ${end}` }, () => this.fetchRegisters());
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => this.fetchRegisters());
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value;
    this.setState({ filter }, () => this.fetchRegisters());
  }

  render() {
    const { period } = this.state;
    const { users, report, teams, loading } = this.props;
    let userOptions,
      teamOptions = [];
    console.log('LOADING', loading);
    if (users.companyUsers && users.companyUsers.data !== undefined) {
      userOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    const data = report ? report.data : [];

    return (
      <Fragment>
        <Header as="h1">Atividades por Usuário</Header>
        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período"
              closable={true}
              width={4}
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />

            <Form.Select
              name="team_ids"
              label="Times"
              clearable
              search
              selection
              multiple
              width={6}
              options={teamOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="user_ids"
              label="Usuários"
              clearable
              search
              selection
              multiple
              width={6}
              options={userOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            {/* <Form.Button
              label="&nbsp;"
              width={2}>
                <Icon name='file excel' /> Exportar
            </Form.Button> */}
          </Form.Group>
        </Form>

        {report && (
          <Fragment>
            <Header as="h2">
              Período: {report.total_days} dias ({report.business_days} dias úteis)
            </Header>
            <Header as="h3">{report.business_hours} horas úteis</Header>
          </Fragment>
        )}
        <Table loading={loading} compact padded celled fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Usuário</Table.HeaderCell>
              <Table.HeaderCell>Horas Lançadas</Table.HeaderCell>
              <Table.HeaderCell>% Lançada</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {data && data.length > 0 && (
            <Table.Body>
              {_.map(data, (row) => (
                <Table.Row key={row.user_id}>
                  <Table.Cell>{row.user}</Table.Cell>
                  <Table.Cell>
                    {moment.duration(row.time_total, 'second').format('HH:mm:ss', { trim: false })}
                  </Table.Cell>
                  <Table.Cell>
                    {Math.round((row.time_total / 3600 / report.business_hours) * 100, 2)}%
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { reports, users, teams } = state;
  return {
    report: reports.report || null,
    teams,
    users,
    loading: reports.loading || false,
  };
}

const connectedActivitiesPerUserScreen = connect(mapStateToProps)(ActivitiesPerUserScreen);
export { connectedActivitiesPerUserScreen as ActivitiesPerUserScreen };
