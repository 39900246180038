import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { leadsActions } from '../../actions/leads.actions';

class LeadsCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      notes: '',
      status_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(leadsActions.getStatus());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { title, notes, status_id } = this.state;
    const { dispatch } = this.props;
    if (title && status_id) {
      dispatch(
        leadsActions.create(
          {
            title,
            notes,
            status_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { title, notes, status_id, submitted } = this.state;

    const { status, loading } = this.props;
    let statusOptions = [];

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Nova Leads</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Título</label>
            <input name="title" placeholder="Título" value={title} onChange={this.handleChange} />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Título é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Comentário</label>
            <input
              name="notes"
              placeholder="Comentário"
              value={notes}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              name="status_id"
              options={statusOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={status_id}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { leads } = state;

  return {
    status: leads.status,
    leads,
    loading: leads.loading || false,
  };
}

const connectedLeadsCreateScreen = connect(mapStateToProps)(LeadsCreateScreen);
export { connectedLeadsCreateScreen as LeadsCreateScreen };
