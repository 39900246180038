export const providersConstants = {
  GETALLPROVIDERS_REQUEST: 'GETALLPROVIDERS_REQUEST',
  GETALLPROVIDERS_SUCCESS: 'GETALLPROVIDERS_SUCCESS',
  GETALLPROVIDERS_FAILURE: 'GETALLPROVIDERS_FAILURE',

  GETMEPROVIDERS_REQUEST: 'GETMEPROVIDERS_REQUEST',
  GETMEPROVIDERS_SUCCESS: 'GETMEPROVIDERS_SUCCESS',
  GETMEPROVIDERS_FAILURE: 'GETMEPROVIDERS_FAILURE',

  GETALLTYPEPROVIDERS_REQUEST: 'GETALLTYPEPROVIDERS_REQUEST',
  GETALLTYPEPROVIDERS_SUCCESS: 'GETALLTYPEPROVIDERS_SUCCESS',
  GETALLTYPEPROVIDERS_FAILURE: 'GETALLTYPEPROVIDERS_FAILURE',

  GETTYPEPROVIDERS_REQUEST: 'GETTYPEPROVIDERS_REQUEST',
  GETTYPEPROVIDERS_SUCCESS: 'GETTYPEPROVIDERS_SUCCESS',
  GETTYPEPROVIDERS_FAILURE: 'GETTYPEPROVIDERS_FAILURE',

  GETPROVIDERS_REQUEST: 'GETPROVIDERS_REQUEST',
  GETPROVIDERS_SUCCESS: 'GETPROVIDERS_SUCCESS',
  GETPROVIDERS_FAILURE: 'GETPROVIDERS_FAILURE',

  CREATEPROVIDERS_REQUEST: 'CREATEPROVIDERS_REQUEST',
  CREATEPROVIDERS_SUCCESS: 'CREATEPROVIDERS_SUCCESS',
  CREATEPROVIDERS_FAILURE: 'CREATEPROVIDERS_FAILURE',

  CREATETYPEPROVIDERS_REQUEST: 'CREATETYPEPROVIDERS_REQUEST',
  CREATETYPEPROVIDERS_SUCCESS: 'CREATETYPEPROVIDERS_SUCCESS',
  CREATETYPEPROVIDERS_FAILURE: 'CREATETYPEPROVIDERS_FAILURE',

  UPDATEPROVIDERS_REQUEST: 'UPDATEPROVIDERS_REQUEST',
  UPDATEPROVIDERS_SUCCESS: 'UPDATEPROVIDERS_SUCCESS',
  UPDATEPROVIDERS_FAILURE: 'UPDATEPROVIDERS_FAILURE',

  UPDATETYPEPROVIDERS_REQUEST: 'UPDATETYPEPROVIDERS_REQUEST',
  UPDATETYPEPROVIDERS_SUCCESS: 'UPDATETYPEPROVIDERS_SUCCESS',
  UPDATETYPEPROVIDERS_FAILURE: 'UPDATETYPEPROVIDERS_FAILURE',

  DELETEPROVIDERS_REQUEST: 'DELETEPROVIDERS_REQUEST',
  DELETEPROVIDERS_SUCCESS: 'DELETEPROVIDERS_SUCCESS',
  DELETEPROVIDERS_FAILURE: 'DELETEPROVIDERS_FAILURE',

  DELETETYPEPROVIDERS_REQUEST: 'DELETETYPEPROVIDERS_REQUEST',
  DELETETYPEPROVIDERS_SUCCESS: 'DELETETYPEPROVIDERS_SUCCESS',
  DELETETYPEPROVIDERS_FAILURE: 'DELETEPROVIDERSTYPE_FAILURE',

  GETALLROLES_REQUEST: 'GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'GETALLROLES_FAILURE',
};
