import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './styles.css';

class HomeScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.validPermissionTimer()) this.props.history.push('/timer');
    else if (this.validPermissionExpenses()) this.props.history.push('/expenses');
    else if (this.validPermissionOpportunity()) this.props.history.push('/opportunity');
    else this.props.history.push('/profile');
  }

  validPermissionTimer() {
    const { me } = this.props;
    if (me && me.role_permissions.some((r) => ['activities.timer'].indexOf(r) >= 0)) {
      return true;
    }
    return false;
  }

  validPermissionExpenses() {
    const { me } = this.props;
    if (me && me.role_permissions.some((r) => ['expenses.index'].indexOf(r) >= 0)) {
      return true;
    }
    return false;
  }

  validPermissionOpportunity() {
    const { me } = this.props;
    if (me && me.role_permissions.some((r) => ['opportunities.index'].indexOf(r) >= 0)) {
      return true;
    }
    return false;
  }

  render() {
    return <Fragment></Fragment>;
  }
}

function mapStateToProps(state) {
  const { reports, teams, users } = state;
  return {
    reports,
    teams,
    me: users.me,
  };
}

const connectedHomeScreen = connect(mapStateToProps)(HomeScreen);
export { connectedHomeScreen as HomeScreen };
