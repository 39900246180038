export const expenseConstants = {
  GETALLEXPENSE_REQUEST: 'GETALLEXPENSE_REQUEST',
  GETALLEXPENSE_SUCCESS: 'GETALLEXPENSE_SUCCESS',
  GETALLEXPENSE_FAILURE: 'GETALLEXPENSE_FAILURE',

  GETMEEXPENSE_REQUEST: 'GETMEEXPENSE_REQUEST',
  GETMEEXPENSE_SUCCESS: 'GETMEEXPENSE_SUCCESS',
  GETMEEXPENSE_FAILURE: 'GETMEEXPENSE_FAILURE',

  GETTEAMEXPENSE_REQUEST: 'GETTEAMEXPENSE_REQUEST',
  GETTEAMEXPENSE_SUCCESS: 'GETTEAMEXPENSE_SUCCESS',
  GETTEAMEXPENSE_FAILURE: 'GETTEAMEXPENSE_FAILURE',

  GETTYPEEXPENSE_REQUEST: 'GETTYPEEXPENSE_REQUEST',
  GETTYPEEXPENSE_SUCCESS: 'GETTYPEEXPENSE_SUCCESS',
  GETTYPEEXPENSE_FAILURE: 'GETTYPEEXPENSE_FAILURE',

  GETCENTEREXPENSE_SUCCESS: 'GETCENTEREXPENSE_SUCCESS',
  GETCENTEREXPENSE_REQUEST: 'GETCENTEREXPENSE_REQUEST',
  GETCENTEREXPENSE_FAILURE: 'GETCENTEREXPENSE_FAILURE',

  GETEXPENSE_REQUEST: 'GETEXPENSE_REQUEST',
  GETEXPENSE_SUCCESS: 'GETEXPENSE_SUCCESS',
  GETEXPENSE_FAILURE: 'GETEXPENSE_FAILURE',

  GETEXPENSETYPE_REQUEST: 'GETEXPENSETYPE_REQUEST',
  GETEXPENSETYPE_SUCCESS: 'GETEXPENSETYPE_SUCCESS',
  GETEXPENSETYPE_FAILURE: 'GETEXPENSETYPE_FAILURE',

  CREATEEXPENSE_REQUEST: 'CREATEEXPENSE_REQUEST',
  CREATEEXPENSE_SUCCESS: 'CREATEEXPENSE_SUCCESS',
  CREATEEXPENSE_FAILURE: 'CREATEEXPENSE_FAILURE',

  CREATEEXPENSETYPE_REQUEST: 'CREATEEXPENSETYPE_REQUEST',
  CREATEEXPENSETYPE_SUCCESS: 'CREATEEXPENSETYPE_SUCCESS',
  CREATEEXPENSETYPE_FAILURE: 'CREATEEXPENSE_FAILURE',

  UPDATEEXPENSE_REQUEST: 'UPDATEEXPENSE_REQUEST',
  UPDATEEXPENSE_SUCCESS: 'UPDATEEXPENSE_SUCCESS',
  UPDATEEXPENSE_FAILURE: 'UPDATEEXPENSE_FAILURE',

  UPDATEEXPENSETYPE_REQUEST: 'UPDATEEXPENSETYPE_REQUEST',
  UPDATEEXPENSETYPE_SUCCESS: 'UPDATEEXPENSETYPE_SUCCESS',
  UPDATEEXPENSETYPE_FAILURE: 'UPDATEEXPENSETYPE_FAILURE',

  DELETEEXPENSETYPE_REQUEST: 'DELETEEXPENSETYPE_REQUEST',
  DELETEEXPENSETYPE_SUCCESS: 'DELETEEXPENSETYPE_SUCCESS',
  DELETEEXPENSETYPE_FAILURE: 'DELETEEXPENSETYPE_FAILURE',

  DELETEEXPENSE_REQUEST: 'DELETEEXPENSE_REQUEST',
  DELETEEXPENSE_SUCCESS: 'DELETEEXPENSE_SUCCESS',
  DELETEEXPENSE_FAILURE: 'DELETEEXPENSE_FAILURE',
};
