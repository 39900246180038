import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { companyActions } from '../../actions/company.actions';
import { AddressForm } from '../../components/AddressForm/AddressForm';
import { maskCnpj } from '../../helpers/mask-cnpj';
import { cnpjValidation } from '../../helpers/cnpj-validation';
import { SketchPicker } from 'react-color';
import fileService from '../../services/file.service';
import * as style from './styles.css';
import AddressService from '../../services/address.service';

class CompanyEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      active: 1,
      title: '',
      cnpj: '',
      primary_color: '',
      secondary_color: '',
      tertiary_color: '',
      logo_url: '',
      login_logo_url: '',
      menu_logo_url: '',
      submitted: false,
      displayColorPickerPrimary: false,
      displayColorPickerSecondary: false,
      displayColorPickerTertiary: false,
    };

    this.addressForm = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleCnpj = this.handleCnpj.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(companyActions.getOne(match.params.id)).then(async () => {
      const { companies } = this.props;
      const company = companies.company;
      this.setState({
        name: company.name,
        title: company.title,
        primary_color: company.primary_color,
        secondary_color: company.secondary_color,
        tertiary_color: company.tertiary_color,
        cnpj: company.cnpj,
        logo_url: company.logo_url,
        menu_logo_url: company.menu_logo_url,
        login_logo_url: company.login_logo_url,
        active: company.active,
        main_address_id: company.main_address_id,
      });
      if (company.main_address_id) {
        const address = await AddressService.getById(company.main_address_id);
        this.addressForm.current.setAddress(address);
      }
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }
  handleClickPrimary = () => {
    this.setState({ displayColorPickerPrimary: !this.state.displayColorPickerPrimary });
  };

  handleClickSecondary = () => {
    this.setState({ displayColorPickerSecondary: !this.state.displayColorPickerSecondary });
  };

  handleClickTertiary = () => {
    this.setState({ displayColorPickerTertiary: !this.state.displayColorPickerTertiary });
  };

  async handleUploadLogo(e, field) {
    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);
    formData.append('public', 1);
    formData.append('dir', 'company/logos');
    const file = await fileService.uploadFile(formData);
    this.setState({ [field]: file[0].url });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      name,
      title,
      cnpj,
      primary_color,
      logo_url,
      secondary_color,
      tertiary_color,
      login_logo_url,
      menu_logo_url,
      active,
    } = this.state;
    const { dispatch, match } = this.props;

    if (!cnpjValidation(cnpj, false)) {
      return;
    }

    if (name && title && logo_url && primary_color && secondary_color && tertiary_color) {
      const address = await this.addressForm.current.save();
      let payload = {
        name,
        title,
        cnpj,
        active,
        primary_color,
        secondary_color,
        tertiary_color,
        logo_url,
        login_logo_url,
        menu_logo_url,
        main_address_id: address.id,
      };

      dispatch(companyActions.update(match.params.id, payload, this.props.history));
    }
  }

  handleCnpj(e) {
    this.setState({ cnpj: maskCnpj(e.target.value) });
  }

  render() {
    const {
      displayColorPickerPrimary,
      displayColorPickerSecondary,
      displayColorPickerTertiary,
      name,
      title,
      cnpj,
      active,
      primary_color,
      secondary_color,
      tertiary_color,
      logo_url,
      login_logo_url,
      menu_logo_url,
      submitted,
    } = this.state;
    const { loading } = this.props;

    let activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];
    const styles = {
      colorPrimary: {
        width: '100px',
        height: '25px',
        borderRadius: '2px',
        border: '1px #D0D0D0 solid',
        backgroundColor: primary_color,
      },
      colorSecondary: {
        width: '100px',
        height: '25px',
        borderRadius: '2px',
        border: '1px #D0D0D0 solid',
        backgroundColor: secondary_color,
      },
      colorTertiary: {
        width: '100px',
        height: '25px',
        borderRadius: '2px',
        border: '1px #D0D0D0 solid',
        backgroundColor: tertiary_color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Empresa</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Group>
            <Form.Field width={8}>
              <label>Nome</label>
              <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
              {submitted && !name && (
                <Label basic color="red" pointing>
                  Nome é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field width={8}>
              <label>Título</label>
              <input name="title" placeholder="Título" value={title} onChange={this.handleChange} />
              {submitted && !title && (
                <Label basic color="red" pointing>
                  Título é requerido
                </Label>
              )}
            </Form.Field>

            <Form.Field width={6}>
              <label>CNPJ</label>
              <input
                name="cnpj"
                placeholder="00.000.000/0000-00"
                value={cnpj}
                onChange={this.handleCnpj}
                maxLength="18"
              />
              {!cnpjValidation(cnpj, false) && submitted && (
                <Label basic color="red" pointing>
                  CNPJ inválido
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Grid>
            <Grid.Column mobile={8} tablet={5} computer={5}>
              <div className={style.colorPicker}>
                <div>
                  <h4>Cor Primária</h4>
                  <div style={styles.swatch} onClick={this.handleClickPrimary}>
                    <div style={styles.colorPrimary} />
                  </div>
                  {displayColorPickerPrimary ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={() => this.setState({ displayColorPickerPrimary: false })}
                      />
                      <SketchPicker
                        color={primary_color}
                        onChange={(color) => this.setState({ primary_color: color.hex })}
                      />
                    </div>
                  ) : null}
                  {submitted && !primary_color && (
                    <Label basic color="red" pointing>
                      Cor Primária é requerido
                    </Label>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={5}>
              <div className={style.colorPicker}>
                <div>
                  <h4>Cor Secundária</h4>
                  <div style={styles.swatch} onClick={this.handleClickSecondary}>
                    <div style={styles.colorSecondary} />
                  </div>
                  {displayColorPickerSecondary ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={() => this.setState({ displayColorPickerSecondary: false })}
                      />
                      <SketchPicker
                        color={secondary_color}
                        onChange={(color) => this.setState({ secondary_color: color.hex })}
                      />
                    </div>
                  ) : null}
                  {submitted && !secondary_color && (
                    <Label basic color="red" pointing>
                      Cor Secundária é requerido
                    </Label>
                  )}
                </div>
              </div>
            </Grid.Column>

            <Grid.Column mobile={8} tablet={5} computer={5}>
              <div className={style.colorPicker}>
                <div>
                  <h4>Cor Terciária</h4>
                  <div style={styles.swatch} onClick={this.handleClickTertiary}>
                    <div style={styles.colorTertiary} />
                  </div>
                  {displayColorPickerTertiary ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={() => this.setState({ displayColorPickerTertiary: false })}
                      />
                      <SketchPicker
                        color={tertiary_color}
                        onChange={(color) => this.setState({ tertiary_color: color.hex })}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid.Column>
          </Grid>
          <Form.Field width={16}>
            <label>Logo URL</label>
            <input
              name="logo_url"
              placeholder="Logo URL"
              value={logo_url}
              onChange={this.handleChange}
            />
            {submitted && !logo_url && (
              <Label basic color="red" pointing>
                Logo URL é requerido
              </Label>
            )}
            <input
              style={{ padding: '7px 0px', border: 'none' }}
              type="file"
              placeholder="Anexar Logo"
              onChange={(e) => this.handleUploadLogo(e, 'logo_url')}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width={10}>
              <label>Logo Login URL</label>
              <input
                name="login_logo_url"
                placeholder="Logo Login URL"
                value={login_logo_url}
                onChange={this.handleChange}
              />

              <input
                style={{ padding: '7px 0px', border: 'none' }}
                type="file"
                placeholder="Anexar Logo"
                onChange={(e) => this.handleUploadLogo(e, 'login_logo_url')}
              />
            </Form.Field>

            <Form.Field width={10}>
              <label>Logo Menu URL</label>
              <input
                name="menu_logo_url"
                placeholder="Logo Menu URL"
                value={menu_logo_url}
                onChange={this.handleChange}
              />
              <input
                style={{ padding: '7px 0px', border: 'none' }}
                type="file"
                placeholder="Anexar Logo"
                onChange={(e) => this.handleUploadLogo(e, 'menu_logo_url')}
              />
            </Form.Field>
          </Form.Group>
          <AddressForm ref={this.addressForm} />
          <Form.Field>
            <label>Situação</label>
            <Dropdown
              placeholder="Situação"
              name="active"
              value={active}
              onChange={(event, data) => this.handleChange(true, data)}
              options={activeOptions}
            />
          </Form.Field>

          <Button primary type="submit">
            Salvar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { companies } = state;
  return {
    companies,
    loading: companies.loading || false,
  };
}

const connectedCompanyEditScreen = connect(mapStateToProps)(CompanyEditScreen);
export { connectedCompanyEditScreen as CompanyEditScreen };
