import moment from 'moment';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { ordersActions } from '../../actions/orders.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class OrdersScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      refresh: false,
      search: '',
      sort: null,
    };
  }

  filterParams(params) {
    const { page, search, sort } = this.state;
    return { page, search, sort, ...params };
  }

  componentDidMount() {
    this.props.dispatch(ordersActions.getAll(this.filterParams())).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { orders, loading } = this.props;
    const { refresh } = this.state;

    if (!orders.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Pedidos</h1>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/orders/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`orders/${item.id}/show`)}
            refresh={refresh}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(ordersActions.getAll(this.filterParams({ page })));
            }}
            header={['#', 'Tipo', 'Nome', 'Fornecedor', 'Status', 'Criado em']}
            search={{
              id: '#',
              name: 'Nome',
              client: 'Cliente',
              'provider.name': 'Fornecedor',
            }}
            columns={[
              'id',
              {
                name: 'type',
                format: (item) => (item.type === 'I' ? 'Interno' : 'Externo'),
              },
              'name',
              {
                name: 'provider',
                format: (item) => item.provider?.name,
              },
              {
                name: 'status',
                format: (item) => item.status?.name,
              },
              {
                name: 'created_at',
                format: (item) => moment(item.created_at).format('DD/MM/YYYY HH:mm'),
              },
            ]}
            data={orders.data || []}
            totalPages={orders.lastPage || 1}
            page={orders.page || 1}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { orders } = state;

  return {
    orders,
    loading: orders?.loading || false,
  };
}

const connectedOrdersScreen = connect(mapStateToProps)(OrdersScreen);
export { connectedOrdersScreen as OrdersScreen };
