import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, FormGroup, Grid, Label } from 'semantic-ui-react';
import { financesAccountsActions } from '../../actions/finances-accounts.actions';
import { companyActions } from '../../actions/company.actions';

class FinancialAccountEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type_id: null,
      company_id: null,
      bank: '',
      bank_agency: '',
      bank_account: '',
      description: '',
      active: 1,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(financesAccountsActions.getAll());
    dispatch(financesAccountsActions.getTypes());
    dispatch(companyActions.getAll());
    dispatch(financesAccountsActions.getById(match.params.id)).then(() => {
      const { financesAccounts } = this.props;
      const account = financesAccounts.item;

      this.setState({
        company_id: account.company_id,
        type_id: account.type_id,
        name: account.name,
        description: account.description,
        bank: account.bank,
        bank_agency: account.bank_agency,
        bank_account: account.bank_account,
        active: account.active,
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, type_id, company_id, bank, bank_agency, bank_account, description, active } =
      this.state;
    const { dispatch, match } = this.props;
    if (name && company_id && type_id) {
      dispatch(
        financesAccountsActions.update(
          match.params.id,
          {
            name,
            type_id,
            company_id,
            bank,
            bank_agency,
            bank_account,
            description,
            active,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const {
      name,
      active,
      type_id,
      company_id,
      bank,
      bank_agency,
      bank_account,
      description,
      submitted,
    } = this.state;
    const { loading, companies, types } = this.props;
    let activeOptions = [],
      companyOptions = [],
      typeOptions = [];

    if (companies && companies.data !== undefined) {
      companyOptions = companies.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Conta Financeira</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <FormGroup>
            <Form.Field width={8}>
              <label>Nome</label>
              <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
              {submitted && !name && (
                <Label basic color="red" pointing>
                  Nome é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field width={6}>
              <label>Empresa</label>
              <Dropdown
                placeholder="Empresa Vinculada"
                fluid
                search
                selection
                name="company_id"
                options={companyOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={company_id}
              />
              {submitted && !company_id && (
                <Label basic color="red" pointing>
                  Empresa é requerida
                </Label>
              )}
            </Form.Field>

            <Form.Field width={6}>
              <label>Tipo</label>
              <Dropdown
                placeholder="Tipo"
                fluid
                search
                selection
                name="type_id"
                options={typeOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={type_id}
              />
              {submitted && !type_id && (
                <Label basic color="red" pointing>
                  Tipo é requerido
                </Label>
              )}
            </Form.Field>
          </FormGroup>
          <FormGroup>
            <Form.Field width={8}>
              <label>Banco</label>
              <input
                name="bank"
                placeholder="Nome Banco"
                value={bank}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={6}>
              <label>Agencia</label>
              <input
                name="bank_agency"
                placeholder="Agencia Bancária"
                value={bank_agency}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={6}>
              <label>Conta</label>
              <input
                name="bank_account"
                placeholder="Conta Bancária"
                value={bank_account}
                onChange={this.handleChange}
              />
            </Form.Field>
          </FormGroup>
          <FormGroup>
            <Form.Field width={15}>
              <label>Descrição</label>
              <input
                name="description"
                placeholder="Descrição"
                value={description}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={5}>
              <label>Status</label>
              <Dropdown
                placeholder="Status"
                name="active"
                value={active}
                fluid
                search
                selection
                defaultValue={1}
                onChange={(event, data) => this.handleChange(true, data)}
                options={activeOptions}
              />
            </Form.Field>
          </FormGroup>
          <Button primary type="submit">
            Salvar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { financesAccounts, companies } = state;
  return {
    companies,
    financesAccounts,
    types: financesAccounts.types,
    loading: financesAccounts.loading || false,
  };
}

const connectedFinancialAccountEditScreen = connect(mapStateToProps)(FinancialAccountEditScreen);
export { connectedFinancialAccountEditScreen as FinancialAccountEditScreen };
