import _ from 'lodash';
import React, { Component } from 'react';
import { Grid, Search } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { productsActions } from '../../actions/products.actions';

const initialState = { isLoading: false, results: [], value: '' };

class SearchBar extends Component {
  state = initialState;

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title });
    if (this.props.onSelect) {
      this.props.onSelect(result.item);
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(productsActions.getAll());
  }

  handleSearchChange = (e, { value }) => {
    const { products } = this.props;

    const source = products?.data?.map((item) => ({
      title: item.name || '',
      description:
        'Descrição: ' +
        item.description +
        '\nPart Number: ' +
        (!item.part_number ? '-' : item.part_number) +
        '\nNCM: ' +
        (!item.ncm ? '-' : item.ncm),
      item,
    }));

    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch),
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;

    return (
      <Grid>
        <Grid.Column>
          <Search
            fluid
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            value={value}
          />
        </Grid.Column>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const { products } = state;
  return {
    products,
    loading: false,
  };
}

const connectedSearchBar = connect(mapStateToProps)(SearchBar);
export { connectedSearchBar as SearchBar };
