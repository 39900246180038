import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';
import { productsActions } from '../../actions/products.actions';

class ProductsCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provider_id: null,
      name: '',
      description: '',
      ncm: '',
      sku: '',
      part_number: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { provider_id, name, description, sku, ncm, part_number } = this.state;
    const { dispatch } = this.props;
    if (provider_id && name && description) {
      dispatch(
        productsActions.create(
          {
            provider_id,
            name,
            description,
            sku,
            ncm,
            part_number,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { provider_id, name, description, ncm, sku, part_number, submitted } = this.state;
    const { providers, loading } = this.props;
    let providersOptions = [];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Produto</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Fornecedor</label>
            <Dropdown
              placeholder="Selecione"
              name="provider_id"
              value={provider_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={providersOptions}
            />
            {submitted && !provider_id && (
              <Label basic color="red" pointing>
                Fornecedor é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Nome</label>
            <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>NCM</label>
            <input name="ncm" placeholder="NCM" value={ncm} onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>SKU</label>
            <input name="sku" placeholder="SKU" value={sku} onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Part Number</label>
            <input
              name="part_number"
              placeholder="Part Number"
              value={part_number}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <textarea
              name="description"
              placeholder="Descrição"
              value={description}
              onChange={this.handleChange}></textarea>
            {submitted && !description && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers, products } = state;
  return {
    providers,
    products,
    loading: products.loading || providers.loading || false,
  };
}

const connectedProductsCreateScreen = connect(mapStateToProps)(ProductsCreateScreen);
export { connectedProductsCreateScreen as ProductsCreateScreen };
