import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Label, TextArea } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';
import { AddressForm } from '../../components/AddressForm/AddressForm';

import { maskCnpj } from '../../helpers/mask-cnpj';
import { cnpjValidation } from '../../helpers/cnpj-validation';

class ProvidersCreateScreen extends Component {
  intervalIdQuote = null;
  intervalIdMapping = null;

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type_id: '',
      notes: '',
      accept_quote: false,
      accept_mapping: false,
    };

    this.addressForm = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCnpj = this.handleCnpj.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(providersActions.getTypes());
  }

  componentWillUnmount() {
    clearInterval(this.intervalIdQuote);
    clearInterval(this.intervalIdMapping);
  }

  handleChange(e, data) {
    if (data) {
      const value = data.checked !== undefined ? data.checked : data.value;
      this.setState({ [data.name]: value === '' ? null : value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, stateRegistration, cnpj, type_id, accept_quote, accept_mapping, notes } =
      this.state;
    const { dispatch } = this.props;

    if (!cnpjValidation(cnpj, false)) {
      return;
    }

    const address = await this.addressForm.current.save();
    if (name && type_id) {
      dispatch(
        providersActions.create(
          {
            name,
            state_registration_number: stateRegistration,
            cnpj,
            main_address_id: address.id,
            type_id,
            notes,
            accept_quote: !!accept_quote,
            accept_mapping: !!accept_mapping,
          },
          this.props.history,
        ),
      );
    }
  }

  handleCnpj(e) {
    this.setState({ cnpj: maskCnpj(e.target.value) });
  }

  render() {
    const {
      name,
      submitted,
      stateRegistration,
      cnpj,
      type_id,
      accept_quote,
      accept_mapping,
      notes,
    } = this.state;
    const { loading, types } = this.props;
    let typesOptions = [];

    if (types && types.data !== undefined) {
      typesOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              name="type_id"
              options={typesOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type_id}
            />
            {submitted && !type_id && (
              <Label basic color="red" pointing>
                Tipo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>CNPJ</label>
            <input
              name="cnpj"
              placeholder="00.000.000/0000-00"
              value={cnpj}
              onChange={this.handleCnpj}
              maxLength="18"
            />
            {!cnpjValidation(cnpj, false) && submitted && (
              <Label basic color="red" pointing>
                CNPJ inválido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Inscrição Estadual</label>
            <input
              name="stateRegistration"
              placeholder="Inscrição Estadual"
              value={stateRegistration}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="accept_quote"
              label="Aceita Cotações"
              checked={accept_quote}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="accept_mapping"
              label="Aceita Mapeamento"
              checked={accept_mapping}
              onChange={this.handleChange}
            />
          </Form.Field>
          <AddressForm ref={this.addressForm} />
          <Form.Field>
            <label>Observações</label>
            <TextArea
              style={{ height: '100px', width: '100%' }}
              placeholder="Observações"
              name="notes"
              maxlength="1024"
              onChange={this.handleChange}
              value={notes}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;
  return {
    providers,
    types: providers.types,
    loading: providers.loading || false,
  };
}

const connectedProvidersCreateScreen = connect(mapStateToProps)(ProvidersCreateScreen);
export { connectedProvidersCreateScreen as ProvidersCreateScreen };
