export const salesConstants = {
  GETALL_SALES_REQUEST: 'GETALL_SALES_REQUEST',
  GETALL_SALES_SUCCESS: 'GETALL_SALES_SUCCESS',
  GETALL_SALES_FAILURE: 'GETALL_SALES_FAILURE',

  GET_SALES_REQUEST: 'GET_SALES_REQUEST',
  GET_SALES_SUCCESS: 'GET_SALES_SUCCESS',
  GET_SALES_FAILURE: 'GET_SALES_FAILURE',

  GET_SALESSTATUS_REQUEST: 'GET_SALESSTATUS_REQUEST',
  GET_SALESSTATUS_SUCCESS: 'GET_SALESSTATUS_SUCCESS',
  GET_SALESSTATUS_FAILURE: 'GET_SALESSTATUS_FAILURE',

  GET_SALESITEMS_REQUEST: 'GET_SALESITEMS_REQUEST',
  GET_SALESITEMS_SUCCESS: 'GET_SALESITEMS_SUCCESS',
  GET_SALESITEMS_FAILURE: 'GET_SALESITEMS_FAILURE',

  GETID_SALESITEMS_REQUEST: 'GETID_SALESITEMS_REQUEST',
  GETID_SALESITEMS_SUCCESS: 'GETID_SALESITEMS_SUCCESS',
  GETID_SALESITEMS_FAILURE: 'GETID_SALESITEMS_FAILURE',

  GET_SALESORDERS_REQUEST: 'GET_SALESORDERS_REQUEST',
  GET_SALESORDERS_SUCCESS: 'GET_SALESORDERS_SUCCESS',
  GET_SALESORDERS_FAILURE: 'GET_SALESORDERS_FAILURE',

  UPDATE_SALES_REQUEST: 'UPDATE_SALES_REQUEST',
  UPDATE_SALES_SUCCESS: 'UPDATE_SALES_SUCCESS',
  UPDATE_SALES_FAILURE: 'UPDATE_SALES_FAILURE',

  UPDATE_SALESITEMS_REQUEST: 'UPDATE_SALESITEMS_REQUEST',
  UPDATE_SALESITEMS_SUCCESS: 'UPDATE_SALESITEMS_SUCCESS',
  UPDATE_SALESITEMS_FAILURE: 'UPDATE_SALESITEMS_FAILURE',

  CREATE_SALES_REQUEST: 'CREATE_SALES_REQUEST',
  CREATE_SALES_SUCCESS: 'CREATE_SALES_SUCCESS',
  CREATE_SALES_FAILURE: 'CREATE_SALES_FAILURE',

  CREATE_SALESITEMS_REQUEST: 'CREATE_SALESITEMS_REQUEST',
  CREATE_SALESITEMS_SUCCESS: 'CREATE_SALESITEMS_SUCCESS',
  CREATE_SALESITEMS_FAILURE: 'CREATE_SALESITEMS_FAILURE',

  CREATE_SALESORDERS_REQUEST: 'CREATE_SALESORDERS_REQUEST',
  CREATE_SALESORDERS_SUCCESS: 'CREATE_SALESORDERS_SUCCESS',
  CREATE_SALESORDERS_FAILURE: 'CREATE_SALESORDERS_FAILURE',

  DELETE_SALES_REQUEST: 'DELETE_SALES_REQUEST',
  DELETE_SALES_SUCCESS: 'DELETE_SALES_SUCCESS',
  DELETE_SALES_FAILURE: 'DELETE_SALES_FAILURE',

  DELETE_SALESITEMS_REQUEST: 'DELETE_SALESITEMS_REQUEST',
  DELETE_SALESITEMS_SUCCESS: 'DELETE_SALESITEMS_SUCCESS',
  DELETE_SALESITEMS_FAILURE: 'DELETE_SALESITEMS_FAILURE',
};
