import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';

class ProviderTypeCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name } = this.state;
    const { dispatch } = this.props;
    if (name) {
      dispatch(
        providersActions.createType({
          name,
        }),
      ).then(() => {
        this.props.history.push('/provider-types');
      });
    }
  }

  render() {
    const { name, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Tipo de Fornecedor</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input
              name="name"
              placeholder="Nome do Tipo"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;
  return {
    loading: providers.loading || false,
  };
}

const connectedProviderTypeCreateScreen = connect(mapStateToProps)(ProviderTypeCreateScreen);
export { connectedProviderTypeCreateScreen as ProviderTypeCreateScreen };
