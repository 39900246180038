import { contactConstants } from '../constants/contact.constants';
import contactService from '../services/contact.service';
import { alertActions } from './alert.actions';

export const contactActions = {
  create,
  update,
  getAll,
  getById,
  deleteById,
};

function create(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const client = await contactService.create(data);

      dispatch(success(client));
      this.props.history.push('/contacts');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(client) {
    return { type: contactConstants.CREATECONTACT_REQUEST, client };
  }
  function success(client) {
    return { type: contactConstants.CREATECONTACT_SUCCESS, client };
  }
  function failure(error) {
    return { type: contactConstants.CREATECONTACT_FAILURE, error };
  }
}

function update(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const client = await contactService.update(id, obj);

      dispatch(success(client));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: contactConstants.UPDATECONTACT_REQUEST };
  }
  function success(client) {
    return { type: contactConstants.UPDATECONTACT_SUCCESS, client };
  }
  function failure(error) {
    return { type: contactConstants.UPDATECONTACT_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const clients = await contactService.getAll(filter);
      dispatch(success(clients));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: contactConstants.GETALLCONTACT_REQUEST };
  }
  function success(clients) {
    return { type: contactConstants.GETALLCONTACT_SUCCESS, clients };
  }
  function failure(error) {
    return { type: contactConstants.GETALLCONTACT_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const client = await contactService.getById(id);

      dispatch(success(client));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(client) {
    return { type: contactConstants.GETCONTACT_REQUEST, client };
  }
  function success(client) {
    return { type: contactConstants.GETCONTACT_SUCCESS, client };
  }
  function failure(error) {
    return { type: contactConstants.GETCONTACT_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const client = await contactService.deleteById(id);

      dispatch(success(client));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(client) {
    return { type: contactConstants.DELETECONTACT_REQUEST, client };
  }
  function success(client) {
    return { type: contactConstants.DELETECONTACT_SUCCESS, client };
  }
  function failure(error) {
    return { type: contactConstants.DELETECONTACT_FAILURE, error };
  }
}
