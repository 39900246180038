import { providersConstants } from '../constants/providers.constants';

export function providers(state = {}, action) {
  switch (action.type) {
    case providersConstants.GETALLPROVIDERS_REQUEST:
    case providersConstants.CREATEPROVIDERS_REQUEST:
    case providersConstants.UPDATEPROVIDERS_REQUEST:
    case providersConstants.UPDATETYPEPROVIDERS_REQUEST:
    case providersConstants.DELETEPROVIDERS_REQUEST:
    case providersConstants.DELETETYPEPROVIDERS_REQUEST:
    case providersConstants.CREATETYPEPROVIDERS_REQUEST:
    case providersConstants.GETTYPEPROVIDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case providersConstants.CREATETYPEPROVIDERS_SUCCESS:
    case providersConstants.GETTYPEPROVIDERS_SUCCESS:
    case providersConstants.DELETETYPEPROVIDERS_SUCCESS:
    case providersConstants.UPDATETYPEPROVIDERS_SUCCESS:
      return {
        ...state,
        item: action.item,
        loading: false,
      };
    case providersConstants.CREATEPROVIDERS_SUCCESS:
    case providersConstants.UPDATEPROVIDERS_SUCCESS:
    case providersConstants.DELETEPROVIDERS_SUCCESS:
    case providersConstants.GETPROVIDERS_SUCCESS:
      return {
        ...state,
        item: action.providers,
        loading: false,
      };
    case providersConstants.GETALLPROVIDERS_SUCCESS:
    case providersConstants.GETMEPROVIDERS_SUCCESS:
      return {
        ...state,
        ...action.providers,
        loading: false,
      };
    case providersConstants.GETALLTYPEPROVIDERS_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case providersConstants.CREATEPROVIDERS_FAILURE:
    case providersConstants.UPDATEPROVIDERS_FAILURE:
    case providersConstants.UPDATETYPEPROVIDERS_FAILURE:
    case providersConstants.CREATETYPEPROVIDERS_FAILURE:
    case providersConstants.DELETEPROVIDERS_FAILURE:
    case providersConstants.DELETETYPEPROVIDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
