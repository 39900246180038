export const projectConstants = {
  CREATEPROJECT_REQUEST: 'CREATEPROJECT_REQUEST',
  CREATEPROJECT_SUCCESS: 'CREATEPROJECT_SUCCESS',
  CREATEPROJECT_FAILURE: 'CREATEPROJECT_FAILURE',

  UPDATEPROJECT_REQUEST: 'UPDATEPROJECT_REQUEST',
  UPDATEPROJECT_SUCCESS: 'UPDATEPROJECT_SUCCESS',
  UPDATEPROJECT_FAILURE: 'UPDATEPROJECT_FAILURE',

  GETALLPROJECT_REQUEST: 'GETALLPROJECT_REQUEST',
  GETALLPROJECT_SUCCESS: 'GETALLPROJECT_SUCCESS',
  GETALLPROJECT_FAILURE: 'GETALLPROJECT_FAILURE',

  GETPROJECT_REQUEST: 'GETPROJECT_REQUEST',
  GETPROJECT_SUCCESS: 'GETPROJECT_SUCCESS',
  GETPROJECT_FAILURE: 'GETPROJECT_FAILURE',

  DELETEPROJECT_REQUEST: 'DELETEPROJECT_REQUEST',
  DELETEPROJECT_SUCCESS: 'DELETEPROJECT_SUCCESS',
  DELETEPROJECT_FAILURE: 'DELETEPROJECT_FAILURE',

  GETSTATUS_REQUEST: 'GETSTATUS_PROJECT_REQUEST',
  GETSTATUS_SUCCESS: 'GETSTATUS_PROJECT_SUCCESS',
  GETSTATUS_FAILURE: 'GETSTATUS_PROJECT_FAILURE',

  GETLOGS_REQUEST: 'GETLOGS_REQUEST',
  GETLOGS_SUCCESS: 'GETLOGS_SUCCESS',
  GETLOGS_FAILURE: 'GETLOGS_FAILURE',
};
