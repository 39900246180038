export const productsConstants = {
  GETALLPRODUCTS_REQUEST: 'GETALLPRODUCTS_REQUEST',
  GETALLPRODUCTS_SUCCESS: 'GETALLPRODUCTS_SUCCESS',
  GETALLPRODUCTS_FAILURE: 'GETALLPRODUCTS_FAILURE',

  GETMEPRODUCTS_REQUEST: 'GETMEPRODUCTS_REQUEST',
  GETMEPRODUCTS_SUCCESS: 'GETMEPRODUCTS_SUCCESS',
  GETMEPRODUCTS_FAILURE: 'GETMEPRODUCTS_FAILURE',

  GETALLTYPEPRODUCTS_REQUEST: 'GETALLTYPEPRODUCTS_REQUEST',
  GETALLTYPEPRODUCTS_SUCCESS: 'GETALLTYPEPRODUCTS_SUCCESS',
  GETALLTYPEPRODUCTS_FAILURE: 'GETALLTYPEPRODUCTS_FAILURE',

  GETTYPEPRODUCTS_REQUEST: 'GETTYPEPRODUCTS_REQUEST',
  GETTYPEPRODUCTS_SUCCESS: 'GETTYPEPRODUCTS_SUCCESS',
  GETTYPEPRODUCTS_FAILURE: 'GETTYPEPRODUCTS_FAILURE',

  GETPRODUCTS_REQUEST: 'GETPRODUCTS_REQUEST',
  GETPRODUCTS_SUCCESS: 'GETPRODUCTS_SUCCESS',
  GETPRODUCTS_FAILURE: 'GETPRODUCTS_FAILURE',

  CREATEPRODUCTS_REQUEST: 'CREATEPRODUCTS_REQUEST',
  CREATEPRODUCTS_SUCCESS: 'CREATEPRODUCTS_SUCCESS',
  CREATEPRODUCTS_FAILURE: 'CREATEPRODUCTS_FAILURE',

  CREATETYPEPRODUCTS_REQUEST: 'CREATETYPEPRODUCTS_REQUEST',
  CREATETYPEPRODUCTS_SUCCESS: 'CREATETYPEPRODUCTS_SUCCESS',
  CREATETYPEPRODUCTS_FAILURE: 'CREATETYPEPRODUCTS_FAILURE',

  UPDATEPRODUCTS_REQUEST: 'UPDATEPRODUCTS_REQUEST',
  UPDATEPRODUCTS_SUCCESS: 'UPDATEPRODUCTS_SUCCESS',
  UPDATEPRODUCTS_FAILURE: 'UPDATEPRODUCTS_FAILURE',

  UPDATETYPEPRODUCTS_REQUEST: 'UPDATETYPEPRODUCTS_REQUEST',
  UPDATETYPEPRODUCTS_SUCCESS: 'UPDATETYPEPRODUCTS_SUCCESS',
  UPDATETYPEPRODUCTS_FAILURE: 'UPDATETYPEPRODUCTS_FAILURE',

  DELETEPRODUCTS_REQUEST: 'DELETEPRODUCTS_REQUEST',
  DELETEPRODUCTS_SUCCESS: 'DELETEPRODUCTS_SUCCESS',
  DELETEPRODUCTS_FAILURE: 'DELETEPRODUCTS_FAILURE',

  DELETETYPEPRODUCTS_REQUEST: 'DELETETYPEPRODUCTS_REQUEST',
  DELETETYPEPRODUCTS_SUCCESS: 'DELETETYPEPRODUCTS_SUCCESS',
  DELETETYPEPRODUCTS_FAILURE: 'DELETEPRODUCTSTYPE_FAILURE',

  GETALLROLES_REQUEST: 'GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'GETALLROLES_FAILURE',
};
