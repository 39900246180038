import { productsModulesConstants } from '../constants/products-modules.constants';
import productsModulesService from '../services/products-modules.service';
import { alertActions } from './alert.actions';

export const productsModulesActions = {
  create,
  update,
  deleteById,
  getAll,
  getById,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const productsModules = await productsModulesService.create(obj);

      dispatch(success(productsModules));
      dispatch(alertActions.success('Módulo de Produto Criado'));
      history.push('/product/modules');
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return {
      type: productsModulesConstants.CREATEPRODUCTSMODULES_REQUEST,
    };
  }

  function success(productsModules) {
    return {
      type: productsModulesConstants.CREATEPRODUCTSMODULES_SUCCESS,
      productsModules,
    };
  }

  function failure(error) {
    return {
      type: productsModulesConstants.CREATEPRODUCTSMODULES_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const productsModules = await productsModulesService.deleteById(id);

      dispatch(success(productsModules));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(productsModules) {
    return {
      type: productsModulesConstants.DELETEPRODUCTSMODULES_REQUEST,
      productsModules,
    };
  }

  function success(productsModules) {
    return {
      type: productsModulesConstants.DELETEPRODUCTSMODULES_SUCCESS,
      productsModules,
    };
  }

  function failure(error) {
    return {
      type: productsModulesConstants.DELETEPRODUCTSMODULES_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const productsModules = await productsModulesService.update(id, obj);

      dispatch(success(productsModules));
      dispatch(alertActions.success('Módulo de Produto Atualizado'));
      history.push('/product/modules');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: productsModulesConstants.UPDATEPRODUCTSMODULES_REQUEST,
    };
  }

  function success(productsModules) {
    return {
      type: productsModulesConstants.UPDATEPRODUCTSMODULES_SUCCESS,
      productsModules,
    };
  }

  function failure(error) {
    return {
      type: productsModulesConstants.UPDATEPRODUCTSMODULES_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const productsModules = await productsModulesService.getAll(filter);

      dispatch(success(productsModules));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: productsModulesConstants.GETALLPRODUCTSMODULES_REQUEST,
    };
  }

  function success(productsModules) {
    return {
      type: productsModulesConstants.GETALLPRODUCTSMODULES_SUCCESS,
      productsModules,
    };
  }

  function failure(error) {
    return {
      type: productsModulesConstants.GETALLPRODUCTSMODULES_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const productsModules = await productsModulesService.getById(id);

      dispatch(success(productsModules));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(productsModules) {
    return {
      type: productsModulesConstants.GETPRODUCTSMODULES_REQUEST,
      productsModules,
    };
  }

  function success(productsModules) {
    return {
      type: productsModulesConstants.GETPRODUCTSMODULES_SUCCESS,
      productsModules,
    };
  }

  function failure(error) {
    return {
      type: productsModulesConstants.GETPRODUCTSMODULES_FAILURE,
      error,
    };
  }
}
