import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { Button, Dropdown, Grid, Header, Icon, Loader } from 'semantic-ui-react';

import moment from 'moment';
import _ from 'lodash';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { expenseActions } from '../../actions/expense.actions';
import { projectActions } from '../../actions/project.actions';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import { DateInput } from 'semantic-ui-calendar-react';
import CurrencyFormat from 'react-currency-format';

class ExpenseScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type_expense: 'me',
      refresh: false,
      project_id: null,
      search_value: null,
      search_per: null,
      type_id: null,
      user_id: null,
      timestamp_start: null,
      timestamp_end: null,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { type_expense } = this.state;

    dispatch(projectActions.getAll());
    dispatch(clientActions.getAll());
    dispatch(userActions.getCompanyUsers());
    dispatch(expenseActions.getTypes());

    this.changeTypeExpense(type_expense);
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value, page: 1 }, () => {
        this.getData();
      });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value, page: 1 }, () => {
        this.getData();
      });
    }
  }

  handleSearch(value) {
    const { search_per } = this.state;

    if (!search_per) return;

    this.setState({
      search_value: value,
    });

    if (value) {
      this.setState({ [search_per]: value });
      this.getData();
    }
  }

  validPermissionExpense() {
    const { me } = this.props;

    if (me && me.role_permissions.some((r) => ['list_expenses'].indexOf(r) >= 0)) {
      return true;
    }

    return false;
  }

  downloadFile(fileType) {
    const { dispatch } = this.props;
    if (!fileType) {
      fileType = 'xlsx';
    }

    dispatch(expenseActions.exportFile(this.filterState(), fileType));
  }

  changeTypeExpense(type) {
    this.setState({ type_expense: type, loading: true }, () => {
      this.getData();
    });
  }

  validPermissionTeam() {
    const { me } = this.props;

    if (me && me.role_permissions.some((r) => ['list_team_expenses'].indexOf(r) >= 0)) {
      return true;
    }

    return false;
  }

  filterState() {
    const filterClean = _.pickBy(this.state);

    let params = null;
    if (filterClean) {
      params = Object.keys(filterClean)
        .map((k) => {
          if (filterClean[k]) {
            let value = encodeURIComponent(filterClean[k]);
            let peer = encodeURIComponent(k);

            if (['project_id', 'user_id', 'type_id', 'client_id'].indexOf(k) !== -1) {
              peer = `expenses.${encodeURIComponent(k)}`;
            }

            if (['timestamp_start'].indexOf(k) !== -1) {
              let param = `${moment(filterClean[k], 'DD/MM/YYYY').format('YYYY-MM-DD')} 00:00:00`;
              value = encodeURIComponent(param);
            }

            if (['timestamp_end'].indexOf(k) !== -1) {
              let param = `${moment(filterClean[k], 'DD/MM/YYYY').format('YYYY-MM-DD')} 23:59:59`;
              value = encodeURIComponent(param);
            }

            return `${peer}=${value}`;
          }
        })
        .join('&');
    }

    return params;
  }

  getData() {
    const { dispatch } = this.props;

    dispatch(expenseActions.getAll(this.filterState()));
  }

  getProjectOptions() {
    const { projects } = this.props;
    let projectOptions = [];

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
          content: (
            <div className="item-flex">
              <Header as="h4" content={item.name} subheader={item.client} />
            </div>
          ),
        };
      });
    }

    return projectOptions;
  }

  render() {
    const { type_expense, project_id, user_id, type_id, client_id } = this.state;

    const { expenses, projects, users, types, clients, loading } = this.props;

    let projectOptions,
      userOptions,
      typeOptions,
      clientOptions = [];

    if (projects && projects.data !== undefined) {
      projectOptions = this.getProjectOptions();
    }

    if (users && users.companyUsers !== undefined) {
      userOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (!expenses.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Despesas</h1>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/expenses/create')}>
              Novo
            </Button>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            {(this.validPermissionExpense() || this.validPermissionTeam()) && (
              <Button.Group size="small">
                <Button
                  positive={type_expense == 'me' ? true : false}
                  onClick={() => this.changeTypeExpense('me')}>
                  Minhas Despesas
                </Button>
                <Button.Or />
                <Button
                  positive={type_expense == 'all' ? true : false}
                  onClick={() => this.changeTypeExpense('all')}>
                  Todas Despesas
                </Button>
              </Button.Group>
            )}
            <Button floated="right" positive onClick={() => this.downloadFile()}>
              <Icon name="file excel" /> Excel
            </Button>
            <Button floated="right" positive onClick={() => this.downloadFile('pdf')}>
              <Icon name="file pdf" />
              PDF
            </Button>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => {
                const { dispatch } = this.props;

                this.handleChange(true, data);
                if (data.value) {
                  dispatch(projectActions.getAll(`projects.client_id=${data.value}`));
                } else {
                  dispatch(projectActions.getAll());
                }
              }}
              value={client_id}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <Dropdown
              placeholder="Projeto"
              fluid
              search
              selection
              clearable
              name="project_id"
              options={projectOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={project_id}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <Dropdown
              placeholder="Usuário"
              fluid
              search
              selection
              clearable
              name="user_id"
              options={userOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={user_id}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              clearable
              name="type_id"
              options={typeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type_id}
            />
          </Grid.Column>

          <Grid.Column floated="right" verticalAlign="middle" mobile={16} tablet={4} computer={4}>
            <DateInput
              closable
              clearable
              dateFormat="DD/MM/YYYY"
              name="timestamp_start"
              placeholder="Data Início"
              onChange={(event, data) => {
                this.setState({ [data.name]: data.value });
                if (this.state.timestamp_end) {
                  this.handleChange(true, data);
                }
              }}
              value={this.state.timestamp_start}
              iconPosition="left"
            />
            <DateInput
              closable
              clearable
              style={{
                marginTop: '5px',
              }}
              name="timestamp_end"
              dateFormat="DD/MM/YYYY"
              placeholder="Data Fim"
              onChange={(event, data) => this.handleChange(true, data)}
              value={this.state.timestamp_end}
              iconPosition="left"
            />
          </Grid.Column>
        </Grid>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`expenses/${item.id}/show`)}
            handleSort={(column) => {
              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}:desc`, sorted: 'desc', page: 1 }, () => {
                  this.getData();
                });
              } else {
                this.setState({ sort: `${column}:asc`, sorted: 'asc', page: 1 }, () => {
                  this.getData();
                });
              }
            }}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={[
              'Descrição',
              'Tipo',
              'Usuário',
              'Cliente',
              'Projeto/Ticket',
              'CC',
              'Reembolsável',
              'Valor',
              'Data',
            ]}
            columns={[
              'description',
              'type',
              'user',
              'client',
              'project_ticket',
              'expense_center_abbrev',
              {
                name: 'refundable',
                format: (item) => (item.refundable ? 'Sim' : 'Não'),
              },
              {
                name: 'amount',
                format: (item) => {
                  return item.kilometer ? (
                    item.kilometer
                  ) : (
                    <CurrencyFormat
                      value={item.amount}
                      displayType={'text'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  );
                },
              },
              {
                name: 'date',
                format: (item) => moment(item.date).format('DD/MM/YYYY'),
              },
            ]}
            data={expenses.data}
            totalPages={expenses.lastPage}
            page={expenses.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { expenses, users, projects, clients } = state;
  return {
    expenses,
    clients,
    users,
    me: users.me,
    projects,
    types: expenses.types,
    loading: expenses.loading || false,
  };
}

const connectedExpenseScreen = connect(mapStateToProps)(ExpenseScreen);
export { connectedExpenseScreen as ExpenseScreen };
