import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class ModalHistoryStatus extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      openHistoryStatus: false,
    };
  }

  onClose() {
    this.setState({ openHistoryStatus: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openHistoryStatus &&
      (this.props.openHistoryStatus !== prevProps.openHistoryStatus ||
        this.props.id != prevProps.id) &&
      !this.state.openHistoryStatus
    ) {
      this.setState({ openHistoryStatus: true });
    }
  }

  render() {
    const { openHistoryStatus } = this.state;
    const { statusHistory } = this.props;

    return (
      <Modal size="small" open={openHistoryStatus} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Histórico de status da oportunidade</Modal.Header>
        <Modal.Content>
          <DynamicTable
            header={['Início ', 'Fim ', 'Estado ']}
            columns={[
              {
                name: 'inicio',
                format: (data) => (
                  <>{data.init_date == null ? '-' : moment(data.init_date).format('DD/MM/YYYY')}</>
                ),
              },
              {
                name: 'fim',
                format: (data) => (
                  <>{data.end_date == null ? '-' : moment(data.end_date).format('DD/MM/YYYY')}</>
                ),
              },
              {
                name: 'estado',
                format: (data) => <>{data.status.name}</>,
              },
            ]}
            data={statusHistory || []}
            totalPages={1}
            handlePaginationChange={() => {}}
            sortable={false}
            showPagination={false}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity } = state;
  return {
    loading: opportunity.loading || false,
  };
}

const connectedModalHistoryStatus = connect(mapStateToProps)(ModalHistoryStatus);
export { connectedModalHistoryStatus as ModalHistoryStatus };
