import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clientActions } from '../../actions/client.actions';
import { Button, Form, Label, Modal } from 'semantic-ui-react';
import { maskCnpj } from '../../helpers/mask-cnpj';
import { cnpjValidation } from '../../helpers/cnpj-validation';

class ModalClient extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleCnpj = this.handleCnpj.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      name: '',
      cnpj: null,
      openClient: false,
      submitted: false,
    };
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, cnpj } = this.state;
    const { dispatch } = this.props;
    if (!cnpjValidation(cnpj, false)) {
      return;
    }
    if (name) {
      let payload = {
        name,
        cnpj,
      };

      dispatch(clientActions.create(payload)).then(() => {
        this.setState({
          name: '',
          cnpj: null,
          submitted: false,
          openClient: false,
        });
        dispatch(clientActions.getAll());
      });
    }
  }

  onClose() {
    this.setState({ openClient: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openClient &&
      (this.props.openClient !== prevProps.openClient || this.props.id != prevProps.id) &&
      !this.state.openClient
    ) {
      this.setState({ openClient: true });
    }
  }

  handleCnpj(e) {
    this.setState({ cnpj: maskCnpj(e.target.value) });
  }

  render() {
    const { name, cnpj, submitted, openClient } = this.state;
    const { loading } = this.props;

    return (
      <Modal size="small" open={openClient} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Novo cliente</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Field>
              <label>Nome</label>
              <input
                name="name"
                placeholder="Nome do Cliente"
                value={name}
                onChange={this.handleChange}
              />
              {submitted && !name && (
                <Label basic color="red" pointing>
                  Nome é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>CNPJ</label>
              <input
                name="cnpj"
                placeholder="00.000.000/0000-00"
                value={cnpj}
                onChange={this.handleCnpj}
                maxLength="18"
              />
              {!cnpjValidation(cnpj, false) && submitted && (
                <Label basic color="red" pointing>
                  CNPJ inválido
                </Label>
              )}
            </Form.Field>
            <Button className="secondary" type="submit" disabled={!name ? true : false}>
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  return {
    clients,
    loading: clients.loading || false,
  };
}

const connectedModalClient = connect(mapStateToProps)(ModalClient);
export { connectedModalClient as ModalClient };
