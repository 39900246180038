import { activityConstants } from '../constants/activity.constants';
import activityService from '../services/activity.service';
import { alertActions } from './alert.actions';

export const activityActions = {
  create,
  createType,
  update,
  updateType,
  deleteById,
  deleteTypeById,
  getAll,
  getById,
  getTypeById,
  timer,
  getTypes,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activity = await activityService.create(obj);
      dispatch(success(activity));
    } catch (error) {
      let errorMessage = 'Erro desconhecido. Tente novamente mais tarde';
      dispatch(failure(errorMessage));
      dispatch(alertActions.error(errorMessage, true));
      throw errorMessage;
    }
  };

  function request() {
    return { type: activityConstants.CREATEACTIVITY_REQUEST };
  }
  function success(activity) {
    return { type: activityConstants.CREATEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.CREATEACTIVITY_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const activity = await activityService.deleteById(id);

      dispatch(success(activity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(activity) {
    return { type: activityConstants.DELETEACTIVITY_REQUEST, activity };
  }
  function success(activity) {
    return { type: activityConstants.DELETEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.DELETEACTIVITY_FAILURE, error };
  }
}

function deleteTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const activity = await activityService.deleteTypeById(id);

      dispatch(success(activity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(activity) {
    return { type: activityConstants.DELETETYPEACTIVITY_REQUEST, activity };
  }
  function success(activity) {
    return { type: activityConstants.DELETETYPEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.DELETETYPEACTIVITY_FAILURE, error };
  }
}

function createType(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activity = await activityService.createType(obj);
      dispatch(alertActions.success('Tipo de Atividade Criado'));
      dispatch(success(activity));
      history.push('/activitytype');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: activityConstants.CREATETYPEACTIVITY_REQUEST };
  }
  function success(activity) {
    return { type: activityConstants.CREATETYPEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.CREATETYPEACTIVITY_FAILURE, error };
  }
}

function update(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activity = await activityService.update(id, obj);

      dispatch(success(activity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: activityConstants.UPDATEACTIVITY_REQUEST };
  }
  function success(activity) {
    return { type: activityConstants.UPDATEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.UPDATEACTIVITY_FAILURE, error };
  }
}

function updateType(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activity = await activityService.updateType(id, obj);

      dispatch(success(activity));
      dispatch(alertActions.success('Tipo de Atividade Alterado'));
      history.push('/activitytype');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: activityConstants.UPDATETYPEACTIVITY_REQUEST };
  }
  function success(activity) {
    return { type: activityConstants.UPDATETYPEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.UPDATETYPEACTIVITY_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activities = await activityService.getAll(filter);

      dispatch(success(activities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: activityConstants.GETALLACTIVITY_REQUEST };
  }
  function success(activities) {
    return { type: activityConstants.GETALLACTIVITY_SUCCESS, activities };
  }
  function failure(error) {
    return { type: activityConstants.GETALLACTIVITY_FAILURE, error };
  }
}

function timer(start_date, end_date) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activities = await activityService.timer(start_date, end_date);

      dispatch(success(activities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: activityConstants.GETMEACTIVITY_REQUEST };
  }
  function success(activities) {
    return { type: activityConstants.GETMEACTIVITY_SUCCESS, activities };
  }
  function failure(error) {
    return { type: activityConstants.GETMEACTIVITY_FAILURE, error };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const types = await activityService.getTypes(filter);

      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: activityConstants.GETALLTYPEACTIVITY_REQUEST };
  }
  function success(types) {
    return { type: activityConstants.GETALLTYPEACTIVITY_SUCCESS, types };
  }
  function failure(error) {
    return { type: activityConstants.GETALLTYPEACTIVITY_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const activity = await activityService.getById(id);

      dispatch(success(activity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(activity) {
    return { type: activityConstants.GETACTIVITY_REQUEST, activity };
  }
  function success(activity) {
    return { type: activityConstants.GETACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.GETACTIVITY_FAILURE, error };
  }
}

function getTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const activity = await activityService.getTypeById(id);

      dispatch(success(activity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(activity) {
    return { type: activityConstants.GETTYPEACTIVITY_REQUEST, activity };
  }
  function success(activity) {
    return { type: activityConstants.GETTYPEACTIVITY_SUCCESS, activity };
  }
  function failure(error) {
    return { type: activityConstants.GETTYPEACTIVITY_FAILURE, error };
  }
}
