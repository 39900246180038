import { userConstants } from '../constants/user.constants';
import userService from '../services/user.service';
import { alertActions } from './alert.actions';

export const userActions = {
  create,
  update,
  editMe,
  login,
  logout,
  getRoles,
  getAll,
  getClientUsers,
  getCompanyUsers,
  getUserMe,
  getById,
  deleteById,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await userService.create(obj);
      dispatch(success(user));
      dispatch(alertActions.success('Usuário criado'));
      history.push('/users');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.CREATEUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.CREATEUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CREATEUSER_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await userService.update(id, obj);
      dispatch(success(user));
      dispatch(alertActions.success('Usuário alterado'));
      history.push('/users');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.UPDATEUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.UPDATEUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATEUSER_FAILURE, error };
  }
}

function editMe(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const me = await userService.updateMe(obj);

      dispatch(success(me));
      dispatch(alertActions.success('Atualização Realizada com Sucesso'));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: userConstants.UPDATEUSERME_REQUEST };
  }
  function success(me) {
    return { type: userConstants.UPDATEUSERME_SUCCESS, me };
  }
  function failure(error) {
    return { type: userConstants.UPDATEUSERME_FAILURE, error };
  }
}

function login(email, password) {
  return async (dispatch) => {
    dispatch(request({ email }));

    try {
      const user = await userService.login(email, password);

      dispatch(success(user));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error, true));

      throw error;
    }
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return async (dispatch) => {
    try {
      await userService.logout();
      localStorage.removeItem('user');
      localStorage.removeItem('me');

      dispatch(success());
    } catch (error) {
      return error;
    }
  };

  function success() {
    return { type: userConstants.LOGOUT };
  }
}

function getRoles() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const roles = await userService.getRoles();

      dispatch(success(roles));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETALLROLES_REQUEST };
  }
  function success(roles) {
    return { type: userConstants.GETALLROLES_SUCCESS, roles };
  }
  function failure(error) {
    return { type: userConstants.GETALLROLES_FAILURE, error };
  }
}

function getUserMe() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const me = await userService.getUserMe();

      dispatch(success(me));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETUSERME_REQUEST };
  }
  function success(me) {
    return { type: userConstants.GETUSERME_SUCCESS, me };
  }
  function failure(error) {
    return { type: userConstants.GETUSERME_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const users = await userService.getAll(filter);

      dispatch(success(users));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETALLUSER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALLUSER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALLUSER_FAILURE, error };
  }
}

function getClientUsers(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      if (!filter) {
        filter = { client: 'any' };
      }

      const users = await userService.getAll(filter);

      dispatch(success(users));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETCLIENTUSER_REQUEST };
  }
  function success(clientUsers) {
    return { type: userConstants.GETCLIENTUSER_SUCCESS, clientUsers };
  }
  function failure(error) {
    return { type: userConstants.GETCLIENTUSER_FAILURE, error };
  }
}

function getCompanyUsers() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const users = await userService.getAll({ client: 'null' });

      dispatch(success(users));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETCOMPANYUSER_REQUEST };
  }
  function success(companyUsers) {
    return { type: userConstants.GETCOMPANYUSER_SUCCESS, companyUsers };
  }
  function failure(error) {
    return { type: userConstants.GETCOMPANYUSER_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const user = await userService.getById(id);

      dispatch(success(user));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(user) {
    return { type: userConstants.GETUSER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GETUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const user = await userService.deleteById(id);

      dispatch(success(user));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(user) {
    return { type: userConstants.DELETEUSER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.DELETEUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.DELETEUSER_FAILURE, error };
  }
}
