import { licensesConstants } from '../constants/licenses.constants';

export function licenses(state = {}, action) {
  switch (action.type) {
    case licensesConstants.GETALL_LICENSES_REQUEST:
    case licensesConstants.CREATE_LICENSES_REQUEST:
    case licensesConstants.DELETE_LICENSES_REQUEST:
    case licensesConstants.GET_LICENSES_REQUEST:
    case licensesConstants.GETBASE64_LICENSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case licensesConstants.CREATE_LICENSES_SUCCESS:
    case licensesConstants.DELETE_LICENSES_SUCCESS:
    case licensesConstants.GET_LICENSES_SUCCESS:
      return {
        ...state,
        item: action.licenses,
        loading: false,
      };
    case licensesConstants.GETALL_LICENSES_SUCCESS:
      return {
        ...state,
        ...action.licenses,
        loading: false,
      };
    case licensesConstants.GETBASE64_LICENSES_SUCCESS:
      return {
        ...state,
        base64: action.base64,
        loading: false,
      };
    case licensesConstants.CREATE_LICENSES_FAILURE:
    case licensesConstants.DELETE_LICENSES_FAILURE:
    case licensesConstants.GET_LICENSES_FAILURE:
    case licensesConstants.GETALL_LICENSES_FAILURE:
    case licensesConstants.GETBASE64_LICENSES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
