import { activityConstants } from '../constants/activity.constants';

export function activities(state = {}, action) {
  switch (action.type) {
    case activityConstants.GETALLACTIVITY_REQUEST:
    case activityConstants.CREATEACTIVITY_REQUEST:
    case activityConstants.UPDATEACTIVITY_REQUEST:
    case activityConstants.UPDATETYPEACTIVITY_REQUEST:
    case activityConstants.DELETEACTIVITY_REQUEST:
    case activityConstants.DELETETYPEACTIVITY_REQUEST:
    case activityConstants.CREATETYPEACTIVITY_REQUEST:
    case activityConstants.GETTYPEACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case activityConstants.CREATETYPEACTIVITY_SUCCESS:
    case activityConstants.GETTYPEACTIVITY_SUCCESS:
    case activityConstants.DELETETYPEACTIVITY_SUCCESS:
    case activityConstants.UPDATETYPEACTIVITY_SUCCESS:
      return {
        ...state,
        itemType: action.activity,
        loading: false,
      };
    case activityConstants.CREATEACTIVITY_SUCCESS:
    case activityConstants.UPDATEACTIVITY_SUCCESS:
    case activityConstants.DELETEACTIVITY_SUCCESS:
    case activityConstants.GETACTIVITY_SUCCESS:
      return {
        ...state,
        item: action.activity,
        loading: false,
      };
    case activityConstants.GETALLACTIVITY_SUCCESS:
    case activityConstants.GETMEACTIVITY_SUCCESS:
      return {
        ...state,
        ...action.activities,
        loading: false,
      };
    case activityConstants.GETALLTYPEACTIVITY_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case activityConstants.CREATEACTIVITY_FAILURE:
    case activityConstants.UPDATEACTIVITY_FAILURE:
    case activityConstants.UPDATETYPEACTIVITY_FAILURE:
    case activityConstants.CREATETYPEACTIVITY_FAILURE:
    case activityConstants.DELETEACTIVITY_FAILURE:
    case activityConstants.DELETETYPEACTIVITY_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
