import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import moment from 'moment';

function ContracsTable({ contracts }) {
  return (
    <>
      <h4>Contratos com Cliente</h4>
      {contracts && contracts.length > 0 ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data (Início e Fim)</Table.HeaderCell>
              <Table.HeaderCell>Horas (Total/Gastas)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {contracts.map((contract) => {
              const hours_spent = moment.duration(contract.hours_spent, 'hour');
              const hours_total = moment.duration(contract.hours_qty, 'hour');

              const isNegative =
                (hours_total.asSeconds() !== 0 || hours_spent.asSeconds() !== 0) &&
                hours_spent.asSeconds() >= hours_total.asSeconds();
              const isWarning =
                !isNegative &&
                (hours_total.asSeconds() !== 0 || hours_spent.asSeconds() !== 0) &&
                hours_spent.asSeconds() >= hours_total.asSeconds() * 0.6;

              return (
                <Table.Row key={contract.id} warning={isWarning} negative={isNegative}>
                  <Table.Cell>{contract.type.name}</Table.Cell>
                  <Table.Cell>
                    {moment(contract.start_date).format('DD/MM/YYYY')} -{' '}
                    {moment(contract.finish_date).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {hours_total.format('HH:mm', { trim: false })}/
                    {hours_spent.format('HH:mm', { trim: false })}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Label basic color="red" pointing>
          Este cliente não tem nenhum contrato ativo
        </Label>
      )}
    </>
  );
}

export default ContracsTable;
