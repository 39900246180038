import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import { managersActions } from '../../actions/managers.actions';

class ManagersEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: null,
      client_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(clientActions.getAll());
    dispatch(userActions.getCompanyUsers());
    dispatch(managersActions.getById(match.params.id)).then(() => {
      const { managers } = this.props;

      this.setState({
        user_id: managers.user_id,
        client_id: managers.client_id,
      });
    });
  }

  handleChange(event, data) {
    const { name, value } = data;
    this.setState({ [name]: value === '' ? null : value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { user_id, client_id } = this.state;
    const { dispatch, match } = this.props;
    if (user_id) {
      dispatch(
        managersActions.update(
          match.params.id,
          {
            user_id,
            client_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { user_id, client_id, submitted } = this.state;
    const { loading, clients, users } = this.props;
    let clientOptions,
      userOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Gerente de Conta</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(event, data)}
              value={client_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Usuário</label>
            <Dropdown
              placeholder="Usuário"
              fluid
              search
              selection
              name="user_id"
              options={userOptions}
              onChange={this.handleChange}
              value={user_id}
            />
            {submitted && !user_id && (
              <Label basic color="red" pointing>
                Usuário é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { managers, clients, users } = state;
  return {
    clients,
    managers,
    users: users.companyUsers,
    loading: managers.loading || false,
  };
}

const connectedManagersEditScreen = connect(mapStateToProps)(ManagersEditScreen);
export { connectedManagersEditScreen as ManagersEditScreen };
