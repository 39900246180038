import { salesConstants } from '../constants/sales.constants';
import salesService from '../services/sales.service';
import { alertActions } from './alert.actions';

export const salesActions = {
  create,
  deleteById,
  getAll,
  getById,
  update,
  getItems,
  getStatus,
  createItems,
  updateItems,
  getByIdItems,
  deleteItems,
  getOrders,
  createOrders,
  synchronizeOpportunitiesFromSalesForce,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const sales = await salesService.create(obj);

      dispatch(success(sales));
      dispatch(alertActions.success('Venda Criada'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: salesConstants.CREATE_SALES_REQUEST,
    };
  }

  function success(sales) {
    return {
      type: salesConstants.CREATE_SALES_SUCCESS,
      sales,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.CREATE_SALES_FAILURE,
      error,
    };
  }
}

function createItems(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const items = await salesService.createItems(id, obj);

      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: salesConstants.CREATE_SALESITEMS_REQUEST,
    };
  }

  function success(items) {
    return {
      type: salesConstants.CREATE_SALESITEMS_SUCCESS,
      items,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.CREATE_SALESITEMS_FAILURE,
      error,
    };
  }
}

function getByIdItems(sale_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const items = await salesService.getByIdItems(sale_id, id);

      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(items) {
    return {
      type: salesConstants.GETID_SALESITEMS_REQUEST,
      saleItem: items,
    };
  }

  function success(items) {
    return {
      type: salesConstants.GETID_SALESITEMS_SUCCESS,
      saleItem: items,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GETID_SALESITEMS_FAILURE,
      error,
    };
  }
}

function updateItems(sale_id, id, payload) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const items = await salesService.updateItems(sale_id, id, payload);

      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(items) {
    return {
      type: salesConstants.UPDATE_SALESITEMS_REQUEST,
      saleItem: items,
    };
  }

  function success(items) {
    return {
      type: salesConstants.UPDATE_SALESITEMS_SUCCESS,
      saleItem: items,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.UPDATE_SALESITEMS_FAILURE,
      error,
    };
  }
}

function createOrders(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const ordersSale = await salesService.createOrders(id, obj);

      dispatch(success(ordersSale));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: salesConstants.CREATE_SALESORDERS_REQUEST,
    };
  }

  function success(ordersSale) {
    return {
      type: salesConstants.CREATE_SALESORDERS_SUCCESS,
      ordersSale,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.CREATE_SALESORDERS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const sales = await salesService.deleteById(id);

      dispatch(success(sales));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(sales) {
    return {
      type: salesConstants.DELETE_SALES_REQUEST,
      sales,
    };
  }

  function success(sales) {
    return {
      type: salesConstants.DELETE_SALES_SUCCESS,
      sales,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.DELETE_SALES_FAILURE,
      error,
    };
  }
}

function deleteItems(sale_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const items = await salesService.deleteItems(sale_id, id);

      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(items) {
    return {
      type: salesConstants.DELETE_SALESITEMS_REQUEST,
      items,
    };
  }

  function success(items) {
    return {
      type: salesConstants.DELETE_SALESITEMS_SUCCESS,
      items,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.DELETE_SALESITEMS_FAILURE,
      error,
    };
  }
}

function getItems(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const items = await salesService.getItems(id, params);
      dispatch(success(items));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: salesConstants.GET_SALESITEMS_REQUEST,
    };
  }

  function success(items) {
    return {
      type: salesConstants.GET_SALESITEMS_SUCCESS,
      items,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GET_SALESITEMS_FAILURE,
      error,
    };
  }
}

function getStatus(params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const status = await salesService.getStatus(params);
      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: salesConstants.GET_SALESSTATUS_REQUEST,
    };
  }

  function success(status) {
    return {
      type: salesConstants.GET_SALESSTATUS_SUCCESS,
      status,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GET_SALESSTATUS_FAILURE,
      error,
    };
  }
}

function getOrders(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const ordersSale = await salesService.getOrders(id, params);
      dispatch(success(ordersSale));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: salesConstants.GET_SALESORDERS_REQUEST,
    };
  }

  function success(ordersSale) {
    return {
      type: salesConstants.GET_SALESORDERS_SUCCESS,
      ordersSale,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GET_SALESORDERS_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const sales = await salesService.getAll(filter);

      dispatch(success(sales));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: salesConstants.GETALL_SALES_REQUEST,
    };
  }

  function success(sales) {
    return {
      type: salesConstants.GETALL_SALES_SUCCESS,
      sales,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GETALL_SALES_FAILURE,
      error,
    };
  }
}

function synchronizeOpportunitiesFromSalesForce() {
  return async (dispatch) => {
    try {
      await salesService.synchronizeOpportunitiesFromSalesForce();
      dispatch(alertActions.success('Oportunidades Sincronizadas com Sucesso!'));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const sales = await salesService.getById(id);

      dispatch(success(sales));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(sales) {
    return {
      type: salesConstants.GET_SALES_REQUEST,
      sales,
    };
  }

  function success(sales) {
    return {
      type: salesConstants.GET_SALES_SUCCESS,
      sales,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.GET_SALES_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const sales = await salesService.update(id, obj);

      dispatch(success(sales));
      dispatch(alertActions.success('Venda Atualizada'));
      history.push(`/sales/${sales.id}/show`);
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: salesConstants.UPDATE_SALES_REQUEST,
    };
  }

  function success(sales) {
    return {
      type: salesConstants.UPDATE_SALES_SUCCESS,
      sales,
    };
  }

  function failure(error) {
    return {
      type: salesConstants.UPDATE_SALES_FAILURE,
      error,
    };
  }
}
