import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { leadsActions } from '../../actions/leads.actions';
import { managersActions } from '../../actions/managers.actions';

class LeadsScreen extends Component {
  componentDidMount() {
    this.props.dispatch(leadsActions.getAll());
  }

  render() {
    const { leads, loading } = this.props;
    if (!leads.data || loading) {
      return <Loader active={loading} />;
    }
    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Leads</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/leads/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`leads/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;

            dispatch(managersActions.getAll(`page=${page}`));
          }}
          header={['ID', 'Status', 'Título']}
          search={{
            id: 'ID',
            status: 'Status',
            title: 'Título',
          }}
          columns={[
            {
              name: 'user',
              format: (item) => item.id,
            },
            {
              name: 'client',
              format: (item) => item.status?.name,
            },
            {
              name: 'updated_at',
              format: (item) => item.title,
            },
          ]}
          data={leads.data}
          totalPages={leads.lastPage}
          page={leads.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { leads } = state;
  return {
    leads,
    loading: leads.loading || false,
  };
}

const connectedLeadsScreen = connect(mapStateToProps)(LeadsScreen);
export { connectedLeadsScreen as LeadsScreen };
