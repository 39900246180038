import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { projectActions } from '../../actions/project.actions';
import { teamActions } from '../../actions/team.actions';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import _ from 'lodash';
import './styles.css';

class ProjectEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      client_id: null,
      teams_id: [],
      owner_id: null,
      status_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(projectActions.getById(match.params.id)).then(() => {
      const { projects } = this.props;

      dispatch(clientActions.getAll());
      dispatch(teamActions.getAll());
      dispatch(projectActions.getStatusProjects());
      dispatch(userActions.getCompanyUsers());

      this.setState({
        name: projects.name,
        client_id: projects.client_id,
        status_id: projects.status_id,
        owner_id: projects.owner_id,
        teams_id: _.map(projects.teams, (item) => item.id),
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, client_id, status_id, teams_id, owner_id } = this.state;
    const { dispatch, match } = this.props;
    if (name && client_id && status_id) {
      let payload = {
        name,
        client_id,
        status_id,
        owner_id,
        teams: teams_id,
      };

      dispatch(projectActions.update(match.params.id, payload)).then(() => {
        this.props.history.push('/projects');
      });
    }
  }

  render() {
    const { name, client_id, status_id, teams_id, owner_id, submitted } = this.state;
    const { clients, status, teams, users, loading } = this.props;
    let clientOptions,
      statusOptions,
      teamOptions,
      userOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Projeto</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome do Projeto</label>
            <input
              name="name"
              placeholder="Nome do Projeto"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
            {submitted && !client_id && (
              <Label basic color="red" pointing>
                Cliente é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              name="status_id"
              options={statusOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={status_id}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Responsável</label>
            <Dropdown
              placeholder="Responsável do Projeto"
              name="owner_id"
              value={owner_id}
              fluid
              search
              selection
              clearable
              onChange={(event, data) => this.handleChange(true, data)}
              options={userOptions}
            />
          </Form.Field>
          <Form.Field>
            <label>Times</label>
            <Dropdown
              name="teams_id"
              onChange={(event, data) => this.handleChange(true, data)}
              placeholder="Times"
              fluid
              multiple
              search
              selection
              options={teamOptions}
              value={teams_id}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, clients, teams, users } = state;
  return {
    status: projects.status,
    projects: projects.item,
    loading: projects.loading || false,
    clients,
    teams,
    users: users.companyUsers,
  };
}

const connectedProjectEditScreen = connect(mapStateToProps)(ProjectEditScreen);
export { connectedProjectEditScreen as ProjectEditScreen };
