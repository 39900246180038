export const clientConstants = {
  CREATECLIENT_REQUEST: 'CREATECLIENT_REQUEST',
  CREATECLIENT_SUCCESS: 'CREATECLIENT_SUCCESS',
  CREATECLIENT_FAILURE: 'CREATECLIENT_FAILURE',

  GETALLCLIENT_REQUEST: 'GETALLCLIENT_REQUEST',
  GETALLCLIENT_SUCCESS: 'GETALLCLIENT_SUCCESS',
  GETALLCLIENT_FAILURE: 'GETALLCLIENT_FAILURE',

  UPDATECLIENT_REQUEST: 'UPDATECLIENT_REQUEST',
  UPDATECLIENT_SUCCESS: 'UPDATECLIENT_SUCCESS',
  UPDATECLIENT_FAILURE: 'UPDATECLIENT_FAILURE',

  GETCLIENT_REQUEST: 'GETCLIENT_REQUEST',
  GETCLIENT_SUCCESS: 'GETCLIENT_SUCCESS',
  GETCLIENT_FAILURE: 'GETCLIENT_FAILURE',

  GETMANAGER_REQUEST: 'GETMANAGER_REQUEST',
  GETMANAGER_SUCCESS: 'GETMANAGER_SUCCESS',
  GETMANAGER_FAILURE: 'GETMANAGER_FAILURE',

  DELETECLIENT_REQUEST: 'DELETECLIENT_REQUEST',
  DELETECLIENT_SUCCESS: 'DELETECLIENT_SUCCESS',
  DELETECLIENT_FAILURE: 'DELETECLIENT_FAILURE',
};
