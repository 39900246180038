import client from '../helpers/HttpClient';

class ticketService {
  async create(ticket) {
    try {
      const result = await client.post('tickets', ticket);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createInteraction(id, interaction) {
    try {
      const result = await client.post(`tickets/${id}/interactions`, interaction);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createExecutors(id, obj) {
    try {
      const result = await client.post(`ticket/${id}/executors`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteExecutors(ticket_id, id) {
    try {
      const result = await client.delete(`ticket/${ticket_id}/executors/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAllExecutors(id, params) {
    try {
      const result = await client.get(`ticket/${id}/executors`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createTeams(id, obj) {
    try {
      const result = await client.post(`ticket/${id}/teams`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteTeams(ticket_id, id) {
    try {
      const result = await client.delete(`ticket/${ticket_id}/teams/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAllTeams(id, params) {
    try {
      const result = await client.get(`ticket/${id}/teams`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      let endpoint = 'tickets';
      const result = await client.get(endpoint, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getReport(params) {
    try {
      let endpoint = 'tickets-report';
      const result = await client.get(endpoint, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getReportPDF(params) {
    try {
      let endpoint = 'report/tickets/pdf';
      const result = await client.get(endpoint, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getInteractions(id, filter) {
    try {
      let endpoint = `tickets/${id}/interactions?sort=desc`;

      if (filter) {
        endpoint = `${endpoint}&${filter}`;
      }
      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatusTickets() {
    try {
      const result = await client.get('tickets/status');

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getPriorities() {
    try {
      const result = await client.get('ticket/priorities');

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`tickets/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`tickets/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async uploadAttachment(id_ticket, data) {
    try {
      const result = await client.post(`tickets/${id_ticket}/attachments`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteAttachmentById(id_ticket, id) {
    try {
      const result = await client.delete(`tickets/${id_ticket}/attachments/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`tickets/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new ticketService();
