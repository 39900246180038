import { financialCategoryConstants } from '../constants/financial-category.constants';

export function financialCategory(state = {}, action) {
  switch (action.type) {
    case financialCategoryConstants.GETALLFINANCIALCATEGORY_REQUEST:
    case financialCategoryConstants.CREATEFINANCIALCATEGORY_REQUEST:
    case financialCategoryConstants.GETFINANCIALCATEGORY_REQUEST:
    case financialCategoryConstants.DELETEFINANCIALCATEGORY_REQUEST:
    case financialCategoryConstants.GETGROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case financialCategoryConstants.GETALLFINANCIALCATEGORY_SUCCESS:
      return {
        ...state,
        ...action.sub_categories,
        loading: false,
      };

    case financialCategoryConstants.GETGROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
        loading: false,
      };

    case financialCategoryConstants.DELETEFINANCIALCATEGORY_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
      };
    case financialCategoryConstants.CREATEFINANCIALCATEGORY_SUCCESS:
    case financialCategoryConstants.GETFINANCIALCATEGORY_SUCCESS:
      return {
        ...state,
        item: action.subCategory,
        loading: false,
      };
    case financialCategoryConstants.CREATEFINANCIALCATEGORY_FAILURE:
    case financialCategoryConstants.GETALLFINANCIALCATEGORY_FAILURE:
    case financialCategoryConstants.DELETEFINANCIALCATEGORY_FAILURE:
    case financialCategoryConstants.GETGROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
