import client from '../helpers/HttpClient';

class ExpenseService {
  async create(obj) {
    try {
      const result = await client.post('expenses', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`expenses/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`expenses/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(filter) {
    try {
      let endpoint = 'expenses';

      if (filter) {
        endpoint = `${endpoint}?${filter}`;
      }

      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getCenters() {
    try {
      const result = await client.get('expense-centers');

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`expenses/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new ExpenseService();
