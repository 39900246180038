import moment from 'moment';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Confirm,
  Dimmer,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Label,
  List,
  Loader,
} from 'semantic-ui-react';
import { ordersActions } from '../../actions/orders.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Interactions } from '../../components/Interactions/Interactions';
import './styles.css';
import { ModalParticipantsOrders } from '../../components/Modals/ModalParticipantsOrders';
import { ModalCreateFileOrder } from '../../components/Modals/ModalCreateFileOrder';
import { currencyLabelHelper } from '../../helpers/currency-label';
import { ModalEmailOrder } from '../../components/Modals/ModalEmailOrder';

class OrdersShowScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteOrder: false,
      openStatusChange: false,
      description: '',
      statusId: null,
      submitted: false,
      openCreateFiles: false,
      openParticipantsModal: false,
      openItems: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(ordersActions.getById(match.params.id)).then((result) => {
      const { orders } = this.props;

      this.setState({
        statusId: orders.status_id,
      });
    });
    dispatch(ordersActions.getParticipants(match.params.id));
    dispatch(ordersActions.getAttachment(match.params.id));
    dispatch(ordersActions.getInteraction(match.params.id));
    dispatch(ordersActions.getEmailText(match.params.id));
    dispatch(ordersActions.getStatus());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { orders, dispatch } = this.props;
    const { description } = this.state;

    if (description) {
      let payload = {
        text: description,
        service_order_id: orders.id,
      };

      dispatch(ordersActions.createInteraction(orders.id, payload)).then(() => {
        const { match } = this.props;
        dispatch(ordersActions.getInteraction(match.params.id));
        this.setState({
          description: '',
          service_order_id: orders.id,
          refresh: true,
          page: 1,
        });
      });
    }
  }

  deleteParticipant(id) {
    const { match, dispatch } = this.props;
    dispatch(ordersActions.deleteParticipantById(match.params.id, id)).then(() => {
      dispatch(ordersActions.getParticipants(match.params.id));
      dispatch(ordersActions.getInteraction(match.params.id));
      this.setState({
        deleteOrderParticipantId: null,
        openDeleteParticipant: false,
      });
    });
  }

  editById() {
    const { match } = this.props;
    this.props.history.push(`/orders/${match.params.id}/edit`);
  }

  handleCancel = () => this.setState({ openDeleteOrder: false });

  deleteShow = () => this.setState({ openDeleteOrder: true });

  deleteById() {
    const { match, dispatch } = this.props;
    dispatch(ordersActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/orders'),
    );
  }

  changeStatus(e, data) {
    const { match, dispatch } = this.props;
    dispatch(ordersActions.updateStatus(match.params.id, { status_id: data.value })).then(() => {
      const { match } = this.props;
      dispatch(ordersActions.getById(match.params.id)).then((result) => {
        const { orders } = this.props;

        this.setState({
          statusId: orders.status_id,
        });
      });
    });
  }

  render() {
    const { orders, participants, statusOrder, attachment, loading, interactions } = this.props;
    const {
      description,
      openDeleteParticipant,
      openDeleteOrder,
      openParticipantsModal,
      statusId,
      openItems,
      openStatusChange,
      openCreateFiles,
      openModalEmailOrder,
    } = this.state;

    let statusOptions = [];

    if (statusOrder !== undefined) {
      statusOptions = statusOrder.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (!orders)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <div id="page-order-show">
        <Fragment>
          <Confirm
            key={'participant'}
            content="Deseja remover este participante?"
            open={openDeleteParticipant}
            onCancel={() => this.setState({ openDeleteParticipant: false })}
            onConfirm={() => this.deleteParticipant(this.state.deleteOrderParticipantId)}
          />
          <Confirm
            key={'opportunity'}
            content="Deseja remover este pedido?"
            open={openDeleteOrder}
            onCancel={this.handleCancel}
            onConfirm={() => this.deleteById()}
          />

          <Grid className="header-page">
            <GridColumn className="header-column-left">
              <h1>Detalhes do Pedido</h1>
              <Label className="order-number">{orders?.id || '-'}</Label>
            </GridColumn>
            <Grid.Column className="header-column-right">
              <Dropdown
                className="btn-status"
                options={statusOptions}
                value={statusId}
                onChange={(event, data) => this.changeStatus(true, data)}
              />
              <Button
                icon="envelope square"
                onClick={() => this.setState({ openModalEmailOrder: true, orderId: orders.id })}
              />
              <Button icon="edit outline" onClick={() => this.editById()} />
              <Button icon="trash alternate outline" onClick={() => this.deleteShow()} />
            </Grid.Column>
          </Grid>

          <Loader active={loading} />
          <ModalParticipantsOrders
            openParticipants={openParticipantsModal}
            onClose={() => this.setState({ openParticipantsModal: false })}
          />
          <ModalCreateFileOrder
            openCreateFiles={openCreateFiles}
            onClose={() => this.setState({ openCreateFiles: false })}
          />
          <ModalEmailOrder
            openModalEmailOrder={openModalEmailOrder}
            onClose={() => this.setState({ openModalEmailOrder: false })}
          />
          {!loading && (
            <>
              <div className="container-inner">
                <Grid>
                  <GridRow columns={1}>
                    <GridColumn>
                      <Card fluid>
                        <Card.Content>
                          <Grid columns={2}>
                            <GridRow>
                              <GridColumn>
                                <GridRow>
                                  <b>Nome:</b>
                                  <span>{orders.name || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Método de Pagamento:</b>
                                  <span>{orders.paymentMethod?.name || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Endereço:</b>
                                  {orders?.deliveryAddress && (
                                    <span>
                                      {orders?.deliveryAddress?.street || ' '},{' '}
                                      {orders?.deliveryAddress?.number ? (
                                        <span>nº {orders?.deliveryAddress?.number}, </span>
                                      ) : (
                                        ' '
                                      )}
                                      {orders?.deliveryAddress?.neighborhood || ' '}
                                    </span>
                                  )}
                                </GridRow>
                                <GridRow>
                                  <b>Cidade:</b>
                                  <span>
                                    {orders?.deliveryAddress?.city} -{' '}
                                    {orders?.deliveryAddress?.state}{' '}
                                  </span>
                                </GridRow>
                              </GridColumn>

                              <GridColumn>
                                <GridRow>
                                  <GridColumn floated="right" style={{ textAlign: 'center' }}>
                                    <b>Criado em</b>
                                    <br />
                                    <span>
                                      {moment(orders?.created_at).format('DD/MM/YY') || '-'}
                                    </span>
                                  </GridColumn>
                                </GridRow>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>
                  </GridRow>

                  <GridRow>
                    <GridColumn width={10}>
                      <Card fluid>
                        <Card.Content>
                          <Grid columns={2}>
                            <GridColumn>
                              <GridRow columns={2}>
                                <GridColumn>
                                  <GridRow>
                                    <b>Detalhes Fornecedor</b>
                                    <br />
                                    <b style={{ fontSize: 13 }}>Nome:</b>
                                    <span>{orders.provider?.name || '-'}</span>
                                  </GridRow>
                                  <GridRow>
                                    <b style={{ fontSize: 13 }}>CNPJ:</b>
                                    <span>{orders.provider?.cnpj || '-'}</span>
                                  </GridRow>
                                  <GridRow>
                                    <b style={{ fontSize: 13 }}>Notas:</b>
                                    <span>{orders.provider?.notes || '-'}</span>
                                  </GridRow>
                                </GridColumn>
                              </GridRow>
                            </GridColumn>

                            <GridColumn>
                              <GridRow>
                                <b>Detalhes Cliente</b>
                                <br />
                                <b style={{ fontSize: 13 }}>Nome:</b>
                                <span>{orders.sale?.client?.name || '-'}</span>
                              </GridRow>
                              <GridRow>
                                <b style={{ fontSize: 13 }}>CNPJ:</b>
                                <span>{orders.sale?.client?.cnpj || '-'}</span>
                              </GridRow>
                              <GridRow>
                                <b style={{ fontSize: 13 }}>Sales Force:</b>
                                <span>{orders.sale?.client?.sales_force_id || '-'}</span>
                              </GridRow>
                            </GridColumn>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>

                    <GridColumn width={6}>
                      <Card fluid className="card-payment">
                        <CardContent>
                          <Grid>
                            <GridRow columns={2}>
                              <GridColumn>
                                <p className="card-payment-label">Código da venda</p>
                                <p className="card-payment-value">{orders.sale?.id || '-'}</p>
                              </GridColumn>
                              <GridColumn>
                                <p className="card-payment-label">Data de fechamento</p>
                                <p className="card-payment-value">
                                  {orders.due_date
                                    ? moment(orders.due_date).format('DD/MM/YY')
                                    : '-'}
                                </p>
                              </GridColumn>
                            </GridRow>
                            <GridRow columns={1}>
                              <GridColumn>
                                <p className="card-payment-label">Nota Fiscal</p>
                                <p className="card-payment-value">{orders.invoice || '-'}</p>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </CardContent>
                      </Card>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </div>
              <div className="container-wrapper ">
                <Button
                  icon="plus square"
                  title="Cadastro de Anexos"
                  onClick={() => this.setState({ openCreateFiles: true })}
                  floated="right"
                  style={{ marginBottom: '-18px' }}
                />

                <div className="container-title">
                  <h1>Anexos</h1>
                </div>

                {!participants ? (
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                ) : (
                  <DynamicTable
                    header={['Anexo', '']}
                    columns={[
                      {
                        name: 'anexo',
                        format: (data) => (
                          <>
                            <Icon name="user" className="user-icon" />
                            {data.description}
                          </>
                        ),
                      },
                      {
                        name: 'dowload',
                        format: (data) => (
                          <>
                            <Button
                              icon="arrow alternate circle down outline"
                              floated="right"
                              style={{ marginBottom: '5px' }}
                              as="a"
                              target="_blank"
                              href={data.file?.url}></Button>
                          </>
                        ),
                      },
                    ]}
                    data={attachment || []}
                    totalPages={1}
                    handlePaginationChange={() => {}}
                    style="basic"
                    celled={false}
                    sortable={false}
                    showPagination={false}
                  />
                )}
              </div>

              <div className="container-wrapper container-participants">
                <Button
                  icon="plus square"
                  title="Cadastro de participantes"
                  onClick={() => this.setState({ openParticipantsModal: true })}
                  floated="right"
                  style={{ marginBottom: '-18px' }}
                />

                <div className="container-title">
                  <h1>Participantes</h1>
                </div>

                {!participants ? (
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                ) : (
                  <DynamicTable
                    header={['Nome', 'Tipo']}
                    columns={[
                      {
                        name: 'name',
                        format: (data) => (
                          <>
                            <Icon name="user" className="user-icon" />
                            {data.user?.name}
                          </>
                        ),
                      },
                      {
                        name: 'type',
                        format: (data) => <>{data.type?.name}</>,
                      },
                      {
                        name: 'delete',
                        format: (data) => (
                          <>
                            <Button
                              icon="trash alternate outline"
                              floated="right"
                              onClick={() =>
                                this.setState({
                                  deleteOrderParticipantId: data.id,
                                  openDeleteParticipant: true,
                                })
                              }
                            />
                          </>
                        ),
                      },
                    ]}
                    data={participants || []}
                    totalPages={1}
                    handlePaginationChange={() => {}}
                    style="basic"
                    celled={false}
                    sortable={false}
                    showPagination={false}
                  />
                )}
              </div>

              <div className="container-wrapper container-note">
                <div className="container-title">
                  <h1>Observações</h1>
                </div>

                <div>
                  <p style={{ whiteSpace: 'pre-line' }}>{orders?.description || '-'}</p>
                </div>
              </div>

              <div className="container-wrapper container-note">
                <div className="container-title">
                  <h1>Itens</h1>
                </div>

                <List divided verticalAlign="middle">
                  <div className="list-content">
                    {orders?.items?.map((item) => (
                      <List.Item className="list-item" key={item.id}>
                        <div className="list-item-icon">
                          <span>
                            <Icon name="box" className="list-item-icon-icon" />
                          </span>
                        </div>

                        <List.Content className="list-content">
                          <List.Header as="h1">{item.name || '-'}</List.Header>

                          <List.Description>
                            <div className="description-row">
                              <span>ID {item.id || '-'}</span>
                              <p>
                                Custo Total
                                <CurrencyFormat
                                  displayType={'text'}
                                  value={item.amount_cost_price / 100 || '-'}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={' ' + currencyLabelHelper(item.currency)}
                                />
                              </p>
                            </div>

                            <div className="description-row">
                              {item && item.type === 'S' ? (
                                <a className="ui blue label detail">Serviço</a>
                              ) : (
                                <a className="ui teal label detail">Produto</a>
                              )}
                            </div>
                          </List.Description>
                        </List.Content>

                        <div className="list-item-right">
                          <p>QTD. {item.quantity || '-'}</p>

                          <h1>
                            <CurrencyFormat
                              displayType={'text'}
                              value={item.amount / 100 || '-'}
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={currencyLabelHelper(item.currency)}
                            />
                          </h1>
                        </div>
                      </List.Item>
                    ))}
                  </div>
                </List>
              </div>

              <div className="container-wrapper container-note">
                <Interactions
                  interactions={interactions}
                  valueContent={description}
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}
                />
              </div>
            </>
          )}
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { orders } = state;
  const participants = orders.participants;
  const attachment = orders.attachment;

  return {
    orders: orders.item,
    statusOrder: orders.statusOrder,
    interactions: orders.interaction,
    attachment: attachment?.data,
    participants: participants?.data,
  };
}

const connectedOrdersShowScreen = connect(mapStateToProps)(OrdersShowScreen);
export { connectedOrdersShowScreen as OrdersShowScreen };
