import { clientConstants } from '../constants/client.constants';

export function clients(state = {}, action) {
  switch (action.type) {
    case clientConstants.CREATECLIENT_REQUEST:
    case clientConstants.GETALLCLIENT_REQUEST:
    case clientConstants.GETCLIENT_REQUEST:
    case clientConstants.GETMANAGER_REQUEST:
    case clientConstants.DELETECLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case clientConstants.GETMANAGER_SUCCESS:
      return {
        ...state,
        manager: action.manager,
        loading: false,
      };
    case clientConstants.CREATECLIENT_SUCCESS:
    case clientConstants.GETCLIENT_SUCCESS:
    case clientConstants.DELETECLIENT_SUCCESS:
      return action.client;
    case clientConstants.GETALLCLIENT_SUCCESS:
      return action.clients;
    case clientConstants.CREATECLIENT_FAILURE:
    case clientConstants.GETALLCLIENT_FAILURE:
    case clientConstants.GETCLIENT_FAILURE:
    case clientConstants.GETMANAGER_FAILURE:
    case clientConstants.DELETECLIENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
