import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { paymentActions } from '../../actions/payment.actions';

class PaymentShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(paymentActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(paymentActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/payment'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/payment/${match.params.id}/edit`);
  }

  render() {
    const { payment, loading } = this.props;
    const { open } = this.state;

    if (!payment)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{payment.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Nome: </h4>
          {payment.name}
          <h4>Status: </h4>
          {payment.active ? 'Ativo' : 'Inativo'}
          <h4>Data de criação: </h4>
          {moment(payment.created_at).format('DD/MM/YYYY') || '-'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { payment } = state;

  return {
    payment: payment.item,
    loading: payment.loading || false,
  };
}

const connectedPaymentShowScreen = connect(mapStateToProps)(PaymentShowScreen);
export { connectedPaymentShowScreen as PaymentShowScreen };
