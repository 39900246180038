import { pdfConstants } from '../constants/pdf.constants';
import pdfService from '../services/pdf.service';
import { alertActions } from './alert.actions';

export const pdfActions = {
  update,
  getAll,
  getById,
};

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const pdf = await pdfService.update(id, obj);

      dispatch(success(pdf));
      history.push('/pdf');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: pdfConstants.UPDATEPDF_REQUEST,
    };
  }

  function success(pdf) {
    return {
      type: pdfConstants.UPDATEPDF_SUCCESS,
      pdf,
    };
  }

  function failure(error) {
    return {
      type: pdfConstants.UPDATEPDF_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const pdf = await pdfService.getAll(filter);

      dispatch(success(pdf));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: pdfConstants.GETALLPDF_REQUEST,
    };
  }

  function success(pdf) {
    return {
      type: pdfConstants.GETALLPDF_SUCCESS,
      pdf,
    };
  }

  function failure(error) {
    return {
      type: pdfConstants.GETALLPDF_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const pdf = await pdfService.getById(id);

      dispatch(success(pdf));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(pdf) {
    return {
      type: pdfConstants.GETPDF_REQUEST,
      pdf,
    };
  }

  function success(pdf) {
    return {
      type: pdfConstants.GETPDF_SUCCESS,
      pdf,
    };
  }

  function failure(error) {
    return {
      type: pdfConstants.GETPDF_FAILURE,
      error,
    };
  }
}
