import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { financesAccountsActions } from '../../actions/finances-accounts.actions';

class FinancialAccountShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(financesAccountsActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(financesAccountsActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/finance/accounts'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/finance/accounts/${match.params.id}/edit`);
  }

  render() {
    const { account, loading } = this.props;
    const { open } = this.state;
    if (!account || loading)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{account.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Empresa: </h4>
          {account?.company?.name}
          <h4>Tipo: </h4>
          {account?.type?.name}
          <h4>Banco: </h4>
          {account.bank}
          <h4>Agencia: </h4>
          {account.bank_agency}
          <h4>Conta: </h4>
          {account.bank_account}
          <h4>Descrição: </h4>
          {account.description}
          <h4>Status: </h4>
          {account.active ? 'Ativo' : 'Inativo'}
          <h4>Data de criação: </h4>
          {moment(account.created_at).format('DD/MM/YYYY') || '-'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { financesAccounts } = state;

  return {
    account: financesAccounts.item,
    loading: financesAccounts.loading || false,
  };
}

const connectedFinancialAccountShowScreen = connect(mapStateToProps)(FinancialAccountShowScreen);
export { connectedFinancialAccountShowScreen as FinancialAccountShowScreen };
