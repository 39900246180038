import { ticketConstants } from '../constants/ticket.constants';

export function tickets(state = {}, action) {
  switch (action.type) {
    case ticketConstants.CREATETICKET_REQUEST:
    case ticketConstants.CREATEINTERACTION_REQUEST:
    case ticketConstants.GETALLTICKET_REQUEST:
    case ticketConstants.GETALLINTERACTION_REQUEST:
    case ticketConstants.GETALLEXECUTORS_REQUEST:
    case ticketConstants.CREATEEXECUTORS_REQUEST:
    case ticketConstants.DELETEEXECUTORS_REQUEST:
    case ticketConstants.GETTICKET_REQUEST:
    case ticketConstants.GETSTATUS_REQUEST:
    case ticketConstants.DELETETICKET_REQUEST:
    case ticketConstants.DELETEATTACHMENT_REQUEST:
    case ticketConstants.CREATEATTACHMENT_REQUEST:
    case ticketConstants.GETTICKETPRIORITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ticketConstants.GETSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case ticketConstants.CREATEINTERACTION_SUCCESS:
      return {
        ...state,
        interaction: action.interaction,
        loading: false,
      };
    case ticketConstants.GETTICKETPRIORITIES_SUCCESS:
      return {
        ...state,
        priorities: action.priorities,
        loading: false,
      };
    case ticketConstants.CREATETICKET_SUCCESS:
    case ticketConstants.UPDATETICKET_SUCCESS:
    case ticketConstants.GETTICKET_SUCCESS:
    case ticketConstants.DELETETICKET_SUCCESS:
    case ticketConstants.DELETEATTACHMENT_SUCCESS:
    case ticketConstants.CREATEATTACHMENT_SUCCESS:
      return {
        ...state,
        item: action.ticket,
        loading: false,
      };
    case ticketConstants.GETALLINTERACTION_SUCCESS:
      return {
        ...state,
        interactions: action.interactions,
        loading: false,
      };
    case ticketConstants.GETALLTICKET_SUCCESS:
      return {
        ...state,
        ...action.tickets,
        loading: false,
      };
    case ticketConstants.GETALLEXECUTORS_SUCCESS:
    case ticketConstants.CREATEEXECUTORS_SUCCESS:
      return {
        ...state,
        executors: action.executors,
        loading: false,
      };
    case ticketConstants.DELETEEXECUTORS_SUCCESS:
      return {
        ...state,
        type: action.executors,
        loading: false,
      };
    case ticketConstants.GETALLTEAMS_SUCCESS:
    case ticketConstants.CREATETEAMS_SUCCESS:
      return {
        ...state,
        teams: action.teams,
        loading: false,
      };
    case ticketConstants.DELETETEAMS_SUCCESS:
      return {
        ...state,
        type: action.teams,
        loading: false,
      };
    case ticketConstants.CREATETICKET_FAILURE:
    case ticketConstants.UPDATETICKET_FAILURE:
    case ticketConstants.CREATEINTERACTION_FAILURE:
    case ticketConstants.GETALLTICKET_FAILURE:
    case ticketConstants.GETALLINTERACTION_FAILURE:
    case ticketConstants.GETALLSTATUS_FAILURE:
    case ticketConstants.GETALLEXECUTORS_FAILURE:
    case ticketConstants.CREATEEXECUTORS_FAILURE:
    case ticketConstants.DELETEEXECUTORS_FAILURE:
    case ticketConstants.GETALLTEAMS_FAILURE:
    case ticketConstants.CREATETEAMS_FAILURE:
    case ticketConstants.DELETETEAMS_FAILURE:
    case ticketConstants.GETTICKET_FAILURE:
    case ticketConstants.DELETETICKET_FAILURE:
    case ticketConstants.DELETEATTACHMENT_FAILURE:
    case ticketConstants.CREATEATTACHMENT_FAILURE:
    case ticketConstants.GETTICKETPRIORITIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
