import { userConstants } from '../constants/user.constants';

let stateDefault = {};

if (localStorage.getItem('me')) {
  stateDefault.me = JSON.parse(localStorage.getItem('me'));
}

export function users(state = stateDefault, action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return {};
    case userConstants.GETALLROLES_REQUEST:
    case userConstants.GETUSERME_REQUEST:
    case userConstants.UPDATEUSERME_REQUEST:
    case userConstants.UPDATEUSER_REQUEST:
    case userConstants.DELETEUSER_REQUEST:
    case userConstants.GETALLUSER_REQUEST:
    case userConstants.GETCOMPANYUSER_REQUEST:
    case userConstants.GETCLIENTUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GETUSER_SUCCESS:
    case userConstants.UPDATEUSER_SUCCESS:
    case userConstants.DELETEUSER_SUCCESS:
      return {
        ...state,
        item: action.user,
        loading: false,
      };
    case userConstants.GETUSERME_SUCCESS:
    case userConstants.UPDATEUSERME_SUCCESS:
      return {
        ...state,
        me: action.me,
        loading: false,
      };
    case userConstants.GETALLUSER_SUCCESS:
      return {
        ...state,
        item: null,
        loading: false,
        ...action.users,
      };
    case userConstants.GETCOMPANYUSER_SUCCESS:
      return {
        ...state,
        item: null,
        companyUsers: action.companyUsers,
        loading: false,
      };
    case userConstants.GETCLIENTUSER_SUCCESS:
      return {
        ...state,
        item: null,
        clientUsers: action.clientUsers,
        loading: false,
      };
    case userConstants.GETALLROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles,
        loading: false,
      };
    case userConstants.GETALLROLES_FAILURE:
    case userConstants.GETUSERME_FAILURE:
    case userConstants.UPDATEUSERME_FAILURE:
    case userConstants.UPDATEUSER_FAILURE:
    case userConstants.DELETEUSER_FAILURE:
    case userConstants.GETALLUSER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
