import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { paymentActions } from '../../actions/payment.actions';

class PaymentScreen extends Component {
  componentDidMount() {
    this.props.dispatch(paymentActions.getAll());
  }

  render() {
    const { payment, loading } = this.props;

    if (!payment.data || loading) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Método de Pagamento</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/payment/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`payment/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;
            dispatch(paymentActions.getAll(`page=${page}`));
          }}
          header={['Nome', 'Status']}
          search={{
            name: 'Nome',
            active: 'Status',
          }}
          columns={[
            'name',
            {
              name: 'status',
              format: (item) => (item.active ? 'Ativo' : 'Inativo'),
            },
          ]}
          data={payment.data}
          totalPages={payment.lastPage}
          page={payment.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { payment } = state;
  return {
    payment,
    loading: payment.loading || false,
  };
}

const connectedPaymentScreen = connect(mapStateToProps)(PaymentScreen);
export { connectedPaymentScreen as PaymentScreen };
