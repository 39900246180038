import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { pdfActions } from '../../actions/pdf.actions';

class PdfShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(pdfActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  editById() {
    const { match } = this.props;
    this.props.history.push(`/pdf/${match.params.id}/edit`);
  }

  format(date) {
    const d = moment(date);
    return d.isValid() ? d.format('DD/MM/YYYY HH:mm:ss') : '-';
  }

  render() {
    const { pdf, loading } = this.props;

    if (!pdf)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{pdf.title || '-'}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>ID: </h4>
          {pdf.id || '-'}
          <h4>Nome: </h4>
          {pdf.title || '-'}
          <h4>Última Alteração: </h4>
          {this.format(pdf.updated_at)}
          <h4>Texto</h4>
          <div
            style={{ border: '1px solid #CCC', padding: '4px' }}
            dangerouslySetInnerHTML={{ __html: pdf.description }}></div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { pdf } = state;

  return {
    pdf: pdf.item,
    loading: pdf.loading || false,
  };
}

const connectedPdfShowScreen = connect(mapStateToProps)(PdfShowScreen);
export { connectedPdfShowScreen as PdfShowScreen };
