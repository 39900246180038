import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { productsActions } from '../../actions/products.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class ProductsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(productsActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { products, loading } = this.props;
    const { refresh } = this.state;

    if (!products.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Produtos</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/products/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`products/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(productsActions.getAll(`page=${page}`));
            }}
            header={['Fabricante', 'Nome', 'Descrição', 'NCM', 'SKU', 'Part Number']}
            search={{
              'provider.name': 'Fabricante',
              name: 'Nome',
              description: 'Descrição',
            }}
            columns={['provider.name', 'name', 'description', 'ncm', 'sku', 'part_number']}
            data={products.data}
            totalPages={products.lastPage}
            page={products.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { products } = state;
  return {
    products,
    loading: products.loading || false,
  };
}

const connectedProductsScreen = connect(mapStateToProps)(ProductsScreen);
export { connectedProductsScreen as ProductsScreen };
