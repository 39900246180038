import { contractConstants } from '../constants/contract.constants';

export function contracts(state = {}, action) {
  switch (action.type) {
    case contractConstants.GETALLCONTRACT_REQUEST:
    case contractConstants.CREATECONTRACT_REQUEST:
    case contractConstants.CREATECONTRACTTYPE_REQUEST:
    case contractConstants.UPDATECONTRACTTYPE_REQUEST:
    case contractConstants.DELETECONTRACTTYPE_REQUEST:
    case contractConstants.DELETECONTRACT_REQUEST:
    case contractConstants.GETTYPECONTRACT_REQUEST:
    case contractConstants.GETMECONTRACT_REQUEST:
    case contractConstants.GETCONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case contractConstants.GETALLCONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.contracts,
      };
    case contractConstants.GETTYPECONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contractTypes: action.contractTypes,
      };
    case contractConstants.CREATECONTRACTTYPE_SUCCESS:
    case contractConstants.GETMECONTRACT_SUCCESS:
    case contractConstants.UPDATECONTRACTTYPE_SUCCESS:
    case contractConstants.DELETECONTRACTTYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case contractConstants.CREATECONTRACT_SUCCESS:
    case contractConstants.GETCONTRACT_SUCCESS:
    case contractConstants.DELETECONTRACT_SUCCESS:
      return {
        ...state,
        item: action.contract,
        loading: false,
      };
    case contractConstants.CREATECONTRACT_FAILURE:
    case contractConstants.CREATECONTRACTTYPE_FAILURE:
    case contractConstants.GETALLCONTRACT_FAILURE:
    case contractConstants.UPDATECONTRACTTYPE_FAILURE:
    case contractConstants.DELETECONTRACTTYPE_FAILURE:
    case contractConstants.DELETECONTRACT_FAILURE:
    case contractConstants.GETTYPECONTRACT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
