import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { Button, Confirm, Modal } from 'semantic-ui-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { opportunityActions } from '../../actions/opportunity.actions';
import { ModalEditQuotes } from './ModalEditQuotes';

class ModalShowQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      openShowQuotes: false,
      deleteQuotesItensId: null,
      quoteId: null,
      itensId: null,
      openDeleteItens: false,
      openItensEdit: false,
    };
  }

  deleteItens(quoteId, id) {
    const { dispatch } = this.props;

    const { opportunity } = this.props;

    dispatch(opportunityActions.deleteQuotesItens(opportunity.id, quoteId, id)).then(() => {
      dispatch(opportunityActions.getQuotesItens(opportunity.id, quoteId));
      this.setState({
        deleteQuotesItensId: null,
        quoteId: null,
        openDeleteItens: false,
      });
    });
  }

  onClose() {
    this.setState({ openShowQuotes: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openShowQuotes &&
      (this.props.openShowQuotes !== prevProps.openShowQuotes || this.props.id != prevProps.id) &&
      !this.state.openShowQuotes
    ) {
      this.setState({ openShowQuotes: true });
    }
  }

  render() {
    const { openShowQuotes, quoteId, itensId, openItensEdit, openDeleteItens } = this.state;
    const { quotesItens } = this.props;

    return (
      <>
        <ModalEditQuotes
          itensId={itensId}
          quoteId={quoteId}
          openItensEdit={openItensEdit}
          onClose={() => this.setState({ openItensEdit: false })}
        />

        <Modal
          style={{ width: 1260 }}
          open={openShowQuotes}
          closeIcon
          onClose={() => this.onClose()}>
          <Confirm
            key={'Itens'}
            content="Deseja remover este item?"
            open={openDeleteItens}
            onCancel={() => this.setState({ openDeleteItens: false })}
            onConfirm={() => this.deleteItens(this.state.quoteId, this.state.deleteQuotesItensId)}
          />
          <Modal.Header>Detalhes dos itens da cotação</Modal.Header>
          <Modal.Content>
            <DynamicTable
              header={[
                'Fornecedor',
                'Part.Num',
                'Descrição',
                'NCM',
                'Qtde',
                'Tipo',
                'Vlr.Unit.',
                'ISS',
                'ICMS',
                'IPI',
                'COFINS',
                'Vlr.Total',
                '',
              ]}
              columns={[
                {
                  name: 'provider',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.provider?.name}</div>
                  ),
                },
                {
                  name: 'part_number',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.part_number}</div>
                  ),
                },
                {
                  name: 'description',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.description}</div>
                  ),
                },
                {
                  name: 'ncm',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.ncm}</div>
                  ),
                },
                {
                  name: 'quantity',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.quantity}</div>
                  ),
                },
                {
                  name: 'type_id',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.type?.name}</div>
                  ),
                },
                {
                  name: 'item_amount',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.item_amount / 100 || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  ),
                },
                {
                  name: 'tax_iss',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_iss || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_icms',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_icms || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_ipi',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_ipi || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_cofins',
                  format: (data) => (
                    <CurrencyFormat
                      displayType={'text'}
                      style={{ textAlign: 'center', fontSize: 13 }}
                      value={data.tax_cofins || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'amount',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.amount / 100 || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  ),
                },
                {
                  name: 'Delete',
                  format: (data) => (
                    <div style={{ marginRight: 10 }}>
                      <Button
                        icon="edit outline"
                        floated="right"
                        style={{ marginBottom: '5px' }}
                        onClick={() =>
                          this.setState({
                            itensId: data.id,
                            quoteId: data.quote_id,
                            openItensEdit: true,
                          })
                        }
                      />
                      <Button
                        icon="trash alternate outline"
                        floated="right"
                        onClick={() =>
                          this.setState({
                            quoteId: data.quote_id,
                            deleteQuotesItensId: data.id,
                            openDeleteItens: true,
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
              data={quotesItens || []}
              totalPages={1}
              handlePaginationChange={() => {}}
              sortable={false}
              showPagination={false}
            />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity } = state;
  const quotesItens = opportunity.quotesItens;
  return {
    opportunity: opportunity.item,
    quotesItens: quotesItens?.data,
    loading: opportunity.loading || false,
  };
}

const connectedModalShowQuotes = connect(mapStateToProps)(ModalShowQuotes);
export { connectedModalShowQuotes as ModalShowQuotes };
