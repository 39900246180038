import { reportConstants } from '../constants/report.constants';

export function reports(state = {}, action) {
  switch (action.type) {
    case reportConstants.GETALLACTIVITY_REQUEST:
    case reportConstants.GETBANKACTIVITY_REQUEST:
    case reportConstants.GETALLEXPENSE_REQUEST:
    case reportConstants.GETALLREPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GETALLACTIVITY_SUCCESS:
      return {
        ...state,
        ...action.activities,
        loading: false,
      };
    case reportConstants.GETBANKACTIVITY_SUCCESS:
      return {
        ...state,
        bank: action.activities,
        loading: false,
      };
    case reportConstants.GETALLEXPENSE_SUCCESS:
      return {
        ...state,
        expenses: action.expenses,
        loading: false,
      };
    case reportConstants.GETALLREPORT_SUCCESS:
      return {
        ...state,
        report: action.report,
        loading: false,
      };
    case reportConstants.GETALLREPORT_FAILURE:
    case reportConstants.GETALLACTIVITY_FAILURE:
    case reportConstants.GETBANKACTIVITY_FAILURE:
    case reportConstants.GETALLEXPENSE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
