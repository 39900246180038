export const paymentConstants = {
  GETALL_PAYMENT_REQUEST: 'GETALL_PAYMENT_REQUEST',
  GETALL_PAYMENT_SUCCESS: 'GETALL_PAYMENT_SUCCESS',
  GETALL_PAYMENT_FAILURE: 'GETALL_PAYMENT_FAILURE',

  GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE: 'GET_PAYMENT_FAILURE',

  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAILURE: 'UPDATE_PAYMENT_FAILURE',

  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',

  DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FAILURE: 'DELETE_PAYMENT_FAILURE',
};
