import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

import { licensesActions } from '../../actions/licenses.actions';
import { productsActions } from '../../actions/products.actions';
import { productsModulesActions } from '../../actions/products-modules.actions';
import { clientActions } from '../../actions/client.actions';

class LicensesCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: null,
      module_id: null,
      client_id: null,
      expires_at: '',
      perpetual_license: false,
      max_itens: 0,
      comments: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(productsActions.getAll());
    dispatch(productsModulesActions.getAll());
    dispatch(clientActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      switch (data.name) {
        case 'product_id':
          this.setState({ product_id: data.value === '' ? null : data.value, module_id: null });
          break;
        case 'perpetual_license':
          this.setState({ perpetual_license: data.checked, expires_at: '' });
          break;
        default:
          this.setState({ [data.name]: data.value === '' ? null : data.value });
          break;
      }
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { product_id, module_id, client_id, expires_at, perpetual_license, max_itens, comments } =
      this.state;
    const { dispatch } = this.props;
    if (product_id && module_id && client_id && (expires_at || perpetual_license) && max_itens) {
      dispatch(
        licensesActions.create(
          {
            product_id,
            module_id,
            client_id,
            expires_at:
              expires_at !== '' ? moment(expires_at, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            max_itens,
            comments,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const {
      product_id,
      module_id,
      client_id,
      expires_at,
      perpetual_license,
      max_itens,
      comments,
      submitted,
    } = this.state;
    const { products, clients, productsModules, loading } = this.props;
    const expires_atMinDate = new Date();
    let productsOptions = [];
    let clientsOptions = [];
    let productsModulesOptions = [];

    if (products && products.data !== undefined) {
      productsOptions = products.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientsOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (productsModules && productsModules.data !== undefined) {
      productsModulesOptions = productsModules.data
        .filter((item) => item.product_id === product_id)
        .map((item) => {
          return { key: item.id, value: item.id, text: item.name };
        });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Nova Licença</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Produto</label>
            <Dropdown
              placeholder="Selecione"
              name="product_id"
              value={product_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={productsOptions}
            />
            {submitted && !product_id && (
              <Label basic color="red" pointing>
                Produto é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Módulo</label>
            <Dropdown
              placeholder="Selecione"
              name="module_id"
              value={module_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={productsModulesOptions}
            />
            {submitted && !module_id && (
              <Label basic color="red" pointing>
                Módulo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Selecione"
              name="client_id"
              value={client_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={clientsOptions}
            />
            {submitted && !client_id && (
              <Label basic color="red" pointing>
                Cliente é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Expiração</label>
            <DateInput
              closable
              name="expires_at"
              dateFormat="DD/MM/YYYY"
              placeholder="Expiração"
              onChange={(event, data) => this.handleChange(true, data)}
              value={expires_at}
              iconPosition="left"
              minDate={expires_atMinDate}
              disabled={perpetual_license}
            />
            {submitted && !expires_at && !perpetual_license && (
              <Label basic color="red" pointing>
                Expiração é requerida
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="perpetual_license"
              label="Licença Perpétua"
              checked={perpetual_license}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Qtd. Itens</label>
            <input
              name="max_itens"
              placeholder="Qtd. Itens"
              value={max_itens}
              onChange={this.handleChange}
              type="number"
            />
            {submitted && !max_itens && (
              <Label basic color="red" pointing>
                Qtd. Itens é requerida
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Comentários</label>
            <textarea
              name="comments"
              placeholder="Comentários"
              value={comments}
              onChange={this.handleChange}></textarea>
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { products, clients, productsModules } = state;
  return {
    products,
    clients,
    productsModules,
    loading: products.loading || false,
  };
}

const connectedLicensesCreateScreen = connect(mapStateToProps)(LicensesCreateScreen);
export { connectedLicensesCreateScreen as LicensesCreateScreen };
