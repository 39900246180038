import client from '../helpers/HttpClient';
import BaseService from './base.service';

class ClientService extends BaseService {
  get basePath() {
    return 'clients';
  }

  async getManager() {
    try {
      const result = await client.get('account-managers');
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
  async synchronizeClientsFromSalesForce() {
    try {
      let endpoint = 'integration/salesforce/sync/clients';
      const result = await client.post(endpoint);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;
      return Promise.reject(returnMessage);
    }
  }
}

export default new ClientService();
