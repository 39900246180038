import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, List, TextArea, Label } from 'semantic-ui-react';
import './styles.css';

class Interactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  render() {
    const { interactions, onSubmit, onChange, valueContent } = this.props;

    const { submitted } = this.state;

    return (
      <div id="page-interaction-show">
        <Fragment>
          <div className="container-title">
            <h1>Interações</h1>
          </div>

          <List divided relaxed className="list-content">
            {interactions?.data?.map((interaction, key) => (
              <List.Item key={key} className="container-content">
                <List.Icon name="user" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="a">{interaction?.author?.name || 'Anonimo'}</List.Header>
                  <List.Description className="text-interaction" as="a">
                    {interaction.text}
                  </List.Description>
                  <List.Description className="date-interaction" as="a">
                    {moment(interaction.created_at).fromNow() || '-'}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
          <Form>
            <Form.Field>
              <TextArea
                style={{ height: '100px', width: '100%' }}
                placeholder="Digite uma Interação"
                name="description"
                onChange={onChange}
                value={valueContent}
              />
              {submitted && !valueContent && (
                <Label basic color="red" pointing>
                  É necessario preencher o campo para enviar
                </Label>
              )}
            </Form.Field>
            <Button content="Enviar" labelPosition="left" onClick={onSubmit} icon="edit" primary />
          </Form>
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps() {}

const connectedInteractions = connect(mapStateToProps)(Interactions);
export { connectedInteractions as Interactions };
