import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { opportunityActions } from '../../actions/opportunity.actions';
import { clientActions } from '../../actions/client.actions';
import { managersActions } from '../../actions/managers.actions';

class OpportunityEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      client_id: null,
      current_status_id: null,
      manager_id: null,
      datetime: '',
      expected_date: '',
      notes: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(clientActions.getAll());
    dispatch(opportunityActions.getStatus());

    dispatch(opportunityActions.getById(match.params.id)).then(() => {
      const { opportunity } = this.props;
      dispatch(managersActions.getAll(`client_id=${opportunity.client_id}`));

      this.setState({
        title: opportunity.title,
        client_id: opportunity.client_id,
        current_status_id: opportunity.current_status_id,
        manager_id: opportunity.manager_id,
        datetime: opportunity.datetime ? moment(opportunity.datetime).format('DD/MM/YYYY') : null,
        expected_date: opportunity.expected_date
          ? moment(opportunity.expected_date).format('DD/MM/YYYY')
          : null,
        notes: opportunity.notes,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      if (data.name === 'client_id') {
        const { dispatch } = this.props;
        dispatch(
          managersActions.getAll(`filter=["string|account_managers.client_id|${data.value}"]`),
        );
      }
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { title, client_id, current_status_id, manager_id, datetime, expected_date, notes } =
      this.state;
    const { dispatch, match } = this.props;

    if (
      (moment(moment(expected_date, 'DD/MM/YYYY')).isAfter(moment(datetime, 'DD/MM/YYYY')) ||
        !expected_date) &&
      title &&
      current_status_id &&
      manager_id &&
      datetime
    ) {
      dispatch(
        opportunityActions.update(
          match.params.id,
          {
            title,
            client_id,
            current_status_id,
            manager_id,
            datetime: datetime ? moment(datetime, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            expected_date: expected_date
              ? moment(expected_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
              : null,
            notes,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const {
      title,
      client_id,
      current_status_id,
      manager_id,
      datetime,
      expected_date,
      notes,
      submitted,
    } = this.state;
    const { clients, managers, loading, status } = this.props;
    let clientOptions,
      statusOptions,
      userOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (managers && managers.data !== undefined) {
      userOptions = managers.data.map((item) => {
        return { key: item.user.id, value: item.id, text: item.user.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Oportunidades</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Título</label>
            <input name="title" onChange={this.handleChange} placeholder="Título" value={title} />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Título é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              clearable
              name="current_status_id"
              options={statusOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={current_status_id}
            />
            {submitted && !current_status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Gerente</label>
            <Dropdown
              placeholder="Gerente"
              fluid
              search
              selection
              name="manager_id"
              options={userOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={manager_id}
            />
            {submitted && !manager_id && (
              <Label basic color="red" pointing>
                Gerente é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Data</label>
            <DateInput
              closable
              clearable
              name="datetime"
              dateFormat="DD/MM/YYYY"
              placeholder="Data"
              onChange={(event, data) => this.handleChange(true, data)}
              value={datetime}
              iconPosition="left"
            />
            {submitted && !datetime && (
              <Label basic color="red" pointing>
                Data é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Data Prevista Fechamento</label>
            <DateInput
              closable
              clearable
              name="expected_date"
              dateFormat="DD/MM/YYYY"
              placeholder="Data Prevista Fechamento"
              onChange={(event, data) => this.handleChange(true, data)}
              value={expected_date}
              iconPosition="left"
            />
            {expected_date &&
              moment(moment(expected_date, 'DD/MM/YYYY')).isBefore(
                moment(datetime, 'DD/MM/YYYY'),
              ) && (
                <Label basic color="red" pointing>
                  Data Prevista não pode ser menor que a data.
                </Label>
              )}
          </Form.Field>

          <Form.Field>
            <label>Descrição</label>
            <TextArea
              name="notes"
              value={notes}
              placeholder="Descrição"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity, managers, clients } = state;
  return {
    clients,
    managers,
    opportunity: opportunity.item,
    status: opportunity.status,
    loading: opportunity.loading || false,
  };
}

const connectedOpportunityEditScreen = connect(mapStateToProps)(OpportunityEditScreen);
export { connectedOpportunityEditScreen as OpportunityEditScreen };
