import { contactConstants } from '../constants/contact.constants';

export function contact(state = {}, action) {
  switch (action.type) {
    case contactConstants.CREATECONTACT_REQUEST:
    case contactConstants.GETALLCONTACT_REQUEST:
    case contactConstants.GETCONTACT_REQUEST:
    case contactConstants.DELETECONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case contactConstants.CREATECONTACT_SUCCESS:
    case contactConstants.GETCONTACT_SUCCESS:
    case contactConstants.DELETECONTACT_SUCCESS:
      return action.client;
    case contactConstants.GETALLCONTACT_SUCCESS:
      return action.clients;
    case contactConstants.CREATECONTACT_FAILURE:
    case contactConstants.GETALLCONTACT_FAILURE:
    case contactConstants.GETCONTACT_FAILURE:
    case contactConstants.DELETECONTACT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
