import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { activityActions } from '../../actions/activity.actions';

class ActivityShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(activityActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(activityActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/report'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/activities/${match.params.id}/edit`);
  }

  render() {
    const { activity, loading } = this.props;
    const { open } = this.state;

    if (loading || !activity) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={10} tablet={10} computer={10}>
            <h1>{activity.description}</h1>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" title="Editar" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} title="Remover" />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <h4>Projeto</h4> {activity.project ? activity.project.name : ''}
            </Grid.Column>
            <Grid.Column>
              <h4>Tipo</h4> {activity.type ? activity.type.name : ''}
            </Grid.Column>
            <Grid.Column>
              <h4>Responsável</h4> {activity.user ? activity.user.name : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <h4>Criado em</h4> {moment(activity.created_at).format('DD/MM/YYYY HH:mm')}
            </Grid.Column>
            <Grid.Column>
              <h4>Início</h4> {moment(activity.timestamp_start).format('DD/MM/YYYY HH:mm')}
            </Grid.Column>
            <Grid.Column>
              <h4>Fim</h4> {moment(activity.timestamp_end).format('DD/MM/YYYY HH:mm')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>Descrição</h4> {activity.description}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>Anexos</h4> {activity.attachment}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { activities } = state;

  return {
    activity: activities.item || null,
    loading: activities.loading || false,
  };
}

const connectedActivityShowScreen = connect(mapStateToProps)(ActivityShowScreen);
export { connectedActivityShowScreen as ActivityShowScreen };
