export const reportConstants = {
  GETALLACTIVITY_REQUEST: 'GETALLACTIVITY_REQUEST',
  GETALLACTIVITY_SUCCESS: 'GETALLACTIVITY_SUCCESS',
  GETALLACTIVITY_FAILURE: 'GETALLACTIVITY_FAILURE',

  GETBANKACTIVITY_REQUEST: 'GETBANKACTIVITY_REQUEST',
  GETBANKACTIVITY_SUCCESS: 'GETBANKACTIVITY_SUCCESS',
  GETBANKACTIVITY_FAILURE: 'GETBANKACTIVITY_FAILURE',

  GETALLEXPENSE_REQUEST: 'GETALLEXPENSE_REQUEST',
  GETALLEXPENSE_SUCCESS: 'GETALLEXPENSE_SUCCESS',
  GETALLEXPENSE_FAILURE: 'GETALLEXPENSE_FAILURE',

  GETALLREPORT_REQUEST: 'GETALLREPORT_REQUEST',
  GETALLREPORT_SUCCESS: 'GETALLREPORT_SUCCESS',
  GETALLREPORT_FAILURE: 'GETALLREPORT_FAILURE',
};
