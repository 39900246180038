import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { clientActions } from '../../actions/client.actions';
import { managersActions } from '../../actions/managers.actions';
import { userActions } from '../../actions/user.actions';

class ManagersCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: null,
      client_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(clientActions.getAll());
    dispatch(userActions.getCompanyUsers());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { user_id, client_id } = this.state;
    const { dispatch } = this.props;
    if (user_id) {
      let payload = {
        user_id,
        client_id,
      };
      dispatch(managersActions.create(payload, this.props.history)).then(() => {
        this.setState({
          submitted: false,
        });
      });
    }
  }

  render() {
    const { user_id, client_id, submitted } = this.state;
    const { loading, clients, users } = this.props;
    let clientOptions,
      userOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Gerente de Conta</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Usuário</label>
            <Dropdown
              placeholder="Usuário"
              fluid
              search
              selection
              name="user_id"
              options={userOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={user_id}
            />
            {submitted && !user_id && (
              <Label basic color="red" pointing>
                Usuário é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { managers, clients, users } = state;
  return {
    clients,
    users: users.companyUsers,
    loading: managers.loading || false,
  };
}

const connectedManagersCreateScreen = connect(mapStateToProps)(ManagersCreateScreen);
export { connectedManagersCreateScreen as ManagersCreateScreen };
