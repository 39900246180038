import { ordersConstants } from '../constants/orders.constants';

export function orders(state = {}, action) {
  switch (action.type) {
    case ordersConstants.GETALLORDERS_REQUEST:
    case ordersConstants.CREATEORDERS_REQUEST:
    case ordersConstants.UPDATEORDERSSTATUS_REQUEST:
    case ordersConstants.UPDATEORDERS_REQUEST:
    case ordersConstants.UPDATEORDERSPARTICIPANT_REQUEST:
    case ordersConstants.DELETEORDERS_REQUEST:
    case ordersConstants.DELETEORDERSPARTICIPANT_REQUEST:
    case ordersConstants.CREATEORDERSPARTICIPANT_REQUEST:
    case ordersConstants.GETORDERSPARTICIPANT_REQUEST:
    case ordersConstants.GETORDERSPARTICIPANTTYPE_REQUEST:
    case ordersConstants.GET_ORDERSINTERACTION_REQUEST:
    case ordersConstants.CREATE_ORDERSINTERACTION_REQUEST:
    case ordersConstants.GETUSERORDERS_REQUEST:
    case ordersConstants.GETPAYMENTORDERS_REQUEST:
    case ordersConstants.CREATEORDERSITEMS_REQUEST:
    case ordersConstants.GET_ORDERSITEMS_REQUEST:
    case ordersConstants.GETID_ORDERSITEMS_REQUEST:
    case ordersConstants.GET_ORDERSSTATUS_REQUEST:
    case ordersConstants.GET_ATTACHMENT_ORDERS_REQUEST:
    case ordersConstants.CREATE_ATTACHMENT_ORDERS_REQUEST:
    case ordersConstants.GET_EMAILTEXTORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ordersConstants.GETORDERSPARTICIPANT_SUCCESS:
    case ordersConstants.DELETEORDERSPARTICIPANT_SUCCESS:
    case ordersConstants.UPDATEORDERSPARTICIPANT_SUCCESS:
      return {
        ...state,
        itemParticipant: action.orders,
        loading: false,
      };
    case ordersConstants.GET_ORDERSSTATUS_SUCCESS:
      return {
        ...state,
        statusOrder: action.statusOrder,
        loading: false,
      };
    case ordersConstants.GET_EMAILTEXTORDER_SUCCESS:
      return {
        ...state,
        orderEmailText: action.orderEmailText,
        loading: false,
      };
    case ordersConstants.UPDATEORDERSSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case ordersConstants.CREATEORDERS_SUCCESS:
    case ordersConstants.UPDATEORDERS_SUCCESS:
    case ordersConstants.DELETEORDERS_SUCCESS:
    case ordersConstants.GETORDERS_SUCCESS:
      return {
        ...state,
        item: action.orders,
        loading: false,
      };
    case ordersConstants.GETALLORDERS_SUCCESS:
    case ordersConstants.GETMEORDERS_SUCCESS:
      return {
        ...state,
        ...action.orders,
        loading: false,
      };
    case ordersConstants.CREATE_ORDERSINTERACTION_SUCCESS:
    case ordersConstants.GET_ORDERSINTERACTION_SUCCESS:
      return {
        ...state,
        interaction: action.interaction,
        loading: false,
      };
    case ordersConstants.CREATE_ATTACHMENT_ORDERS_SUCCESS:
    case ordersConstants.GET_ATTACHMENT_ORDERS_SUCCESS:
      return {
        ...state,
        attachment: action.attachment,
        loading: false,
      };
    case ordersConstants.CREATEORDERSPARTICIPANT_SUCCESS:
    case ordersConstants.GETALLORDERSPARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: action.participants,
        loading: false,
      };
    case ordersConstants.GETORDERSPARTICIPANTTYPE_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case ordersConstants.GETUSERORDERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        loading: false,
      };

    case ordersConstants.CREATEORDERSITEMS_SUCCESS:
    case ordersConstants.GET_ORDERSITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
        loading: false,
      };
    case ordersConstants.GETID_ORDERSITEMS_SUCCESS:
      return {
        ...state,
        orderItem: action.orderItem,
        loading: false,
      };
    case ordersConstants.GETPAYMENTORDERS_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        loading: false,
      };
    case ordersConstants.CREATEORDERS_FAILURE:
    case ordersConstants.UPDATEORDERSSTATUS_FAILURE:
    case ordersConstants.UPDATEORDERS_FAILURE:
    case ordersConstants.UPDATEORDERSPARTICIPANT_FAILURE:
    case ordersConstants.CREATEORDERSPARTICIPANT_FAILURE:
    case ordersConstants.GETORDERSPARTICIPANTTYPE_FAILURE:
    case ordersConstants.DELETEORDERS_FAILURE:
    case ordersConstants.DELETEORDERSPARTICIPANT_FAILURE:
    case ordersConstants.GET_ORDERSINTERACTION_FAILURE:
    case ordersConstants.CREATE_ORDERSINTERACTION_FAILURE:
    case ordersConstants.GETUSERORDERS_FAILURE:
    case ordersConstants.GETPAYMENTORDERS_FAILURE:
    case ordersConstants.CREATEORDERSITEMS_FAILURE:
    case ordersConstants.GET_ORDERSITEMS_FAILURE:
    case ordersConstants.GETID_ORDERSITEMS_FAILURE:
    case ordersConstants.GET_ORDERSSTATUS_FAILURE:
    case ordersConstants.GET_ATTACHMENT_ORDERS_FAILURE:
    case ordersConstants.CREATE_ATTACHMENT_ORDERS_FAILURE:
    case ordersConstants.GET_EMAILTEXTORDER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
