import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ticketActions } from '../../actions/ticket.actions';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';

class ModalPriority extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      priority_id: this.props.priority_id,
      openModalPriority: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(ticketActions.getPriorities());
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { ticket_id, dispatch, history } = this.props;
    const { priority_id } = this.state;

    if (priority_id) {
      let payload = {
        priority_id,
      };

      dispatch(ticketActions.update(ticket_id, payload, history)).then(() => {
        const { ticket_id } = this.props;
        dispatch(ticketActions.getById(ticket_id));
        this.onClose();
        this.setState({
          ticket_id: ticket_id,
          submitted: false,
        });
        this.setState({
          refresh: true,
          page: 1,
        });
      });
    }
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onClose() {
    this.setState({ openModalPriority: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openModalPriority &&
      (this.props.openModalPriority !== prevProps.openModalPriority ||
        this.props.id != prevProps.id) &&
      !this.state.openModalPriority
    ) {
      this.setState({ openModalPriority: true });
    }
  }

  render() {
    const { submitted, openModalPriority, priority_id } = this.state;
    const { priorities } = this.props;

    let priorityOptions = [];

    if (priorities && priorities.data !== undefined) {
      priorityOptions = priorities.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="small" open={openModalPriority} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Selecione a Prioridade</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Dropdown
                name="priority_id"
                onChange={(event, data) => this.handleChange(true, data)}
                placeholder="Prioridade"
                fluid
                search
                selection
                options={priorityOptions}
                value={priority_id}
              />
              {submitted && !priority_id && (
                <Label basic color="red" pointing>
                  Prioridade é requerida
                </Label>
              )}
            </Form.Field>
            <Button content="Atualizar" labelPosition="left" icon="save" primary />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    priority_id: tickets.item.priority_id,
    priorities: tickets.priorities,
  };
}

const connectedModalPriority = connect(mapStateToProps)(ModalPriority);
export { connectedModalPriority as ModalPriority };
