import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';
import { ordersActions } from '../../actions/orders.actions';
import { DateInput } from 'semantic-ui-calendar-react';
import { companyActions } from '../../actions/company.actions';
import { providersActions } from '../../actions/providers.actions';
import moment from 'moment';

class OrderEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      user_id: null,
      billing_company_id: null,
      payment_method_id: null,
      provider_id: null,
      description: '',
      due_date: null,
      invoice: '',
      type: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(providersActions.getAll());
    dispatch(ordersActions.getUser());
    dispatch(ordersActions.getPayment());
    dispatch(companyActions.getAll());

    dispatch(ordersActions.getById(match.params.id)).then(() => {
      const { order } = this.props;
      this.setState({
        name: order.name,
        user_id: order.user_id,
        billing_company_id: order.billing_company_id,
        payment_method_id: order.payment_method_id,
        description: order.description,
        due_date: order.due_date ? moment(order.due_date).format('DD/MM/YYYY') : null,
        provider_id: order.provider_id,
        invoice: order.invoice,
        type: order.type,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      name,
      user_id,
      billing_company_id,
      provider_id,
      description,
      payment_method_id,
      due_date,
      invoice,
      type,
    } = this.state;
    const { dispatch, match } = this.props;

    if (name) {
      const date = due_date ? moment(due_date, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
      dispatch(
        ordersActions.update(match.params.id, {
          name,
          user_id,
          billing_company_id,
          provider_id,
          description,
          due_date: date,
          payment_method_id,
          invoice,
          type,
        }),
      ).then(() => {
        const { order } = this.props;

        this.props.history.push(`/orders/${order.id}/show`);
      });
    }
  }

  render() {
    const {
      name,
      user_id,
      billing_company_id,
      provider_id,
      description,
      payment_method_id,
      due_date,
      invoice,
      submitted,
      type,
    } = this.state;
    const { loading, providers, companies, orders, users, payment } = this.props;
    let companyOptions,
      userOptions,
      paymentOptions,
      providersOptions = [];

    const typeOptions = [
      { key: 'I', value: 'I', text: 'Interno' },
      { key: 'E', value: 'E', text: 'Externo' },
    ];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (companies && companies.data !== undefined) {
      companyOptions = companies.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (payment && payment.data !== undefined) {
      paymentOptions = payment.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Editar Pedido</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Tipo</label>
            <Dropdown
              placeholder="Tipo"
              fluid
              search
              selection
              name="type"
              options={typeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={type}
            />
            {submitted && !type && (
              <Label basic color="red" pointing>
                Tipo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Nome</label>
            <input name="name" onChange={this.handleChange} placeholder="Nome" value={name} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Vendedor </label>
            <Dropdown
              placeholder="Vendedor "
              fluid
              search
              selection
              name="user_id"
              options={userOptions}
              onChange={(_, data) => this.handleChange(true, data)}
              value={user_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Fornecedor</label>
            <Dropdown
              placeholder="Fornecedor"
              fluid
              search
              selection
              name="provider_id"
              options={providersOptions}
              onChange={(_, data) => this.handleChange(true, data)}
              value={provider_id}
            />
            {submitted && !provider_id && (
              <Label basic color="red" pointing>
                Fornecedor é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Método Pagamento</label>
            <Dropdown
              placeholder="Método Pagamento"
              fluid
              search
              selection
              name="payment_method_id"
              options={paymentOptions}
              onChange={(_, data) => this.handleChange(true, data)}
              value={payment_method_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Empresa Faturamento</label>
            <Dropdown
              placeholder="Empresa Faturamento"
              fluid
              search
              selection
              name="billing_company_id"
              options={companyOptions}
              onChange={(_, data) => this.handleChange(true, data)}
              value={billing_company_id}
            />
          </Form.Field>
          <Form.Field>
            <label>Data de fechamento</label>
            <DateInput
              closable
              clearable
              name="due_date"
              dateFormat="DD/MM/YYYY"
              placeholder="Data de fechamento"
              onChange={(event, data) => this.handleChange(true, data)}
              value={due_date}
              iconPosition="left"
            />
          </Form.Field>
          <Form.Field>
            <label>Nota Fiscal</label>
            <input
              name="invoice"
              onChange={this.handleChange}
              placeholder="Nota Fiscal"
              value={invoice}
            />
          </Form.Field>
          <Form.Field>
            <label>Observações</label>
            <TextArea
              name="description"
              value={description}
              placeholder="Observações"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { orders, companies, providers } = state;

  return {
    companies,
    providers,
    order: orders.item,
    users: orders.users,
    payment: orders.payment,
    loading: orders?.loading || false,
  };
}

const connectedOrderEditScreen = connect(mapStateToProps)(OrderEditScreen);
export { connectedOrderEditScreen as OrderEditScreen };
