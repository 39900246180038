import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label, TextArea } from 'semantic-ui-react';
import TimerMachine from 'react-timer-machine';
import moment from 'moment';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { activityActions } from '../../actions/activity.actions';
import { projectActions } from '../../actions/project.actions';
import _ from 'lodash';

class ActivityCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity_id: null,
      loading: false,
      projectOptionsFilter: [],
      updated: true,
      project_text: null,
      working: false,
      activityExpense: {},
      time_manual_start: '',
      time_manual_end: '',
      closeProject: false,
      timestamp_start: null,
      timestamp_end: null,
      description: '',
      mode: 'auto',
      type_id: null,
      status_id: null,
      project_id: null,
      submitted: false,
      timeStart: 0,
      deleteId: null,
    };
  }

  initialState() {
    this.setState({
      activity_id: null,
      loading: false,
      updated: true,
      project_text: null,
      closeProject: false,
      working: false,
      time_manual_start: null,
      time_manual_end: null,
      timestamp_start: null,
      timestamp_end: null,
      description: '',
      mode: 'auto',
      type_id: null,
      status_id: null,
      project_id: null,
      submitted: false,
      timeStart: 0,
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(activityActions.getTypes());
    dispatch(projectActions.getAll('perPage=5000'));
  }

  componentDidUpdate() {
    if (this.props.recreate && this.state.updated) {
      this.setState(this.props.recreate, () => this.setState({ updated: false }));
    }
  }

  handleStart() {
    const {
      project_id,
      mode,
      description,
      type_id,
      timestamp_end,
      working,
      time_manual_start,
      time_manual_end,
    } = this.state;
    const { dispatch } = this.props;

    if (project_id && type_id && description && !timestamp_end && !working) {
      let payload = {
        project_id,
        mode,
        type_id,
        description,
        timestamp_start: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      if (mode !== 'auto' && time_manual_start && time_manual_end) {
        payload.timestamp_start = moment(time_manual_start, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss',
        );
        payload.timestamp_end = moment(time_manual_end, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss',
        );
      }

      this.setState({ loading: true });
      dispatch(activityActions.create(payload))
        .then(() => {
          if (mode === 'auto') {
            this.setState({ working: true, submitted: false, description });
          } else {
            dispatch(activityActions.timer(this.props.startDate, this.props.endDate));
            this.initialState();
          }

          this.setState({ loading: false, updated: true });
        })
        .catch((error) => {
          console.log('ERROR', error);
          this.setState({ loading: false, submitted: false });
        });
    } else {
      this.setState({ loading: false, submitted: true });
    }
  }

  handleStop() {
    const { dispatch, activity, activities } = this.props;
    let idActivity = activity.id;

    if ((!activity || !activity.id) && activities.current_activity) {
      idActivity = activities.current_activity.id;
    }

    try {
      this.setState({ loading: true });
      dispatch(
        activityActions.update(idActivity, {
          timestamp_end: moment().format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
        .then(() => {
          dispatch(activityActions.timer(this.props.startDate, this.props.endDate)).then(() => {
            this.setState({ updated: true, refresh: true });
            this.initialState();
          });
        })
        .catch(() => {
          this.setState({ loading: false, submitted: false });
        });
    } catch (error) {
      this.setState({ working: false });
    }
  }

  changeMode(obj) {
    this.setState(obj);
  }

  toggleTimer() {
    if (!this.state.working) {
      this.handleStart();
    } else {
      this.handleStop();
    }
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  getProjectOptions() {
    const { projects } = this.props;
    let projectOptions = [];

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
          status_id: item.status_id,
          item: item,
          content: (
            <div className="item-flex">
              <h4>
                <i>{item.code}</i> {item.name}
              </h4>
              <span>{item.client.name}</span>
              <Label>{item.status.name}</Label>
            </div>
          ),
        };
      });
    }

    return projectOptions;
  }

  searchProject(items, search) {
    return _.filter(items, (item) => {
      const tokens = search.toLowerCase().split(' ');
      let match = false;
      for (const token of tokens) {
        if (
          item.item.client.name.toLowerCase().indexOf(token) > -1 ||
          item.item.status.name.toLowerCase().indexOf(token) > -1 ||
          (item.item.code && item.item.code.toLowerCase().indexOf(token) > -1) ||
          item.item.name.toLowerCase().indexOf(token) > -1
        ) {
          match = true;
          break;
        }
      }

      return match;
    });
  }

  getTypeOptions() {
    const { types } = this.props;
    let typeOptions = [];

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return typeOptions;
  }

  render() {
    const {
      project_id,
      type_id,
      description,
      loading,
      mode,
      submitted,
      time_manual_start,
      time_manual_end,
      working,
    } = this.state;

    const { dispatch } = this.props;
    const projectOptions = this.getProjectOptions();
    const typeOptions = this.getTypeOptions();

    return (
      <Grid>
        <Grid.Column computer={9} mobile={16} verticalAlign="middle">
          <Form>
            <TextArea
              name="description"
              value={description}
              error={submitted && !description ? true : false}
              onClick={(e) => e.preventDefault()}
              onChange={(e) => this.handleChange(e)}
              placeholder="Descrição da tarefa"
            />
          </Form>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={3}>
          <Dropdown
            placeholder="Projeto"
            fluid
            selection
            floating
            name="project_id"
            search={this.searchProject}
            error={submitted && !project_id ? true : false}
            options={projectOptions}
            onChange={(event, data) => {
              this.handleChange(true, data);
              let statusId = null;
              for (let option of projectOptions) {
                if (option.value == data.value) {
                  statusId = option.status_id;
                }
              }
              dispatch(
                activityActions.getTypes(`status_id=${statusId}&filter=["string|active|1"]`),
              );
            }}
            value={project_id}></Dropdown>
          <Dropdown
            placeholder="Tipo"
            fluid
            search
            selection
            name="type_id"
            disabled={!project_id}
            style={{ marginTop: '5px' }}
            error={submitted && !type_id ? true : false}
            options={typeOptions}
            onChange={(event, data) => this.handleChange(true, data)}
            value={type_id}
          />
        </Grid.Column>

        <Grid.Column textAlign="center" verticalAlign="middle" mobile={8} tablet={2} computer={3}>
          {mode === 'manual' && (
            <div>
              <DateTimeInput
                closable
                clearable
                error={submitted && !this.state.time_manual_start ? true : false}
                dateTimeFormat="DD/MM/YYYY HH:mm:ss"
                name="time_manual_start"
                placeholder="Data Início"
                onChange={(event, data) => this.handleChange(true, data)}
                value={this.state.time_manual_start}
                iconPosition="left"
              />
              <DateTimeInput
                closable
                clearable
                error={submitted && !this.state.time_manual_end ? true : false}
                name="time_manual_end"
                style={{ marginTop: '5px' }}
                dateTimeFormat="DD/MM/YYYY HH:mm:ss"
                placeholder="Data Fim"
                onChange={(event, data) => this.handleChange(true, data)}
                value={this.state.time_manual_end}
                iconPosition="left"
              />
            </div>
          )}
          {mode === 'auto' && (
            <h2 className="container-time-used">
              <TimerMachine
                timeStart={this.state.timeStart}
                started={this.state.working}
                paused={false}
                countdown={false}
                interval={1000}
                className="time-used"
                formatTimer={(time, ms) =>
                  moment.duration(ms, 'milliseconds').format('HH:mm:ss', { trim: false })
                }
              />
            </h2>
          )}
        </Grid.Column>

        <Grid.Column verticalAlign="middle" mobile={8} tablet={1} computer={1}>
          <div className="action-activity">
            <Button
              size={'huge'}
              circular
              disabled={
                (!working &&
                  (!type_id || (mode == 'manual' && (!time_manual_start || !time_manual_end)))) ||
                loading
                  ? true
                  : false
              }
              color={mode == 'auto' ? (!working ? 'grey' : 'red') : 'teal'}
              icon={!working ? (mode == 'auto' ? 'play' : 'save') : 'stop'}
              onClick={() => this.toggleTimer()}
            />

            <div className="mode">
              <span
                className={mode == 'auto' ? 'automatic selected' : 'automatic'}
                onClick={() => this.changeMode({ mode: 'auto' })}>
                A
              </span>
              <span
                className={mode == 'manual' ? 'manual selected' : 'manual'}
                onClick={() => this.changeMode({ mode: 'manual' })}>
                M
              </span>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { activities, projects } = state;
  return {
    activities,
    types: activities.types,
    activity: activities.item || null,
    projects,
    project: projects.item || null,
    status: projects.status || null,
  };
}

const connectedActivityCreate = connect(mapStateToProps)(ActivityCreate);
export { connectedActivityCreate as ActivityCreate };
