export const ordersConstants = {
  GETALLORDERS_REQUEST: 'GETALLORDERS_REQUEST',
  GETALLORDERS_SUCCESS: 'GETALLORDERS_SUCCESS',
  GETALLORDERS_FAILURE: 'GETALLORDERS_FAILURE',

  GETMEORDERS_REQUEST: 'GETMEORDERS_REQUEST',
  GETMEORDERS_SUCCESS: 'GETMEORDERS_SUCCESS',
  GETMEORDERS_FAILURE: 'GETMEORDERS_FAILURE',

  GETUSERORDERS_REQUEST: 'GETUSERORDERS_REQUEST',
  GETUSERORDERS_SUCCESS: 'GETUSERORDERS_SUCCESS',
  GETUSERORDERS_FAILURE: 'GETUSERORDERS_FAILURE',

  GET_ATTACHMENT_ORDERS_REQUEST: 'GET_ATTACHMENT_ORDERS_REQUEST',
  GET_ATTACHMENT_ORDERS_SUCCESS: 'GET_ATTACHMENT_ORDERS_SUCCESS',
  GET_ATTACHMENT_ORDERS_FAILURE: 'GET_ATTACHMENT_ORDERS_FAILURE',

  GETPAYMENTORDERS_REQUEST: 'GETPAYMENTORDERS_REQUEST',
  GETPAYMENTORDERS_SUCCESS: 'GETPAYMENTORDERS_SUCCESS',
  GETPAYMENTORDERS_FAILURE: 'GETPAYMENTORDERS_FAILURE',

  GETALLORDERSPARTICIPANT_REQUEST: 'GETALLORDERSPARTICIPANT_REQUEST',
  GETALLORDERSPARTICIPANT_SUCCESS: 'GETALLORDERSPARTICIPANT_SUCCESS',
  GETALLORDERSPARTICIPANT_FAILURE: 'GETALLORDERSPARTICIPANT_FAILURE',

  GETORDERSPARTICIPANT_REQUEST: 'GETORDERSPARTICIPANT_REQUEST',
  GETORDERSPARTICIPANT_SUCCESS: 'GETORDERSPARTICIPANT_SUCCESS',
  GETORDERSPARTICIPANT_FAILURE: 'GETORDERSPARTICIPANT_FAILURE',

  GETORDERSPARTICIPANTTYPE_REQUEST: 'GETORDERSPARTICIPANTTYPE_REQUEST',
  GETORDERSPARTICIPANTTYPE_SUCCESS: 'GETORDERSPARTICIPANTTYPE_SUCCESS',
  GETORDERSPARTICIPANTTYPE_FAILURE: 'GETORDERSPARTICIPANTTYPE_FAILURE',

  GETORDERS_REQUEST: 'GETORDERS_REQUEST',
  GETORDERS_SUCCESS: 'GETORDERS_SUCCESS',
  GETORDERS_FAILURE: 'GETORDERS_FAILURE',

  CREATEORDERS_REQUEST: 'CREATEORDERS_REQUEST',
  CREATEORDERS_SUCCESS: 'CREATEORDERS_SUCCESS',
  CREATEORDERS_FAILURE: 'CREATEORDERS_FAILURE',

  CREATEORDERSPARTICIPANT_REQUEST: 'CREATEORDERSPARTICIPANT_REQUEST',
  CREATEORDERSPARTICIPANT_SUCCESS: 'CREATEORDERSPARTICIPANT_SUCCESS',
  CREATEORDERSPARTICIPANT_FAILURE: 'CREATEORDERSPARTICIPANT_FAILURE',

  CREATE_ORDERSINTERACTION_REQUEST: 'CREATE_ORDERSINTERACTION_REQUEST',
  CREATE_ORDERSINTERACTION_SUCCESS: 'CREATE_ORDERSINTERACTION_SUCCESS',
  CREATE_ORDERSINTERACTION_FAILURE: 'CREATE_ORDERSINTERACTION_FAILURE',

  CREATEORDERSITEMS_REQUEST: 'CREATEORDERSITEMS_REQUEST',
  CREATEORDERSITEMS_SUCCESS: 'CREATEORDERSITEMS_SUCCESS',
  CREATEORDERSITEMS_FAILURE: 'CREATEORDERSITEMS_FAILURE',

  CREATE_ATTACHMENT_ORDERS_REQUEST: 'CREATE_ATTACHMENT_ORDERS_REQUEST',
  CREATE_ATTACHMENT_ORDERS_SUCCESS: 'CREATE_ATTACHMENT_ORDERS_SUCCESS',
  CREATE_ATTACHMENT_ORDERS_FAILURE: 'CREATE_ATTACHMENT_ORDERS_FAILURE',

  GET_ORDERSITEMS_REQUEST: 'GET_ORDERSITEMS_REQUEST',
  GET_ORDERSITEMS_SUCCESS: 'GET_ORDERSITEMS_SUCCESS',
  GET_ORDERSITEMS_FAILURE: 'GET_ORDERSITEMS_FAILURE',

  GETID_ORDERSITEMS_REQUEST: 'GETID_ORDERSITEMS_REQUEST',
  GETID_ORDERSITEMS_SUCCESS: 'GETID_ORDERSITEMS_SUCCESS',
  GETID_ORDERSITEMS_FAILURE: 'GETID_ORDERSITEMS_FAILURE',

  UPDATEORDERS_REQUEST: 'UPDATEORDERS_REQUEST',
  UPDATEORDERS_SUCCESS: 'UPDATEORDERS_SUCCESS',
  UPDATEORDERS_FAILURE: 'UPDATEORDERS_FAILURE',

  UPDATEORDERSPARTICIPANT_REQUEST: 'UPDATEORDERSPARTICIPANT_REQUEST',
  UPDATEORDERSPARTICIPANT_SUCCESS: 'UPDATEORDERSPARTICIPANT_SUCCESS',
  UPDATEORDERSPARTICIPANT_FAILURE: 'UPDATEORDERSPARTICIPANT_FAILURE',

  UPDATEORDERSSTATUS_REQUEST: 'UPDATEORDERSSTATUS_REQUEST',
  UPDATEORDERSSTATUS_SUCCESS: 'UPDATEORDERSSTATUS_SUCCESS',
  UPDATEORDERSSTATUS_FAILURE: 'UPDATEORDERSSTATUS_FAILURE',

  DELETEORDERS_REQUEST: 'DELETEORDERS_REQUEST',
  DELETEORDERS_SUCCESS: 'DELETEORDERS_SUCCESS',
  DELETEORDERS_FAILURE: 'DELETEORDERS_FAILURE',

  DELETEORDERSPARTICIPANT_REQUEST: 'DELETEORDERSPARTICIPANT_REQUEST',
  DELETEORDERSPARTICIPANT_SUCCESS: 'DELETEORDERSPARTICIPANT_SUCCESS',
  DELETEORDERSPARTICIPANT_FAILURE: 'DELETEORDERSPARTICIPANT_FAILURE',

  GETALLROLES_REQUEST: 'GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'GETALLROLES_FAILURE',

  GET_ORDERSSTATUS_REQUEST: 'GET_ORDERSSTATUS_REQUEST',
  GET_ORDERSSTATUS_SUCCESS: 'GET_ORDERSSTATUS_SUCCESS',
  GET_ORDERSSTATUS_FAILURE: 'GET_ORDERSSTATUS_FAILURE',

  GET_ORDERSINTERACTION_REQUEST: 'GET_ORDERSINTERACTION_REQUEST',
  GET_ORDERSINTERACTION_SUCCESS: 'GET_ORDERSINTERACTION_SUCCESS',
  GET_ORDERSINTERACTION_FAILURE: 'GET_ORDERSINTERACTION_FAILURE',

  GET_EMAILTEXTORDER_REQUEST: 'GET_EMAILTEXTORDER_REQUEST',
  GET_EMAILTEXTORDER_SUCCESS: 'GET_EMAILTEXTORDER_SUCCESS',
  GET_EMAILTEXTORDER_FAILURE: 'GET_EMAILTEXTORDER_FAILURE',
};
