import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { paymentActions } from '../../actions/payment.actions';

class PaymentCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      active: 1,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, active } = this.state;
    const { dispatch } = this.props;
    if (name) {
      dispatch(
        paymentActions.create(
          {
            name,
            active,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { name, active, submitted } = this.state;
    const { loading } = this.props;
    let activeOptions = [];

    activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Método de Pagamento</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome do método</label>
            <input
              name="name"
              placeholder="Nome so método"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              name="active"
              value={active}
              fluid
              search
              selection
              defaultValue={1}
              onChange={(event, data) => this.handleChange(true, data)}
              options={activeOptions}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { payment } = state;

  return {
    loading: payment.loading || false,
  };
}

const connectedPaymentCreateScreen = connect(mapStateToProps)(PaymentCreateScreen);
export { connectedPaymentCreateScreen as PaymentCreateScreen };
