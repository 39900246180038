import { financesAccountsConstants } from '../constants/finances-accounts.constants';

import financesAccountsService from '../services/finances-accounts.service';
import { alertActions } from './alert.actions';

export const financesAccountsActions = {
  create,
  createType,
  deleteById,
  deleteTypeById,
  getAll,
  getById,
  getTypeById,
  getTypes,
  update,
  updateType,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const financesAccounts = await financesAccountsService.create(obj);

      dispatch(success(financesAccounts));
      alertActions.success('Conta Criada com Sucesso!');
      history.push('/finance/accounts');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: financesAccountsConstants.CREATEFINANCESACCOUNTS_REQUEST };
  }
  function success(financesAccounts) {
    return {
      type: financesAccountsConstants.CREATEFINANCESACCOUNTS_SUCCESS,
      financesAccounts,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.CREATEFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function createType(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await financesAccountsService.createType(obj);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: financesAccountsConstants.CREATEFINANCESACCOUNTSTYPE_REQUEST,
    };
  }
  function success(type) {
    return {
      type: financesAccountsConstants.CREATEFINANCESACCOUNTSTYPE_SUCCESS,
      item: type,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.CREATEFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const financesAccounts = await financesAccountsService.deleteById(id);

      dispatch(success(financesAccounts));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(financesAccounts) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTS_REQUEST,
      financesAccounts,
    };
  }
  function success(financesAccounts) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTS_SUCCESS,
      financesAccounts,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function deleteTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await financesAccountsService.deleteTypeById(id);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_REQUEST,
      item: type,
    };
  }
  function success(type) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_SUCCESS,
      item: type,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const financesAccounts = await financesAccountsService.getAll(filter);

      dispatch(success(financesAccounts));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: financesAccountsConstants.GETALLFINANCESACCOUNTS_REQUEST };
  }
  function success(financesAccounts) {
    return {
      type: financesAccountsConstants.GETALLFINANCESACCOUNTS_SUCCESS,
      financesAccounts,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.GETALLFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const financesAccounts = await financesAccountsService.getById(id);

      dispatch(success(financesAccounts));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(financesAccounts) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTS_REQUEST,
      financesAccounts,
    };
  }
  function success(financesAccounts) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTS_SUCCESS,
      financesAccounts,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function getTypeById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const type = await financesAccountsService.getTypeById(id);

      dispatch(success(type));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(type) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTSTYPE_REQUEST,
      item: type,
    };
  }
  function success(type) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTSTYPE_SUCCESS,
      item: type,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.GETFINANCESACCOUNTSTYPE_FAILURE,
      error,
    };
  }
}

function getTypes(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const types = await financesAccountsService.getTypes(filter);

      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: financesAccountsConstants.GETTYPEFINANCESACCOUNTS_REQUEST };
  }
  function success(types) {
    return {
      type: financesAccountsConstants.GETTYPEFINANCESACCOUNTS_SUCCESS,
      types,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.GETTYPEFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const financesAccounts = await financesAccountsService.update(id, obj);

      dispatch(success(financesAccounts));
      history.push('/finance/accounts');
      alertActions.success('Conta Atualizada com Sucesso!');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: financesAccountsConstants.UPDATEFINANCESACCOUNTS_REQUEST };
  }
  function success(financesAccounts) {
    return {
      type: financesAccountsConstants.UPDATEFINANCESACCOUNTS_SUCCESS,
      financesAccounts,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.UPDATEFINANCESACCOUNTS_FAILURE,
      error,
    };
  }
}

function updateType(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const type = await financesAccountsService.updateType(id, obj);

      dispatch(success(type));
      this.props.history.push('/finances-accounts/types');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_REQUEST,
    };
  }
  function success(type) {
    return {
      type: financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_SUCCESS,
      item: type,
    };
  }
  function failure(error) {
    return {
      type: financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_FAILURE,
      error,
    };
  }
}
