export const companyConstants = {
  GETALLCOMPANY_REQUEST: 'GETALLCOMPANY_REQUEST',
  GETALLCOMPANY_SUCCESS: 'GETALLCOMPANY_SUCCESS',
  GETALLCOMPANY_FAILURE: 'GETALLCOMPANY_FAILURE',

  GETCOMPANY_REQUEST: 'GETCOMPANY_REQUEST',
  GETCOMPANY_SUCCESS: 'GETCOMPANY_SUCCESS',
  GETCOMPANY_FAILURE: 'GETCOMPANY_FAILURE',

  GETONECOMPANY_REQUEST: 'GETONECOMPANY_REQUEST',
  GETONECOMPANY_SUCCESS: 'GETONECOMPANY_SUCCESS',
  GETONECOMPANY_FAILURE: 'GETONECOMPANY_FAILURE',

  CREATEONECOMPANY_REQUEST: 'CREATEONECOMPANY_REQUEST',
  CREATEONECOMPANY_SUCCESS: 'CREATEONECOMPANY_SUCCESS',
  CREATEONECOMPANY_FAILURE: 'CREATEONECOMPANY_FAILURE',

  CREATECOMPANY_REQUEST: 'CREATECOMPANY_REQUEST',
  CREATECOMPANY_SUCCESS: 'CREATECOMPANY_SUCCESS',
  CREATECOMPANY_FAILURE: 'CREATECOMPANY_FAILURE',

  UPDATECOMPANY_REQUEST: 'UPDATECOMPANY_REQUEST',
  UPDATECOMPANY_SUCCESS: 'UPDATECOMPANY_SUCCESS',
  UPDATECOMPANY_FAILURE: 'UPDATECOMPANY_FAILURE',

  DELETECOMPANY_REQUEST: 'DELETECOMPANY_REQUEST',
  DELETECOMPANY_SUCCESS: 'DELETECOMPANY_SUCCESS',
  DELETECOMPANY_FAILURE: 'DELETECOMPANY_FAILURE',
};
