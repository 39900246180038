import client from '../helpers/HttpClient';
import BaseService from './base.service';

class ActivityService extends BaseService {
  get basePath() {
    return 'activities';
  }

  async createType(obj) {
    try {
      const result = await client.post('activities/type', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateType(id, obj) {
    try {
      const result = await client.put(`activities/type/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getTypes(filter) {
    try {
      let endpoint = 'activities/type';
      if (filter) {
        endpoint = `${endpoint}?${filter}`;
      }
      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async timer(start_date, end_date) {
    try {
      const result = await client.get(`me/timer?start_date=${start_date}&end_date=${end_date}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getTypeById(id) {
    try {
      const result = await client.get(`activities/type/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteTypeById(id) {
    try {
      const result = await client.delete(`activities/type/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new ActivityService();
