export const ticketConstants = {
  CREATETICKET_REQUEST: 'CREATETICKET_REQUEST',
  CREATETICKET_SUCCESS: 'CREATETICKET_SUCCESS',
  CREATETICKET_FAILURE: 'CREATETICKET_FAILURE',

  CREATEINTERACTION_REQUEST: 'CREATEINTERACTION_REQUEST',
  CREATEINTERACTION_SUCCESS: 'CREATEINTERACTION_SUCCESS',
  CREATEINTERACTION_FAILURE: 'CREATEINTERACTION_FAILURE',

  CREATEEXECUTORS_REQUEST: 'CREATEEXECUTORS_REQUEST',
  CREATEEXECUTORS_SUCCESS: 'CREATEEXECUTORS_SUCCESS',
  CREATEEXECUTORS_FAILURE: 'CREATEEXECUTORS_FAILURE',

  CREATETEAMS_REQUEST: 'CREATETEAMS_REQUEST',
  CREATETEAMS_SUCCESS: 'CREATETEAMS_SUCCESS',
  CREATETEAMS_FAILURE: 'CREATETEAMS_FAILURE',

  UPDATETICKET_REQUEST: 'UPDATETICKET_REQUEST',
  UPDATETICKET_SUCCESS: 'UPDATETICKET_SUCCESS',
  UPDATETICKET_FAILURE: 'UPDATETICKET_FAILURE',

  GETALLTICKET_REQUEST: 'GETALLTICKET_REQUEST',
  GETALLTICKET_SUCCESS: 'GETALLTICKET_SUCCESS',
  GETALLTICKET_FAILURE: 'GETALLTICKET_FAILURE',

  GETALLINTERACTION_REQUEST: 'GETALLINTERACTION_REQUEST',
  GETALLINTERACTION_SUCCESS: 'GETALLINTERACTION_SUCCESS',
  GETALLINTERACTION_FAILURE: 'GETALLINTERACTION_FAILURE',

  GETALLEXECUTORS_REQUEST: 'GETALLEXECUTORS_REQUEST',
  GETALLEXECUTORS_SUCCESS: 'GETALLEXECUTORS_SUCCESS',
  GETALLEXECUTORS_FAILURE: 'GETALLEXECUTORS_FAILURE',

  GETALLTEAMS_REQUEST: 'GETALLTEAMS_REQUEST',
  GETALLTEAMS_SUCCESS: 'GETALLTEAMS_SUCCESS',
  GETALLTEAMS_FAILURE: 'GETALLTEAMS_FAILURE',

  GETTICKET_REQUEST: 'GETTICKET_REQUEST',
  GETTICKET_SUCCESS: 'GETTICKET_SUCCESS',
  GETTICKET_FAILURE: 'GETTICKET_FAILURE',

  GETTICKETPRIORITIES_REQUEST: 'GETTICKETPRIORITIES_REQUEST',
  GETTICKETPRIORITIES_SUCCESS: 'GETTICKETPRIORITIES_SUCCESS',
  GETTICKETPRIORITIES_FAILURE: 'GETTICKETPRIORITIES_FAILURE',

  DELETETICKET_REQUEST: 'DELETETICKET_REQUEST',
  DELETETICKET_SUCCESS: 'DELETETICKET_SUCCESS',
  DELETETICKET_FAILURE: 'DELETETICKET_FAILURE',

  DELETEATTACHMENT_REQUEST: 'DELETEATTACHMENT_REQUEST',
  DELETEATTACHMENT_SUCCESS: 'DELETEATTACHMENT_SUCCESS',
  DELETEATTACHMENT_FAILURE: 'DELETEATTACHMENT_FAILURE',

  DELETEEXECUTORS_REQUEST: 'DELETEEXECUTORS_REQUEST',
  DELETEEXECUTORS_SUCCESS: 'DELETEEXECUTORS_SUCCESS',
  DELETEEXECUTORS_FAILURE: 'DELETEEXECUTORS_FAILURE',

  DELETETEAMS_REQUEST: 'DELETETEAMS_REQUEST',
  DELETETEAMS_SUCCESS: 'DELETETEAMS_SUCCESS',
  DELETETEAMS_FAILURE: 'DELETETEAMS_FAILURE',

  CREATEATTACHMENT_REQUEST: 'CREATEATTACHMENT_REQUEST',
  CREATEATTACHMENT_SUCCESS: 'CREATEATTACHMENT_SUCCESS',
  CREATEATTACHMENT_FAILURE: 'CREATEATTACHMENT_FAILURE',

  GETSTATUS_REQUEST: 'GETSTATUS_TICKET_REQUEST',
  GETSTATUS_SUCCESS: 'GETSTATUS_TICKET_SUCCESS',
  GETSTATUS_FAILURE: 'GETSTATUS_TICKET_FAILURE',
};
