import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ordersActions } from '../../actions/orders.actions';
import { Button, Modal, Loader } from 'semantic-ui-react';

class ModalEmailOrder extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      openModalEmailOrder: false,
      html: '',
      orderId: '',
    };
  }

  async sendEmail(orderId) {
    this.setState({ loading: true });
    const { dispatch } = this.props;
    const params = {
      send: true,
    };
    await dispatch(ordersActions.sendOrderEmail(orderId, params)).then(() =>
      this.setState({ loading: false }),
    );
  }

  onClose() {
    this.setState({ openModalEmailOrder: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openModalEmailOrder &&
      (this.props.openModalEmailOrder !== prevProps.openModalEmailOrder ||
        this.props.id != prevProps.id) &&
      !this.state.openModalEmailOrder
    ) {
      this.setState({ openModalEmailOrder: true });
    }
  }

  render() {
    const { openModalEmailOrder, loading } = this.state;
    const { orderEmailText } = this.props;

    return (
      <>
        <Modal size="large" open={openModalEmailOrder} closeIcon onClose={() => this.onClose()}>
          <Modal.Header>{orderEmailText?.title || 'Email de Pedido'}</Modal.Header>
          <Modal.Content>
            <div
              style={{ padding: '4px' }}
              dangerouslySetInnerHTML={{ __html: orderEmailText?.html }}></div>
            <Button
              style={{ marginTop: 20, float: 'right', marginBottom: 20 }}
              loading={loading}
              primary
              onClick={() => this.sendEmail(orderEmailText.orderId)}>
              Enviar Email
            </Button>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { orders } = state;
  return {
    orderEmailText: orders?.orderEmailText,
    loading: orders.loading || false,
  };
}

const connectedModalEmailOrder = connect(mapStateToProps)(ModalEmailOrder);
export { connectedModalEmailOrder as ModalEmailOrder };
