import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Dropdown, Grid, Icon, Input, Loader } from 'semantic-ui-react';
import { opportunityActions } from '../../actions/opportunity.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { clientActions } from '../../actions/client.actions';
import { managersActions } from '../../actions/managers.actions';
import _ from 'lodash';

class OpportunityScreen extends Component {
  STORAGE_KEY = 'OpportunityScreenState';
  REFRESH_INTERVAL = 120 * 1000;
  intervalId = null;

  constructor(props) {
    super(props);

    this.state = {
      accordionOpen: false,
      refresh: false,
      filter: {
        title: '',
        client_id: null,
        code: '',
        search: null,
        current_status_id: null,
        manager_id: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  toggleInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.setState({ intervalEnabled: false });
    } else {
      this.intervalId = setInterval(() => this.getData(), this.REFRESH_INTERVAL);
      this.setState({ intervalEnabled: true });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);

    // Dispatch acessory entities load
    dispatch(clientActions.getAll());
    dispatch(clientActions.getManager());
    dispatch(opportunityActions.getStatus());
    dispatch(managersActions.getAll());
    this.props.dispatch(opportunityActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });

    // Load previous filter from storage, if any
    const state = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (state) {
      this.setState(state, () => this.getData());
    } else {
      this.getData();
    }
    const filter = state && state.filter ? state.filter : {};
    dispatch(
      opportunityActions.getAll({
        'opportunity.client_id': filter.client_id === '' ? null : filter.client_id,
      }),
    );

    this.toggleInterval();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  persistStateToStorage() {
    const { filter, accordionOpen } = this.state;
    const state = { filter, accordionOpen };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  getData() {
    const { dispatch } = this.props;

    const { filter } = this.state;
    const params = [];
    _.forEach(filter, (value, key) => {
      if (value && key == 'timestamp_start') {
        value = `${moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 00:00:00`;
      }

      if (value && key == 'timestamp_end') {
        value = `${moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 23:59:59`;
      }
      params.push(`"${typeof value}|opportunities.${key}|${value}"`);
    });
    const { page, sort } = this.state;
    params.page = page;
    params.sort = sort;
    dispatch(opportunityActions.getAll(`filter=[${params}]`)).then(() => {
      this.persistStateToStorage();
    });
  }

  handleAccordionClick = () => {
    const { accordionOpen } = this.state;
    this.setState({ accordionOpen: !accordionOpen }, () => this.persistStateToStorage());
  };

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  handleChange(e, data) {
    const { filter } = this.state;
    if (data) {
      filter[data.name] = data.value;
    } else {
      const { name, value } = e.target;
      filter[name] = value;
    }

    this.setState({ filter }, () => this.getData());
  }

  changeFilter(statusId) {
    const { filter } = this.state;
    filter.status_id = statusId;
    this.setState({ filter }, () => this.getData());
  }

  render() {
    const { opportunity, clients, loading, status, managers, dispatch } = this.props;
    const { refresh, filter, accordionOpen } = this.state;
    let clientOptions,
      statusOptions,
      managerOptions = [];
    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (managers && managers.data !== undefined) {
      managerOptions = managers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.user?.name };
      });
    }

    if (!opportunity.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Oportunidades</h1>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/opportunity/kanban')}>
              Boards
            </Button>
            <Button primary onClick={() => this.props.history.push('/opportunity/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Accordion styled fluid>
          <Accordion.Title active={accordionOpen} index={0} onClick={this.handleAccordionClick}>
            <Icon name="add square" />
            Mais filtros
          </Accordion.Title>
          <Accordion.Content active={accordionOpen}>
            <Grid>
              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Input
                  placeholder="Código"
                  fluid
                  search
                  selection
                  clearable
                  name="code"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.code}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Input
                  placeholder="Título"
                  fluid
                  search
                  selection
                  clearable
                  name="title"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.title}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Cliente"
                  fluid
                  search
                  selection
                  clearable
                  name="client_id"
                  options={clientOptions}
                  onChange={(event, data) => {
                    this.handleChange(true, data);
                    dispatch(
                      opportunityActions.getAll({
                        'opportunity.client_id': data.value === '' ? null : data.value,
                      }),
                    );
                  }}
                  value={filter.client_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Status"
                  fluid
                  search
                  selection
                  clearable
                  name="current_status_id"
                  options={statusOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.current_status_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Gerente"
                  fluid
                  search
                  selection
                  clearable
                  name="manager_id"
                  options={managerOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.manager_id}
                />
              </Grid.Column>
            </Grid>
          </Accordion.Content>
        </Accordion>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`opportunity/${item.id}/show`)}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={['Código', 'Título', 'Cliente', 'Gerente', 'Status', 'Criada em']}
            columns={[
              'code',
              'title',
              {
                name: 'client',
                format: (item) => item.client?.name,
              },
              {
                name: 'manager',
                format: (item) => item.manager?.user.name,
              },
              {
                name: 'status',
                format: (item) => item.status?.name,
              },
              {
                name: 'datetime',
                format: (item) => moment(item.created_at).format('DD/MM/YYYY'),
              },
            ]}
            data={opportunity.data}
            totalPages={opportunity.lastPage}
            page={opportunity.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity, managers, clients } = state;
  return {
    opportunity,
    clients,
    managers,
    status: opportunity.status,
    loading: opportunity.loading || false,
  };
}

const connectedOpportunityScreen = connect(mapStateToProps)(OpportunityScreen);
export { connectedOpportunityScreen as OpportunityScreen };
