import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { ordersActions } from '../../actions/orders.actions';
import { providersActions } from '../../actions/providers.actions';
import { Button, Form, Label, Modal } from 'semantic-ui-react';

class ModalCreateFileOrder extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      public: null,
      file_id: null,
      description: null,
      url: null,
      service_order_id: null,
      openCreateFiles: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onChangeUpload(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);
    formData.append('public', 1);
    formData.append('dir', 'orders/attachments');
    this.setState({ files: formData });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { orders, dispatch } = this.props;
    const { description, url, file_id } = this.state;
    if (description && url) {
      let payload = {
        description,
        url,
        service_order_id: orders.id,
        file_id,
      };

      if (this.state.files)
        await dispatch(opportunityActions.uploadFile(this.state.files)).then((res) => {
          const { file } = res;
          payload.url = file.url;
          payload.file_id = file.id;
        });

      await dispatch(ordersActions.createAttachment(orders.id, payload)).then(() => {
        this.setState({
          submitted: false,
          openCreateFiles: false,
        });
        dispatch(ordersActions.getAttachment(orders.id));
        this.onClose();
      });
    }
  };

  onClose() {
    const initialState = {
      public: null,
      file_id: null,
      description: null,
      url: null,
      service_order_id: null,
      openCreateFiles: false,
      submitted: false,
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openCreateFiles &&
      (this.props.openCreateFiles !== prevProps.openCreateFiles || this.props.id != prevProps.id) &&
      !this.state.openCreateFiles
    ) {
      this.setState({ openCreateFiles: true });
    }
  }

  render() {
    const { description, url, openCreateFiles, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Modal size="small" open={openCreateFiles} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Cadastro de Anexos</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Group>
              <Form.Field width={10}>
                <label>Descrição</label>
                <input
                  name="description"
                  placeholder="Descrição"
                  value={description}
                  onChange={this.handleChange}
                />
                {submitted && !description && (
                  <Label basic color="red" pointing>
                    Número é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={10}>
                <label>Anexo</label>
                <input
                  style={{ padding: '7px 0px', border: 'none' }}
                  name="url"
                  type="file"
                  placeholder="Anexo"
                  value={url}
                  onChange={(e) => this.onChangeUpload(e)}
                />
              </Form.Field>
            </Form.Group>
            <Button primary type="submit" disabled={!description && !url ? true : false}>
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { orders } = state;
  return {
    orders: orders.item,
    attachment: orders.attachment,
    loading: orders.loading || false,
  };
}

const connectedModalCreateFileOrder = connect(mapStateToProps)(ModalCreateFileOrder);
export { connectedModalCreateFileOrder as ModalCreateFileOrder };
