import client from '../helpers/HttpClient';
import BaseService from './base.service';

class ProjectService extends BaseService {
  get basePath() {
    return 'projects';
  }

  async updateStatus(projectId, payload) {
    try {
      const result = await client.patch(`/projects/${projectId}/status`, payload);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  async getStatusProjects() {
    try {
      const result = await client.get('projects/status');
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }

  async getLogsProjects(id) {
    try {
      const result = await client.get(`projects/${id}/status-logs`);
      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }
}

export default new ProjectService();
