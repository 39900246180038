import { transactionConstants } from '../constants/transaction.constants';

export function transaction(state = {}, action) {
  switch (action.type) {
    case transactionConstants.GETALLTRANSACTION_REQUEST:
    case transactionConstants.CREATETRANSACTION_REQUEST:
    case transactionConstants.CREATETRANSACTIONTYPE_REQUEST:
    case transactionConstants.UPDATETRANSACTIONTYPE_REQUEST:
    case transactionConstants.DELETETRANSACTIONTYPE_REQUEST:
    case transactionConstants.DELETETRANSACTION_REQUEST:
    case transactionConstants.GETTRANSACTIONTYPE_REQUEST:
    case transactionConstants.GETMETRANSACTION_REQUEST:
    case transactionConstants.GETTEAMTRANSACTION_REQUEST:
    case transactionConstants.GETTYPETRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case transactionConstants.CREATETRANSACTIONTYPE_SUCCESS:
    case transactionConstants.UPDATETRANSACTIONTYPE_SUCCESS:
    case transactionConstants.DELETETRANSACTIONTYPE_SUCCESS:
    case transactionConstants.DELETETRANSACTION_SUCCESS:
    case transactionConstants.GETTRANSACTIONTYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case transactionConstants.CREATETRANSACTION_SUCCESS:
    case transactionConstants.GETTRANSACTION_SUCCESS:
      return {
        ...state,
        item: action.transaction,
        loading: false,
      };
    case transactionConstants.GETALLTRANSACTION_SUCCESS:
    case transactionConstants.GETMETRANSACTION_SUCCESS:
    case transactionConstants.GETTEAMTRANSACTION_SUCCESS:
      return {
        ...state,
        ...action.transaction,
        loading: false,
      };
    case transactionConstants.GETTYPETRANSACTION_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case transactionConstants.GETCENTERTRANSACTION_SUCCESS:
      return {
        ...state,
        centers: action.centers,
        loading: false,
      };
    case transactionConstants.CREATETRANSACTION_FAILURE:
    case transactionConstants.CREATETRANSACTIONTYPE_FAILURE:
    case transactionConstants.GETALLTRANSACTION_FAILURE:
    case transactionConstants.GETTRANSACTIONTYPE_FAILURE:
    case transactionConstants.UPDATETRANSACTIONTYPE_FAILURE:
    case transactionConstants.DELETETRANSACTIONTYPE_FAILURE:
    case transactionConstants.DELETETRANSACTION_FAILURE:
    case transactionConstants.GETMETRANSACTION_FAILURE:
    case transactionConstants.GETTEAMTRANSACTION_FAILURE:
    case transactionConstants.GETTYPETRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
