import { opportunityConstants } from '../constants/opportunity.constants';
import opportunityService from '../services/opportunity.service';
import fileService from '../services/file.service';
import { alertActions } from './alert.actions';

export const opportunityActions = {
  create,
  deleteById,
  getAll,
  getStatus,
  getStatusBoard,
  getParticipants,
  getParticipantsUser,
  getParticipantsType,
  createParticipants,
  deleteParticipants,
  getById,
  update,
  updateStatusChange,
  createInteraction,
  getInteraction,
  getItens,
  updateItens,
  deleteItens,
  getByIdItens,
  createItens,
  getQuotes,
  deleteQuotes,
  getQuotesItens,
  createQuotes,
  createQuotesItens,
  updateQuotesItens,
  deleteQuotesItens,
  uploadAttachment,
  getByIdItensQuote,
  getMappings,
  createMappings,
  getStatusHistory,
  deleteMappings,
  updateStatus,
  getQuoteItemType,
  uploadFile,
};

function uploadAttachment(opportunity_id, id, data) {
  return async (dispatch) => {
    try {
      const attachment = await opportunityService.uploadAttachment(opportunity_id, id, data);
      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function success(attachment) {
    return {
      type: opportunityConstants.CREATEATTACHMENT_SUCCESS,
      attachment,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATEATTACHMENT_FAILURE,
      error,
    };
  }
}

function uploadFile(data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const file = await fileService.uploadFile(data);
      return dispatch(success(file[0]));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.UPLOAD_FILE_REQUEST,
    };
  }

  function success(file) {
    return {
      type: opportunityConstants.UPLOAD_FILE_SUCCESS,
      file,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.UPLOAD_FILE_FAILURE,
      error,
    };
  }
}

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const opportunity = await opportunityService.create(obj);

      dispatch(success(opportunity));
      dispatch(alertActions.success('Oportunidade Criada'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITY_REQUEST,
    };
  }

  function success(opportunity) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITY_SUCCESS,
      opportunity,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITY_FAILURE,
      error,
    };
  }
}

function createInteraction(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interaction = await opportunityService.createInteraction(id, obj);

      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYINTERACTION_REQUEST,
    };
  }

  function success(interaction) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYINTERACTION_SUCCESS,
      interaction,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYINTERACTION_FAILURE,
      error,
    };
  }
}

function createItens(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const itens = await opportunityService.createItens(id, obj);

      dispatch(success(itens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYITENS_REQUEST,
    };
  }

  function success(itens) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYITENS_SUCCESS,
      itens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYITENS_FAILURE,
      error,
    };
  }
}

function createMappings(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const mappings = await opportunityService.createMappings(id, obj);

      dispatch(success(mappings));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_REQUEST,
    };
  }

  function success(mappings) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_SUCCESS,
      mappings,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_FAILURE,
      error,
    };
  }
}

function createParticipants(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const participants = await opportunityService.createParticipants(id, obj);

      dispatch(success(participants));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_REQUEST,
    };
  }

  function success(participants) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_SUCCESS,
      participants,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_FAILURE,
      error,
    };
  }
}

function createQuotes(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const quotes = await opportunityService.createQuotes(id, obj);
      dispatch(success(quotes));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTES_REQUEST,
    };
  }

  function success(quotes) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTES_SUCCESS,
      quotes,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTES_FAILURE,
      error,
    };
  }
}

function createQuotesItens(opportunity_id, quote_id, obj) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItens = await opportunityService.createQuotesItens(opportunity_id, quote_id, obj);
      dispatch(success(quotesItens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_REQUEST,
    };
  }

  function success(quotesItens) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_SUCCESS,
      quotesItens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_FAILURE,
      error,
    };
  }
}

function updateQuotesItens(opportunity_id, quote_id, id, obj) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItens = await opportunityService.updateQuotesItens(
        opportunity_id,
        quote_id,
        id,
        obj,
      );
      dispatch(success(quotesItens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_REQUEST,
    };
  }

  function success(quotesItens) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_SUCCESS,
      quotesItens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_FAILURE,
      error,
    };
  }
}

function getByIdItensQuote(opportunity_id, quote_id, id, obj) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItens = await opportunityService.getByIdItensQuote(
        opportunity_id,
        quote_id,
        id,
        obj,
      );
      dispatch(success(quotesItens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYQUOTESITENS_REQUEST,
    };
  }

  function success(quotesItens) {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYQUOTESITENS_SUCCESS,
      quotesItens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYQUOTESITENS_FAILURE,
      error,
    };
  }
}

function deleteQuotesItens(opportunity_id, quote_id, id, obj) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItens = await opportunityService.deleteQuotesItens(
        opportunity_id,
        quote_id,
        id,
        obj,
      );
      dispatch(success(quotesItens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_REQUEST,
    };
  }

  function success(quotesItens) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_SUCCESS,
      quotesItens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_FAILURE,
      error,
    };
  }
}

function deleteParticipants(opportunity_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const participants = await opportunityService.deleteParticipants(opportunity_id, id);

      dispatch(success(participants));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(participants) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_REQUEST,
      participants,
    };
  }

  function success(participants) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_SUCCESS,
      participants,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_FAILURE,
      error,
    };
  }
}

function deleteMappings(opportunity_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const mappings = await opportunityService.deleteMappings(opportunity_id, id);

      dispatch(success(mappings));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(mappings) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_REQUEST,
      mappings,
    };
  }

  function success(mappings) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_SUCCESS,
      mappings,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_FAILURE,
      error,
    };
  }
}

function deleteItens(opportunity_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const itens = await opportunityService.deleteItens(opportunity_id, id);

      dispatch(success(itens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(itens) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_REQUEST,
      itens,
    };
  }

  function success(itens) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_SUCCESS,
      itens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const opportunity = await opportunityService.deleteById(id);

      dispatch(success(opportunity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(opportunity) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITY_REQUEST,
      opportunity,
    };
  }

  function success(opportunity) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITY_SUCCESS,
      opportunity,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITY_FAILURE,
      error,
    };
  }
}

function getStatus(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const status = await opportunityService.getStatus(filter);
      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUS_REQUEST,
    };
  }

  function success(status) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUS_SUCCESS,
      status,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUS_FAILURE,
      error,
    };
  }
}

function getStatusBoard() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const statusBoard = await opportunityService.getStatusBoard();
      dispatch(success(statusBoard));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_REQUEST,
    };
  }

  function success(statusBoard) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_SUCCESS,
      statusBoard,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_FAILURE,
      error,
    };
  }
}

function getStatusHistory(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const statusHistory = await opportunityService.getStatusHistory(id, params);
      dispatch(success(statusHistory));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSHISTORY_REQUEST,
    };
  }

  function success(statusHistory) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSHISTORY_SUCCESS,
      statusHistory,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYSTATUSHISTORY_FAILURE,
      error,
    };
  }
}

function getQuotes(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotes = await opportunityService.getQuotes(id, params);
      dispatch(success(quotes));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTES_REQUEST,
    };
  }

  function success(quotes) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTES_SUCCESS,
      quotes,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTES_FAILURE,
      error,
    };
  }
}

function deleteQuotes(opportunity_id, id) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotes = await opportunityService.deleteQuotes(opportunity_id, id);
      dispatch(success(quotes));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTES_REQUEST,
    };
  }

  function success(quotes) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTES_SUCCESS,
      quotes,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYQUOTES_FAILURE,
      error,
    };
  }
}

function getQuotesItens(opportunity_id, id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItens = await opportunityService.getQuotesItens(opportunity_id, id, params);
      dispatch(success(quotesItens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENS_REQUEST,
    };
  }

  function success(quotesItens) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENS_SUCCESS,
      quotesItens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENS_FAILURE,
      error,
    };
  }
}

function getParticipants(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const participants = await opportunityService.getParticipants(id, params);

      dispatch(success(participants));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANT_REQUEST,
    };
  }

  function success(participants) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANT_SUCCESS,
      participants,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANT_FAILURE,
      error,
    };
  }
}

function getParticipantsUser(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const users = await opportunityService.getParticipantsUser(filter);
      dispatch(success(users));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_REQUEST,
    };
  }

  function success(users) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_SUCCESS,
      users,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_FAILURE,
      error,
    };
  }
}

function getParticipantsType(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const types = await opportunityService.getParticipantsType(filter);
      dispatch(success(types));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_REQUEST,
    };
  }

  function success(types) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_SUCCESS,
      types,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_FAILURE,
      error,
    };
  }
}

function getQuoteItemType(filter) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const quotesItensType = await opportunityService.getQuoteItemType(filter);
      dispatch(success(quotesItensType));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_REQUEST,
    };
  }

  function success(quotesItensType) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_SUCCESS,
      quotesItensType,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_FAILURE,
      error,
    };
  }
}

function getInteraction(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const interaction = await opportunityService.getInteraction(id, params);
      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYINTERACTION_REQUEST,
    };
  }

  function success(interaction) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYINTERACTION_SUCCESS,
      interaction,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYINTERACTION_FAILURE,
      error,
    };
  }
}

function getItens(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const itens = await opportunityService.getItens(id, params);
      dispatch(success(itens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYITENS_REQUEST,
    };
  }

  function success(itens) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYITENS_SUCCESS,
      itens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYITENS_FAILURE,
      error,
    };
  }
}

function getMappings(id, params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const mappings = await opportunityService.getMappings(id, params);
      dispatch(success(mappings));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GET_OPPORTUNITYMAPPINGS_REQUEST,
    };
  }

  function success(mappings) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYMAPPINGS_SUCCESS,
      mappings,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITYMAPPINGS_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const opportunity = await opportunityService.getAll(filter);

      dispatch(success(opportunity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: opportunityConstants.GETALL_OPPORTUNITY_REQUEST,
    };
  }

  function success(opportunity) {
    return {
      type: opportunityConstants.GETALL_OPPORTUNITY_SUCCESS,
      opportunity,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GETALL_OPPORTUNITY_FAILURE,
      error,
    };
  }
}

function getByIdItens(opportunity_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const itens = await opportunityService.getByIdItens(opportunity_id, id);

      dispatch(success(itens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(itens) {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYITENS_REQUEST,
      opportunityItem: itens,
    };
  }

  function success(itens) {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYITENS_SUCCESS,
      opportunityItem: itens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GETID_OPPORTUNITYITENS_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const opportunity = await opportunityService.getById(id);

      dispatch(success(opportunity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(opportunity) {
    return {
      type: opportunityConstants.GET_OPPORTUNITY_REQUEST,
      opportunity,
    };
  }

  function success(opportunity) {
    return {
      type: opportunityConstants.GET_OPPORTUNITY_SUCCESS,
      opportunity,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.GET_OPPORTUNITY_FAILURE,
      error,
    };
  }
}

function updateStatusChange(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const statusChange = await opportunityService.updateStatusChange(id, obj);

      dispatch(success(statusChange));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_REQUEST,
    };
  }

  function success(statusChange) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_SUCCESS,
      statusChange,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_FAILURE,
      error,
    };
  }
}

function updateItens(opportunity_id, id, payload) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const itens = await opportunityService.updateItens(opportunity_id, id, payload);

      dispatch(success(itens));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(itens) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_REQUEST,
      opportunityItem: itens,
    };
  }

  function success(itens) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_SUCCESS,
      opportunityItem: itens,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.DELETE_OPPORTUNITYITENS_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const opportunity = await opportunityService.update(id, obj);

      dispatch(success(opportunity));
      dispatch(alertActions.success('Oportunidade Atualizada'));
      history.push('/opportunity');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITY_REQUEST,
    };
  }

  function success(opportunity) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITY_SUCCESS,
      opportunity,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITY_FAILURE,
      error,
    };
  }
}

function updateStatus(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const opportunity = await opportunityService.updateStatus(id, obj);

      dispatch(success(opportunity));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_REQUEST,
    };
  }

  function success(status) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_SUCCESS,
      status,
    };
  }

  function failure(error) {
    return {
      type: opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_FAILURE,
      error,
    };
  }
}
