import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Button, Dimmer, Dropdown, Grid, Loader } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import _ from 'lodash';
import { reportActions } from '../../actions/report.actions';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';
import { projectActions } from '../../actions/project.actions';
import { teamActions } from '../../actions/team.actions';
import { activityActions } from '../../actions/activity.actions';
import { ticketActions } from '../../actions/ticket.actions';

class ActivityConsolidatedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timestamp_start: moment().startOf('month').format('YYYY-MM-DD'),
      timestamp_finish: moment().endOf('month').format('YYYY-MM-DD'),
      page: 1,
      group: 'client',
      refresh: false,
      period: 'month',
    };
  }

  callActivities() {
    const { dispatch } = this.props;
    this.setState({ refresh: true });
    let stateFilter = _.pickBy(this.state);

    dispatch(reportActions.getActivitiesReport(stateFilter)).then(() =>
      this.setState({ refresh: false }),
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.callActivities();
    dispatch(userActions.getCompanyUsers());
    dispatch(clientActions.getAll());
    dispatch(projectActions.getAll());
    dispatch(teamActions.getAll());
    dispatch(activityActions.getTypes());
  }

  handleChange(data) {
    this.setState({ [data.name]: data.value }, () => {
      this.callActivities(this.state);
    });
  }

  getDataActivities() {
    const { reports } = this.props;

    let result = [];
    let total = 0;
    let hours = 0;

    _.map(reports.data, (item) => {
      total = _.sumBy(item.result, 'activities_count');
      hours = _.sumBy(item.result, 'time_spent');

      result.push({
        name: item.filter,
        total,
        hours,
      });
    });

    return result;
  }

  downloadFile() {
    const { dispatch } = this.props;
    dispatch(ticketActions.exportFile(this.filterState()));
  }

  render() {
    const { timestamp_start, timestamp_finish, group, refresh } = this.state;
    const { reports, users, clients, projects, activityType, teams } = this.props;
    let userOptions,
      clientOptions,
      projectOptions,
      activityTypeOptions,
      teamOptions = [];

    if (users.companyUsers && users.companyUsers.data !== undefined) {
      userOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (activityType && activityType.data !== undefined) {
      activityTypeOptions = activityType.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (!reports)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    const data = this.getDataActivities();

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h3>Atividades - Consolidado</h3>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            <h4>Agrupar Por: </h4>
            <Button.Group>
              <Button
                positive={group == 'client' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'client' })}>
                Cliente
              </Button>
              <Button
                positive={group == 'project' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'project' })}>
                Projeto
              </Button>
              <Button
                positive={group == 'type' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'type' })}>
                Tipo
              </Button>
              <Button
                positive={group == 'team' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'team' })}>
                Time
              </Button>
              <Button
                positive={group == 'user' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'user' })}>
                Usuário
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column floated="right" verticalAlign="middle" mobile={16} tablet={4} computer={4}>
            <DateInput
              closable
              clearable
              error={!timestamp_start ? true : false}
              dateFormat="DD/MM/YYYY"
              name="timestamp_start"
              style={{ marginBottom: '5px' }}
              placeholder="Data Início"
              onChange={(e, data) => {
                const value = moment(data.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
                if (data.value && value !== timestamp_start) {
                  let param = data;
                  param.value = value;
                  this.handleChange(param);
                } else {
                  this.setState({ timestamp_start: '' });
                }
              }}
              value={moment(timestamp_start).format('DD/MM/YYYY')}
              icon="date"
            />

            <DateInput
              closable
              clearable
              error={!timestamp_finish ? true : false}
              dateFormat="DD/MM/YYYY"
              name="timestamp_finish"
              placeholder="Data Fim"
              onChange={(e, data) => {
                const value = moment(data.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
                if (data.value && value !== timestamp_finish) {
                  let param = data;
                  param.value = value;
                  this.handleChange(param);
                } else {
                  this.setState({ timestamp_finish: '' });
                }
              }}
              value={moment(timestamp_finish).format('DD/MM/YYYY')}
              icon="date"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="projects.client_id"
                placeholder="Cliente"
                fluid
                clearable
                search
                selection
                options={clientOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="activities.project_id"
                placeholder="Projeto"
                fluid
                search
                selection
                clearable
                options={projectOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="activities.type_id"
                placeholder="Tipo Atividade"
                fluid
                search
                selection
                clearable
                options={activityTypeOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={3}
            computer={3}
            className={this.state.userMe ? 'hidden' : ''}>
            <div className="actions-filter">
              <Dropdown
                name="activities.user_id"
                placeholder="Usuário"
                fluid
                search
                selection
                clearable
                options={userOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <div className="actions-filter">
              <Dropdown
                name="team_projects.team_id"
                placeholder="Time"
                fluid
                search
                selection
                clearable
                options={teamOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
        </Grid>

        <div className="table-container">
          <DynamicTable
            header={['Nome', 'Atividades', 'Total Horas']}
            columns={[
              'name',
              'total',
              {
                name: 'hours',
                format: (item) =>
                  moment.duration(item.hours / 1000, 'second').format('HH:mm:ss', { trim: false }),
              },
            ]}
            data={data}
            totalPages={1}
            page={refresh}
            handleSort={() => {
              return;
            }}
            refresh={refresh}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { reports, users, activities, projects, teams, clients } = state;
  return {
    reports,
    clients,
    teams,
    users,
    me: users.me,
    projects,
    activityType: activities.types,
  };
}

const connectedActivityConsolidatedScreen = connect(mapStateToProps)(ActivityConsolidatedScreen);
export { connectedActivityConsolidatedScreen as ActivityConsolidatedScreen };
