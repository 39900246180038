import { productsModulesConstants } from '../constants/products-modules.constants';

export function productsModules(state = {}, action) {
  switch (action.type) {
    case productsModulesConstants.GETALLPRODUCTSMODULES_REQUEST:
    case productsModulesConstants.CREATEPRODUCTSMODULES_REQUEST:
    case productsModulesConstants.UPDATEPRODUCTSMODULES_REQUEST:
    case productsModulesConstants.DELETEPRODUCTSMODULES_REQUEST:
    case productsModulesConstants.GETPRODUCTSMODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsModulesConstants.CREATEPRODUCTSMODULES_SUCCESS:
    case productsModulesConstants.UPDATEPRODUCTSMODULES_SUCCESS:
    case productsModulesConstants.DELETEPRODUCTSMODULES_SUCCESS:
    case productsModulesConstants.GETPRODUCTSMODULES_SUCCESS:
      return {
        ...state,
        item: action.productsModules,
        loading: false,
      };
    case productsModulesConstants.GETALLPRODUCTSMODULES_SUCCESS:
      return {
        ...state,
        ...action.productsModules,
        loading: false,
      };
    case productsModulesConstants.CREATEPRODUCTSMODULES_FAILURE:
    case productsModulesConstants.UPDATEPRODUCTSMODULES_FAILURE:
    case productsModulesConstants.DELETEPRODUCTSMODULES_FAILURE:
    case productsModulesConstants.GETPRODUCTSMODULES_FAILURE:
    case productsModulesConstants.GETALLPRODUCTSMODULES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
