import { opportunityConstants } from '../constants/opportunity.constants';

export function opportunity(state = {}, action) {
  switch (action.type) {
    case opportunityConstants.GETALL_OPPORTUNITY_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYSTATUS_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_REQUEST:
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANT_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITY_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITY_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYINTERACTION_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYINTERACTION_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYITENS_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYITENS_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITYITENS_REQUEST:
    case opportunityConstants.UPDATE_OPPORTUNITYITENS_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYQUOTES_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITYQUOTES_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENS_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYQUOTES_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_REQUEST:
    case opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYMAPPINGS_REQUEST:
    case opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_REQUEST:
    case opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYSTATUSHISTORY_REQUEST:
    case opportunityConstants.UPDATE_OPPORTUNITY_REQUEST:
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_REQUEST:
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_REQUEST:
    case opportunityConstants.DELETEATTACHMENT_REQUEST:
    case opportunityConstants.CREATEATTACHMENT_REQUEST:
    case opportunityConstants.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case opportunityConstants.DELETE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_SUCCESS:
      return {
        ...state,
        type: action.participants,
        loading: false,
      };
    case opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_SUCCESS:
    case opportunityConstants.DELETEATTACHMENT_SUCCESS:
    case opportunityConstants.CREATEATTACHMENT_SUCCESS:
      return {
        ...state,
        type: action.mappings,
        loading: false,
      };
    case opportunityConstants.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        type: action.file,
        loading: false,
      };
    case opportunityConstants.DELETE_OPPORTUNITYITENS_SUCCESS:
      return {
        ...state,
        type: action.itens,
        loading: false,
      };
    case opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_SUCCESS:
      return {
        ...state,
        type: action.quotesItens,
        loading: false,
      };
    case opportunityConstants.DELETE_OPPORTUNITYQUOTES_SUCCESS:
      return {
        ...state,
        type: action.quotes,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITY_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITY_SUCCESS:
    case opportunityConstants.UPDATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        item: action.opportunity,
        loading: false,
      };
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_SUCCESS:
      return {
        ...state,
        statusState: action.statusState,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYINTERACTION_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYINTERACTION_SUCCESS:
      return {
        ...state,
        interaction: action.interaction,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_SUCCESS:
      return {
        ...state,
        statusBoard: action.statusBoard,
        loading: false,
      };
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_SUCCESS:
      return {
        ...state,
        statusChange: action.statusChange,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYITENS_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYITENS_SUCCESS:
      return {
        ...state,
        itens: action.itens,
        loading: false,
      };
    case opportunityConstants.GETID_OPPORTUNITYITENS_SUCCESS:
    case opportunityConstants.UPDATE_OPPORTUNITYITENS_SUCCESS:
      return {
        ...state,
        opportunityItem: action.opportunityItem,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: action.participants,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_SUCCESS:
      return {
        ...state,
        users: action.users,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYQUOTES_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYQUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.quotes,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENS_SUCCESS:
    case opportunityConstants.GETID_OPPORTUNITYQUOTESITENS_SUCCESS:
    case opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_SUCCESS:
      return {
        ...state,
        quotesItens: action.quotesItens,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_SUCCESS:
      return {
        ...state,
        quotesItensType: action.quotesItensType,
        loading: false,
      };
    case opportunityConstants.GETALL_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        ...action.opportunity,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_SUCCESS:
    case opportunityConstants.GET_OPPORTUNITYMAPPINGS_SUCCESS:
      return {
        ...state,
        mappings: action.mappings,
        loading: false,
      };
    case opportunityConstants.GET_OPPORTUNITYSTATUSHISTORY_SUCCESS:
      return {
        ...state,
        statusHistory: action.statusHistory,
        loading: false,
      };
    case opportunityConstants.CREATE_OPPORTUNITY_FAILURE:
    case opportunityConstants.GETALL_OPPORTUNITY_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITY_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYSTATUS_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYSTATUSBOARD_FAILURE:
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSCHANGE_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYPARTICIPANT_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITYPARTICIPANT_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANT_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTTYPE_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYPARTICIPANTUSER_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYINTERACTION_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYINTERACTION_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYITENS_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYITENS_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITYITENS_FAILURE:
    case opportunityConstants.UPDATE_OPPORTUNITYITENS_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYQUOTES_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITYQUOTES_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENS_FAILURE:
    case opportunityConstants.UPDATE_OPPORTUNITYQUOTESITENS_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITYQUOTESITENS_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYQUOTES_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYQUOTESITENS_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYMAPPINGS_FAILURE:
    case opportunityConstants.CREATE_OPPORTUNITYMAPPINGS_FAILURE:
    case opportunityConstants.DELETE_OPPORTUNITYMAPPINGS_FAILURE:
    case opportunityConstants.UPDATE_OPPORTUNITY_FAILURE:
    case opportunityConstants.UPDATE_OPPORTUNITYSTATUSSTATE_FAILURE:
    case opportunityConstants.GET_OPPORTUNITYQUOTESITENSTYPE_FAILURE:
    case opportunityConstants.DELETEATTACHMENT_FAILURE:
    case opportunityConstants.CREATEATTACHMENT_FAILURE:
    case opportunityConstants.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
