import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { ordersActions } from '../../actions/orders.actions';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';

class ModalParticipantsOrders extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      user_id: '',
      type_id: '',
      openParticipants: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(ordersActions.getParticipantsType());
    dispatch(opportunityActions.getParticipantsUser());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { orders, dispatch } = this.props;
    const { user_id, type_id } = this.state;
    if (user_id && type_id) {
      let payload = {
        user_id,
        type_id,
        service_order_id: orders.id,
      };

      dispatch(ordersActions.createParticipant(orders.id, payload)).then(() => {
        this.setState({
          submitted: false,
          openParticipants: false,
        });

        dispatch(ordersActions.getParticipants(orders.id));
        dispatch(ordersActions.getInteraction(orders.id));
        this.onClose();
      });
    }
  }

  onClose() {
    const initialState = {
      user_id: null,
      type_id: null,
      openParticipants: false,
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openParticipants &&
      (this.props.openParticipants !== prevProps.openParticipants ||
        this.props.id != prevProps.id) &&
      !this.state.openParticipants
    ) {
      this.setState({ openParticipants: true });
    }
  }

  render() {
    const { user_id, type_id, submitted, openParticipants } = this.state;
    const { loading, types, users } = this.props;
    let typesOptions,
      usersOptions = [];

    if (types && types !== undefined) {
      typesOptions = types?.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      usersOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="small" open={openParticipants} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Cadastro de um novo participante</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Field>
              <label>Usuário</label>
              <Dropdown
                placeholder="Usuário"
                fluid
                search
                selection
                name="user_id"
                options={usersOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={user_id}
              />
              {submitted && !user_id && (
                <Label basic color="red" pointing>
                  Usuário é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Tipo de Usuário</label>
              <Dropdown
                placeholder="Tipo"
                fluid
                search
                selection
                name="type_id"
                options={typesOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={type_id}
              />
              {submitted && !type_id && (
                <Label basic color="red" pointing>
                  Tipo é requerido
                </Label>
              )}
            </Form.Field>

            <Button
              className="secondary"
              type="submit"
              disabled={!user_id && type_id ? true : false}>
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { orders, opportunity } = state;
  return {
    orders: orders.item,
    participants: orders.participants,
    types: orders.types || null,
    users: opportunity.users || null,
    loading: orders.loading || false,
  };
}

const connectedModalParticipantsOrders = connect(mapStateToProps)(ModalParticipantsOrders);
export { connectedModalParticipantsOrders as ModalParticipantsOrders };
