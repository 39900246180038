import client from '../helpers/HttpClient';

class ordersService {
  async create(obj) {
    try {
      const result = await client.post('service/orders', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createParticipant(id, obj) {
    try {
      const result = await client.post(`service-orders/${id}/participants`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createAttachment(id, obj) {
    try {
      const result = await client.post(`service-order/${id}/attachments`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAttachment(id, params) {
    try {
      const result = await client.get(`service-order/${id}/attachments`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getParticipantsType() {
    try {
      const result = await client.get(`service-order/participant/types`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createItems(id, obj) {
    try {
      const result = await client.post(`service-order/${id}/items`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getByIdItems(order_id, id, params) {
    try {
      const result = await client.get(`service-order/${order_id}/items/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getItems(id, params) {
    try {
      const result = await client.get(`service-order/${id}/items`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createInteraction(id, obj) {
    try {
      const result = await client.post(`service-order/${id}/interactions`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`service/orders/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateStatus(id, obj) {
    try {
      const result = await client.patch(`service-order/${id}/status`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateParticipant(id, obj) {
    try {
      const result = await client.put(`service/orders/participant/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      const result = await client.get(`service/orders`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;
      return Promise.reject(returnMessage);
    }
  }

  async getParticipants(orderId, params) {
    try {
      const result = await client.get(`service-orders/${orderId}/participants`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getInteraction(id, params) {
    try {
      const result = await client.get(`service-order/${id}/interactions`, {
        params,
      });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatus() {
    try {
      const result = await client.get(`/service/order/status`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getUser(params) {
    try {
      const result = await client.get(`users?client=null`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getPayment(params) {
    try {
      const result = await client.get(`payment-methods`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getOrSendEmailText(orderId, params) {
    try {
      const result = await client.get(`service-orders/${orderId}/email-text`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;
      return Promise.reject(returnMessage);
    }
  }

  async timer(start_date, end_date) {
    try {
      const result = await client.get(`me/timer?start_date=${start_date}&end_date=${end_date}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`service/orders/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getParticipantById(id) {
    try {
      const result = await client.get(`service/orders/participant/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`service/orders/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteParticipantById(id, orderId) {
    try {
      const result = await client.delete(`service-orders/${orderId}/participants/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new ordersService();
