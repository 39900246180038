import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Input, Label } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { clientActions } from '../../actions/client.actions';
import { contractActions } from '../../actions/contract.actions';
import { productsActions } from '../../actions/products.actions';

class ContractEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: '',
      start_date: '',
      finish_date: '',
      type_id: '',
      product_id: '',
      hours_qty: 0,
      period: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(clientActions.getAll());
    dispatch(contractActions.getTypes());
    dispatch(productsActions.getAll());
    dispatch(contractActions.getById(match.params.id)).then(() => {
      const { contracts } = this.props;
      const contract = contracts.item;

      this.setState({
        client_id: contract.client_id,
        type_id: contract.type_id,
        product_id: contract.product_id,
        start_date: moment(contract.start_date).format('DD/MM/YYYY'),
        finish_date: moment(contract.finish_date).format('DD/MM/YYYY'),
        hours_qty: moment.duration(contract.hours_qty, 'hours').format('HH:mm'),
        period: contract.period,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { client_id, type_id, product_id, start_date, finish_date, hours_qty, period } =
      this.state;

    const { dispatch, match } = this.props;
    if (client_id && start_date && finish_date && hours_qty && period) {
      dispatch(
        contractActions.update(
          match.params.id,
          {
            client_id,
            type_id,
            product_id,
            start_date: moment(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            finish_date: moment(finish_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            hours_qty: this.convertTimeToFloat(hours_qty),
            period,
          },
          this.props.history,
        ),
      );
    }
  }

  convertTimeToFloat(time) {
    const parts = time.split(':');

    let hours = parseInt(parts[0].replace(/\D/g, ''), 10);
    let minutes = 0;
    if (parts.length > 0) {
      minutes = parseInt(parts[1], 10) / 60;
    }

    let result = hours + minutes;
    if (Number.isNaN(result)) result = 0;
    return result;
  }

  render() {
    const {
      client_id,
      type_id,
      product_id,
      start_date,
      finish_date,
      hours_qty,
      period,
      submitted,
    } = this.state;
    const { clients, loading, contractTypes, products } = this.props;
    let clientOptions = [],
      productOptions = [],
      typeOptions = [];
    const types = [
      {
        id: 'C',
        name: 'Contrato',
      },
      {
        id: 'A',
        name: 'Anual',
      },
      {
        id: 'M',
        name: 'Mensal',
      },
      {
        id: 'M',
        name: 'Mensal',
      },
      {
        id: 'T',
        name: 'Trimestral',
      },
      {
        id: 'S',
        name: 'Semestral',
      },
    ];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (products && products.data !== undefined) {
      productOptions = products.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    const periodOptions = types.map((item) => {
      return { key: item.id, value: item.id, text: item.name };
    });

    if (contractTypes && contractTypes.data !== undefined) {
      typeOptions = contractTypes.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Alterar Contrato</h1>
          </Grid.Column>
        </Grid>
        <div className="container-inner">
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Field>
              <label>Cliente</label>
              <Dropdown
                placeholder="Selecione"
                fluid
                search
                selection
                name="client_id"
                options={clientOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={client_id}
              />
            </Form.Field>
            <Form.Field>
              <label>Tipo</label>
              <Dropdown
                placeholder="Tipo"
                fluid
                search
                selection
                name="type_id"
                options={typeOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={type_id}
              />
              {submitted && !client_id && (
                <Label basic color="red" pointing>
                  Tipo é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Produto</label>
              <Dropdown
                placeholder="Produto"
                fluid
                search
                selection
                name="product_id"
                options={productOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={product_id}
              />
            </Form.Field>
            <Form.Field>
              <label>Data Início</label>
              <DateInput
                closable
                clearable
                name="start_date"
                dateFormat="DD/MM/YYYY"
                placeholder="Data Início"
                onChange={(event, data) => this.handleChange(true, data)}
                value={start_date}
                iconPosition="left"
              />
              {submitted && !start_date && (
                <Label basic color="red" pointing>
                  Data de Início é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Data Fim</label>
              <DateInput
                closable
                clearable
                name="finish_date"
                dateFormat="DD/MM/YYYY"
                placeholder="Data Fim"
                onChange={(event, data) => this.handleChange(true, data)}
                value={finish_date}
                iconPosition="left"
              />
              {submitted && !finish_date && (
                <Label basic color="red" pointing>
                  Data de Fim é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Quantidade Horas</label>
              <Input name="hours_qty" type="text" value={hours_qty} onChange={this.handleChange} />
              {submitted && !hours_qty && (
                <Label basic color="red" pointing>
                  Quantidade de Horas é requerido
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>Periodicidade</label>
              <Dropdown
                placeholder="Tipo"
                fluid
                search
                selection
                name="period"
                options={periodOptions}
                onChange={(event, data) => this.handleChange(true, data)}
                value={period}
              />
              {submitted && !period && (
                <Label basic color="red" pointing>
                  Periodicidade é requerido
                </Label>
              )}
            </Form.Field>
            <Button primary type="submit">
              Atualizar
            </Button>
          </Form>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { clients, contracts, products } = state;
  return {
    clients,
    contracts,
    products,
    contractTypes: contracts && contracts.contractTypes ? contracts.contractTypes : [],
    loading: contracts.loading || clients.loading || false,
  };
}

const connectedContractEditScreen = connect(mapStateToProps)(ContractEditScreen);
export { connectedContractEditScreen as ContractEditScreen };
