import { clientConstants } from '../constants/client.constants';
import clientService from '../services/client.service';
import { alertActions } from './alert.actions';

export const clientActions = {
  create,
  update,
  getAll,
  getById,
  getManager,
  deleteById,
  exportFile,
  synchronizeClientsFromSalesForce,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const client = await clientService.create(obj);
      dispatch(success(client));
      alertActions.success('Cliente Criado');
      if (history) history.push('/clients');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(client) {
    return {
      type: clientConstants.CREATECLIENT_REQUEST,
      client,
    };
  }

  function success(client) {
    return {
      type: clientConstants.CREATECLIENT_SUCCESS,
      client,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.CREATECLIENT_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const client = await clientService.update(id, obj);
      dispatch(success(client));
      dispatch(alertActions.success('Cliente Atualizado'));
      history.push('/clients');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: clientConstants.UPDATECLIENT_REQUEST,
    };
  }

  function success(client) {
    return {
      type: clientConstants.UPDATECLIENT_SUCCESS,
      client,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.UPDATECLIENT_FAILURE,
      error,
    };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const clients = await clientService.getAll(filter);
      dispatch(success(clients));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: clientConstants.GETALLCLIENT_REQUEST,
    };
  }

  function success(clients) {
    return {
      type: clientConstants.GETALLCLIENT_SUCCESS,
      clients,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.GETALLCLIENT_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const client = await clientService.getById(id);
      dispatch(success(client));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(client) {
    return {
      type: clientConstants.GETCLIENT_REQUEST,
      client,
    };
  }

  function success(client) {
    return {
      type: clientConstants.GETCLIENT_SUCCESS,
      client,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.GETCLIENT_FAILURE,
      error,
    };
  }
}

function getManager(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const manager = await clientService.getManager(filter);

      dispatch(success(manager));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return {
      type: clientConstants.GETMANAGER_REQUEST,
    };
  }

  function success(manager) {
    return {
      type: clientConstants.GETMANAGER_SUCCESS,
      manager,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.GETMANAGER_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const client = await clientService.deleteById(id);
      dispatch(success(client));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(client) {
    return {
      type: clientConstants.DELETECLIENT_REQUEST,
      client,
    };
  }

  function success(client) {
    return {
      type: clientConstants.DELETECLIENT_SUCCESS,
      client,
    };
  }

  function failure(error) {
    return {
      type: clientConstants.DELETECLIENT_FAILURE,
      error,
    };
  }
}

function exportFile(filter) {
  return async (dispatch) => {
    try {
      const clients = await clientService.getAll(filter);
      window.open(clients.url, '_blank');
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function synchronizeClientsFromSalesForce() {
  return async (dispatch) => {
    try {
      await clientService.synchronizeClientsFromSalesForce();
      dispatch(alertActions.success('Clients Sincronizados com Sucesso!'));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}
