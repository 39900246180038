import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Image, List, Loader } from 'semantic-ui-react';
import { teamActions } from '../../actions/team.actions';
import _ from 'lodash';

class TeamShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(teamActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(teamActions.deleteById(match.params.id)).then(() => this.props.history.push('/teams'));
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/teams/${match.params.id}/edit`);
  }

  listMembers(item) {
    if (!item.users) {
      return null;
    }

    const content = (
      <>
        <h4>Membros: </h4>
        <List>
          {_.map(item.users, (item) => (
            <List.Item>
              <Image src="imgs/square-image.png" />
              <List.Content>
                <List.Header as="a">{item.name}</List.Header>
                <List.Description>{item.email}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </>
    );

    return content;
  }

  render() {
    const { teams, loading } = this.props;
    const { open } = this.state;

    if (!teams || !teams.item) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{teams.item.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Nome: </h4>
          {teams.item.name ? teams.item.name : ''}
          <h4>Descrição: </h4>
          {teams.item.description}
          {this.listMembers(teams.item)}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { teams } = state;
  return {
    teams,
    loading: teams.loading || false,
  };
}

const connectedTeamShowScreen = connect(mapStateToProps)(TeamShowScreen);
export { connectedTeamShowScreen as TeamShowScreen };
