import client from '../helpers/HttpClient';

class contactService {
  async create(data) {
    try {
      const result = await client.post('contacts', data);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(filter) {
    try {
      let endpoint = 'contacts';

      if (filter) {
        endpoint = `${endpoint}?${filter}`;
      }

      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`contacts/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`contacts/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`contacts/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new contactService();
