import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Label } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';

class UserCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      useEmailAsUsername: true,
      username: '',
      password: '',
      role_id: null,
      client_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getRoles());
    dispatch(clientActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      if (typeof data.checked === 'boolean') data.value = data.checked;
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else if (e.target.name) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, email, useEmailAsUsername, username, password, role_id, client_id } = this.state;
    const { dispatch } = this.props;
    const user_name = useEmailAsUsername ? email : username;

    if ((name && user_name, password && role_id)) {
      let param = {
        name,
        email,
        username: user_name,
        password,
        role_id,
        client_id,
      };

      dispatch(userActions.create(param, this.props.history));
    }
  }

  render() {
    const { name, email, useEmailAsUsername, username, password, role_id, client_id, submitted } =
      this.state;
    const { roles, loading, clients } = this.props;
    let roleOptions,
      clientOptions = [];

    if (roles && roles.data !== undefined) {
      roleOptions = roles.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <div className="header-page">
          <h1>Novo Usuário</h1>
        </div>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome Completo</label>
            <input
              name="name"
              placeholder="Nome do Usuário"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input
              name="email"
              type="email"
              placeholder="Email do usuário"
              value={email}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="useEmailAsUsername"
              label="Utilizar e-mail como username"
              checked={useEmailAsUsername}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Username</label>
            <input
              name="username"
              type="text"
              placeholder="Nome de usuário"
              value={username || (useEmailAsUsername && email) || ''}
              onChange={this.handleChange}
              disabled={useEmailAsUsername}
            />
            {submitted && !username && !useEmailAsUsername && (
              <Label basic color="red" pointing>
                Username é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Senha</label>
            <input
              name="password"
              type="password"
              placeholder="Senha do usuário"
              value={password}
              onChange={this.handleChange}
            />
            {submitted && (!password || password.length < 6) && (
              <Label basic color="red" pointing>
                Senha é requerido e deve ter no mínimo 6 caracteres
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Permissão</label>
            <Dropdown
              placeholder="Perfil"
              fluid
              search
              selection
              name="role_id"
              options={roleOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={role_id}
            />
            {submitted && !role_id && (
              <Label basic color="red" pointing>
                Perfil é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              clearable
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={client_id}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { users, clients } = state;
  return {
    users,
    loading: users.loading || false,
    clients,
    roles: users.roles,
  };
}

const connectedUserCreateScreen = connect(mapStateToProps)(UserCreateScreen);
export { connectedUserCreateScreen as UserCreateScreen };
