import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { teamActions } from '../../actions/team.actions';
import { userActions } from '../../actions/user.actions';

class TeamCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      members_id: [],
      description: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getCompanyUsers());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, members_id, description } = this.state;

    const { dispatch } = this.props;
    if (name && description) {
      dispatch(
        teamActions.create({
          name,
          description,
          users: members_id,
        }),
      ).then(() => {
        this.props.history.push('/teams');
      });
    }
  }

  render() {
    const { users, loading } = this.props;
    const { name, description, submitted } = this.state;

    let teamOptions = [];

    if (users && users.companyUsers !== undefined) {
      teamOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Time</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input
              name="name"
              placeholder="Nome do Time"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <input
              name="description"
              placeholder="Descrição"
              value={description}
              onChange={this.handleChange}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Membros</label>
            <Dropdown
              name="members_id"
              onChange={(event, data) => this.handleChange(true, data)}
              placeholder="Membros"
              fluid
              multiple
              search
              selection
              options={teamOptions}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { teams, users } = state;
  return {
    teams,
    users,
    loading: users.loading || false,
  };
}

const connectedTeamCreateScreen = connect(mapStateToProps)(TeamCreateScreen);
export { connectedTeamCreateScreen as TeamCreateScreen };
