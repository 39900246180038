export const financesPayablesConstants = {
  GETALLFINANCESPAYABLES_REQUEST: 'GETALLFINANCESPAYABLES_REQUEST',
  GETALLFINANCESPAYABLES_SUCCESS: 'GETALLFINANCESPAYABLES_SUCCESS',
  GETALLFINANCESPAYABLES_FAILURE: 'GETALLFINANCESPAYABLES_FAILURE',

  GETMEFINANCESPAYABLES_REQUEST: 'GETMEFINANCESPAYABLES_REQUEST',
  GETMEFINANCESPAYABLES_SUCCESS: 'GETMEFINANCESPAYABLES_SUCCESS',
  GETMEFINANCESPAYABLES_FAILURE: 'GETMEFINANCESPAYABLES_FAILURE',

  GETFINANCESPAYABLES_REQUEST: 'GETFINANCESPAYABLES_REQUEST',
  GETFINANCESPAYABLES_SUCCESS: 'GETFINANCESPAYABLES_SUCCESS',
  GETFINANCESPAYABLES_FAILURE: 'GETFINANCESPAYABLES_FAILURE',

  UPDATEFINANCESPAYABLES_REQUEST: 'UPDATEFINANCESPAYABLES_REQUEST',
  UPDATEFINANCESPAYABLES_SUCCESS: 'UPDATEFINANCESPAYABLES_SUCCESS',
  UPDATEFINANCESPAYABLES_FAILURE: 'UPDATEFINANCESPAYABLES_FAILURE',

  CREATEFINANCESPAYABLES_REQUEST: 'CREATEFINANCESPAYABLES_REQUEST',
  CREATEFINANCESPAYABLES_SUCCESS: 'CREATEFINANCESPAYABLES_SUCCESS',
  CREATEFINANCESPAYABLES_FAILURE: 'CREATEFINANCESPAYABLES_FAILURE',

  CREATEFINANCESPAYABLESTYPE_REQUEST: 'CREATEFINANCESPAYABLESTYPE_REQUEST',
  CREATEFINANCESPAYABLESTYPE_SUCCESS: 'CREATEFINANCESPAYABLESTYPE_SUCCESS',
  CREATEFINANCESPAYABLESTYPE_FAILURE: 'CREATEFINANCESPAYABLES_FAILURE',

  GETTYPEFINANCESPAYABLES_REQUEST: 'GETTYPEFINANCESPAYABLES_REQUEST',
  GETTYPEFINANCESPAYABLES_SUCCESS: 'GETTYPEFINANCESPAYABLES_SUCCESS',
  GETTYPEFINANCESPAYABLES_FAILURE: 'GETTYPEFINANCESPAYABLES_FAILURE',

  GETFINANCESPAYABLESTYPE_REQUEST: 'GETFINANCESPAYABLESTYPE_REQUEST',
  GETFINANCESPAYABLESTYPE_SUCCESS: 'GETFINANCESPAYABLESTYPE_SUCCESS',
  GETFINANCESPAYABLESTYPE_FAILURE: 'GETFINANCESPAYABLESTYPE_FAILURE',

  UPDATEFINANCESPAYABLESTYPE_REQUEST: 'UPDATEFINANCESPAYABLESTYPE_REQUEST',
  UPDATEFINANCESPAYABLESTYPE_SUCCESS: 'UPDATEFINANCESPAYABLESTYPE_SUCCESS',
  UPDATEFINANCESPAYABLESTYPE_FAILURE: 'UPDATEFINANCESPAYABLESTYPE_FAILURE',

  DELETEFINANCESPAYABLESTYPE_REQUEST: 'DELETEFINANCESPAYABLESTYPE_REQUEST',
  DELETEFINANCESPAYABLESTYPE_SUCCESS: 'DELETEFINANCESPAYABLESTYPE_SUCCESS',
  DELETEFINANCESPAYABLESTYPE_FAILURE: 'DELETEFINANCESPAYABLESTYPE_FAILURE',

  DELETEFINANCESPAYABLES_REQUEST: 'DELETEFINANCESPAYABLES_REQUEST',
  DELETEFINANCESPAYABLES_SUCCESS: 'DELETEFINANCESPAYABLES_SUCCESS',
  DELETEFINANCESPAYABLES_FAILURE: 'DELETEFINANCESPAYABLES_FAILURE',
};
