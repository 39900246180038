// import { projectConstants } from '../constants/project.constants';

export function search(state = {}, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return { ...state };
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      return state;
  }
}
