import { reportConstants } from '../constants/report.constants';
import reportService from '../services/report.service';
import { alertActions } from './alert.actions';

export const reportActions = {
  getActivities,
  getActivitiesBank,
  getActivitiesReport,
  getActivitiesPerUserReport,
  exportFile,
  exportBank,
  exportFileExpense,
  getExpenses,
  getAll,
};

function getAll(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const reports = await reportService.getAll(obj);

      dispatch(success(reports));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETALLREPORT_REQUEST };
  }
  function success(reports) {
    return { type: reportConstants.GETALLREPORT_SUCCESS, reports };
  }
  function failure(error) {
    return { type: reportConstants.GETALLREPORT_FAILURE, error };
  }
}

function getActivities(params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activities = await reportService.getActivities(params);

      dispatch(success(activities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETALLACTIVITY_REQUEST };
  }
  function success(activities) {
    return { type: reportConstants.GETALLACTIVITY_SUCCESS, activities };
  }
  function failure(error) {
    return { type: reportConstants.GETALLACTIVITY_FAILURE, error };
  }
}

function getActivitiesBank(params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activities = await reportService.getActivitiesBank(params);

      dispatch(success(activities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETBANKACTIVITY_REQUEST };
  }
  function success(activities) {
    return { type: reportConstants.GETBANKACTIVITY_SUCCESS, activities };
  }
  function failure(error) {
    return { type: reportConstants.GETBANKACTIVITY_FAILURE, error };
  }
}

function getActivitiesPerUserReport(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const report = await reportService.getActivitiesPerUserReport(filter);
      dispatch(success(report));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETALLREPORT_REQUEST };
  }
  function success(report) {
    return { type: reportConstants.GETALLREPORT_SUCCESS, report };
  }
  function failure(error) {
    return { type: reportConstants.GETALLREPORT_FAILURE, error };
  }
}

function getActivitiesReport(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const activities = await reportService.getActivitiesReport(filter);

      dispatch(success(activities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETALLACTIVITY_REQUEST };
  }
  function success(activities) {
    return { type: reportConstants.GETALLACTIVITY_SUCCESS, activities };
  }
  function failure(error) {
    return { type: reportConstants.GETALLACTIVITY_FAILURE, error };
  }
}

function getExpenses(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const expenses = await reportService.getExpenses(filter);

      dispatch(success(expenses));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: reportConstants.GETALLEXPENSE_REQUEST };
  }
  function success(expenses) {
    return { type: reportConstants.GETALLEXPENSE_SUCCESS, expenses };
  }
  function failure(error) {
    return { type: reportConstants.GETALLEXPENSE_FAILURE, error };
  }
}

function exportFile(filter) {
  return async (dispatch) => {
    try {
      const params = filter;
      params.export = 'xlsx';
      const activities = await reportService.getActivities(params);
      window.open(activities.url, '_blank');
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function exportBank(filter) {
  return async (dispatch) => {
    try {
      const params = filter;
      params.export = 'xlsx';
      const activities = await reportService.getActivitiesBank(params);
      window.open(activities.url, '_blank');
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function exportFileExpense(filter) {
  return async (dispatch) => {
    try {
      filter.export = 'csv';

      const expenses = await reportService.getExpenses(filter);
      const data = new Blob([expenses], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(data);

      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'report.csv');
      tempLink.click();
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}
