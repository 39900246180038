import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, List } from 'semantic-ui-react';
import { ticketActions } from '../../actions/ticket.actions';

class Executors extends Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      ticketId: null,
      deleteExecutorId: null,
      openConfirm: false,
    };
  }

  deleteExecutors(ticket_id, id) {
    const { dispatch } = this.props;

    dispatch(ticketActions.deleteExecutors(ticket_id, id)).then(() => {
      dispatch(ticketActions.getById(ticket_id)).then(() => {
        dispatch(ticketActions.getAllExecutors(ticket_id));
        this.setState({
          ticketId: null,
          deleteExecutorId: null,
          openConfirm: false,
        });
      });
    });
  }

  render() {
    const { executors } = this.props;

    const { openConfirm } = this.state;

    return (
      <Fragment>
        <Confirm
          key={'executors'}
          content="Deseja remover este executor?"
          open={openConfirm}
          onCancel={() => this.setState({ openConfirm: false })}
          onConfirm={() => this.deleteExecutors(this.state.ticketId, this.state.deleteExecutorId)}
        />
        <input
          name="file"
          id="file"
          ref={this.fileInputRef}
          className="input hidden"
          type="file"
          onChange={(e) => this.onChangeUpload(e)}
        />
        {executors && executors.total === 0 && (
          <p textAlign="center" style={{ textStyle: 'italic' }}>
            Nenhum executor
          </p>
        )}
        {executors && executors.total > 0 && (
          <List divided verticalAlign="middle" style={{ maxHeight: '150px', overflow: 'auto' }}>
            {executors?.data?.map((executors) => (
              <List.Item key={executors.id}>
                <List.Content>{executors?.user?.name || '-'}</List.Content>
                <List.Content floated="right">
                  <Button
                    negative
                    size="small"
                    icon="close"
                    onClick={() =>
                      this.setState({
                        ticketId: executors.ticket_id,
                        deleteExecutorId: executors.id,
                        openConfirm: true,
                      })
                    }
                  />
                </List.Content>
                <List.Description>{executors?.user?.email}</List.Description>
              </List.Item>
            ))}
          </List>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    tickets,
  };
}

const connectedExecutors = connect(mapStateToProps)(Executors);
export { connectedExecutors as Executors };
