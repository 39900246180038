import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import { pdfActions } from '../../actions/pdf.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class PdfScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(pdfActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { pdf, loading } = this.props;
    const { refresh } = this.state;

    if (!pdf.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>PDF</h1>
          </Grid.Column>
        </Grid>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`pdf/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(pdfActions.getAll(`page=${page}`));
            }}
            header={['Nome', 'Última Alteração']}
            search={{
              title: 'Nome',
            }}
            columns={[
              'title',
              {
                name: 'updated_at',
                format: (item) => {
                  const date = moment(item.updated_at);
                  return date.isValid() ? date.format('DD/MM/YYYY HH:mm') : '-';
                },
              },
            ]}
            data={pdf.data}
            totalPages={pdf.lastPage}
            page={pdf.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { pdf } = state;

  return {
    pdf,
    loading: pdf.loading || false,
  };
}

const connectedPdfScreen = connect(mapStateToProps)(PdfScreen);
export { connectedPdfScreen as PdfScreen };
