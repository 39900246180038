import React, { Component } from 'react';
import { connect } from 'react-redux';
import { expenseActions } from '../../actions/expense.actions';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';
import CurrencyFormat from 'react-currency-format';
import { Button, Dropdown, Form, Input, Label, Modal, Table } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

class ModalExpense extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      type_id: null,
      center_id: null,
      description: '',
      date: '',
      refundable: true,
      amount: 0.0,
      kilometer: null,
      kilometer_fare: null,
      submitted: false,
      openExpense: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(expenseActions.getTypes());
    dispatch(expenseActions.getCenters());
  }

  handleChange(e, data) {
    if (data && data.name == 'refundable') {
      this.setState({ refundable: data.checked });
    } else if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { type_id, center_id, description, date, amount, kilometer, kilometer_fare, refundable } =
      this.state;
    const { dispatch, activityExpense, ticket_id } = this.props;
    if (type_id && description && date && (amount || (kilometer_fare && kilometer))) {
      let payload = {
        type_id,
        center_id,
        description,
        refundable,
        date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        amount:
          parseFloat(amount)
            .toFixed(2)
            .toString()
            .replace(/[^0-9|-]/g, '') / 100,
        kilometers: kilometer_fare ? kilometer : null,
      };

      if (ticket_id) {
        payload.ticket_id = parseInt(ticket_id);
      }

      if (activityExpense) {
        payload.activity_id = activityExpense.id;
        payload.project_id = activityExpense.project_id;
      }

      dispatch(expenseActions.create(payload)).then(() => {
        this.setState({
          type_id: null,
          project_id: null,
          center_id: null,
          description: '',
          date: '',
          amount: 0.0,
          kilometer: null,
          kilometer_fare: null,
          submitted: false,
        });

        if (!activityExpense) {
          dispatch(expenseActions.getAll(`expenses.ticket_id=${ticket_id}`));
        } else {
          dispatch(expenseActions.getAll(`expenses.activity_id=${activityExpense.id}`));
        }
      });
    }
  }

  onClose() {
    this.setState({ openExpense: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;

    if (
      this.props.activityExpense &&
      this.props.activityExpense.id &&
      this.props.activityExpense.id !== prevProps.activityExpense.id
    ) {
      dispatch(expenseActions.getAll(`expenses.activity_id=${this.props.activityExpense.id}`)).then(
        () => {
          this.setState({ openExpense: true });
        },
      );
    } else if (
      this.props.openExpense &&
      (this.props.openExpense !== prevProps.openExpense || this.props.id != prevProps.id) &&
      !this.state.openExpense
    ) {
      this.setState({ openExpense: true });
    }
  }

  rowContent() {
    const { expenses } = this.props;

    if (!expenses.data || !expenses.data.length) {
      return (
        <Table.Row key={0}>
          <Table.Cell colSpan="5" textAlign="center">
            Nenhum Resultado Encontrado
          </Table.Cell>
        </Table.Row>
      );
    }

    const rowContent = Array.from(expenses.data, (item, index) => (
      <Table.Row key={index} onClick={() => this.props.history.push(`expenses/${item.id}/show`)}>
        <Table.Cell className="item-link">{item.description}</Table.Cell>
        <Table.Cell className="item-link">{item.type}</Table.Cell>
        <Table.Cell className="item-link">{item.expense_center_abbrev}</Table.Cell>
        <Table.Cell className="item-link">
          {item.kilometer ? (
            item.kilometer
          ) : (
            <CurrencyFormat
              value={item.amount}
              displayType={'text'}
              decimalSeparator=","
              thousandSeparator="."
              prefix={'R$ '}
            />
          )}
        </Table.Cell>
        <Table.Cell className="item-link">{moment(item.date).format('DD/MM/YYYY')}</Table.Cell>
        <Table.Cell className="item-link">{item.refundable ? 'Sim' : 'Não'}</Table.Cell>
      </Table.Row>
    ));
    return rowContent;
  }

  render() {
    const {
      type_id,
      center_id,
      description,
      date,
      submitted,
      openExpense,
      amount,
      refundable,
      kilometer,
      kilometer_fare,
    } = this.state;
    const { types, centers } = this.props;
    let typeOptions,
      centerOptions = [];

    if (types && types.data !== undefined) {
      typeOptions = types.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (centers && centers.data !== undefined) {
      centerOptions = centers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.abbrev };
      });
    }

    return (
      <Modal size="small" open={openExpense} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Despesas</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Field width={4}>
                <label>Tipo</label>
                <Dropdown
                  placeholder="Tipo"
                  fluid
                  search
                  selection
                  name="type_id"
                  options={typeOptions || []}
                  onChange={(event, data) => {
                    const { dispatch } = this.props;
                    dispatch(expenseActions.getTypeById(data.value)).then(() => {
                      const { type } = this.props;

                      this.setState({
                        kilometer_fare: type.kilometer_fare,
                      });
                    });
                    this.handleChange(true, data);
                  }}
                  value={type_id}
                />
                {submitted && !type_id && (
                  <Label basic color="red" pointing>
                    Tipo é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Centro de Custo</label>
                <Dropdown
                  placeholder="Centro Custo"
                  fluid
                  search
                  selection
                  name="center_id"
                  options={centerOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={center_id}
                />
                {submitted && !center_id && (
                  <Label basic color="red" pointing>
                    Centro de custo é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Data</label>
                <DateInput
                  closable
                  clearable
                  name="date"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={date}
                  iconPosition="left"
                />
                {submitted && !date && (
                  <Label basic color="red" pointing>
                    Data é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                {!kilometer_fare && (
                  <>
                    <label>Valor (R$)</label>
                    <CurrencyInput
                      name="amount"
                      placeholder="Valor (R$)"
                      value={amount}
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      onChangeEvent={(event, maskedvalue, floatvalue) =>
                        this.handleChange(true, {
                          name: 'amount',
                          value: floatvalue,
                        })
                      }
                    />
                  </>
                )}
                {!kilometer_fare && submitted && !amount && (
                  <Label basic color="red" pointing>
                    Valor é requerido
                  </Label>
                )}

                {kilometer_fare && (
                  <>
                    <label>Distância (km)</label>
                    <Input
                      name="kilometer"
                      type="number"
                      onChange={this.handleChange}
                      placeholder="Distância (km)"
                    />
                  </>
                )}
                {kilometer_fare && submitted && !kilometer && (
                  <Label basic color="red" pointing>
                    Distância é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Descrição</label>
              <Input
                name="description"
                placeholder="Descrição"
                min="4"
                value={description}
                onChange={this.handleChange}
              />
              {(submitted && !description) ||
                (description && description.length <= 3 && (
                  <Label basic color="red" pointing>
                    Descrição é requerido (Mínimo 4 caracteres)
                  </Label>
                ))}
            </Form.Field>
            <Form.Field>
              <Form.Checkbox
                name="refundable"
                label="Reembolsável"
                checked={refundable}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Button
              className="secondary"
              type="submit"
              disabled={!description || !type_id || !date ? true : false}>
              Lançar
            </Button>
          </Form>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell singleLine>Descrição</Table.HeaderCell>
                <Table.HeaderCell singleLine>Tipo</Table.HeaderCell>
                <Table.HeaderCell singleLine>CC</Table.HeaderCell>
                <Table.HeaderCell singleLine>Valor</Table.HeaderCell>
                <Table.HeaderCell singleLine>Data</Table.HeaderCell>
                <Table.HeaderCell singleLine>Reemb.</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.rowContent()}</Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { expenses, projects } = state;
  return {
    types: expenses.types,
    centers: expenses.centers,
    type: expenses.type,
    expenses,
    projects,
  };
}

const connectedModalExpense = connect(mapStateToProps)(ModalExpense);
export { connectedModalExpense as ModalExpense };
