import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { expenseActions } from '../../actions/expense.actions';

class ExpenseTypeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(expenseActions.getTypes()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { types, loading } = this.props;
    const { refresh } = this.state;

    if (!types || !types.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Tipo de Despesas</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/expense-type/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <DynamicTable
          onClick={(item) => this.props.history.push(`/expense-type/${item.id}/show`)}
          handlePaginationChange={(page) => {
            const { dispatch } = this.props;

            dispatch(expenseActions.getTypes(`page=${page}`));
          }}
          refresh={refresh}
          header={['Nome', 'Status']}
          search={{
            name: 'Nome',
            active: 'Status',
          }}
          columns={[
            'name',
            {
              name: 'active',
              type: 'boolean',
            },
          ]}
          data={types.data}
          totalPages={types.lastPage}
          page={types.page}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { expenses } = state;
  return {
    types: expenses.types,
    loading: expenses.loading || false,
  };
}

const connectedExpenseTypeScreen = connect(mapStateToProps)(ExpenseTypeScreen);
export { connectedExpenseTypeScreen as ExpenseTypeScreen };
