export const transactionConstants = {
  GETALLTRANSACTION_REQUEST: 'GETALLTRANSACTION_REQUEST',
  GETALLTRANSACTION_SUCCESS: 'GETALLTRANSACTION_SUCCESS',
  GETALLTRANSACTION_FAILURE: 'GETALLTRANSACTION_FAILURE',

  GETTRANSACTION_REQUEST: 'GETTRANSACTION_REQUEST',
  GETTRANSACTION_SUCCESS: 'GETTRANSACTION_SUCCESS',
  GETTRANSACTION_FAILURE: 'GETTRANSACTION_FAILURE',

  UPDATETRANSACTION_REQUEST: 'UPDATETRANSACTION_REQUEST',
  UPDATETRANSACTION_SUCCESS: 'UPDATETRANSACTION_SUCCESS',
  UPDATETRANSACTION_FAILURE: 'UPDATETRANSACTION_FAILURE',

  CREATETRANSACTION_REQUEST: 'CREATETRANSACTION_REQUEST',
  CREATETRANSACTION_SUCCESS: 'CREATETRANSACTION_SUCCESS',
  CREATETRANSACTION_FAILURE: 'CREATETRANSACTION_FAILURE',

  CREATETRANSACTIONTYPE_REQUEST: 'CREATETRANSACTIONTYPE_REQUEST',
  CREATETRANSACTIONTYPE_SUCCESS: 'CREATETRANSACTIONTYPE_SUCCESS',
  CREATETRANSACTIONTYPE_FAILURE: 'CREATETRANSACTION_FAILURE',

  DELETETRANSACTION_REQUEST: 'DELETETRANSACTION_REQUEST',
  DELETETRANSACTION_SUCCESS: 'DELETETRANSACTION_SUCCESS',
  DELETETRANSACTION_FAILURE: 'DELETETRANSACTION_FAILURE',
};
