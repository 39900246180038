import { companyConstants } from '../constants/company.constants';

export function companies(state = {}, action) {
  switch (action.type) {
    case companyConstants.GETALLCOMPANY_REQUEST:
    case companyConstants.CREATECOMPANY_REQUEST:
    case companyConstants.GETONECOMPANY_REQUEST:
    case companyConstants.CREATEONECOMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case companyConstants.CREATECOMPANY_SUCCESS:
    case companyConstants.GETCOMPANY_SUCCESS:
      return {
        ...state,
        item: action.company,
      };
    case companyConstants.GETONECOMPANY_SUCCESS:
    case companyConstants.CREATEONECOMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
        loading: false,
      };
    case companyConstants.GETALLCOMPANY_SUCCESS:
      return {
        ...state,
        ...action.companies,
        loading: false,
      };
    case companyConstants.CREATECOMPANY_FAILURE:
    case companyConstants.GETALLCOMPANY_FAILURE:
    case companyConstants.GETONECOMPANY_FAILURE:
    case companyConstants.CREATEONECOMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
