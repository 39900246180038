import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { Button, Form, Label, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { InputCoins } from '../InputCoins/InputCoins';
import { DateInput } from 'semantic-ui-calendar-react';

class ModalItens extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      name: '',
      amount: 0,
      currency: 'BRL',
      date: null,
      file_url: null,
      preferential: 0,
      openItens: false,
      submitted: false,
    };
  }

  handleChange(e, data) {
    if (data) {
      const value = data.checked !== undefined ? data.checked : data.value;
      this.setState({ [data.name]: value === '' ? null : value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { opportunity, dispatch } = this.props;
    const { name, amount, currency, date, file_url, preferential, submitted } = this.state;

    let price = null;

    if (amount.toString().match(/(\.)/g)) {
      price = amount.toString().replace(/(\.|\,)/g, '');
    } else {
      price = amount * 100;
    }

    if (amount && name && date) {
      let payload = {
        name,
        opportunity_id: opportunity.id,
        currency,
        date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        amount: parseInt(price),
        file_url,
        preferential,
      };
      if (this.state.files) {
        await dispatch(opportunityActions.uploadFile(this.state.files)).then((res) => {
          const { file } = res;
          payload.file_url = file.url;
        });
      }
      await dispatch(opportunityActions.createItens(opportunity.id, payload)).then(() => {
        this.setState({
          openItens: false,
        });
        dispatch(opportunityActions.getItens(opportunity.id));
        dispatch(opportunityActions.getInteraction(opportunity.id));
        this.onClose();
      });
    }
  };

  onClose() {
    const initialState = {
      name: '',
      amount: 0,
      currency: 'BRL',
      date: null,
      file_url: null,
      preferential: 0,
      openItens: false,
      submitted: false,
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openItens &&
      (this.props.openItens !== prevProps.openItens || this.props.id != prevProps.id) &&
      !this.state.openItens
    ) {
      this.setState({ openItens: true });
    }
  }

  onChangeUpload(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);
    formData.append('public', 1);
    formData.append('dir', 'opportunity/items');
    this.setState({ files: formData });
  }

  render() {
    const { name, amount, currency, date, file_url, preferential, submitted, openItens } =
      this.state;
    const { loading } = this.props;

    return (
      <Modal size="small" open={openItens} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Cadastro de nova proposta</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Group>
              <Form.Field width={10}>
                <label>Descrição</label>
                <input
                  name="name"
                  placeholder="Descrição"
                  value={name}
                  onChange={this.handleChange}
                />
                {submitted && !name && (
                  <Label basic color="red" pointing>
                    Descrição é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={10}>
                <label>Valor Proposta</label>
                <InputCoins
                  name={'amount'}
                  amount={amount}
                  currency={currency}
                  onChange={this.handleChange}
                />
                {submitted && !amount && (
                  <Label basic color="red" pointing>
                    Preço é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginTop: '20px' }}>
              <Form.Field width={10}>
                <label>Data</label>
                <DateInput
                  closable
                  clearable
                  name="date"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={date}
                  iconPosition="left"
                />
                {submitted && !date && (
                  <Label basic color="red" pointing>
                    Data é requerida
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={10}>
                <label>Arquivo</label>
                <input
                  style={{ padding: '7px 0px', border: 'none' }}
                  name="file_url"
                  type="file"
                  placeholder="Arquivo"
                  value={file_url}
                  onChange={(e) => this.onChangeUpload(e)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginTop: '20px' }}>
              <Form.Field width={10}>
                <Form.Checkbox
                  toggle
                  name="preferential"
                  label="Preferencial"
                  checked={preferential}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Button primary type="submit" disabled={!amount && !name ? true : false}>
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity } = state;
  return {
    opportunity: opportunity.item,
    itens: opportunity.itens || null,
    loading: opportunity.loading || false,
  };
}

const connectedModalItens = connect(mapStateToProps)(ModalItens);
export { connectedModalItens as ModalItens };
