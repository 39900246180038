import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { projectActions } from '../../actions/project.actions';
import { activityActions } from '../../actions/activity.actions';

class ActivityTypeCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      status_id: '',
      active: 1,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(projectActions.getStatusProjects());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, active, status_id } = this.state;
    const { dispatch } = this.props;
    if (name && status_id && active) {
      dispatch(
        activityActions.createType(
          {
            name,
            active,
            status_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { name, active, status_id, submitted } = this.state;
    const { status, loading } = this.props;
    let statusOptions,
      activeOptions = [];

    if (status !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Tipo de Atividade</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome do Tipo</label>
            <input
              name="name"
              placeholder="Nome do Tipo"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Estado do Projeto</label>
            <Dropdown
              placeholder="Estado do Projeto"
              name="status_id"
              value={status_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={statusOptions}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Estado é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status do Projeto"
              name="active"
              value={active}
              fluid
              search
              selection
              defaultValue={1}
              onChange={(event, data) => this.handleChange(true, data)}
              options={activeOptions}
            />
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, activities } = state;
  return {
    projects,
    status: projects.status,
    loading: projects.loading || activities.loading || false,
  };
}

const connectedActivityTypeCreateScreen = connect(mapStateToProps)(ActivityTypeCreateScreen);
export { connectedActivityTypeCreateScreen as ActivityTypeCreateScreen };
