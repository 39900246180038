import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { DateInput } from 'semantic-ui-calendar-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { ticketActions } from '../../actions/ticket.actions';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import { teamActions } from '../../actions/team.actions';

import './styles.css';

class TicketScreen extends Component {
  STORAGE_KEY = 'TicketScreenState';
  REFRESH_INTERVAL = 120 * 1000;
  intervalId = null;

  constructor(props) {
    super(props);

    this.state = {
      accordionOpen: false,
      intervalEnabled: false,
      filter: {
        client_id: null,
        search: null,
        status_id: '-1',
        user_id: null,
        timestamp_start: null,
        timestamp_end: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  toggleInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.setState({ intervalEnabled: false });
    } else {
      this.intervalId = setInterval(() => this.getData(), this.REFRESH_INTERVAL);
      this.setState({ intervalEnabled: true });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);

    // Dispatch acessory entities load
    dispatch(clientActions.getAll());
    dispatch(userActions.getCompanyUsers());
    dispatch(ticketActions.getStatusTickets());
    dispatch(teamActions.getAll());

    // Load previous filter from storage, if any
    const state = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (state) {
      this.setState(state, () => this.getData());
    } else {
      this.getData();
    }
    const filter = state && state.filter ? state.filter : {};
    dispatch(
      userActions.getAll({
        client: filter.client_id === '' ? null : filter.client_id,
      }),
    );

    this.toggleInterval();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  persistStateToStorage() {
    const { filter, accordionOpen } = this.state;
    const state = { filter, accordionOpen };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  getData() {
    const { dispatch } = this.props;

    const { filter } = this.state;
    const params = {};
    _.forEach(filter, (value, key) => {
      if (value && key == 'timestamp_start') {
        value = `${moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 00:00:00`;
      }

      if (value && key == 'timestamp_end') {
        value = `${moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 23:59:59`;
      }

      params['tickets.' + key] = value === '' ? null : value;
    });

    const { page, sort } = this.state;
    params.page = page;
    params.sort = sort;

    dispatch(ticketActions.getAll(params)).then(() => {
      this.persistStateToStorage();
    });
  }

  handleAccordionClick = () => {
    const { accordionOpen } = this.state;
    this.setState({ accordionOpen: !accordionOpen }, () => this.persistStateToStorage());
  };

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  handleChange(e, data) {
    const { filter } = this.state;
    if (data) {
      filter[data.name] = data.value;
    } else {
      const { name, value } = e.target;
      filter[name] = value;
    }

    this.setState({ filter }, () => this.getData());
  }

  changeFilter(statusId) {
    const { filter } = this.state;
    filter.status_id = statusId;
    this.setState({ filter }, () => this.getData());
  }

  render() {
    const { tickets, clients, users, teams, status, loading, dispatch } = this.props;
    const { filter, accordionOpen } = this.state;
    let clientOptions,
      userOptions,
      companyUserOptions,
      statusOptions,
      teamOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.companyUsers && users.companyUsers.data !== undefined) {
      companyUserOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });

      statusOptions.push({
        key: Math.random(),
        value: '-1',
        text: 'Não Fechados',
      });
    }

    if (!tickets.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Row>
            <Grid.Column mobile={12} tablet={12} computer={12}>
              <h1>Tickets</h1>
            </Grid.Column>
            <Grid.Column mobile={4} tablet={4} computer={4} textAlign="right">
              <Checkbox
                toggle
                checked={this.intervalId != null}
                onClick={() => this.toggleInterval()}
                label="Atualizar"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Button.Group fluid>
                <Button positive={filter.status_id == '-1'} onClick={() => this.changeFilter(-1)}>
                  Não Fechados
                </Button>
                <Button.Or text="ou" />
                <Button
                  positive={filter.status_id === null}
                  onClick={() => this.changeFilter(null)}>
                  Todos
                </Button>
              </Button.Group>
            </Grid.Column>

            <Grid.Column textAlign="center" width={8}>
              <Input
                fluid
                icon="search"
                value={filter.search}
                onChange={(e, data) => this.handleSearch(data.value)}
                placeholder="Procurar..."
                loading={loading}
              />
            </Grid.Column>

            <Grid.Column textAlign="right" width={4}>
              <Button fluid primary onClick={() => this.props.history.push('/tickets/create')}>
                Novo Ticket
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Accordion styled fluid>
          <Accordion.Title active={accordionOpen} index={0} onClick={this.handleAccordionClick}>
            <Icon name="add square" />
            Mais filtros
          </Accordion.Title>
          <Accordion.Content active={accordionOpen}>
            <Grid>
              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Cliente"
                  fluid
                  search
                  selection
                  clearable
                  name="client_id"
                  options={clientOptions}
                  onChange={(event, data) => {
                    this.handleChange(true, data);
                    dispatch(
                      userActions.getAll({
                        client: data.value === '' ? null : data.value,
                      }),
                    );
                  }}
                  value={filter.client_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Solicitante"
                  fluid
                  search
                  selection
                  clearable
                  name="user_id"
                  options={userOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.user_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Status"
                  fluid
                  search
                  selection
                  clearable
                  name="status_id"
                  options={statusOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.status_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Time"
                  fluid
                  search
                  selection
                  clearable
                  name="team_id"
                  options={teamOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.team_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                  placeholder="Executor"
                  fluid
                  search
                  selection
                  clearable
                  name="assignee_id"
                  options={companyUserOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.assignee_id}
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <DateInput
                  closable
                  clearable
                  dateFormat="DD/MM/YYYY"
                  name="timestamp_start"
                  placeholder="Data Início"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.timestamp_start}
                  iconPosition="left"
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={3} computer={3}>
                <DateInput
                  closable
                  clearable
                  name="timestamp_end"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data Fim"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={filter.timestamp_end}
                  iconPosition="left"
                />
              </Grid.Column>
            </Grid>
          </Accordion.Content>
        </Accordion>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`tickets/${item.id}/show`)}
            handleSort={(column) => {
              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}:desc`, sorted: 'desc', page: 1 }, () => {
                  this.getData();
                });
              } else {
                this.setState({ sort: `${column}:asc`, sorted: 'asc', page: 1 }, () => {
                  this.getData();
                });
              }
            }}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={[
              '#',
              'Titulo',
              'Solicitante',
              'Cliente',
              'Times',
              'Executores',
              'Última Alteração',
              'Status',
            ]}
            columns={[
              'id',
              'title',
              'user',
              'client',
              'teams',
              'executors',
              {
                name: 'updated_at',
                format: (item) => moment(item.updated_at).format('DD/MM/YYYY'),
              },
              'status',
            ]}
            data={tickets.data}
            totalPages={tickets.lastPage}
            page={tickets.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets, clients, users, teams } = state;
  return {
    clients,
    users,
    teams,
    status: tickets.status,
    tickets,
    loading: tickets.loading || false,
  };
}

const connectedTicketScreen = connect(mapStateToProps)(TicketScreen);
export { connectedTicketScreen as TicketScreen };
