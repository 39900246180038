import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import { contactActions } from '../../actions/contact.actions';

class ContactCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { display_name, email, phone_main, notes } = this.state;
    const { dispatch } = this.props;
    const data = {
      display_name,
      email,
      phone_main,
      notes,
    };
    dispatch(contactActions.create(data));
    this.props.history.push('/contacts');
  }

  render() {
    const { display_name, email, phone_main, notes, submitted } = this.state;
    const { loading } = this.props;
    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Contato</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input
              name="display_name"
              placeholder="Nome"
              value={display_name}
              onChange={this.handleChange}
            />
            {submitted && !display_name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>E-mail</label>
            <input name="email" placeholder="E-mail" value={email} onChange={this.handleChange} />
            {submitted && !email && (
              <Label basic color="red" pointing>
                E-mail é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Telefone</label>
            <input
              name="phone_main"
              placeholder="Telefone"
              value={phone_main}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Notas</label>
            <input name="notes" placeholder="Notas" value={notes} onChange={this.handleChange} />
          </Form.Field>
          <Button primary type="submit">
            Salvar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { contact } = state;
  return {
    contact,
    loading: contact.loading || false,
  };
}

const connectedContactCreateScreen = connect(mapStateToProps)(ContactCreateScreen);
export { connectedContactCreateScreen as ContactCreateScreen };
