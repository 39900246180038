import { projectConstants } from '../constants/project.constants';

export function projects(state = {}, action) {
  switch (action.type) {
    case projectConstants.CREATEPROJECT_REQUEST:
    case projectConstants.GETALLPROJECT_REQUEST:
    case projectConstants.GETPROJECT_REQUEST:
    case projectConstants.GETSTATUS_REQUEST:
    case projectConstants.GETLOGS_REQUEST:
    case projectConstants.UPDATEPROJECT_REQUEST:
    case projectConstants.DELETEPROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectConstants.GETSTATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.status,
      };
    case projectConstants.GETLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: action.logs,
      };
    case projectConstants.CREATEPROJECT_SUCCESS:
    case projectConstants.GETPROJECT_SUCCESS:
    case projectConstants.DELETEPROJECT_SUCCESS:
    case projectConstants.UPDATEPROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.project,
      };
    case projectConstants.GETALLPROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.projects,
      };
    case projectConstants.CREATEPROJECT_FAILURE:
    case projectConstants.GETALLPROJECT_FAILURE:
    case projectConstants.GETSTATUS_FAILURE:
    case projectConstants.GETLOGS_FAILURE:
    case projectConstants.GETPROJECT_FAILURE:
    case projectConstants.DELETEPROJECT_FAILURE:
    case projectConstants.UPDATEPROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
