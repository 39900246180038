import moment from 'moment';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Confirm,
  CardContent,
  Dimmer,
  Grid,
  GridColumn,
  Icon,
  GridRow,
  Label,
  List,
  Loader,
} from 'semantic-ui-react';
import { salesActions } from '../../actions/sales.actions';
import { ModalItensSales } from '../../components/Modals/ModalItensSales';
import { ModalEditItensSales } from '../../components/Modals/ModalEditItensSales';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { ModalOrdersSales } from '../../components/Modals/ModalOrdersSales';
import './styles.css';
import { currencyLabelHelper } from '../../helpers/currency-label';

moment.locale('pt-br');

class SalesShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openOrders: false,
      open: false,
      openItem: false,
      openItemsEdit: false,
      openItemsId: false,
      itemsId: null,
      deleteItemsId: null,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(salesActions.getById(match.params.id));
    dispatch(salesActions.getOrders(match.params.id));
    dispatch(salesActions.getItems(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(salesActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/sales'),
    );
  }

  deleteItems(id) {
    const { match, dispatch } = this.props;

    dispatch(salesActions.deleteItems(match.params.id, id)).then(() => {
      dispatch(salesActions.getItems(match.params.id));
      this.setState({ deleteItemsId: null, openItemsId: false });
    });
  }

  editById() {
    const { match } = this.props;
    this.props.history.push(`/sales/${match.params.id}/edit`);
  }

  render() {
    const { sales, loading, ordersSale, items } = this.props;
    const { open, openItem, openItemsId, openItemsEdit, itemsId, openOrders } = this.state;

    if (!sales)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <div id="page-sales-show">
        <Fragment>
          <Confirm
            key={'sales'}
            content="Deseja remover esta venda?"
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.deleteById()}
          />
          <Confirm
            key={'items'}
            content="Deseja remover este item?"
            open={openItemsId}
            onCancel={() => this.setState({ openItemsId: false })}
            onConfirm={() => this.deleteItems(this.state.deleteItemsId)}
          />

          <Grid className="header-page">
            <GridColumn className="header-column-left">
              <h1>Vendas</h1>
              <Label className="sales-number">{sales?.id || '-'}</Label>
            </GridColumn>
            <Grid.Column className="header-column-right">
              <Button icon="edit outline" onClick={() => this.editById()} />
              <Button icon="trash alternate outline" onClick={this.deleteShow} />
            </Grid.Column>
          </Grid>
          <Loader active={loading} />
          <ModalItensSales
            openItens={openItem}
            onClose={() => this.setState({ openItem: false })}
          />
          <ModalEditItensSales
            itemsId={itemsId}
            openItemsEdit={openItemsEdit}
            onClose={() => this.setState({ openItemsEdit: false })}
          />
          <ModalOrdersSales
            openOrders={openOrders}
            onClose={() => this.setState({ openOrders: false })}
          />

          {!loading && (
            <>
              <div className="container-inner">
                <Grid>
                  <GridRow columns={1}>
                    <GridColumn>
                      <Card fluid>
                        <Card.Content>
                          <Grid columns={2}>
                            <GridRow>
                              <GridColumn>
                                <GridRow>
                                  <b>Título:</b>
                                  <span>{sales.title || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Cliente:</b>
                                  <span>{sales.client?.name || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>ID Sales Force:</b>
                                  <span>{sales.sales_force_opportunity_number || '-'}</span>
                                </GridRow>
                                <GridRow>
                                  <b>Status:</b>
                                  <span>{sales.status?.name || '-'}</span>
                                </GridRow>
                              </GridColumn>

                              <GridColumn>
                                <GridRow>
                                  <GridColumn floated="right" style={{ textAlign: 'center' }}>
                                    <b>Data</b>
                                    <br />
                                    <span>
                                      {moment(sales.datetime).format('DD/MM/YYYY') || '-'}
                                    </span>
                                  </GridColumn>
                                </GridRow>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>
                  </GridRow>

                  <GridRow>
                    <GridColumn width={10}>
                      <Card fluid>
                        <Card.Content>
                          <Grid>
                            <GridColumn>
                              <GridRow columns={2}>
                                <GridColumn>
                                  <b>CNPJ:</b>
                                  <span>{sales.client?.cnpj || '-'}</span>
                                </GridColumn>
                              </GridRow>
                              <GridRow>
                                <b>Endereço:</b>
                                {sales?.client?.address && (
                                  <span>
                                    {sales.client?.address
                                      ? `${
                                          sales.client?.address.street
                                            ? sales.client?.address.street + ', '
                                            : ' '
                                        } ${
                                          sales.client?.address.number
                                            ? sales.client?.address.number + ', '
                                            : ' '
                                        } ${
                                          sales.client?.address.complement
                                            ? sales.client?.address.complement + ' - '
                                            : ' '
                                        }  ${
                                          sales.client?.address.city
                                            ? sales.client?.address.city + ' - '
                                            : ' '
                                        } ${sales.client?.address.state}`
                                      : ''}
                                  </span>
                                )}
                              </GridRow>
                            </GridColumn>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </GridColumn>

                    <GridColumn width={6}>
                      <Card fluid className="card-payment">
                        <CardContent>
                          <Grid>
                            <GridRow columns={2}>
                              <GridColumn>
                                <p className="card-payment-label">Vendedor</p>
                                <p className="card-payment-value">{sales.author?.name || '-'}</p>
                              </GridColumn>
                              <GridColumn>
                                <p className="card-payment-label">Última Alteração</p>
                                <p className="card-payment-value">
                                  {moment(sales.updated_at).format('DD/MM/YYYY') || '-'}
                                </p>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </CardContent>
                      </Card>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </div>

              <div className="all-content">
                <div className="container-wrapper container-left">
                  <Button
                    icon="plus square"
                    title="Cadastro do Pedido"
                    onClick={() => this.setState({ openOrders: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />
                  <div className="container-title">
                    <h1>Pedidos</h1>
                  </div>

                  <div className="list-content">
                    <DynamicTable
                      onClick={(data) => this.props.history.push(`/orders/${data.id}/show`)}
                      header={['#', 'Tipo', 'Código', 'Criado em']}
                      columns={[
                        {
                          name: 'id',
                          format: (data) => <>{data.id || '-'}</>,
                        },
                        {
                          name: 'type',
                          format: (data) => <>{data?.type === 'I' ? 'Interno' : 'Externo'}</>,
                        },
                        {
                          name: 'name',
                          format: (data) => <>{data.name || '-'}</>,
                        },
                        {
                          name: 'createdAt',
                          format: (data) => (
                            <>
                              <div style={{ fontSize: '13px' }}>
                                {data.created_at == null
                                  ? '-'
                                  : moment(data.created_at).format('DD/MM/YY')}
                              </div>
                            </>
                          ),
                        },
                      ]}
                      data={ordersSale || []}
                      totalPages={1}
                      handlePaginationChange={() => {}}
                      style="basic"
                      celled={false}
                      sortable={false}
                      showPagination={false}
                    />
                  </div>
                </div>

                <div className="container-wrapper">
                  <Button
                    icon="plus square"
                    title="Cadastro de item"
                    onClick={() => this.setState({ openItem: true })}
                    floated="right"
                    style={{ marginBottom: '-18px' }}
                  />
                  <div className="container-title">
                    <h1>Itens</h1>
                  </div>

                  <List divided verticalAlign="middle">
                    <div className="list-content">
                      {items?.data?.map((item) => (
                        <List.Item className="list-item" key={item.id}>
                          <div className="list-item-icon">
                            <span>
                              <Icon
                                name="box"
                                className="list-item-icon-icon"
                                style={
                                  item.service_order_item_id
                                    ? { color: '#84e0be' }
                                    : { color: 'none' }
                                }
                              />
                            </span>
                          </div>

                          <List.Content className="list-content">
                            {item && item.type === 'S' ? (
                              <a class="ui blue label detail">Serviço</a>
                            ) : (
                              <a class="ui teal label detail">Produto</a>
                            )}
                            <List.Header as="h1">{item.name || '-'}</List.Header>

                            <List.Description>
                              <div className="description-row" style={{ marginLeft: 5 }}>
                                <span>Part Number: {item.part_number || '-'}</span>
                              </div>

                              <div className="description-row">
                                <p>
                                  Custo.Unit.:
                                  <CurrencyFormat
                                    displayType={'text'}
                                    value={item.unit_cost_price / 100 || '-'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={currencyLabelHelper(item.currency)}
                                  />
                                </p>
                              </div>

                              <div className="description-row">
                                <p>
                                  Custo.Total:
                                  <CurrencyFormat
                                    displayType={'text'}
                                    value={item.amount_cost_price / 100 || '-'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={currencyLabelHelper(item.currency)}
                                  />
                                </p>
                              </div>

                              <div className="description-middle">
                                <p>Qtd. {item.quantity || '-'}</p>
                                <p className="description-middle-right">
                                  Venda.Unit.:
                                  <CurrencyFormat
                                    displayType={'text'}
                                    value={item.unit_price / 100 || '-'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={currencyLabelHelper(item.currency)}
                                  />
                                </p>
                              </div>
                            </List.Description>
                          </List.Content>

                          <div className="list-item-right">
                            <h1>
                              <CurrencyFormat
                                displayType={'text'}
                                value={item.amount / 100 || '-'}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={currencyLabelHelper(item.currency)}
                              />
                            </h1>
                          </div>
                          <div className="button-itens">
                            <Button
                              style={{ marginBottom: 5 }}
                              icon="edit outline"
                              floated="right"
                              onClick={() =>
                                this.setState({ itemsId: item.id, openItemsEdit: true })
                              }
                            />
                            <Button
                              icon="trash alternate outline"
                              floated="right"
                              onClick={() =>
                                this.setState({ deleteItemsId: item.id, openItemsId: true })
                              }
                            />
                          </div>
                        </List.Item>
                      ))}
                    </div>
                  </List>
                </div>
              </div>

              <div className="all-content" style={{ marginBottom: 10 }}>
                <div className="container-wrapper">
                  <div className="container-title">
                    <h1>Observações</h1>
                  </div>

                  <div>
                    <p style={{ whiteSpace: 'pre-line' }}>{sales.description || '-'}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sales } = state;
  const ordersSale = sales.ordersSale;

  return {
    sales: sales.item,
    items: sales.items || null,
    ordersSale: ordersSale?.data,
    loading: sales.loading || false,
  };
}

const connectedSalesShowScreen = connect(mapStateToProps)(SalesShowScreen);
export { connectedSalesShowScreen as SalesShowScreen };
