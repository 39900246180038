import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

let middleware;

if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger();
  middleware = applyMiddleware(thunkMiddleware, loggerMiddleware);
} else {
  middleware = applyMiddleware(thunkMiddleware);
}

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
