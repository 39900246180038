import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import CurrencyFormat from 'react-currency-format';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { reportActions } from '../../actions/report.actions';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';
import { projectActions } from '../../actions/project.actions';
import { teamActions } from '../../actions/team.actions';
import { activityActions } from '../../actions/activity.actions';
import { expenseActions } from '../../actions/expense.actions';
import './styles.css';
import _ from 'lodash';

class ExpenseConsolidatedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timestamp_start: `${moment().startOf('month').format('YYYY-MM-DD')} 00:00:00`,
      timestamp_finish: `${moment().endOf('month').format('YYYY-MM-DD')} 23:59:59`,
      page: 1,
      group: 'client',
      refresh: false,
      period: 'month',
    };
  }

  callExpenses() {
    const { dispatch } = this.props;
    this.setState({ refresh: true });
    dispatch(reportActions.getExpenses(this.state)).then(() => this.setState({ refresh: false }));
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.callExpenses();
    dispatch(userActions.getCompanyUsers());
    dispatch(clientActions.getAll());
    dispatch(projectActions.getAll());
    dispatch(teamActions.getAll());
    dispatch(activityActions.getTypes());
    dispatch(expenseActions.getTypes());
  }

  handleChange(data) {
    if (data.value != '') {
      this.setState({ [data.name]: data.value }, () => {
        this.callExpenses(this.state);
      });
    } else {
      let stateParams = this.state;
      if (stateParams[data.name]) delete stateParams[data.name];
      this.setState(stateParams, () => {
        this.callExpenses(stateParams);
      });
    }
  }

  getDataExpense() {
    const { reports } = this.props;
    let result = [];
    let total = 0;
    let amount = 0;

    _.map(reports.expenses?.data, (item) => {
      total = _.sumBy(item.result, 'expenses_count');
      amount = _.sumBy(item.result, 'total_amount');

      result.push({
        name: item.filter,
        total,
        amount,
      });
    });

    return result;
  }

  render() {
    const { timestamp_start, timestamp_finish, group, refresh } = this.state;
    const { users, clients, projects, activityType, teams } = this.props;
    let userOptions,
      clientOptions,
      projectOptions,
      activityTypeOptions,
      teamOptions = [];

    if (users.companyUsers && users.companyUsers.data !== undefined) {
      userOptions = users.companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (activityType && activityType.data !== undefined) {
      activityTypeOptions = activityType.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (projects && projects.data !== undefined) {
      projectOptions = projects.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    const data = this.getDataExpense();
    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h3>Despesas - Consolidado</h3>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            <h4>Agrupar Por: </h4>
            <Button.Group>
              <Button
                positive={group == 'client' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'client' })}>
                Cliente
              </Button>
              <Button
                positive={group == 'project' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'project' })}>
                Projeto
              </Button>
              <Button
                positive={group == 'type' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'type' })}>
                Tipo
              </Button>
              <Button
                positive={group == 'team' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'team' })}>
                Time
              </Button>
              <Button
                positive={group == 'user' ? true : false}
                onClick={() => this.handleChange({ name: 'group', value: 'user' })}>
                Usuário
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column floated="right" verticalAlign="middle" mobile={16} tablet={4} computer={4}>
            <DateInput
              error={!timestamp_start ? true : false}
              dateFormat="DD/MM/YYYY"
              name="timestamp_start"
              style={{ marginBottom: '5px' }}
              placeholder="Data Início"
              onChange={(e, data) => {
                const value = `${moment(data.value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 00:00:00`;
                if (data.value && value !== timestamp_start) {
                  let param = data;
                  param.value = value;
                  this.handleChange(param);
                }
              }}
              value={moment(timestamp_start).format('DD/MM/YYYY')}
            />

            <DateInput
              error={!timestamp_finish ? true : false}
              dateFormat="DD/MM/YYYY"
              name="timestamp_finish"
              placeholder="Data Fim"
              onChange={(e, data) => {
                const value = `${moment(data.value, 'DD/MM/YYYY').format('YYYY-MM-DD')} 23:59:59`;
                if (data.value && value !== timestamp_finish) {
                  let param = data;
                  param.value = value;
                  this.handleChange(param);
                }
              }}
              value={moment(timestamp_finish).format('DD/MM/YYYY')}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="projects.client_id"
                placeholder="Cliente"
                fluid
                clearable
                search
                selection
                options={clientOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="project_id"
                placeholder="Projeto"
                fluid
                search
                selection
                clearable
                options={projectOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={3} computer={3}>
            <div className="actions-filter">
              <Dropdown
                name="type_id"
                placeholder="Tipo de Despesa"
                fluid
                search
                selection
                clearable
                options={activityTypeOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={3}
            computer={3}
            className={this.state.userMe ? 'hidden' : ''}>
            <div className="actions-filter">
              <Dropdown
                name="expenses.user_id"
                placeholder="Usuário"
                fluid
                search
                selection
                clearable
                options={userOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <div className="actions-filter">
              <Dropdown
                name="team_id"
                placeholder="Time"
                fluid
                search
                selection
                clearable
                options={teamOptions}
                onChange={(e, data) => this.handleChange(data)}
              />
            </div>
          </Grid.Column>
        </Grid>

        <div className="table-container">
          <DynamicTable
            header={['Nome', 'Quantidade Despesas', 'Valor Total']}
            columns={[
              'name',
              'total',
              {
                name: 'amount',
                format: (item) => {
                  return (
                    <CurrencyFormat
                      value={item.amount}
                      displayType={'text'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  );
                },
              },
            ]}
            data={data}
            totalPages={1}
            page={refresh}
            handleSort={() => {
              return;
            }}
            refresh={refresh}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { reports, users, expenses, projects, teams, clients } = state;
  return {
    reports,
    clients,
    teams,
    users,
    me: users.me,
    projects,
    activityType: expenses.types,
  };
}

const connectedExpenseConsolidatedScreen = connect(mapStateToProps)(ExpenseConsolidatedScreen);
export { connectedExpenseConsolidatedScreen as ExpenseConsolidatedScreen };
