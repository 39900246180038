import { financesAccountsConstants } from '../constants/finances-accounts.constants';

export function financesAccounts(state = {}, action) {
  switch (action.type) {
    case financesAccountsConstants.GETALLFINANCESACCOUNTS_REQUEST:
    case financesAccountsConstants.CREATEFINANCESACCOUNTS_REQUEST:
    case financesAccountsConstants.CREATEFINANCESACCOUNTSTYPE_REQUEST:
    case financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_REQUEST:
    case financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_REQUEST:
    case financesAccountsConstants.DELETEFINANCESACCOUNTS_REQUEST:
    case financesAccountsConstants.GETFINANCESACCOUNTSTYPE_REQUEST:
    case financesAccountsConstants.GETMEFINANCESACCOUNTS_REQUEST:
    case financesAccountsConstants.GETFINANCESACCOUNTS_REQUEST:
    case financesAccountsConstants.GETTYPEFINANCESACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case financesAccountsConstants.CREATEFINANCESACCOUNTSTYPE_SUCCESS:
    case financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_SUCCESS:
    case financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_SUCCESS:
    case financesAccountsConstants.DELETEFINANCESACCOUNTS_SUCCESS:
    case financesAccountsConstants.GETFINANCESACCOUNTSTYPE_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case financesAccountsConstants.CREATEFINANCESACCOUNTS_SUCCESS:
    case financesAccountsConstants.GETFINANCESACCOUNTS_SUCCESS:
      return {
        ...state,
        item: action.financesAccounts,
        loading: false,
      };
    case financesAccountsConstants.GETALLFINANCESACCOUNTS_SUCCESS:
    case financesAccountsConstants.GETMEFINANCESACCOUNTS_SUCCESS:
    case financesAccountsConstants.GETTEAMFINANCESACCOUNTS_SUCCESS:
      return {
        ...state,
        ...action.financesAccounts,
        loading: false,
      };
    case financesAccountsConstants.GETTYPEFINANCESACCOUNTS_SUCCESS:
      return {
        ...state,
        types: action.types,
        loading: false,
      };
    case financesAccountsConstants.GETCENTERFINANCESACCOUNTS_SUCCESS:
      return {
        ...state,
        centers: action.centers,
        loading: false,
      };
    case financesAccountsConstants.CREATEFINANCESACCOUNTS_FAILURE:
    case financesAccountsConstants.CREATEFINANCESACCOUNTSTYPE_FAILURE:
    case financesAccountsConstants.GETALLFINANCESACCOUNTS_FAILURE:
    case financesAccountsConstants.GETFINANCESACCOUNTSTYPE_FAILURE:
    case financesAccountsConstants.UPDATEFINANCESACCOUNTSTYPE_FAILURE:
    case financesAccountsConstants.DELETEFINANCESACCOUNTSTYPE_FAILURE:
    case financesAccountsConstants.DELETEFINANCESACCOUNTS_FAILURE:
    case financesAccountsConstants.GETMEFINANCESACCOUNTS_FAILURE:
    case financesAccountsConstants.GETTEAMFINANCESACCOUNTS_FAILURE:
    case financesAccountsConstants.GETTYPEFINANCESACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
