import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { ticketActions } from '../../actions/ticket.actions';
import { userActions } from '../../actions/user.actions';
import { clientActions } from '../../actions/client.actions';
import { contractActions } from '../../actions/contract.actions';
import ContracsTable from '../../components/ContracsTable/ContracsTable';
import RichTextEditor from 'react-rte';
import moment from 'moment';

import './styles.css';

class TicketCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: RichTextEditor.createEmptyValue(),
      status_id: '',
      user_id: '',
      client_id: '',
      priority_id: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getClientUsers());
    dispatch(clientActions.getAll());
    dispatch(ticketActions.getStatusTickets());
    dispatch(ticketActions.getPriorities());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { title, description, status_id, user_id, client_id, priority_id } = this.state;

    const { dispatch } = this.props;
    if (title && description.toString('html') && status_id && user_id && client_id && priority_id) {
      dispatch(
        ticketActions.create(
          {
            title,
            description: description.toString('html'),
            status_id,
            user_id,
            client_id,
            priority_id,
          },
          this.props.history,
        ),
      );
    }
  }

  getContracts(client_id) {
    const { dispatch } = this.props;

    const now = moment();
    const start_date = now.startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end_date = now.endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');

    dispatch(
      contractActions.getAll({
        start_date,
        end_date,
        client_id,
      }),
    );
  }

  render() {
    const { priorities, users, status, clients, loading, dispatch, contracts } = this.props;
    const { title, description, status_id, user_id, client_id, priority_id, submitted } =
      this.state;

    let prioritiesOptions, userOptions, statusOptions, clientOptions;

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.clientUsers && users.clientUsers.data !== undefined) {
      userOptions = users.clientUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (priorities && priorities.data !== undefined) {
      prioritiesOptions = priorities.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Ticket</h1>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Titulo</label>
            <input
              name="title"
              placeholder="Titulo do Ticket"
              value={title}
              onChange={this.handleChange}
            />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Titulo é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <RichTextEditor
              value={description}
              onChange={(value) => this.handleChange(true, { name: 'description', value })}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Prioridade</label>
            <Dropdown
              placeholder="Prioridade"
              name="priority_id"
              value={priority_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={prioritiesOptions}
            />
            {submitted && !priority_id && (
              <Label basic color="red" pointing>
                Prioridade é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Cliente</label>
            <Dropdown
              placeholder="Cliente"
              fluid
              search
              selection
              name="client_id"
              options={clientOptions}
              onChange={(event, data) => {
                this.handleChange(true, data);
                dispatch(
                  userActions.getClientUsers({
                    client: data.value === '' ? null : data.value,
                  }),
                );
                if (data.value) {
                  this.getContracts(data.value);
                }
              }}
              value={client_id}
            />
            {submitted && !client_id && (
              <Label basic color="red" pointing>
                Cliente é requerido
              </Label>
            )}
            {client_id && <ContracsTable contracts={contracts} />}
          </Form.Field>
          <Form.Field>
            <label>Solicitante</label>
            <Dropdown
              name="user_id"
              onChange={(event, data) => this.handleChange(true, data)}
              placeholder="Usuário"
              fluid
              search
              selection
              options={userOptions}
              value={user_id}
            />
            {submitted && !user_id && (
              <Label basic color="red" pointing>
                Solicitante é requerido
              </Label>
            )}
          </Form.Field>

          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              name="status_id"
              value={status_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={statusOptions}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Status é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets, users, clients, contracts } = state;
  return {
    status: tickets.status,
    priorities: tickets.priorities,
    contracts: contracts?.data || [],
    tickets,
    clients,
    users,
  };
}

const connectedTicketCreateScreen = connect(mapStateToProps)(TicketCreateScreen);
export { connectedTicketCreateScreen as TicketCreateScreen };
