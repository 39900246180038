import { financialCategoryConstants } from '../constants/financial-category.constants';
import financialCategoryService from '../services/financial-category.service';
import { alertActions } from './alert.actions';
import transactionCategoryService from '../services/transaction-category.service';

export const financialCategoryActions = {
  create,
  deleteById,
  getAll,
  getById,
  update,
  getGroups,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const subCategory = await financialCategoryService.create(obj);
      dispatch(success(subCategory));
      history.push('/finance/categories');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: financialCategoryConstants.CREATEFINANCIALCATEGORY_REQUEST };
  }
  function success(subCategory) {
    return {
      type: financialCategoryConstants.CREATEFINANCIALCATEGORY_SUCCESS,
      subCategory,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.CREATEFINANCIALCATEGORY_FAILURE,
      error,
    };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const subCategory = await financialCategoryService.deleteById(id);

      dispatch(success(subCategory));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(subCategory) {
    return {
      type: financialCategoryConstants.DELETEFINANCIALCATEGORY_REQUEST,
      subCategory,
    };
  }
  function success(subCategory) {
    return {
      type: financialCategoryConstants.DELETEFINANCIALCATEGORY_SUCCESS,
      subCategory,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.DELETEFINANCIALCATEGORY_FAILURE,
      error,
    };
  }
}
function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const sub_categories = await financialCategoryService.getAll(filter);
      dispatch(success(sub_categories));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: financialCategoryConstants.GETALLFINANCIALCATEGORY_REQUEST };
  }
  function success(sub_categories) {
    return {
      type: financialCategoryConstants.GETALLFINANCIALCATEGORY_SUCCESS,
      sub_categories,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.GETALLFINANCIALCATEGORY_FAILURE,
      error,
    };
  }
}

function getGroups(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const groups = await transactionCategoryService.getAll(filter);
      dispatch(success(groups));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: financialCategoryConstants.GETGROUPS_REQUEST };
  }
  function success(groups) {
    return {
      type: financialCategoryConstants.GETGROUPS_SUCCESS,
      groups,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.GETGROUPS_FAILURE,
      error,
    };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const subCategory = await financialCategoryService.getById(id);

      dispatch(success(subCategory));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(subCategory) {
    return {
      type: financialCategoryConstants.GETFINANCIALCATEGORY_REQUEST,
      subCategory,
    };
  }
  function success(subCategory) {
    return {
      type: financialCategoryConstants.GETFINANCIALCATEGORY_SUCCESS,
      subCategory,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.GETFINANCIALCATEGORY_FAILURE,
      error,
    };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const subCategory = await financialCategoryService.update(id, obj);

      dispatch(success(subCategory));
      history.push('/finance/categories');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: financialCategoryConstants.UPDATEFINANCIALCATEGORY_REQUEST };
  }
  function success(subCategory) {
    return {
      type: financialCategoryConstants.UPDATEFINANCIALCATEGORY_SUCCESS,
      subCategory,
    };
  }
  function failure(error) {
    return {
      type: financialCategoryConstants.UPDATEFINANCIALCATEGORY_FAILURE,
      error,
    };
  }
}
