import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import RichTextEditor from 'react-rte';
import CurrencyFormat from 'react-currency-format';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import {
  Button,
  Checkbox,
  Confirm,
  Divider,
  Form,
  Grid,
  Icon,
  Label,
  List,
  Loader,
  Visibility,
} from 'semantic-ui-react';
import _ from 'lodash';
import { ticketActions } from '../../actions/ticket.actions';
import { expenseActions } from '../../actions/expense.actions';
import { activityActions } from '../../actions/activity.actions';
import { contractActions } from '../../actions/contract.actions';
import { ModalExpense } from '../../components/Modals/ModalExpense';
import { ModalExecutors } from '../../components/Modals/ModalExecutors';
import { ModalTeams } from '../../components/Modals/ModalTeams';
import { ModalPriority } from '../../components/Modals/ModalPriority';
import { Executors } from '../../components/TicketExecutors/Executors';
import { Teams } from '../../components/TicketTeams/Teams';
import './styles.css';
import ContracsTable from '../../components/ContracsTable/ContracsTable';

class TicketShowScreen extends Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      interactions: [],
      deleteInteractionId: null,
      interaction: RichTextEditor.createEmptyValue(),
      open: false,
      confirmInteractionOpen: false,
      confirmNoTime: false,
      openExpense: false,
      openInteraction: false,
      page: 1,
      file: null,
      refresh: false,
      totalPages: 1,
      lastPages: 1,
      ticketItem: {},
      openListActivities: false,
      submitted: false,
      text: '',
      timestamp_start: '',
      timestamp_end: '',
      internal: false,
      openModalExecutor: false,
      openModalTeams: false,
      openModalPriority: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadScreen();
  }

  loadScreen() {
    const { match, dispatch } = this.props;
    dispatch(ticketActions.getById(match.params.id)).then(() => {
      const { tickets } = this.props;
      const now = moment();
      const start_date = now.startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const end_date = now.endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const client_id = tickets.item.client.id;

      dispatch(
        contractActions.getAll({
          start_date,
          end_date,
          client_id,
        }),
      );
    });
    dispatch(ticketActions.getAllExecutors(match.params.id));
    dispatch(ticketActions.getAllTeams(match.params.id));
    dispatch(expenseActions.getAll(`expenses.ticket_id=${match.params.id}`));
    dispatch(activityActions.getAll(`ticket_id=${match.params.id}`));
    this.moreInteraction();
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(ticketActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/tickets'),
    );
  }

  editById() {
    const { match } = this.props;
    this.props.history.push(`/tickets/${match.params.id}/edit`);
  }

  openListActivities(item) {
    this.setState({ openListActivities: true, ticketItem: item });
  }

  handleChange(e, data) {
    if (data) {
      if (data.name === 'internal') {
        this.setState({ internal: data.checked });
      } else {
        this.setState({ [data.name]: data.value });
      }
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  sendInteraction() {
    this.setState({ submitted: true });

    const { dispatch, match } = this.props;
    const { interaction, timestamp_start, timestamp_end, internal } = this.state;

    if (interaction.toString('html')) {
      let payload = {
        text: interaction.toString('html'),
        internal: internal,
      };

      if (timestamp_start && timestamp_end) {
        payload.timestamp_start = moment(timestamp_start, 'DD/MM/YYYY HH:mm').format(
          'YYYY-MM-DD HH:mm',
        );
        payload.timestamp_end = moment(timestamp_end, 'DD/MM/YYYY HH:mm').format(
          'YYYY-MM-DD HH:mm',
        );
      }
      dispatch(ticketActions.createInteraction(match.params.id, payload)).then(() => {
        const { match } = this.props;
        dispatch(activityActions.getAll(`ticket_id=${match.params.id}`));

        this.setState({
          text: '',
          interaction: RichTextEditor.createEmptyValue(),
          timestamp_start: '',
          timestamp_end: '',
          submitted: false,
        });

        this.setState({
          refresh: true,
          page: 1,
        });

        this.moreInteraction();
      });
    }
  }

  moreInteraction() {
    const { dispatch, match } = this.props;

    if (this.state.page <= this.state.lastPages || this.state.refresh) {
      dispatch(ticketActions.getInteractions(match.params.id, `page=${this.state.page}`)).then(
        () => {
          const { interactions } = this.props;
          let data = this.state.interactions;

          if (this.state.page == 1) {
            data = interactions.data;
          } else {
            data = data.concat(interactions.data);
          }

          this.setState({
            page: this.state.page + 1,
            totalPages: interactions.total,
            lastPages: interactions.lastPage,
            interactions: _.orderBy(data, ['id'], ['desc']),
          });
        },
      );
    }
  }

  onChangeUpload(e) {
    const { dispatch, match } = this.props;

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);

    dispatch(ticketActions.uploadAttachment(match.params.id, formData)).then(() =>
      this.loadScreen(),
    );
  }

  deleteAttachment(id) {
    const { dispatch, match } = this.props;

    dispatch(ticketActions.deleteAttachmentById(match.params.id, id)).then(() => {
      dispatch(ticketActions.getById(match.params.id)).then(() => {
        this.moreInteraction();
        this.setState({ deleteInteractionId: null, openInteraction: false });
      });
    });
  }

  send(status) {
    const { dispatch, match } = this.props;

    const { timestamp_start, timestamp_end, confirmNoTime, sendStatus } = this.state;

    if (!timestamp_start && !timestamp_end && !confirmNoTime) {
      this.setState({ confirmInteractionOpen: true, sendStatus: status });
      return;
    }

    if (!status && confirmNoTime && sendStatus) {
      status = sendStatus;
    }

    this.setState({ confirmNoTime: false, sendStatus: null });

    if (!status) {
      this.sendInteraction();
    } else {
      dispatch(
        ticketActions.update(match.params.id, {
          status_id: status,
        }),
      ).then(() => {
        const { match, dispatch } = this.props;

        dispatch(ticketActions.getById(match.params.id)).then(() => {
          this.sendInteraction();
        });
      });
    }
  }

  render() {
    const { contracts, executors, teams, tickets, expenses, activities, match, loading } =
      this.props;

    const {
      open,
      openExpense,
      interactions,
      interaction,
      submitted,
      openInteraction,
      timestamp_start,
      timestamp_end,
      confirmInteractionOpen,
      openModalExecutor,
      openModalTeams,
      openModalPriority,
    } = this.state;

    if (!tickets || !tickets.item || !interactions) {
      return <Loader active={loading} />;
    }

    let ticket_id = match.params.id;

    return (
      <Fragment>
        <Confirm
          key={'ticket'}
          content="Deseja remover este ticket?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <ModalExpense
          openExpense={openExpense}
          ticket_id={ticket_id}
          onClose={() => this.setState({ openExpense: false })}
        />
        <ModalExecutors
          openModalExecutor={openModalExecutor}
          ticket_id={ticket_id}
          onClose={() => this.setState({ openModalExecutor: false })}
        />
        <ModalTeams
          openModalTeam={openModalTeams}
          ticket_id={ticket_id}
          onClose={() => this.setState({ openModalTeams: false })}
        />
        <ModalPriority
          openModalPriority={openModalPriority}
          ticket_id={ticket_id}
          onClose={() => this.setState({ openModalPriority: false })}
          history={this.props.history}
        />
        <Confirm
          key={'interaction'}
          content="Deseja remover este arquivo?"
          open={openInteraction}
          onCancel={() => this.setState({ openInteraction: false })}
          onConfirm={() => this.deleteAttachment(this.state.deleteInteractionId)}
        />

        <Confirm
          open={confirmInteractionOpen}
          content="Você não preencheu o tempo de atividade. Deseja continuar assim mesmo?"
          onCancel={() => this.setState({ confirmInteractionOpen: false })}
          onConfirm={() =>
            this.setState({ confirmNoTime: true, confirmInteractionOpen: false }, () => this.send())
          }
        />

        <Grid className="header-page" loading={loading}>
          <Grid.Row>
            <Grid.Column computer={1} mobile={1} tablet={1}>
              <Label as="span" color="blue" style={{ fontWeight: 'bold', fontSize: '18px' }} ribbon>
                {tickets.item.id}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={10} tablet={10} computer={10}>
              <h1>{tickets.item.title}</h1>
            </Grid.Column>
            <Grid.Column mobile={5} tablet={5} computer={5} textAlign="right">
              <Button icon="edit outline" onClick={() => this.editById()} />
              <Button icon="trash alternate outline" onClick={this.deleteShow} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Prioridade</h4>
                <Button
                  icon="edit"
                  style={{ marginLeft: 120 }}
                  onClick={() => this.setState({ openModalPriority: true })}
                  title="Editar"
                />
              </div>
              {tickets.item.priority ? tickets.item.priority.name : ' - '}
            </Grid.Column>
            <Grid.Column>
              <h4>Solicitante</h4> {tickets.item.user ? tickets.item.user.name : ' - '}
            </Grid.Column>
            <Grid.Column>
              <h4>Cliente</h4> {tickets.item.client ? tickets.item.client.name : ' - '}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={5}>
            <Grid.Column>
              <h4>Status</h4> {tickets.item.status?.name || ' - '}
            </Grid.Column>
            <Grid.Column>
              <h4>Produto</h4> {tickets.item.product?.name || ' - '}
            </Grid.Column>
            <Grid.Column>
              <h4>Criado em</h4> {moment(tickets.item.created_at).format('DD/MM/YYYY HH:mm:ss')}
            </Grid.Column>
            <Grid.Column>
              <h4>Última Atualização</h4>{' '}
              {moment(tickets.item.updated_at).format('DD/MM/YYYY HH:mm:ss')}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <ContracsTable contracts={contracts} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>Descrição</h4>
              <div dangerouslySetInnerHTML={{ __html: tickets.item.description }}></div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={5} divided>
            <Grid.Column>
              <h4>
                Executores
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {executors ? executors.total : ''}
                </Label>
                <Button
                  icon="plus circle"
                  onClick={() => this.setState({ openModalExecutor: true })}
                  floated="right"
                  title="Adicionar"
                  htmlFor="file"
                />
              </h4>
              <Executors executors={executors} />
            </Grid.Column>
            <Grid.Column>
              <h4>
                Times
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {teams ? teams.total : ''}
                </Label>
                <Button
                  icon="plus circle"
                  onClick={() => this.setState({ openModalTeams: true })}
                  floated="right"
                  title="Adicionar"
                  htmlFor="file"
                />
              </h4>
              <Teams teams={teams} />
            </Grid.Column>
            <Grid.Column>
              <h4>
                Anexos
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {tickets.item.attachment ? tickets.item.attachment.length : ''}
                </Label>
                <Button
                  icon="plus circle"
                  onClick={() => this.fileInputRef.current.click()}
                  floated="right"
                  title="Anexar novo arquivo"
                  htmlFor="file"
                />
              </h4>
              <input
                name="file"
                id="file"
                ref={this.fileInputRef}
                className="input hidden"
                type="file"
                onChange={(e) => this.onChangeUpload(e)}
              />
              {tickets.item.attachment && tickets.item.attachment.length == 0 && (
                <p textAlign="center" style={{ textStyle: 'italic' }}>
                  Nenhum anexo
                </p>
              )}
              {tickets.item.attachment && tickets.item.attachment.length > 0 && (
                <List
                  divided
                  verticalAlign="middle"
                  style={{ maxHeight: '150px', overflow: 'auto' }}>
                  {tickets.item.attachment.map((attachment, key) => (
                    <List.Item key={attachment.id + key}>
                      <List.Content as="a" href={attachment.url} target="_blank">
                        {_.last(attachment.url.split('/'))}
                      </List.Content>

                      <List.Content floated="right">
                        <Button
                          negative
                          icon="close"
                          onClick={() =>
                            this.setState({
                              deleteInteractionId: attachment.id,
                              openInteraction: true,
                            })
                          }
                        />
                      </List.Content>
                      <List.Description>
                        {moment(attachment.created_at).format('DD/MM/YYYY HH:mm')}
                      </List.Description>
                    </List.Item>
                  ))}
                </List>
              )}
            </Grid.Column>

            <Grid.Column>
              <h4>
                Despesas
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {expenses.data ? expenses.data.length : ''}
                </Label>
                <Button
                  icon="plus square"
                  title="Lançar nova despesa"
                  onClick={() => this.setState({ openExpense: true })}
                  floated="right"
                />
              </h4>
              {expenses.data && expenses.data.length == 0 && (
                <p textAlign="center" style={{ textStyle: 'italic' }}>
                  Nenhuma despesa
                </p>
              )}
              {expenses.data && expenses.data.length > 0 && (
                <List
                  divided
                  verticalAlign="middle"
                  style={{ maxHeight: '150px', overflow: 'auto' }}>
                  {expenses.data.map((expense) => (
                    <List.Item key={expense.id}>
                      <List.Header>{expense.description}</List.Header>
                      <List.Content>
                        {moment(expense.date).format('DD/MM/YYYY')} -{' '}
                        <CurrencyFormat
                          value={expense.amount}
                          displayType={'text'}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={'R$ '}
                        />
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              )}
            </Grid.Column>

            <Grid.Column>
              <h4>
                Atividades
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {activities.data ? activities.data.length : ''}
                </Label>
              </h4>
              {activities.data && activities.data.length == 0 && (
                <p textAlign="center" style={{ textStyle: 'italic' }}>
                  Nenhum anexo
                </p>
              )}
              {activities.data && activities.data.length > 0 && (
                <List
                  divided
                  verticalAlign="middle"
                  style={{ maxHeight: '150px', overflow: 'auto' }}>
                  {activities.data.map((activity) => (
                    <List.Item key={activity.id}>
                      <List.Header>{activity.user.name}</List.Header>
                      <List.Content>
                        {moment(activity.timestamp_start).format('DD/MM/YYYY HH:mm')} -{' '}
                        {moment(activity.timestamp_end).format('HH:mm')}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>
                Interações
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {this.state.interactions ? this.state.interactions.length : ''}
                </Label>
              </h4>
              <Form reply>
                <div className="interaction-activity">
                  <h4>Tempo de Atendimento</h4>
                  <Form.Group widths="equal">
                    <Form.Field width="5">
                      <DateTimeInput
                        closable
                        clearable
                        initialDate={moment().format('DD/MM/YYYY HH:mm')}
                        name="timestamp_start"
                        dateTimeFormat="DD/MM/YYYY HH:mm"
                        placeholder="Início"
                        onChange={(event, data) => this.handleChange(true, data)}
                        value={timestamp_start}
                        iconPosition="left"
                      />
                    </Form.Field>
                    <Form.Field width="5">
                      <DateTimeInput
                        closable
                        clearable
                        name="timestamp_end"
                        minDate={timestamp_start}
                        dateTimeFormat="DD/MM/YYYY HH:mm"
                        initialDate={moment().format('DD/MM/YYYY HH:mm')}
                        placeholder="Fim"
                        onChange={(event, data) => this.handleChange(true, data)}
                        value={timestamp_end}
                        iconPosition="left"
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
                <Divider horizontal>Interação</Divider>
                <div id="editor">
                  <RichTextEditor
                    style={{ height: '200px' }}
                    placeholder="Digite uma Interação"
                    value={this.state.interaction}
                    onChange={(value) => this.handleChange(true, { name: 'interaction', value })}
                  />
                </div>
                {submitted && !interaction && (
                  <Label basic color="red" pointing>
                    Interação é requerido
                  </Label>
                )}
                <Form.Field>
                  <Checkbox name="internal" onChange={this.handleChange} label="Interno" />
                </Form.Field>
                <Button
                  content="Enviar"
                  labelPosition="left"
                  onClick={() => this.send()}
                  icon="edit"
                  primary
                />
                {tickets.item.status_id === 1 && (
                  <>
                    <Button
                      content="Enviar e Abrir"
                      labelPosition="left"
                      onClick={() => this.send(2)}
                      icon="edit"
                      primary
                    />
                  </>
                )}
                {tickets.item.status_id === 2 && (
                  <>
                    <Button
                      content="Enviar e Fechar"
                      labelPosition="left"
                      onClick={() => this.send(3)}
                      icon="edit"
                      primary
                    />
                    <Button
                      content="Enviar e Colocar em Espera"
                      onClick={() => this.send(4)}
                      labelPosition="left"
                      icon="edit"
                      primary
                    />
                  </>
                )}
                {tickets.item.status_id === 3 && (
                  <>
                    <Button
                      content="Reabrir"
                      labelPosition="left"
                      onClick={() => this.send(2)}
                      icon="edit"
                      primary
                    />
                  </>
                )}
                {tickets.item.status_id === 4 && (
                  <>
                    <Button
                      content="Enviar e Fechar"
                      onClick={() => this.send(3)}
                      labelPosition="left"
                      icon="edit"
                      primary
                    />
                    <Button
                      content="Enviar e Reabrir"
                      onClick={() => this.send(1)}
                      labelPosition="left"
                      icon="edit"
                      primary
                    />
                  </>
                )}
              </Form>
              <Visibility
                as="div"
                className="ui animated middle aligned list"
                continuous={false}
                once={false}
                onBottomVisible={() => this.moreInteraction()}>
                {interactions.map((interaction, key) => (
                  <List.Item
                    key={key}
                    className={interaction.internal ? 'item-feed internal' : 'item-feed'}>
                    <Icon name="user" size="large" />
                    <List.Content>
                      <List.Header as="a">{interaction.user}</List.Header>
                      <List.Header as="span" style={{ fontSize: '12px', color: 'rgba(0,0,0,.7)' }}>
                        {moment(interaction.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </List.Header>
                      <List.Description style={{ color: 'rgba(0,0,0,.87)', fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: interaction.text }}></div>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </Visibility>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets, expenses, activities, contracts } = state;
  return {
    tickets,
    expenses,
    activities,
    contracts: contracts?.data || [],
    executors: tickets.executors || null,
    teams: tickets.teams || null,
    interactions: tickets.interactions || null,
    loading: tickets.loading || contracts.loading || false,
  };
}

const connectedTicketShowScreen = connect(mapStateToProps)(TicketShowScreen);
export { connectedTicketShowScreen as TicketShowScreen };
