import { ticketConstants } from '../constants/ticket.constants';
import ticketService from '../services/ticket.service';
import { alertActions } from './alert.actions';

export const ticketActions = {
  create,
  createInteraction,
  update,
  getAll,
  getReport,
  getById,
  deleteById,
  deleteAttachmentById,
  createExecutors,
  deleteExecutors,
  getAllExecutors,
  createTeams,
  deleteTeams,
  getAllTeams,
  uploadAttachment,
  getStatusTickets,
  exportFile,
  exportPDF,
  getInteractions,
  getPriorities,
};

function uploadAttachment(id_ticket, data) {
  return async (dispatch) => {
    dispatch(request(data));

    try {
      const attachment = await ticketService.uploadAttachment(id_ticket, data);

      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(attachment) {
    return { type: ticketConstants.CREATEATTACHMENT_REQUEST, attachment };
  }
  function success(attachment) {
    return { type: ticketConstants.CREATEATTACHMENT_SUCCESS, attachment };
  }
  function failure(error) {
    return { type: ticketConstants.CREATEATTACHMENT_FAILURE, error };
  }
}

function exportPDF(filter) {
  return async (dispatch) => {
    try {
      const tickets = await ticketService.getReportPDF(filter);
      window.open(tickets, '_blank');
    } catch (error) {
      console.error('ERROR', error);
      dispatch(alertActions.error(error));
    }
  };
}

function exportFile(filter) {
  return async (dispatch) => {
    try {
      filter.export = 'xlsx';
      const tickets = await ticketService.getReport(filter);
      window.open(tickets.url, '_blank');
    } catch (error) {
      console.error('ERROR', error);
      dispatch(alertActions.error(error));
    }
  };
}

function deleteAttachmentById(id_ticket, id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const attachment = await ticketService.deleteAttachmentById(id_ticket, id);

      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(attachment) {
    return { type: ticketConstants.DELETEATTACHMENT_REQUEST, attachment };
  }
  function success(attachment) {
    return { type: ticketConstants.DELETEATTACHMENT_SUCCESS, attachment };
  }
  function failure(error) {
    return { type: ticketConstants.DELETEATTACHMENT_FAILURE, error };
  }
}

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const ticket = await ticketService.create(obj);

      dispatch(success(ticket));
      history.push('/tickets');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.CREATETICKET_REQUEST };
  }
  function success(ticket) {
    return { type: ticketConstants.CREATETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.CREATETICKET_FAILURE, error };
  }
}

function createInteraction(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interaction = await ticketService.createInteraction(id, obj);

      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.CREATEINTERACTION_REQUEST };
  }
  function success(interaction) {
    return { type: ticketConstants.CREATEINTERACTION_SUCCESS, interaction };
  }
  function failure(error) {
    return { type: ticketConstants.CREATEINTERACTION_FAILURE, error };
  }
}

function createExecutors(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const executors = await ticketService.createExecutors(id, obj);

      dispatch(success(executors));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: ticketConstants.CREATEEXECUTORS_REQUEST };
  }
  function success(executors) {
    return { type: ticketConstants.CREATEEXECUTORS_SUCCESS, executors };
  }
  function failure(error) {
    return { type: ticketConstants.CREATEEXECUTORS_FAILURE, error };
  }
}

function getAllExecutors(id, params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const executors = await ticketService.getAllExecutors(id, params);

      dispatch(success(executors));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLEXECUTORS_REQUEST };
  }
  function success(executors) {
    return { type: ticketConstants.GETALLEXECUTORS_SUCCESS, executors };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLEXECUTORS_FAILURE, error };
  }
}

function deleteExecutors(ticket_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const executors = await ticketService.deleteExecutors(ticket_id, id);

      dispatch(success(executors));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(executors) {
    return { type: ticketConstants.DELETEEXECUTORS_REQUEST, executors };
  }
  function success(executors) {
    return { type: ticketConstants.DELETEEXECUTORS_SUCCESS, executors };
  }
  function failure(error) {
    return { type: ticketConstants.DELETEEXECUTORS_FAILURE, error };
  }
}

function createTeams(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const teams = await ticketService.createTeams(id, obj);

      dispatch(success(teams));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request() {
    return { type: ticketConstants.CREATETEAMS_REQUEST };
  }
  function success(teams) {
    return { type: ticketConstants.CREATETEAMS_SUCCESS, teams };
  }
  function failure(error) {
    return { type: ticketConstants.CREATETEAMS_FAILURE, error };
  }
}

function getAllTeams(id, params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const teams = await ticketService.getAllTeams(id, params);

      dispatch(success(teams));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLTEAMS_REQUEST };
  }
  function success(teams) {
    return { type: ticketConstants.GETALLTEAMS_SUCCESS, teams };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLTEAMS_FAILURE, error };
  }
}

function deleteTeams(ticket_id, id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const teams = await ticketService.deleteTeams(ticket_id, id);

      dispatch(success(teams));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(teams) {
    return { type: ticketConstants.DELETETEAMS_REQUEST, teams };
  }
  function success(teams) {
    return { type: ticketConstants.DELETETEAMS_SUCCESS, teams };
  }
  function failure(error) {
    return { type: ticketConstants.DELETETEAMS_FAILURE, error };
  }
}

function update(id, obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const ticket = await ticketService.update(id, obj);

      dispatch(success(ticket));
      if (history) {
        history.push(`/tickets/${id}/show`);
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.UPDATETICKET_REQUEST };
  }
  function success(ticket) {
    return { type: ticketConstants.UPDATETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.UPDATETICKET_FAILURE, error };
  }
}

function getAll(params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const tickets = await ticketService.getAll(params);

      dispatch(success(tickets));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLTICKET_REQUEST };
  }
  function success(tickets) {
    return { type: ticketConstants.GETALLTICKET_SUCCESS, tickets };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLTICKET_FAILURE, error };
  }
}

function getReport(params) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const tickets = await ticketService.getReport(params);

      dispatch(success(tickets));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLTICKET_REQUEST };
  }
  function success(tickets) {
    return { type: ticketConstants.GETALLTICKET_SUCCESS, tickets };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLTICKET_FAILURE, error };
  }
}

function getInteractions(id, filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interactions = await ticketService.getInteractions(id, filter);

      dispatch(success(interactions));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLINTERACTION_REQUEST };
  }
  function success(interactions) {
    return { type: ticketConstants.GETALLINTERACTION_SUCCESS, interactions };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLINTERACTION_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const ticket = await ticketService.getById(id);

      dispatch(success(ticket));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(ticket) {
    return { type: ticketConstants.GETTICKET_REQUEST, ticket };
  }
  function success(ticket) {
    return { type: ticketConstants.GETTICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.GETTICKET_FAILURE, error };
  }
}

function getStatusTickets() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const status = await ticketService.getStatusTickets();

      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETSTATUS_REQUEST };
  }
  function success(status) {
    return { type: ticketConstants.GETSTATUS_SUCCESS, status };
  }
  function failure(error) {
    return { type: ticketConstants.GETSTATUS_FAILURE, error };
  }
}

function getPriorities() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const priorities = await ticketService.getPriorities();

      dispatch(success(priorities));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETTICKETPRIORITIES_REQUEST };
  }
  function success(priorities) {
    return { type: ticketConstants.GETTICKETPRIORITIES_SUCCESS, priorities };
  }
  function failure(error) {
    return { type: ticketConstants.GETTICKETPRIORITIES_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const ticket = await ticketService.deleteById(id);

      dispatch(success(ticket));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(ticket) {
    return { type: ticketConstants.DELETETICKET_REQUEST, ticket };
  }
  function success(ticket) {
    return { type: ticketConstants.DELETETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.DELETETICKET_FAILURE, error };
  }
}
