import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { Form, Header, Icon, Loader } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { reportActions } from '../../actions/report.actions';
import { userActions } from '../../actions/user.actions';
import { teamActions } from '../../actions/team.actions';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class ActivityBankScreen extends Component {
  STORAGE_KEY = 'ActivityBankState';

  constructor(props) {
    super(props);

    this.state = {
      period: '',
      filter: {
        timestamp_start: null,
        timestamp_end: null,
      },
      page: 1,
      sort: 'timestamp_start:desc',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(userActions.getCompanyUsers());
    dispatch(teamActions.getAll());

    let start = moment();
    let end = moment();
    if (moment().date() < 15) {
      start = start.subtract(1, 'month');
      end = end.subtract(1, 'month');
    }

    start = start.startOf('month').format(DATE_FORMAT);
    end = end.endOf('month').format(DATE_FORMAT);
    this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

    // Load previous filter from storage, if any
    const state = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (state) {
      delete state.filter.export;
      this.setState(state, () => this.fetchActivities());
    } else {
      this.fetchActivities();
    }
  }

  persistStateToStorage() {
    const { filter } = this.state;
    const state = { filter };
    delete state.filter.export;
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  fetchActivities() {
    const { dispatch } = this.props;
    const params = this.filterParams();

    dispatch(reportActions.getActivitiesBank(params)).then(() => {
      this.persistStateToStorage();
    });
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value || null;
    this.setState({ filter }, () => this.fetchActivities());
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => this.fetchActivities());
  }

  filterParams() {
    const { filter, period, page, sort } = this.state;

    if (period) {
      var [startDate, endDate] = period.split(' - ');
      if (startDate) {
        filter.timestamp_start = `${moment(startDate, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
      } else {
        filter.timestamp_start = null;
      }

      if (endDate) {
        filter.timestamp_end = `${moment(endDate, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      } else {
        filter.timestamp_end = null;
      }
    } else {
      filter.timestamp_start = null;
      filter.timestamp_end = null;
    }

    const params = filter;
    params.page = page;
    params.sort = sort;

    return params;
  }

  downloadFile() {
    const { dispatch } = this.props;
    dispatch(reportActions.exportBank(this.filterParams()));
  }

  render() {
    const { filter, period } = this.state;
    const { reports, bank, loading } = this.props;

    const data = bank;

    if (!data) {
      return <Loader active={loading} />;
    }

    const { users, teams } = this.props;
    let userOptions,
      teamOptions = [];

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Header as="h1">Banco de Horas</Header>
        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período"
              closable={true}
              width={4}
              clearable
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />

            <Form.Select
              name="team_id"
              label="Time"
              clearable
              search
              selection
              width={4}
              value={filter['team_id']}
              options={teamOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="activities.user_id"
              label="Usuário"
              clearable
              search
              selection
              width={4}
              value={filter['activities.user_id']}
              options={userOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Button onClick={() => this.downloadFile()} width="4" label="&nbsp;">
              <Icon name="file excel" />
              Exportar
            </Form.Button>
          </Form.Group>
        </Form>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.fetchActivities();
              });
            }}
            handleSort={(column) => {
              if (column == 'expenses') return false;

              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}:desc`, sorted: 'desc', page: 1 }, () => {
                  this.fetchActivities();
                });
              } else {
                this.setState({ sort: `${column}:asc`, sorted: 'asc', page: 1 }, () => {
                  this.fetchActivities();
                });
              }
            }}
            header={['Usuário', 'Período', 'Total', 'Compensável', 'Extra', 'BH', 'Compensadas']}
            columns={[
              {
                name: 'user',
                format: (item) => item.user,
              },
              {
                name: 'month',
                format: (item) => item.month,
              },
              {
                name: 'time_total',
                format: (item) =>
                  moment.duration(item.time_total, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_over',
                format: (item) =>
                  moment.duration(item.time_over, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_extra',
                format: (item) =>
                  moment.duration(item.time_extra, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_compensable',
                format: (item) =>
                  moment
                    .duration(item.time_compensable, 'second')
                    .format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_compensed',
                format: (item) =>
                  moment
                    .duration(item.time_compensed, 'second')
                    .format('HH:mm:ss', { trim: false }),
              },
            ]}
            data={data}
            totalPages={reports.lastPage}
            page={reports.page}
            onClick={() => true}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { reports, users, activities, projects, teams, clients } = state;
  return {
    reports,
    bank: reports && reports.bank ? reports.bank : null,
    clients,
    teams,
    users: users.companyUsers,
    me: users.me,
    projects,
    activityType: activities.types,
    loading: activities.loading || false,
  };
}

const connectedActivityScreen = connect(mapStateToProps)(ActivityBankScreen);
export { connectedActivityScreen as ActivityBankScreen };
