export const transactionCategoryConstants = {
  GETALLTRANSACTIONCATEGORY_REQUEST: 'GETALLTRANSACTIONCATEGORY_REQUEST',
  GETALLTRANSACTIONCATEGORY_SUCCESS: 'GETALLTRANSACTIONCATEGORY_SUCCESS',
  GETALLTRANSACTIONCATEGORY_FAILURE: 'GETALLTRANSACTIONCATEGORY_FAILURE',

  GETTRANSACTIONCATEGORY_REQUEST: 'GETTRANSACTIONCATEGORY_REQUEST',
  GETTRANSACTIONCATEGORY_SUCCESS: 'GETTRANSACTIONCATEGORY_SUCCESS',
  GETTRANSACTIONCATEGORY_FAILURE: 'GETTRANSACTIONCATEGORY_FAILURE',

  UPDATETRANSACTIONCATEGORY_REQUEST: 'UPDATETRANSACTIONCATEGORY_REQUEST',
  UPDATETRANSACTIONCATEGORY_SUCCESS: 'UPDATETRANSACTIONCATEGORY_SUCCESS',
  UPDATETRANSACTIONCATEGORY_FAILURE: 'UPDATETRANSACTIONCATEGORY_FAILURE',

  CREATETRANSACTIONCATEGORY_REQUEST: 'CREATETRANSACTIONCATEGORY_REQUEST',
  CREATETRANSACTIONCATEGORY_SUCCESS: 'CREATETRANSACTIONCATEGORY_SUCCESS',
  CREATETRANSACTIONCATEGORY_FAILURE: 'CREATETRANSACTIONCATEGORY_FAILURE',

  DELETETRANSACTIONCATEGORY_REQUEST: 'DELETETRANSACTIONCATEGORY_REQUEST',
  DELETETRANSACTIONCATEGORY_SUCCESS: 'DELETETRANSACTIONCATEGORY_SUCCESS',
  DELETETRANSACTIONCATEGORY_FAILURE: 'DELETETRANSACTIONCATEGORY_FAILURE',
};
