import { salesConstants } from '../constants/sales.constants';

export function sales(state = {}, action) {
  switch (action.type) {
    case salesConstants.GETALL_SALES_REQUEST:
    case salesConstants.GET_SALESITEMS_REQUEST:
    case salesConstants.GET_SALESORDERS_REQUEST:
    case salesConstants.GET_SALESSTATUS_REQUEST:
    case salesConstants.CREATE_SALESITEMS_REQUEST:
    case salesConstants.UPDATE_SALESITEMS_REQUEST:
    case salesConstants.DELETE_SALESITEMS_REQUEST:
    case salesConstants.CREATE_SALESORDERS_REQUEST:
    case salesConstants.CREATE_SALES_REQUEST:
    case salesConstants.DELETE_SALES_REQUEST:
    case salesConstants.UPDATE_SALES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesConstants.DELETE_SALES_SUCCESS:
      return {
        ...state,
        type: action.item,
        loading: false,
      };
    case salesConstants.DELETE_SALESITEMS_SUCCESS:
      return {
        ...state,
        type: action.items,
        loading: false,
      };
    case salesConstants.CREATE_SALES_SUCCESS:
    case salesConstants.GET_SALES_SUCCESS:
    case salesConstants.UPDATE_SALES_SUCCESS:
      return {
        ...state,
        item: action.sales,
        loading: false,
      };
    case salesConstants.CREATE_SALESITEMS_SUCCESS:
    case salesConstants.GET_SALESITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
        loading: false,
      };
    case salesConstants.GETID_SALESITEMS_SUCCESS:
    case salesConstants.UPDATE_SALESITEMS_SUCCESS:
      return {
        ...state,
        saleItem: action.saleItem,
        loading: false,
      };
    case salesConstants.CREATE_SALESORDERS_SUCCESS:
    case salesConstants.GET_SALESORDERS_SUCCESS:
      return {
        ...state,
        ordersSale: action.ordersSale,
        loading: false,
      };
    case salesConstants.GET_SALESSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case salesConstants.GETALL_SALES_SUCCESS:
      return {
        ...state,
        ...action.sales,
        loading: false,
      };
    case salesConstants.GETALL_SALES_FAILURE:
    case salesConstants.GET_SALESITEMS_FAILURE:
    case salesConstants.GET_SALESORDERS_FAILURE:
    case salesConstants.GET_SALESSTATUS_FAILURE:
    case salesConstants.CREATE_SALESITEMS_FAILURE:
    case salesConstants.UPDATE_SALESITEMS_FAILURE:
    case salesConstants.DELETE_SALESITEMS_FAILURE:
    case salesConstants.CREATE_SALESORDERS_FAILURE:
    case salesConstants.CREATE_SALES_FAILURE:
    case salesConstants.DELETE_SALES_FAILURE:
    case salesConstants.UPDATE_SALES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
