import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { companyActions } from '../../actions/company.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';

class CompanyScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(companyActions.getAll()).then(() => {
      this.setState({
        refresh: true,
      });
    });
  }

  render() {
    const { companies, loading } = this.props;
    const { refresh } = this.state;

    if (!companies.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Enpresas</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button primary onClick={() => this.props.history.push('/companies/create')}>
              Novo
            </Button>
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            refresh={refresh}
            onClick={(item) => this.props.history.push(`companies/${item.id}/show`)}
            handlePaginationChange={(page) => {
              const { dispatch } = this.props;
              dispatch(companyActions.getAll(`page=${page}`));
            }}
            header={['Nome', 'Cnpj', 'Título']}
            search={{
              name: 'Nome',
              title: 'Título',
            }}
            columns={['name', 'cnpj', 'title']}
            data={companies.data}
            totalPages={companies.lastPage}
            page={companies.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { companies } = state;
  return {
    companies,
    loading: companies.loading || false,
  };
}

const connectedCompanyScreen = connect(mapStateToProps)(CompanyScreen);
export { connectedCompanyScreen as CompanyScreen };
