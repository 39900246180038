export const contractConstants = {
  GETALLCONTRACT_REQUEST: 'GETALLCONTRACT_REQUEST',
  GETALLCONTRACT_SUCCESS: 'GETALLCONTRACT_SUCCESS',
  GETALLCONTRACT_FAILURE: 'GETALLCONTRACT_FAILURE',

  GETMECONTRACT_REQUEST: 'GETMECONTRACT_REQUEST',
  GETMECONTRACT_SUCCESS: 'GETMECONTRACT_SUCCESS',
  GETMECONTRACT_FAILURE: 'GETMECONTRACT_FAILURE',

  GETTYPECONTRACT_REQUEST: 'GETTYPECONTRACT_REQUEST',
  GETTYPECONTRACT_SUCCESS: 'GETTYPECONTRACT_SUCCESS',
  GETTYPECONTRACT_FAILURE: 'GETTYPECONTRACT_FAILURE',

  GETCONTRACT_REQUEST: 'GETCONTRACT_REQUEST',
  GETCONTRACT_SUCCESS: 'GETCONTRACT_SUCCESS',
  GETCONTRACT_FAILURE: 'GETCONTRACT_FAILURE',

  CREATECONTRACT_REQUEST: 'CREATECONTRACT_REQUEST',
  CREATECONTRACT_SUCCESS: 'CREATECONTRACT_SUCCESS',
  CREATECONTRACT_FAILURE: 'CREATECONTRACT_FAILURE',

  CREATECONTRACTTYPE_REQUEST: 'CREATECONTRACTTYPE_REQUEST',
  CREATECONTRACTTYPE_SUCCESS: 'CREATECONTRACTTYPE_SUCCESS',
  CREATECONTRACTTYPE_FAILURE: 'CREATECONTRACT_FAILURE',

  UPDATECONTRACT_REQUEST: 'UPDATECONTRACT_REQUEST',
  UPDATECONTRACT_SUCCESS: 'UPDATECONTRACT_SUCCESS',
  UPDATECONTRACT_FAILURE: 'UPDATECONTRACT_FAILURE',

  UPDATECONTRACTTYPE_REQUEST: 'UPDATECONTRACTTYPE_REQUEST',
  UPDATECONTRACTTYPE_SUCCESS: 'UPDATECONTRACTTYPE_SUCCESS',
  UPDATECONTRACTTYPE_FAILURE: 'UPDATECONTRACTTYPE_FAILURE',

  DELETECONTRACTTYPE_REQUEST: 'DELETECONTRACTTYPE_REQUEST',
  DELETECONTRACTTYPE_SUCCESS: 'DELETECONTRACTTYPE_SUCCESS',
  DELETECONTRACTTYPE_FAILURE: 'DELETECONTRACTTYPE_FAILURE',

  DELETECONTRACT_REQUEST: 'DELETECONTRACT_REQUEST',
  DELETECONTRACT_SUCCESS: 'DELETECONTRACT_SUCCESS',
  DELETECONTRACT_FAILURE: 'DELETECONTRACT_FAILURE',
};
