import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import RichTextEditor from 'react-rte';

import { productsActions } from '../../actions/products.actions';
import { productsModulesActions } from '../../actions/products-modules.actions';

class ProductsModulesCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: null,
      name: '',
      description: RichTextEditor.createEmptyValue(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(productsActions.getAll());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { product_id, name, description } = this.state;
    const { dispatch } = this.props;
    if (product_id && name && description.getEditorState().getCurrentContent().hasText()) {
      dispatch(
        productsModulesActions.create(
          {
            product_id,
            name,
            description: description.toString('html'),
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { product_id, name, description, submitted } = this.state;
    const { products, loading } = this.props;
    let productsOptions = [];

    if (products && products.data !== undefined) {
      productsOptions = products.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Novo Módulo de Produto</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome</label>
            <input name="name" placeholder="Nome" value={name} onChange={this.handleChange} />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <RichTextEditor
              value={description}
              onChange={(value) => this.handleChange(true, { name: 'description', value })}
            />
            {submitted && !description.getEditorState().getCurrentContent().hasText() && (
              <Label basic color="red" pointing>
                Descrição é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Produto</label>
            <Dropdown
              placeholder="Selecione"
              name="product_id"
              value={product_id}
              fluid
              search
              selection
              onChange={(event, data) => this.handleChange(true, data)}
              options={productsOptions}
            />
            {submitted && !product_id && (
              <Label basic color="red" pointing>
                Produto é requerido
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit">
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { products } = state;
  return {
    products,
    loading: products.loading || false,
  };
}

const connectedProductsModulesCreateScreen = connect(mapStateToProps)(ProductsModulesCreateScreen);
export { connectedProductsModulesCreateScreen as ProductsModulesCreateScreen };
