import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import { expenseActions } from '../../actions/expense.actions';

class ExpenseTypeShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(expenseActions.getTypeById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(expenseActions.deleteTypeById(match.params.id)).then(() => {
      this.props.history.push('/expense-types');
    });
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/expense-type/${match.params.id}/edit`);
  }

  render() {
    const { type, loading } = this.props;
    const { open } = this.state;

    if (!type) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{type.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <div className="container-inner">
          <h4>Nome: </h4>
          {type.name}
          <h4>Status: </h4>
          {type.active ? 'Ativo' : 'Inativo'}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { expenses } = state;
  return {
    type: expenses.type || null,
    loading: expenses.loading || false,
  };
}

const connectedExpenseTypeShowScreen = connect(mapStateToProps)(ExpenseTypeShowScreen);
export { connectedExpenseTypeShowScreen as ExpenseTypeShowScreen };
