import { projectConstants } from '../constants/project.constants';
import projectService from '../services/project.service';
import { alertActions } from './alert.actions';

export const projectActions = {
  create,
  update,
  updateStatus,
  getAll,
  exportFile,
  getById,
  deleteById,
  getStatusProjects,
  getLogsProjects,
};

function create(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const project = await projectService.create(obj);

      dispatch(success(project));

      history.push('/projects');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.CREATEPROJECT_REQUEST };
  }
  function success(project) {
    return { type: projectConstants.CREATEPROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: projectConstants.CREATEPROJECT_FAILURE, error };
  }
}

function update(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const project = await projectService.update(id, obj);

      dispatch(success(project));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.UPDATEPROJECT_REQUEST };
  }
  function success(project) {
    return { type: projectConstants.UPDATEPROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: projectConstants.UPDATEPROJECT_FAILURE, error };
  }
}

function updateStatus(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const project = await projectService.updateStatus(id, obj);

      dispatch(success(project));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.UPDATEPROJECT_REQUEST };
  }
  function success(project) {
    return { type: projectConstants.UPDATEPROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: projectConstants.UPDATEPROJECT_FAILURE, error };
  }
}

function getAll(filter) {
  const filterParam = filter ? filter : false;
  return async (dispatch) => {
    dispatch(request());

    try {
      const projects = await projectService.getAll(filterParam);

      dispatch(success(projects));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.GETALLPROJECT_REQUEST };
  }
  function success(projects) {
    return { type: projectConstants.GETALLPROJECT_SUCCESS, projects };
  }
  function failure(error) {
    return { type: projectConstants.GETALLPROJECT_FAILURE, error };
  }
}

function exportFile(filter) {
  return async (dispatch) => {
    try {
      const projects = await projectService.getAll(filter);
      window.open(projects.url, '_blank');
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const project = await projectService.getById(id);

      dispatch(success(project));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(project) {
    return { type: projectConstants.GETPROJECT_REQUEST, project };
  }
  function success(project) {
    return { type: projectConstants.GETPROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: projectConstants.GETPROJECT_FAILURE, error };
  }
}

function getStatusProjects() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const status = await projectService.getStatusProjects();

      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.GETSTATUS_REQUEST };
  }
  function success(status) {
    return { type: projectConstants.GETSTATUS_SUCCESS, status };
  }
  function failure(error) {
    return { type: projectConstants.GETSTATUS_FAILURE, error };
  }
}

function getLogsProjects(id) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const logs = await projectService.getLogsProjects(id);

      dispatch(success(logs));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: projectConstants.GETLOGS_REQUEST };
  }
  function success(logs) {
    return { type: projectConstants.GETLOGS_SUCCESS, logs };
  }
  function failure(error) {
    return { type: projectConstants.GETLOGS_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const project = await projectService.deleteById(id);

      dispatch(success(project));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(project) {
    return { type: projectConstants.DELETEPROJECT_REQUEST, project };
  }
  function success(project) {
    return { type: projectConstants.DELETEPROJECT_SUCCESS, project };
  }
  function failure(error) {
    return { type: projectConstants.DELETEPROJECT_FAILURE, error };
  }
}
