import BaseService from './base.service';

class PdfTemplateService extends BaseService {
  get basePath() {
    return 'pdf-templates';
  }

  async create() {
    throw 'Not available';
  }

  async delete() {
    throw 'Not available';
  }
}

export default new PdfTemplateService();
