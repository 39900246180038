import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Confirm, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { providersActions } from '../../actions/providers.actions';

class ProvidersShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(providersActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(providersActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/providers'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/providers/${match.params.id}/edit`);
  }

  render() {
    const { providers, loading } = this.props;
    const { open } = this.state;

    if (!providers)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{providers.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <h4>Nome: </h4>
          {providers.name}
          <h4>Tipo: </h4>
          {providers.type && providers.type.name}
          <h4>CNPJ: </h4>
          {providers.cnpj}
          <h4>Inscrição Estadual: </h4>
          {providers.state_registration_number}
          <h4>
            Aceita Mapeamento: <br />{' '}
            <Checkbox toggle checked={providers.accept_mapping ? true : false} />
          </h4>
          <h4>
            Aceita Cotações: <br />{' '}
            <Checkbox toggle checked={providers.accept_quote ? true : false} />
          </h4>
          <h4>Endereço: </h4>
          {providers.address?.street
            ? `${providers.address?.street}${providers.address?.number ? ',' : ''} ${
                providers.address?.number
              } - ${providers.address?.complement} ${providers.address?.complement ? '-' : ''} ${
                providers.address?.neighborhood
              } ${providers.address?.neighborhood ? '-' : ''} ${providers.address?.city} - ${
                providers.address?.state
              }`
            : ''}
          <h4>Observações: </h4>
          {providers.notes}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { providers } = state;

  return {
    providers: providers.item,
    loading: providers.loading || false,
  };
}

const connectedProvidersShowScreen = connect(mapStateToProps)(ProvidersShowScreen);
export { connectedProvidersShowScreen as ProvidersShowScreen };
