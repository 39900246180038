import React, { Component } from 'react';
import { connect } from 'react-redux';
import { opportunityActions } from '../../actions/opportunity.actions';
import { providersActions } from '../../actions/providers.actions';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import CurrencyFormat from 'react-currency-format';
import { SearchBar } from '../SearchBar/SearchBar';

import { Button, Dropdown, Form, Label, Modal, Table } from 'semantic-ui-react';
import { InputCoins } from '../InputCoins/InputCoins';

class ModalCreateQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitItem = this.handleSubmitItem.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCurrency = this.handleCurrency.bind(this);
  }

  getInitialState() {
    return {
      provider_id: null,
      code: '',
      quote_id: null,
      name: '',
      product_id: null,
      description: null,
      type_id: '',
      part_number: '',
      item_amount: 0,
      tax_iss: null,
      ncm: null,
      tax_icms: null,
      tax_ipi: '15',
      tax_cofins: '9,25',
      amount: 0,
      item_currency: 'BRL',
      quantity: null,
      openCreateQuote: false,
      submitted: false,
      itensList: [],
      productId: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(providersActions.getAll());
    dispatch(opportunityActions.getQuoteItemType());
    dispatch(opportunityActions.getQuotesItens());
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
    if (e.target && e.target.name == 'quantity')
      this.handleTotal(e, { value: e.target.value, name: e.target.name });
  }

  handleProduct(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
    if (e.target.name == 'quantity')
      this.handleTotal(e, { value: e.target.value, name: e.target.name });
  }

  handleTotal(e, data) {
    this.setState({ [data.name]: data.value });

    const { quantity, item_amount } = this.state;
    if ((item_amount, quantity)) {
      const { quantity, item_amount } = this.state;
      const sum = data.name == 'item_amount' ? data.value * quantity : item_amount * data.value;
      this.setState({ amount: sum });
    }
  }

  deleteItem = (e, data) => {
    e.preventDefault();
    const index = this.state.itensList.indexOf(data);
    this.state.itensList.splice(index, 1);
    this.setState({ open: true });
  };

  handleCurrency(e) {
    const { value } = e.target;
    const formatValue = parseInt(value.replace(/[\D]+/g, ''));
    var tmp = formatValue + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

    const { name } = e.target;

    if (typeof tmp !== Number) this.setState({ [name]: 0 });

    if (tmp == 'NaN') {
      this.setState({ [name]: ' ' });
    } else {
      this.setState({ [name]: tmp });
    }
  }

  formatDecimal(value) {
    var retorno = value.replace('.', '');
    return parseFloat(retorno).toFixed(2);
  }

  async handleSubmitItem(e) {
    e.preventDefault();
    const { opportunity, dispatch } = this.props;
    const { provider_id, code } = this.state;
    let quotePayload = {
      provider_id,
      opportunity_id: opportunity.id,
      code,
    };
    await dispatch(opportunityActions.createQuotes(opportunity.id, quotePayload)).then(() => {
      const { quotes } = this.props;

      this.setState({
        quote_id: quotes.id,
      });
    });

    this.state.itensList.forEach((list) => {
      const {
        name,
        ncm,
        description,
        type_id,
        part_number,
        item_amount,
        tax_iss,
        tax_icms,
        tax_ipi,
        tax_cofins,
        amount,
        item_currency,
        quantity,
        provider_id,
        product_id,
      } = list;

      let payload = {
        quote_id: this.state.quote_id,
        name,
        product_id,
        description,
        type_id,
        part_number,
        item_amount: item_amount * 100,
        tax_iss: this.formatDecimal(tax_iss),
        tax_icms: this.formatDecimal(tax_icms),
        ncm,
        tax_ipi: this.formatDecimal(tax_ipi),
        tax_cofins: this.formatDecimal(tax_cofins),
        amount: amount * 100,
        item_currency,
        quantity: Number(quantity),
        provider_id,
      };
      dispatch(
        opportunityActions.createQuotesItens(opportunity.id, this.state.quote_id, payload),
      ).then(() => {
        this.setState({
          openCreateQuote: false,
          refresh: true,
          page: 1,
        });
        dispatch(opportunityActions.getQuotes(opportunity.id));
        dispatch(opportunityActions.getInteraction(opportunity.id));
        this.onClose();
      });
    });
  }

  async handleSubmit() {
    const {
      name,
      ncm,
      code,
      description,
      type_id,
      part_number,
      quote_id,
      item_amount,
      tax_iss,
      tax_icms,
      tax_ipi,
      tax_cofins,
      amount,
      item_currency,
      quantity,
      openCreateQuote,
      submitted,
      provider_id,
      product_id,
    } = this.state;
    if (
      code &&
      provider_id &&
      type_id &&
      description &&
      quantity &&
      item_amount &&
      tax_iss &&
      tax_icms &&
      tax_ipi &&
      tax_cofins
    ) {
      this.setState({
        itensList: this.state.itensList.concat({
          quote_id,
          name,
          ncm,
          description,
          type_id,
          part_number,
          item_amount,
          tax_iss,
          tax_icms,
          tax_ipi,
          tax_cofins,
          amount,
          item_currency,
          quantity,
          openCreateQuote,
          submitted,
          provider_id,
          product_id,
        }),
        quote_id: null,
        provider_id: '',
        name: '',
        product_id: null,
        description: null,
        type_id: '',
        part_number: '',
        item_amount: null,
        tax_iss: '',
        ncm: '',
        tax_icms: '',
        tax_ipi: '15',
        tax_cofins: '9,25',
        amount: 0,
        item_currency: 'BRL',
        quantity: '',
        submitted: false,
      });
    } else {
      this.setState({ submitted: true });
    }
  }

  onClose() {
    const initialState = {
      provider_id: null,
      code: null,
      name: '',
      product_id: null,
      description: null,
      type_id: '',
      item_amount: 0,
      tax_iss: null,
      ncm: null,
      tax_icms: null,
      amount: null,
      tax_ipi: '15',
      tax_cofins: '9,25',
      item_currency: 'BRL',
      quantity: null,
      openCreateQuote: false,
      submitted: false,
      itensList: [],
      quote_id: '',
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openCreateQuote &&
      (this.props.openCreateQuote !== prevProps.openCreateQuote || this.props.id != prevProps.id) &&
      !this.state.openCreateQuote
    ) {
      this.setState({ openCreateQuote: true });
    }
  }

  rowContent() {
    const { quotesItens } = this.props;

    if (!quotesItens?.data || !quotesItens?.data?.length) {
      return (
        <Table.Row key={0}>
          <Table.Cell colSpan="15" textAlign="center">
            Nenhum Resultado Encontrado
          </Table.Cell>
        </Table.Row>
      );
    }
  }

  render() {
    const {
      provider_id,
      code,
      ncm,
      description,
      type_id,
      part_number,
      item_amount,
      item_currency,
      tax_iss,
      tax_icms,
      tax_ipi,
      tax_cofins,
      amount,
      quantity,
      openCreateQuote,
      submitted,
    } = this.state;
    const { loading, providers, quotesItensType } = this.props;
    let providersOptions,
      quotesItensTypeOptions = [];

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (quotesItensType && quotesItensType.data !== undefined) {
      quotesItensTypeOptions = quotesItensType.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }
    return (
      <Modal
        style={{ width: 1190 }}
        open={openCreateQuote}
        closeIcon
        onClose={() => this.onClose()}>
        <Modal.Header>Cadastro de cotação</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading} style={{ marginBottom: '20px' }}>
            <Form.Group>
              <Form.Field>
                <label>Código</label>
                <input
                  name="code"
                  placeholder="Código"
                  value={code}
                  type="text"
                  onChange={this.handleChange}
                />
                {submitted && !code && (
                  <Label basic color="red" pointing>
                    Código é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <b>Item</b>
            <hr />
            <Form.Group>
              <Form.Field width={4}>
                <label>Fornecedor</label>
                <Dropdown
                  placeholder="Fornecedor"
                  fluid
                  search
                  selection
                  name="provider_id"
                  options={providersOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={provider_id}
                />
                {submitted && !provider_id && (
                  <Label basic color="red" pointing>
                    Fornecedor é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Tipo</label>
                <Dropdown
                  placeholder="Tipo"
                  fluid
                  search
                  selection
                  name="type_id"
                  options={quotesItensTypeOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={type_id}
                />
                {submitted && !type_id && (
                  <Label basic color="red" pointing>
                    Tipo é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={5}>
                <label>Produto</label>
                <SearchBar
                  onSelect={(item) =>
                    this.setState({
                      part_number: item.part_number,
                      description: item.name,
                      ncm: item.ncm,
                      product_id: item.id,
                    })
                  }
                />
              </Form.Field>
              <Form.Field width={5}>
                <label>Part Number</label>
                <input
                  name="part_number"
                  placeholder="Part Number"
                  value={part_number}
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field width={5}>
                <label>Descrição</label>
                <input
                  name="description"
                  placeholder="Descrição"
                  value={description}
                  type="text"
                  onChange={this.handleChange}
                />
                {submitted && !description && (
                  <Label basic color="red" pointing>
                    Descrição é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={5}>
                <label>NCM</label>
                <input
                  name="ncm"
                  placeholder="Ncm"
                  value={ncm}
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={3}>
                <label>Qtde</label>
                <input
                  name="quantity"
                  placeholder="Qtde"
                  type="number"
                  value={quantity}
                  onChange={this.handleChange}
                />
                {submitted && !quantity && (
                  <Label basic color="red" pointing>
                    Quantidade é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={5}>
                <label>Valor Unitário</label>
                <InputCoins
                  name={'item_amount'}
                  amount={item_amount}
                  currency={item_currency}
                  onChange={(event, data) => this.handleTotal(event, data)}
                />
                {submitted && !item_amount && (
                  <Label basic color="red" pointing>
                    Valor é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={3}>
                <label>ISS </label>
                <input
                  name="tax_iss"
                  placeholder="ISS"
                  value={tax_iss}
                  onChange={this.handleCurrency}
                />
                {submitted && !tax_iss && (
                  <Label basic color="red" pointing>
                    ISS é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={3}>
                <label>ICMS </label>
                <input
                  name="tax_icms"
                  value={tax_icms}
                  placeholder="ICMS"
                  onChange={this.handleCurrency}
                />
                {submitted && !tax_icms && (
                  <Label basic color="red" pointing>
                    ICMS é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={3}>
                <label>IPI </label>
                <input
                  name="tax_ipi"
                  placeholder="IPI"
                  value={tax_ipi}
                  onChange={this.handleCurrency}
                />
                {submitted && !tax_ipi && (
                  <Label basic color="red" pointing>
                    IPI é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={3}>
                <label>COFINS </label>
                <input
                  name="tax_cofins"
                  placeholder="COFINS"
                  value={tax_cofins}
                  onChange={this.handleCurrency}
                />
                {submitted && !tax_cofins && (
                  <Label basic color="red" pointing>
                    COFINS é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={5}>
                <label>Valor Total</label>
                <InputCoins amount={amount} currency={item_currency} onChange={this.handleChange} />
              </Form.Field>
              <Button
                style={{ float: 'right', height: 48, marginTop: 17 }}
                className="secondary"
                type="submit">
                Add item
              </Button>
            </Form.Group>
          </Form>

          <Form onSubmit={this.handleSubmitItem} loading={loading} style={{ marginBottom: '20px' }}>
            <DynamicTable
              header={[
                'Part.Num',
                'Descrição',
                'NCM',
                'Qtde.',
                'Tipo',
                'Vlr.Unit.',
                'ISS',
                'ICMS',
                'IPI',
                'COFINS',
                'Vlr.Total',
                '',
              ]}
              columns={[
                'part_number',
                'description',
                'ncm',
                {
                  name: 'quantity',
                  format: (data) => (
                    <div style={{ textAlign: 'center', fontSize: 13 }}>{data.quantity}</div>
                  ),
                },
                {
                  name: 'type_id',
                  format: (data) => (
                    <>
                      {data.type_id == 4
                        ? 'Prestação'
                        : data.type_id == 3
                        ? 'Prestação'
                        : data.type_id == 2
                        ? 'Serviço'
                        : data.type_id == 1
                        ? 'Produto'
                        : ' '}
                    </>
                  ),
                },
                {
                  name: 'item_amount',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.item_amount || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  ),
                },
                {
                  name: 'tax_iss',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_iss || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_icms',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_icms || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_ipi',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.tax_ipi || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'tax_cofins',
                  format: (data) => (
                    <CurrencyFormat
                      displayType={'text'}
                      style={{ textAlign: 'center', fontSize: 13 }}
                      value={data.tax_cofins || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={' %'}
                    />
                  ),
                },
                {
                  name: 'amount',
                  format: (data) => (
                    <CurrencyFormat
                      style={{ textAlign: 'center', fontSize: 13 }}
                      displayType={'text'}
                      value={data.amount || '-'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  ),
                },
                {
                  name: 'Delete',
                  format: (data) => (
                    <>
                      <Button
                        icon="trash alternate outline"
                        onClick={(e) => this.deleteItem(e, data)}
                      />
                    </>
                  ),
                },
              ]}
              data={this.state.itensList}
              totalPages={1}
              page={1}
            />
            <Button
              className="secondary"
              type="submit"
              disabled={
                !code && !provider_id && !type_id && !description && !quantity && !item_amount
              }>
              Criar item da cotação
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { opportunity, products, providers } = state;
  return {
    opportunity: opportunity.item,
    quotes: opportunity.quotes,
    quotesItens: opportunity.quotesItens,
    quotesItensType: opportunity.quotesItensType,
    products,
    providers,
    loading: opportunity.loading || false,
  };
}

const connectedModalCreateQuotes = connect(mapStateToProps)(ModalCreateQuotes);
export { connectedModalCreateQuotes as ModalCreateQuotes };
