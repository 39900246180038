import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Grid, Loader } from 'semantic-ui-react';
import { clientActions } from '../../actions/client.actions';
import './styles.css';

class ClientShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      name: '',
      submitted: false,
    };
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(clientActions.getById(match.params.id));
  }

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(clientActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/clients'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/clients/${match.params.id}/edit`);
  }

  render() {
    const { clients, loading } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Loader active={loading} />
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>{clients.name}</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button icon="edit outline" title="Editar" onClick={() => this.editById()} />
            <Button icon="trash alternate outline" title="Remover" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <div className="container-inner">
          <h4>Nome: </h4>
          {clients.name}
          <h4>Nome Fantasia: </h4>
          {clients.trading_name}
          <h4>CNPJ: </h4>
          {clients.cnpj}
          <h4>Inscrição Estadual: </h4>
          {clients.state_registration_number}
          <h4>Inscrição Municipal: </h4>
          {clients.city_registration_number}
          <h4>Dominios E-Mail: </h4>
          {clients.email_domains}
          <h4>Endereço: </h4>
          {clients.address
            ? `${clients.address?.street ? clients.address?.street + ',' : ''} ${
                clients.address?.number ? clients.address?.number + ',' : ''
              } ${clients.address?.complement ? clients.address?.complement + '-' : ''} ${
                clients.address?.neighborhood ? clients.address?.neighborhood + '-' : ''
              } ${clients.address?.city ? clients.address?.city + ' -' : ''} ${
                clients.address?.state
              }`
            : ''}
          <h4>Situação: </h4>
          {clients.active ? 'Ativo' : 'Inativo'}
          <h4>Observações: </h4>
          {clients.notes}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  return {
    clients,
    loading: clients.loading || false,
  };
}

const connectedClientShowScreen = connect(mapStateToProps)(ClientShowScreen);
export { connectedClientShowScreen as ClientShowScreen };
