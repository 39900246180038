import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  Confirm,
  Dimmer,
  Grid,
  GridRow,
  GridColumn,
  Loader,
} from 'semantic-ui-react';
import moment from 'moment';

import { ModalLicense } from '../../components/Modals/ModalLicense';
import { licensesActions } from '../../actions/licenses.actions';
import './styles.css';

class LicensesShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openModalLicense: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(licensesActions.getById(match.params.id));
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(licensesActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/licenses'),
    );
  }

  render() {
    const { licenses, loading } = this.props;
    const { open, openModalLicense } = this.state;

    if (!licenses)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <ModalLicense
          openModalLicense={openModalLicense}
          license_id={this.props.match.params.id}
          onClose={() => this.setState({ openModalLicense: false })}
        />

        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <h1>Detalhes da Licença</h1>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
            <Button
              icon="file code outline"
              onClick={() => this.setState({ openModalLicense: true })}
            />
            <Button icon="trash alternate outline" onClick={this.deleteShow} />
          </Grid.Column>
        </Grid>
        <Loader active={loading} />
        <div className="container-inner">
          <Card fluid>
            <Card.Content>
              <Grid columns={1}>
                <GridRow>
                  <GridColumn>
                    <GridRow>
                      <h4 className="field-title">Produto:</h4>
                      <span>{licenses.product ? licenses.product.name : '-'}</span>
                    </GridRow>
                    <GridRow>
                      <h4 className="field-title">Módulo:</h4>
                      <span>{licenses.module ? licenses.module.name : '-'}</span>
                    </GridRow>
                    <GridRow>
                      <h4 className="field-title">Cliente:</h4>
                      <span>{licenses.client ? licenses.client.name : '-'}</span>
                    </GridRow>
                    <GridRow>
                      <h4 className="field-title">Expiração:</h4>
                      <span>
                        {licenses.expires_at
                          ? moment(licenses.expires_at).format('DD/MM/YYYY', { trim: false })
                          : 'Perpétua'}
                      </span>
                    </GridRow>
                    <GridRow>
                      <h4 className="field-title">Qtd. Itens:</h4>
                      <span>{licenses.max_itens || '-'}</span>
                    </GridRow>
                    <GridRow>
                      <h4>Comentários:</h4>
                    </GridRow>
                    <GridRow>{licenses.comments || ''}</GridRow>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Card.Content>
          </Card>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { licenses } = state;
  return {
    licenses: licenses.item,
    loading: licenses.loading || false,
  };
}

const connectedLicensesShowScreen = connect(mapStateToProps)(LicensesShowScreen);
export { connectedLicensesShowScreen as LicensesShowScreen };
