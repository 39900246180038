import BaseService from './base.service';
import client from '../helpers/HttpClient';

class ProviderService extends BaseService {
  get basePath() {
    return 'providers';
  }

  async getTypes(filter) {
    try {
      let endpoint = 'provider-types';
      if (filter) {
        endpoint = `${endpoint}?${filter}`;
      }
      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      return Promise.reject(this.handleError(error));
    }
  }
}

export default new ProviderService();
