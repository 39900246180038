import client from '../helpers/HttpClient';

class opportunityService {
  async update(id, obj) {
    try {
      const result = await client.put(`opportunities/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateStatus(id, obj) {
    try {
      const result = await client.put(`opportunities/${id}/status`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateItens(opportunity_id, id, payload) {
    try {
      const result = await client.put(`opportunity/${opportunity_id}/itens/${id}`, payload);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatus(filter) {
    try {
      const result = await client.get(`opportunity/status?${filter ? filter : ''}`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatusBoard() {
    try {
      const result = await client.get(`opportunity/board`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateStatusChange(id, obj) {
    try {
      const result = await client.patch(`opportunity/${id}/status`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getQuotes(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/quotes`, { params });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getQuotesItens(opportunity_id, id, params) {
    try {
      const result = await client.get(`opportunity/${opportunity_id}/quote/${id}/items`, {
        params,
      });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async updateQuotesItens(opportunity_id, quote_id, id, params) {
    try {
      const result = await client.put(
        `opportunity/${opportunity_id}/quote/${quote_id}/items/${id}`,
        params,
      );
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteQuotesItens(opportunity_id, quote_id, id, params) {
    try {
      const result = await client.delete(
        `opportunity/${opportunity_id}/quote/${quote_id}/items/${id}`,
        { params },
      );
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getParticipants(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/participants`, {
        params,
      });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getStatusHistory(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/history/status`, {
        params,
      });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getParticipantsUser() {
    try {
      const result = await client.get(`users?client=null`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getParticipantsType() {
    try {
      const result = await client.get(`opportunity/participant/types`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
  async getQuoteItemType() {
    try {
      const result = await client.get(`opportunity/quote/item/types`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getInteraction(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/interactions`, {
        params,
      });
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getItens(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/itens`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getMappings(id, params) {
    try {
      const result = await client.get(`opportunity/${id}/mappings`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getAll(params) {
    try {
      let endpoint = 'opportunities';
      const result = await client.get(`${endpoint}?${params ? params : ''}`);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getByIdItens(opportunity_id, id, params) {
    try {
      const result = await client.get(`opportunity/${opportunity_id}/itens/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getByIdItensQuote(opportunity_id, quote_id, id, params) {
    try {
      const result = await client.get(
        `opportunity/${opportunity_id}/quote/${quote_id}/items/${id}`,
        { params },
      );

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async getById(id, params) {
    try {
      const result = await client.get(`opportunities/${id}`, { params });

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async create(obj) {
    try {
      const result = await client.post('opportunities', obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createInteraction(id, obj) {
    try {
      const result = await client.post(`opportunity/${id}/interactions`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createItens(id, obj) {
    try {
      const result = await client.post(`opportunity/${id}/itens`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createMappings(id, obj) {
    try {
      const result = await client.post(`opportunity/${id}/mappings`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createParticipants(id, obj) {
    try {
      const result = await client.post(`opportunity/${id}/participants`, obj);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createQuotesItens(opportunity_id, quote_id, obj) {
    try {
      const result = await client.post(
        `opportunity/${opportunity_id}/quote/${quote_id}/items`,
        obj,
      );
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async createQuotes(opportunity_id, obj) {
    try {
      const result = await client.post(`opportunity/${opportunity_id}/quotes`, obj);
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`opportunities/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteParticipants(opportunity_id, id) {
    try {
      const result = await client.delete(`opportunity/${opportunity_id}/participants/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async uploadAttachment(opportunity_id, id, data) {
    try {
      const result = await client.post(
        `opportunity/${opportunity_id}/mappings/${id}/attach`,
        data,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteMappings(opportunity_id, id) {
    try {
      const result = await client.delete(`opportunity/${opportunity_id}/mappings/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteQuotes(opportunity_id, id) {
    try {
      const result = await client.delete(`opportunity/${opportunity_id}/quotes/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }

  async deleteItens(opportunity_id, id) {
    try {
      const result = await client.delete(`opportunity/${opportunity_id}/itens/${id}`);

      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new opportunityService();
