import BaseService from './base.service';
import client from '../helpers/HttpClient';

class ContractService extends BaseService {
  get basePath() {
    return 'contracts';
  }

  async getTypes() {
    try {
      const result = await client.get('contract/types');
      return result.data;
    } catch (error) {
      const returnMessage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMessage);
    }
  }
}

export default new ContractService();
