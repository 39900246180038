import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Modal,
  Popup,
  Table,
  TextArea,
} from 'semantic-ui-react';
import { projectActions } from '../../actions/project.actions';
import moment from 'moment';
import _ from 'lodash';
import './styles.css';
import square from '../../assets/imgs/square-image.png';

class ProjectShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openModal: false,
      submitted: false,
      status_id: null,
      comment: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(projectActions.getById(match.params.id));
    dispatch(projectActions.getStatusProjects());
    dispatch(projectActions.getLogsProjects(match.params.id));
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { status_id, comment } = this.state;
    const { dispatch, match } = this.props;
    if (status_id && comment) {
      let payload = {
        comment,
        status_id,
      };

      dispatch(projectActions.updateStatus(match.params.id, payload)).then(() => {
        dispatch(projectActions.getById(match.params.id));
        this.setState({ isOpen: false, status_id: null, comment: null });
      });
    }
  }

  listTeams(item) {
    if (!item.teams) {
      return null;
    }

    const content = (
      <>
        <h4>Time: </h4>
        <List>
          {_.map(item.teams, (item) => (
            <List.Item>
              <Image src={square} />
              <List.Content>
                <List.Header as="a">{item.name}</List.Header>
                <List.Description>{item.description}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </>
    );

    return content;
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(projectActions.deleteById(match.params.id)).then(() =>
      this.props.history.push('/projects'),
    );
  }

  editById() {
    const { match } = this.props;

    this.props.history.push(`/projects/${match.params.id}/edit`);
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { projects, status, logs, loading } = this.props;
    const { open, status_id, comment, openModal, submitted } = this.state;
    let statusOptions = [];

    if (!projects || !status || !logs || loading) {
      return <Loader active={loading} />;
    }

    if (status !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Confirm
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Modal size={'large'} open={openModal} onClose={() => this.setState({ openModal: false })}>
          <Modal.Header>Histórico de Alteração de Status</Modal.Header>
          <Modal.Content>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Motivo</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Usuário</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Data</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(logs.data, (item) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{item.comment}</Table.Cell>
                      <Table.Cell textAlign="center">{item.user}</Table.Cell>
                      <Table.Cell textAlign="center">{item.status}</Table.Cell>
                      <Table.Cell width={3}>
                        {moment(item.start_timestamp).format('DD/MM/YYYY HH:mm:ss')}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>

        <Loader active={loading} />
        <Grid className="header-page">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <h1>{projects.name}</h1>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6} textAlign="right">
              <Button icon="edit outline" onClick={() => this.editById()} />
              <Button icon="trash alternate outline" onClick={this.deleteShow} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="container-inner">
          <h4>Código: </h4>
          {projects.code || '-'} - criado em{' '}
          {moment(projects.created_at).format('DD/MM/YYYY HH:mm')}
          <h4>Nome: </h4>
          {projects.name}
          <h4>Cliente: </h4>
          {projects.client?.name}
          <h4>Responsável: </h4>
          {projects.owner?.name}
          <h4>Status: </h4>
          {projects.status?.name}
          <p>
            <Popup
              wide
              trigger={
                <Button primary size="small">
                  Alterar Estado
                </Button>
              }
              on="click"
              open={this.state.isOpen}
              onClose={this.handleClose}
              onOpen={this.handleOpen}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Field>
                  <label>Status</label>
                  <Dropdown
                    placeholder="Status do Projeto"
                    name="status_id"
                    value={status_id}
                    fluid
                    search
                    selection
                    onChange={(event, data) => this.handleChange(true, data)}
                    options={statusOptions}
                  />
                  {submitted && !status_id && (
                    <Label basic color="red" pointing>
                      Status é requerido
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <TextArea
                    name="comment"
                    onChange={(e) => this.handleChange(e)}
                    placeholder="Motivo"
                    value={comment}
                  />
                </Form.Field>
                <Button primary fluid type="submit" disabled={!comment ? true : false}>
                  Atualizar
                </Button>
              </Form>
            </Popup>
            <Button onClick={() => this.setState({ openModal: true })} icon>
              <Icon name="this.props.history" />
            </Button>
          </p>
          {this.listTeams(projects)}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, users } = state;

  return {
    projects: projects.item,
    loading: projects.loading || false,
    status: projects.status,
    logs: projects.logs,
    me: users.me || null,
  };
}

const connectedProjectShowScreen = connect(mapStateToProps)(ProjectShowScreen);
export { connectedProjectShowScreen as ProjectShowScreen };
