import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Header, Icon, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { ticketActions } from '../../actions/ticket.actions';
import { clientActions } from '../../actions/client.actions';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import { teamActions } from '../../actions/team.actions';
import { alertActions } from '../../actions/alert.actions';
import CurrencyFormat from 'react-currency-format';

const DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_FORMAT = 'YYYY-MM-DD';

class TicketReportScreen extends Component {
  STORAGE_KEY = 'TicketReportScreenState';

  constructor(props) {
    super(props);

    this.state = {
      period: '',
      filter: {
        client_id: null,
        search_value: null,
        search_per: null,
        status_id: null,
        user_id: null,
        timestamp_start: null,
        timestamp_end: null,
      },
      refresh: false,
      page: 1,
      sort: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    const { filter } = this.state;
    filter[data.name] = data.value || null;

    this.setState({ ...filter }, () => this.getData());
  }

  handlePeriodChange(period) {
    this.setState({ period }, () => this.getData());
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    dispatch(clientActions.getAll());
    dispatch(ticketActions.getStatusTickets());
    dispatch(teamActions.getAll());
    dispatch(userActions.getCompanyUsers());

    let start = moment();
    let end = moment();
    if (moment().date() < 15) {
      start = start.subtract(1, 'month');
      end = end.subtract(1, 'month');
    }

    // Load previous filter from storage, if any
    const state = JSON.parse(await localStorage.getItem(this.STORAGE_KEY));

    if (state) {
      if (state.filter.timestamp_start) {
        start = moment(state.filter.timestamp_start).format(DATE_FORMAT);
        end = moment(state.filter.timestamp_end).format(DATE_FORMAT);
      } else {
        start = start.startOf('month').format(DATE_FORMAT);
        end = end.endOf('month').format(DATE_FORMAT);
      }

      this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

      this.setState(state, () => this.getData());
      dispatch(
        userActions.getAll({
          client: state.filter ? state.filter.client_id : null,
        }),
      );
    } else {
      start = start.startOf('month').format(DATE_FORMAT);
      end = end.endOf('month').format(DATE_FORMAT);
      this.setState({ period: `${start} - ${end}` }, () => this.persistStateToStorage());

      this.getData();
      dispatch(userActions.getAll());
    }
  }

  persistStateToStorage() {
    const { filter } = this.state;
    const state = { filter };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
  }

  getData() {
    const { dispatch } = this.props;

    dispatch(ticketActions.getReport(this.filterParams())).then(() => {
      this.setState({ refresh: true });
      this.persistStateToStorage();
    });
  }

  filterParams() {
    const { filter, period, page, sort } = this.state;

    if (period) {
      var [startDate, endDate] = period.split(' - ');
      if (startDate) {
        filter.timestamp_start = `${moment(startDate, DATE_FORMAT).format(FILTER_FORMAT)} 00:00:00`;
      } else {
        filter.timestamp_start = null;
      }

      if (endDate) {
        filter.timestamp_end = `${moment(endDate, DATE_FORMAT).format(FILTER_FORMAT)} 23:59:59`;
      } else {
        filter.timestamp_end = null;
      }
    } else {
      filter.timestamp_start = null;
      filter.timestamp_end = null;
    }

    const params = filter;

    delete params.export;
    params.page = page;
    params.sort = sort;

    return params;
  }

  downloadFile() {
    const { dispatch } = this.props;
    dispatch(ticketActions.exportFile(this.filterParams()));
  }

  downloadPDFFile() {
    const { dispatch } = this.props;
    const params = this.filterParams();
    if (!params['tickets.client_id'] || !params.timestamp_start || !params.timestamp_end) {
      dispatch(
        alertActions.error('Para exportar o PDF é necessário selecionar o período e o cliente.'),
      );
      return;
    }
    dispatch(
      ticketActions.exportPDF({
        client_id: params['tickets.client_id'],
        start_date: params.timestamp_start.split(' ')[0],
        end_date: params.timestamp_end.split(' ')[0],
      }),
    );
  }

  render() {
    const { tickets, clients, users, companyUsers, teams, status, loading } = this.props;
    const { period, filter, refresh } = this.state;
    let clientOptions,
      userOptions,
      statusOptions,
      teamOptions,
      companyUserOptions = [];

    if (clients && clients.data !== undefined) {
      clientOptions = clients.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (users && users.data !== undefined) {
      userOptions = users.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (companyUsers && companyUsers.data !== undefined) {
      companyUserOptions = companyUsers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (teams && teams.data !== undefined) {
      teamOptions = teams.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });

      statusOptions.push({
        key: Math.random(),
        value: '-1',
        text: 'Não Fechados',
      });
    }

    if (!tickets.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Header as="h1">Tickets - Relatório</Header>
        <Form>
          <Form.Group>
            <DatesRangeInput
              name="period"
              localization="pt-br"
              placeholder="Período"
              value={period}
              iconPosition="left"
              dateFormat={DATE_FORMAT}
              label="Período"
              closable={true}
              width={4}
              clearable
              onChange={(e, data) => this.handlePeriodChange(data.value)}
            />

            <Form.Select
              name="tickets.client_id"
              label="Cliente"
              clearable
              search
              selection
              value={filter['tickets.client_id']}
              width={4}
              options={clientOptions}
              onChange={(e, data) => {
                const { dispatch } = this.props;
                dispatch(
                  userActions.getAll({
                    client: data.value === '' ? null : data.value,
                  }),
                );
                this.handleChange(data);
              }}
            />

            <Form.Select
              name="tickets.user_id"
              label="Solicitante"
              clearable
              search
              selection
              value={filter['tickets.user_id']}
              width={4}
              options={userOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="tickets.status_id"
              label="Status"
              clearable
              search
              selection
              width={4}
              value={filter['tickets.status_id']}
              options={statusOptions}
              onChange={(e, data) => this.handleChange(data)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              name="tickets.team_id"
              label="Time"
              clearable
              search
              selection
              width={4}
              value={filter['tickets.team_id']}
              options={teamOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Select
              name="tickets.assignee_id"
              label="Executor"
              clearable
              search
              selection
              value={filter['tickets.assignee_id']}
              width={4}
              options={companyUserOptions}
              onChange={(e, data) => this.handleChange(data)}
            />

            <Form.Button onClick={() => this.downloadPDFFile()} label="&nbsp;">
              <Icon name="file pdf" />
              Exportar PDF
            </Form.Button>

            <Form.Button onClick={() => this.downloadFile()} label="&nbsp;">
              <Icon name="file excel" />
              Exportar XLSX
            </Form.Button>
          </Form.Group>
        </Form>
        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`tickets/${item.id}/show`)}
            handleSort={(column) => {
              if (this.state.sorted === 'asc') {
                this.setState({ sort: `${column}:desc`, sorted: 'desc', page: 1 }, () => {
                  this.getData();
                });
              } else {
                this.setState({ sort: `${column}:asc`, sorted: 'asc', page: 1 }, () => {
                  this.getData();
                });
              }
            }}
            refresh={refresh}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => {
                this.getData();
              });
            }}
            header={[
              '#',
              'Titulo',
              'Solicitante',
              'Cliente',
              'Times',
              'Executores',
              'Status',
              'Despesas',
              'Criado em',
              'Horário Comercial',
              'Horário Não Comercial',
              'Total',
            ]}
            columns={[
              'id',
              'title',
              'user',
              'client',
              'teams',
              'executors',
              'status',
              {
                name: 'expenses',
                format: (item) => {
                  return (
                    <CurrencyFormat
                      value={item.expenses}
                      displayType={'text'}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$ '}
                    />
                  );
                },
              },
              {
                name: 'created_at',
                format: (item) => moment(item.created_at).format('DD/MM/YYYY', { trim: false }),
              },
              {
                name: 'time_commercial',
                format: (item) =>
                  moment
                    .duration(item.time_commercial, 'second')
                    .format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_extra',
                format: (item) =>
                  moment.duration(item.time_extra, 'second').format('HH:mm:ss', { trim: false }),
              },
              {
                name: 'time_total',
                format: (item) =>
                  moment.duration(item.time_total, 'second').format('HH:mm:ss', { trim: false }),
              },
            ]}
            data={tickets.data}
            totalPages={tickets.lastPage}
            page={tickets.page}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets, clients, users, teams } = state;
  return {
    clients,
    users,
    companyUsers: users.companyUsers,
    teams,
    status: tickets.status,
    tickets,
    loading: tickets.loading || false,
  };
}

const connectedTicketReportScreen = connect(mapStateToProps)(TicketReportScreen);
export { connectedTicketReportScreen as TicketReportScreen };
