import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Label, Modal } from 'semantic-ui-react';
import { ordersActions } from '../../actions/orders.actions';
import CurrencyFormat from 'react-currency-format';
import { DateInput } from 'semantic-ui-calendar-react';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { providersActions } from '../../actions/providers.actions';
import { companyActions } from '../../actions/company.actions';
import { salesActions } from '../../actions/sales.actions';
import moment from 'moment';
import { currencyLabelHelper } from '../../helpers/currency-label';

class ModalOrdersSales extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleItem = this.handleItem.bind(this);
  }

  getInitialState() {
    return {
      name: '',
      provider_id: null,
      due_date: null,
      nameItem: '',
      check: false,
      unit_price: 0,
      currency: 'BRL',
      quantity: 1,
      amount: 25,
      itensList: [],
      service_order_id: null,
      openOrders: false,
      invoice: '',
      submitted: false,
    };
  }

  componentDidMount() {
    const { dispatch, sales } = this.props;

    dispatch(ordersActions.getAll());
    dispatch(ordersActions.getUser());
    dispatch(providersActions.getAll());
    dispatch(ordersActions.getPayment());
    dispatch(companyActions.getAll());
    dispatch(salesActions.getItems(sales.id));
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value === '' ? null : data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { sales, dispatch } = this.props;
    const { name, provider_id, due_date, type, submitted } = this.state;

    if (name && type && provider_id && due_date && this.state.itensList.length) {
      let payload = {
        name,
        type,
        user_id: sales.author_id,
        provider_id,
        due_date: moment(due_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        sale_id: sales.id,
        description: sales.description,
      };

      await dispatch(salesActions.createOrders(sales.id, payload)).then(() => {
        const { ordersSale } = this.props;
        this.setState({
          service_order_id: ordersSale.id,
        });
      });

      this.state.itensList.forEach(async (list) => {
        const {
          id,
          type,
          nameItem,
          quantity,
          unit_price,
          amount,
          currency,
          unit_cost_price,
          amount_cost_price,
          margin_liquid,
          part_number,
        } = list;

        let payloadItem = {
          service_order_id: this.state.service_order_id,
          quantity,
          amount,
          unit_price,
          currency,
          margin_liquid,
          unit_cost_price,
          amount_cost_price,
          name: nameItem,
          type,
          part_number,
        };

        await dispatch(ordersActions.createItems(this.state.service_order_id, payloadItem)).then(
          async () => {
            const { orders } = this.props;
            const created = orders?.items;
            const payloadSale = {
              service_order_item_id: created.id,
            };
            await dispatch(salesActions.updateItems(sales.id, id, payloadSale));
            dispatch(salesActions.getOrders(sales.id));
            dispatch(salesActions.getItems(sales.id));
          },
        );
      });
      this.setState({
        refresh: true,
        page: 1,
      });
      this.onClose();
    }
  }

  async handleItem(itemsId, data, checked) {
    if (checked) {
      this.setState({
        itensList: this.state.itensList.concat({
          id: data.id,
          nameItem: data.name,
          quantity: data.quantity,
          unit_price: data.unit_price,
          amount: data.amount,
          currency: data.currency,
          unit_cost_price: data.unit_cost_price,
          amount_cost_price: data.amount_cost_price,
          type: data.type,
          margin_liquid: data.margin_liquid,
          part_number: data.part_number,
        }),
      });
    } else {
      const itemListClean = this.state.itensList.filter((item) => item.id != itemsId);
      this.setState({
        itensList: itemListClean,
      });
    }
  }

  onClose() {
    const initialState = {
      name: '',
      type: '',
      provider_id: null,
      nameItem: '',
      due_date: null,
      unit_price: 0,
      currency: 'BRL',
      quantity: 1,
      amount: 25,
      itensList: [],
      service_order_id: null,
      openOrders: false,
      invoice: '',
      submitted: false,
    };
    this.setState({
      ...initialState,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openOrders &&
      (this.props.openOrders !== prevProps.openOrders || this.props.id != prevProps.id) &&
      !this.state.openOrders
    ) {
      this.setState({ openOrders: true });
    }
  }

  render() {
    const { name, provider_id, openOrders, due_date, type, submitted } = this.state;
    const { loading, providers, items } = this.props;
    let itemOptions,
      providersOptions = [];

    const typeOptions = [
      { key: 'I', value: 'I', text: 'Interno' },
      { key: 'E', value: 'E', text: 'Externo' },
    ];

    if (items && items.length) {
      itemOptions = items.filter((item) => item.service_order_item_id === null);
    }

    if (providers && providers.data !== undefined) {
      providersOptions = providers.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Modal size="large" open={openOrders} closeIcon onClose={() => this.onClose()}>
        <Modal.Header>Criar Pedido</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} loading={loading}>
            <Form.Group>
              <Form.Field width={8}>
                <label>Tipo</label>
                <Dropdown
                  placeholder="Tipo"
                  fluid
                  search
                  selection
                  name="type"
                  options={typeOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={type}
                />
                {submitted && !type && (
                  <Label basic color="red" pointing>
                    Tipo é requerido
                  </Label>
                )}
              </Form.Field>
              <Form.Field width={8}>
                <label>Código</label>
                <input name="name" onChange={this.handleChange} placeholder="Código" value={name} />
                {submitted && !name && (
                  <Label basic color="red" pointing>
                    Código é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={8}>
                <label>Fornecedor </label>
                <Dropdown
                  placeholder="Fornecedor "
                  fluid
                  search
                  selection
                  name="provider_id"
                  options={providersOptions}
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={provider_id}
                />
                {submitted && !provider_id && (
                  <Label basic color="red" pointing>
                    Fornecedor é requerido
                  </Label>
                )}
              </Form.Field>

              <Form.Field width={8}>
                <label>Data de Fechamento </label>
                <DateInput
                  closable
                  clearable
                  name="due_date"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Data de fechamento"
                  onChange={(event, data) => this.handleChange(true, data)}
                  value={due_date}
                  iconPosition="left"
                />
                {submitted && !due_date && (
                  <Label basic color="red" pointing>
                    Data de Fechamento é requerido
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <b style={{ fontSize: 15 }}>Item</b>
            <div className="list-content" style={{ marginTop: '8px' }}>
              <DynamicTable
                header={[
                  '',
                  'Nome',
                  'Part Number',
                  'Quantidade',
                  'Custo Unit',
                  'Custo Total',
                  ' Vlr.Unit',
                  'Total',
                ]}
                columns={[
                  {
                    name: 'check',
                    format: (data) => (
                      <>
                        <Form.Checkbox
                          style={{ display: 'flex', justifyContent: 'center' }}
                          onChange={(e, checked) => this.handleItem(data.id, data, checked.checked)}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'name',
                    format: (data) => <>{data.name || '-'}</>,
                  },
                  {
                    name: 'part_number',
                    format: (data) => <>{data.part_number || '-'}</>,
                  },
                  {
                    name: 'quantity',
                    format: (data) => <>{data.quantity || '-'}</>,
                  },
                  {
                    name: 'unit_cost_price',
                    format: (data) => (
                      <>
                        <CurrencyFormat
                          displayType={'text'}
                          value={data.unit_cost_price / 100 || '-'}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={currencyLabelHelper(data.currency)}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'amouunt_cost_price',
                    format: (data) => (
                      <>
                        <CurrencyFormat
                          displayType={'text'}
                          value={data.amount_cost_price / 100 || '-'}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={currencyLabelHelper(data.currency)}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'unit_price',
                    format: (data) => (
                      <>
                        <CurrencyFormat
                          displayType={'text'}
                          value={data.unit_price / 100 || '-'}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={currencyLabelHelper(data.currency)}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'amount',
                    format: (data) => (
                      <>
                        <CurrencyFormat
                          displayType={'text'}
                          value={data.amount / 100 || '-'}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={currencyLabelHelper(data.currency)}
                        />
                      </>
                    ),
                  },
                ]}
                data={itemOptions || []}
                totalPages={1}
                showPagination={false}
              />
              {submitted && !this.state.itensList.length && (
                <Label basic color="red" pointing>
                  É preciso selecionar ao menos um item
                </Label>
              )}
            </div>
            <Button primary type="submit">
              Criar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { sales, orders, companies, providers } = state;
  const items = sales.items;

  return {
    sales: sales.item,
    ordersSale: sales.ordersSale,
    orders,
    companies,
    users: orders.users,
    providers,
    items: items?.data,
    payment: orders.payment,
    loading: orders?.loading || false,
  };
}

const connectedModalOrdersSales = connect(mapStateToProps)(ModalOrdersSales);
export { connectedModalOrdersSales as ModalOrdersSales };
