import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Input, Pagination, Table } from 'semantic-ui-react';

class DynamicTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      column: null,
      search_per: null,
      refresh: false,
      search_value: '',
      columns: [],
      search: null,
      direction: null,
      data: [],
      header: [],
      totalPages: 1,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.props, filteredData: this.props.data });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
      if (!data.value) {
        this.setState({
          search_value: '',
          filteredData: this.state.data,
        });
      }
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.page !== this.props.page ||
      (prevProps.data && prevProps.data.length != this.props.data.length) ||
      (this.props.refresh && !this.state.refresh) ||
      (this.props.data &&
        this.props.data.length &&
        prevProps.data &&
        this.props.data[0].id != prevProps.data[0].id) ||
      (this.props.totalPages &&
        prevProps.totalPages &&
        this.props.totalPages != prevProps.totalPages)
    ) {
      this.setState({
        ...this.props,
        filteredData: this.props.data,
        refresh: true,
      });
    }
  }

  handleSearch(value) {
    const { data } = this.state;
    this.setState({
      search_value: value,
      filteredData: data,
    });

    const search = value.toLowerCase();
    if (!this.props.handleSearch) {
      let newData = data.filter((item) => {
        let keys = Object.keys(item);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          const value =
            typeof item[key] === 'object' && item[key] && item[key].name
              ? item[key].name
              : item[key];
          if (value && value.toString().toLowerCase().indexOf(search) > -1) {
            return true;
          }
        }

        return false;
      });

      this.setState({
        search_value: value,
        filteredData: newData,
      });
    } else {
      this.props.handleSearch(search);
    }
  }

  handleSort(clickedColumn) {
    const { data, direction } = this.state;
    let column = typeof clickedColumn == 'object' ? clickedColumn.name : clickedColumn;

    this.setState({
      column: column,
      filteredData: _.orderBy(data, [column], direction == 'ascending' ? 'desc' : 'asc'),
      direction: direction == 'ascending' ? 'descending' : 'ascending',
    });
  }

  validRow(data, header) {
    if (!data || !data.length) {
      return (
        <Table.Row key={0}>
          <Table.Cell colSpan={header.length} textAlign="center">
            Nenhum Resultado Encontrado
          </Table.Cell>
        </Table.Row>
      );
    }
  }

  transformResult(item, value) {
    switch (item.type) {
      case 'boolean':
        return value ? 'Ativo' : 'Inativo';

      default:
        return value;
    }
  }

  cellContent(data) {
    const { columns } = this.state;
    return _.map(columns, (item, index) => {
      let value = _.get(data, item);
      if (typeof item === 'object' && typeof item.format == 'function') {
        value = item.format(data);
      } else if (typeof item === 'object') {
        value = this.transformResult(item, data[item.name]);
      }
      return (
        <Table.Cell key={index} {...item.props}>
          {value}
        </Table.Cell>
      );
    });
  }

  onClick(item) {
    this.props.onClick(item);
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ page: activePage });
    if (this.props.handlePaginationChange) {
      this.props.handlePaginationChange(activePage);
    }
  };

  renderSearch() {
    const { search, search_value } = this.state;

    if (!search) {
      return null;
    }

    return (
      <Input
        type="text"
        name="search_value"
        placeholder="Digite para pesquisar..."
        icon="search"
        fluid
        value={search_value}
        onChange={(event) => this.handleSearch(event.target.value)}></Input>
    );
  }

  render() {
    const { column, columns, filteredData, header, direction, page, totalPages } = this.state;

    return (
      <>
        {this.renderSearch()}
        <Table
          padded
          sortable={this.props.sortable}
          celled={this.props.celled}
          fixed
          basic={this.props.style === 'basic' ? 'very' : false}>
          <Table.Header>
            <Table.Row>
              {_.map(header, (item, key) => (
                <Table.HeaderCell
                  key={key}
                  sorted={column === columns[key] ? direction : null}
                  onClick={() => {
                    if (this.props.handleSort) {
                      let column =
                        typeof columns[key] == 'object' ? columns[key].name : columns[key];

                      this.props.handleSort(column, direction);
                    } else {
                      this.handleSort(columns[key]);
                    }
                  }}>
                  {item}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(filteredData, (item, key) => (
              <Table.Row
                key={key}
                onClick={() => this.onClick(item)}
                className={this.props.onClick ? 'item-link' : ''}>
                {this.cellContent(item)}
              </Table.Row>
            ))}
            {this.validRow(filteredData, header)}
          </Table.Body>
        </Table>
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
          {this.props.showPagination && (
            <Pagination
              activePage={page}
              ellipsisItem={null}
              onPageChange={this.handlePaginationChange}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          )}
        </div>
      </>
    );
  }
}

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  search: PropTypes.object,
  columns: PropTypes.array.isRequired,
  style: PropTypes.string,
  celled: PropTypes.bool,
  sortable: PropTypes.bool,
  showPagination: PropTypes.bool,
};

DynamicTable.defaultProps = {
  celled: true,
  sortable: true,
  showPagination: true,
};

export { DynamicTable };
