import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { alertActions } from '../actions/alert.actions';
import { companyActions } from '../actions/company.actions';
import { MenuBand } from '../components/MenuBand/MenuBand';
import { ActivityEditScreen } from '../screens/Activity/edit';
import { ActivityScreen } from '../screens/Activity/index';
import { ActivityShowScreen } from '../screens/Activity/show';
import { ActivityConsolidatedScreen } from '../screens/ActivityConsolidated';
import { ActivityBankScreen } from '../screens/ActivityConsolidated/bank';
import { ActivitiesPerUserScreen } from '../screens/ActivityConsolidated/user';
import { ActivityTypeScreen } from '../screens/ActivityType';
import { ActivityTypeCreateScreen } from '../screens/ActivityType/create';
import { ContactCreateScreen } from '../components/ClientCardList/create';
import { ClientCardList } from '../components/ClientCardList/ClientCardList';
import { ActivityTypeEditScreen } from '../screens/ActivityType/edit';
import { ActivityTypeShowScreen } from '../screens/ActivityType/show';

import { ClientScreen } from '../screens/Client';

import { ClientCreateScreen } from '../screens/Client/create';
import { ClientEditScreen } from '../screens/Client/edit';
import { ClientShowScreen } from '../screens/Client/show';
import { ContractScreen } from '../screens/Contract';
import { ContractCreateScreen } from '../screens/Contract/create';
import { ContractEditScreen } from '../screens/Contract/edit';
import { ContractShowScreen } from '../screens/Contract/show';
import { ExpenseScreen } from '../screens/Expense';
import { ExpenseCreateScreen } from '../screens/Expense/create';
import { ExpenseEditScreen } from '../screens/Expense/edit';
import { ExpenseShowScreen } from '../screens/Expense/show';
import { ExpenseConsolidatedScreen } from '../screens/ExpenseConsolidated';
import { ExpenseTypeScreen } from '../screens/ExpenseType';
import { ExpenseTypeCreateScreen } from '../screens/ExpenseType/create';
import { ExpenseTypeEditScreen } from '../screens/ExpenseType/edit';
import { ExpenseTypeShowScreen } from '../screens/ExpenseType/show';
import FailedMessage from '../screens/FailedMessage';
import { HomeScreen } from '../screens/Home';
import { LoginScreen } from '../screens/Login';
import { MailsScreen } from '../screens/Mails';
import { MailsEditScreen } from '../screens/Mails/edit';
import { MailsShowScreen } from '../screens/Mails/show';
import { OrdersScreen } from '../screens/Orders';
import { OrdersShowScreen } from '../screens/Orders/show';
import { OrdersCreateScreen } from '../screens/Orders/create';
import { SalesScreen } from '../screens/Sales';
import { SalesCreateScreen } from '../screens/Sales/create';
import { SalesEditScreen } from '../screens/Sales/edit';
import { SalesShowScreen } from '../screens/Sales/show';
import { OpportunityScreen } from '../screens/Opportunity';
import { OpportunityCreateScreen } from '../screens/Opportunity/create';
import { OpportunityEditScreen } from '../screens/Opportunity/edit';
import { OpportunityShowScreen } from '../screens/Opportunity/show';
import { OpportunityKanbanScreen } from '../screens/Opportunity/kanban';
import { LeadsScreen } from '../screens/Leads';
import { LeadsCreateScreen } from '../screens/Leads/create';
import { LeadsEditScreen } from '../screens/Leads/edit';
import { LeadsShowScreen } from '../screens/Leads/show';
import { PdfScreen } from '../screens/Pdf';
import { PdfEditScreen } from '../screens/Pdf/edit';
import { PdfShowScreen } from '../screens/Pdf/show';
import { ProductsScreen } from '../screens/Products';
import { ProductsCreateScreen } from '../screens/Products/create';
import { ProductsEditScreen } from '../screens/Products/edit';
import { ProductsShowScreen } from '../screens/Products/show';
import { ProductsModulesScreen } from '../screens/ProductsModules';
import { ProductsModulesCreateScreen } from '../screens/ProductsModules/create';
import { ProductsModulesEditScreen } from '../screens/ProductsModules/edit';
import { ProductsModulesShowScreen } from '../screens/ProductsModules/show';
import { LicensesScreen } from '../screens/Licenses';
import { LicensesCreateScreen } from '../screens/Licenses/create';
import { LicensesShowScreen } from '../screens/Licenses/show';
import { ProfileScreen } from '../screens/Profile';
import { ProjectScreen } from '../screens/Project';
import { ProjectCreateScreen } from '../screens/Project/create';
import { ProjectEditScreen } from '../screens/Project/edit';
import { ProjectShowScreen } from '../screens/Project/show';
import { ProvidersScreen } from '../screens/Providers';
import { ProvidersCreateScreen } from '../screens/Providers/create';
import { ProvidersEditScreen } from '../screens/Providers/edit';
import { ProvidersShowScreen } from '../screens/Providers/show';
import { ProviderTypeScreen } from '../screens/ProviderType';
import { ProviderTypeCreateScreen } from '../screens/ProviderType/create';
import { ProviderTypeEditScreen } from '../screens/ProviderType/edit';
import { ProviderTypeShowScreen } from '../screens/ProviderType/show';
import { PaymentScreen } from '../screens/Payment';
import { PaymentCreateScreen } from '../screens/Payment/create';
import { PaymentEditScreen } from '../screens/Payment/edit';
import { PaymentShowScreen } from '../screens/Payment/show';
import { TeamScreen } from '../screens/Team';
import { TeamCreateScreen } from '../screens/Team/create';
import { TeamEditScreen } from '../screens/Team/edit';
import { TeamShowScreen } from '../screens/Team/show';
import { TicketScreen } from '../screens/Ticket';
import { TicketCreateScreen } from '../screens/Ticket/create';
import { TicketEditScreen } from '../screens/Ticket/edit';
import { TicketReportScreen } from '../screens/Ticket/report';
import { TicketShowScreen } from '../screens/Ticket/show';
import { TimerScreen } from '../screens/Timer';
import { UserScreen } from '../screens/User';
import { UserCreateScreen } from '../screens/User/create';
import { UserEditScreen } from '../screens/User/edit';
import { UserShowScreen } from '../screens/User/show';
import { ManagersScreen } from '../screens/Managers';
import { ManagersCreateScreen } from '../screens/Managers/create';
import { ManagersEditScreen } from '../screens/Managers/edit';
import { ManagersShowScreen } from '../screens/Managers/show';
import './colors.css';
import './styles.css';
import { OrderEditScreen } from '../screens/Orders/edit';
import { CompanyScreen } from '../screens/Company';
import { CompanyCreateScreen } from '../screens/Company/create';
import { CompanyShowScreen } from '../screens/Company/show';
import { CompanyEditScreen } from '../screens/Company/edit';
import { FinancialAccountScreen } from '../screens/FinancialAccount';
import { FinancialAccountCreateScreen } from '../screens/FinancialAccount/create';
import { FinancialAccountShowScreen } from '../screens/FinancialAccount/show';
import { FinancialAccountEditScreen } from '../screens/FinancialAccount/edit';
import { FinancialCategoryScreen } from '../screens/FinancialCategories';
import { FinancialCategoryShowScreen } from '../screens/FinancialCategories/show';
import { FinancialCategoryCreateScreen } from '../screens/FinancialCategories/create';
import { FinancialCategoryEditScreen } from '../screens/FinancialCategories/edit';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    dispatch(companyActions.getById(1)).then(() => {
      let { company } = this.props;

      if (company) {
        localStorage.setItem('company', JSON.stringify(company));
      }
    });

    this.props.history.listen(() => {
      dispatch(alertActions.clear());
    });
  }

  getCompany() {
    const data = localStorage.getItem('company');
    return data ? JSON.parse(data) : null;
  }

  customTheme() {
    let { company } = this.props;

    if (!company) return null;

    const content = (
      <style type="text/css">
        :root {'{'}
        --primary: {company.primary_color}; --secondary: {company.secondary_color}; --tertiary:{' '}
        {company.tertiary_color};{'}'}
      </style>
    );

    return content;
  }

  customTitle() {
    const company = this.getCompany();

    if (!company) return null;

    return <title>{company.title}</title>;
  }

  lastMessage = null;

  render() {
    const { alert } = this.props;
    if (alert.message && alert.show !== false && alert.message !== this.lastMessage) {
      let message = alert.message;
      if (typeof message !== 'string') {
        message = message.message || 'Desconhecido';
      }
      this.lastMessage = alert.message;
      toast({
        type: alert.type,
        icon: 'info',
        description: message,
        time: 5000,
        onClose: () => {
          const { dispatch } = this.props;
          dispatch(alertActions.clear());
        },
      });
    }

    return (
      <Fragment>
        {this.customTheme()}
        {this.customTitle()}
        <SemanticToastContainer position="bottom-right" />
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/404" component={FailedMessage} />

          <MenuBand>
            <div className="main-container">
              <div className="wrapper">
                <Route exact path="/" component={HomeScreen} />
                <Route
                  exact
                  path="/timer"
                  authorize={['activities.timer']}
                  component={TimerScreen}
                />
                <Route
                  exact
                  path="/activities-consolidated"
                  component={ActivityConsolidatedScreen}
                  authorize={['activities.index']}
                />
                <Route
                  exact
                  path="/activities/per-user"
                  component={ActivitiesPerUserScreen}
                  authorize={['activities.timer']}
                />
                <Route
                  exact
                  path="/activities/:id/show"
                  component={ActivityShowScreen}
                  authorize={['activities.show']}
                />
                <Route
                  exact
                  path="/activities/:id/edit"
                  component={ActivityEditScreen}
                  authorize={['activities.update']}
                />
                <Route
                  exact
                  path="/activities/dashboard"
                  component={HomeScreen}
                  authorize={['activities.show']}
                />

                <Route exact path="/profile" authorize={['all']} component={ProfileScreen} />

                <Route
                  exact
                  path="/activities"
                  authorize={['activities.index']}
                  component={ActivityScreen}
                />
                <Route
                  exact
                  path="/activities-bank"
                  authorize={['activities.bank.index']}
                  component={ActivityBankScreen}
                />

                <Route
                  exact
                  path="/clients"
                  authorize={['clients.index']}
                  component={ClientScreen}
                />
                <Route
                  exact
                  path="/clients/:id/edit"
                  authorize={['clients.update']}
                  component={ClientEditScreen}
                />
                <Route
                  exact
                  path="/clients/:id/show"
                  authorize={['clients.show']}
                  component={ClientShowScreen}
                />
                <Route
                  exact
                  path="/clients/create"
                  authorize={['clients.store']}
                  component={ClientCreateScreen}
                />

                <Route exact path="/teams" authorize={['teams.index']} component={TeamScreen} />
                <Route
                  exact
                  path="/teams/:id/edit"
                  authorize={['teams.update']}
                  component={TeamEditScreen}
                />
                <Route
                  exact
                  path="/teams/:id/show"
                  authorize={['teams.show']}
                  component={TeamShowScreen}
                />
                <Route
                  exact
                  path="/teams/create"
                  authorize={['teams.store']}
                  component={TeamCreateScreen}
                />

                <Route
                  exact
                  path="/projects"
                  authorize={['projects.index']}
                  component={ProjectScreen}
                />
                <Route
                  exact
                  path="/projects/:id/edit"
                  authorize={['projects.update']}
                  component={ProjectEditScreen}
                />
                <Route
                  exact
                  path="/projects/:id/show"
                  authorize={['projects.show']}
                  component={ProjectShowScreen}
                />
                <Route
                  exact
                  path="/projects/create"
                  authorize={['projects.store']}
                  component={ProjectCreateScreen}
                />

                <Route exact path="/users" authorize={['users.index']} component={UserScreen} />
                <Route
                  exact
                  path="/users/:id/edit"
                  authorize={['users.update']}
                  component={UserEditScreen}
                />
                <Route
                  exact
                  path="/users/:id/show"
                  authorize={['users.show']}
                  component={UserShowScreen}
                />
                <Route
                  exact
                  path="/users/create"
                  authorize={['users.store']}
                  component={UserCreateScreen}
                />
                <Route
                  exact
                  path="/managers"
                  authorize={['account.managers.index']}
                  component={ManagersScreen}
                />
                <Route
                  exact
                  path="/managers/:id/edit"
                  authorize={['account.managers.update']}
                  component={ManagersEditScreen}
                />
                <Route
                  exact
                  path="/managers/:id/show"
                  authorize={['account.managers.show']}
                  component={ManagersShowScreen}
                />
                <Route
                  exact
                  path="/managers/create"
                  authorize={['account.managers.store']}
                  component={ManagersCreateScreen}
                />
                <Route
                  exact
                  path="/tickets"
                  authorize={['tickets.index']}
                  component={TicketScreen}
                />
                <Route
                  exact
                  path="/tickets/:id/edit"
                  authorize={['tickets.update']}
                  component={TicketEditScreen}
                />
                <Route
                  exact
                  path="/tickets/:id/show"
                  authorize={['tickets.show']}
                  component={TicketShowScreen}
                />
                <Route
                  exact
                  path="/tickets/create"
                  authorize={['tickets.store']}
                  component={TicketCreateScreen}
                />
                <Route
                  exact
                  path="/tickets-report"
                  authorize={['tickets.index']}
                  component={TicketReportScreen}
                />

                <Route
                  exact
                  path="/expenses"
                  authorize={['expenses.index', 'expenses.permissions']}
                  component={ExpenseScreen}
                />
                <Route
                  exact
                  path="/expenses/:id/edit"
                  authorize={['expenses.update']}
                  component={ExpenseEditScreen}
                />
                <Route
                  exact
                  path="/expenses/:id/show"
                  authorize={['expenses.show']}
                  component={ExpenseShowScreen}
                />
                <Route
                  exact
                  path="/expenses/create"
                  authorize={['expenses.store']}
                  component={ExpenseCreateScreen}
                />

                <Route
                  exact
                  path="/expenses-activity"
                  authorize={['expenses.index', 'expense.me']}
                  component={ExpenseConsolidatedScreen}
                />

                <Route
                  exact
                  path="/contracts"
                  authorize={['contracts.index']}
                  component={ContractScreen}
                />
                <Route
                  exact
                  path="/contracts/:id/edit"
                  authorize={['contracts.update']}
                  component={ContractEditScreen}
                />
                <Route
                  exact
                  path="/contracts/:id/show"
                  authorize={['contracts.show']}
                  component={ContractShowScreen}
                />
                <Route
                  exact
                  path="/contracts/create"
                  authorize={['contracts.store']}
                  component={ContractCreateScreen}
                />

                <Route
                  exact
                  path="/activitytype"
                  authorize={['activity.types.index']}
                  component={ActivityTypeScreen}
                />
                <Route
                  exact
                  path="/activitytype/:id/edit"
                  authorize={['activity.types.update']}
                  component={ActivityTypeEditScreen}
                />
                <Route
                  exact
                  path="/activitytype/:id/show"
                  authorize={['activity.types.show']}
                  component={ActivityTypeShowScreen}
                />
                <Route
                  exact
                  path="/activitytype/create"
                  authorize={['activity.types.store']}
                  component={ActivityTypeCreateScreen}
                />

                <Route
                  exact
                  path="/expense-types"
                  authorize={['expense.type.index']}
                  component={ExpenseTypeScreen}
                />
                <Route
                  exact
                  path="/expense-type/:id/edit"
                  authorize={['expense.type.update']}
                  component={ExpenseTypeEditScreen}
                />
                <Route
                  exact
                  path="/expense-type/:id/show"
                  authorize={['expense.type.show']}
                  component={ExpenseTypeShowScreen}
                />
                <Route
                  exact
                  path="/expense-type/create"
                  authorize={['expense.type.store']}
                  component={ExpenseTypeCreateScreen}
                />

                <Route
                  exact
                  path="/products"
                  authorize={['products.index']}
                  component={ProductsScreen}
                />
                <Route
                  exact
                  path="/products/:id/edit"
                  authorize={['products.update']}
                  component={ProductsEditScreen}
                />
                <Route
                  exact
                  path="/products/:id/show"
                  authorize={['products.show']}
                  component={ProductsShowScreen}
                />
                <Route
                  exact
                  path="/products/create"
                  authorize={['products.store']}
                  component={ProductsCreateScreen}
                />

                <Route
                  exact
                  path="/product/modules"
                  authorize={['product.module.index']}
                  component={ProductsModulesScreen}
                />
                <Route
                  exact
                  path="/product/modules/:id/edit"
                  authorize={['product.module.update']}
                  component={ProductsModulesEditScreen}
                />
                <Route
                  exact
                  path="/product/modules/:id/show"
                  authorize={['product.module.show']}
                  component={ProductsModulesShowScreen}
                />
                <Route
                  exact
                  path="/product/modules/create"
                  authorize={['product.module.store']}
                  component={ProductsModulesCreateScreen}
                />

                <Route
                  exact
                  path="/licenses"
                  authorize={['licenses.index']}
                  component={LicensesScreen}
                />
                <Route
                  exact
                  path="/licenses/:id/show"
                  authorize={['licenses.show']}
                  component={LicensesShowScreen}
                />
                <Route
                  exact
                  path="/licenses/create"
                  authorize={['licenses.store']}
                  component={LicensesCreateScreen}
                />

                <Route
                  exact
                  path="/providers"
                  authorize={['providers.index']}
                  component={ProvidersScreen}
                />
                <Route
                  exact
                  path="/providers/:id/edit"
                  authorize={['providers.update']}
                  component={ProvidersEditScreen}
                />
                <Route
                  exact
                  path="/providers/:id/show"
                  authorize={['providers.show']}
                  component={ProvidersShowScreen}
                />
                <Route
                  exact
                  path="/providers/create"
                  authorize={['providers.store']}
                  component={ProvidersCreateScreen}
                />

                <Route
                  exact
                  path="/provider-types"
                  authorize={['provider.types.index']}
                  component={ProviderTypeScreen}
                />
                <Route
                  exact
                  path="/provider-types/:id/edit"
                  authorize={['provider.types.update']}
                  component={ProviderTypeEditScreen}
                />
                <Route
                  exact
                  path="/provider-types/:id/show"
                  authorize={['provider.types.show']}
                  component={ProviderTypeShowScreen}
                />
                <Route
                  exact
                  path="/provider-types/create"
                  authorize={['provider.types.store']}
                  component={ProviderTypeCreateScreen}
                />

                <Route
                  exact
                  path="/payment"
                  authorize={['payment.methods.index']}
                  component={PaymentScreen}
                />
                <Route
                  exact
                  path="/payment/:id/edit"
                  authorize={['payment.methods.update']}
                  component={PaymentEditScreen}
                />
                <Route
                  exact
                  path="/payment/:id/show"
                  authorize={['payment.methods.show']}
                  component={PaymentShowScreen}
                />
                <Route
                  exact
                  path="/payment/create"
                  authorize={['payment.methods.store']}
                  component={PaymentCreateScreen}
                />
                <Route
                  exact
                  path="/orders"
                  authorize={['service.orders.index']}
                  component={OrdersScreen}
                />
                <Route
                  exact
                  path="/orders/:id/show"
                  authorize={['service.orders.show']}
                  component={OrdersShowScreen}
                />
                <Route
                  exact
                  path="/orders/:id/edit"
                  authorize={['service.orders.update']}
                  component={OrderEditScreen}
                />
                <Route
                  exact
                  path="/orders/create"
                  authorize={['service.orders.store']}
                  component={OrdersCreateScreen}
                />
                <Route exact path="/sales" authorize={['sale.index']} component={SalesScreen} />
                <Route
                  exact
                  path="/sales/:id/edit"
                  authorize={['sale.update']}
                  component={SalesEditScreen}
                />
                <Route
                  exact
                  path="/sales/:id/show"
                  authorize={['sale.show']}
                  component={SalesShowScreen}
                />
                <Route
                  exact
                  path="/sales/create"
                  authorize={['sale.store']}
                  component={SalesCreateScreen}
                />
                <Route
                  exact
                  path="/opportunity"
                  authorize={['opportunities.index']}
                  component={OpportunityScreen}
                />
                <Route
                  exact
                  path="/opportunity/:id/edit"
                  authorize={['opportunities.update']}
                  component={OpportunityEditScreen}
                />
                <Route
                  exact
                  path="/opportunity/:id/show"
                  authorize={['opportunities.show']}
                  component={OpportunityShowScreen}
                />
                <Route
                  exact
                  path="/opportunity/create"
                  authorize={['opportunities.store']}
                  component={OpportunityCreateScreen}
                />
                <Route
                  exact
                  path="/opportunity/kanban"
                  authorize={['opportunity.status.show']}
                  component={OpportunityKanbanScreen}
                />
                <Route exact path="/leads" authorize={['leads.index']} component={LeadsScreen} />
                <Route
                  exact
                  path="/leads/:id/edit"
                  authorize={['leads.update']}
                  component={LeadsEditScreen}
                />
                <Route
                  exact
                  path="/leads/:id/show"
                  authorize={['leads.show']}
                  component={LeadsShowScreen}
                />
                <Route
                  exact
                  path="/leads/create"
                  authorize={['leads.store']}
                  component={LeadsCreateScreen}
                />
                <Route
                  exact
                  path="/mails"
                  authorize={['email.texts.index']}
                  component={MailsScreen}
                />
                <Route
                  exact
                  path="/mails/:id/edit"
                  authorize={['email.texts.update']}
                  component={MailsEditScreen}
                />
                <Route
                  exact
                  path="/mails/:id/show"
                  authorize={['email.texts.show']}
                  component={MailsShowScreen}
                />

                <Route
                  exact
                  path="/pdf"
                  authorize={['pdf.templates.index']}
                  component={PdfScreen}
                />
                <Route
                  exact
                  path="/pdf/:id/edit"
                  authorize={['pdf.templates.update']}
                  component={PdfEditScreen}
                />
                <Route
                  exact
                  path="/pdf/:id/show"
                  authorize={['pdf.templates.show']}
                  component={PdfShowScreen}
                />
                <Route
                  exact
                  path="/contact/create"
                  authorize={['contacts.store']}
                  component={ContactCreateScreen}
                />
                <Route
                  exact
                  path="/contacts"
                  authorize={['contacts.index']}
                  component={ClientCardList}
                />
                <Route
                  exact
                  path="/companies"
                  authorize={['companies.index']}
                  component={CompanyScreen}
                />
                <Route
                  exact
                  path="/companies/create"
                  authorize={['companies.store']}
                  component={CompanyCreateScreen}
                />
                <Route
                  exact
                  path="/companies/:id/show"
                  authorize={['companies.show']}
                  component={CompanyShowScreen}
                />
                <Route
                  exact
                  path="/companies/:id/edit"
                  authorize={['companies.update']}
                  component={CompanyEditScreen}
                />
                <Route
                  exact
                  path="/finance/accounts"
                  authorize={['financial.accounts.index']}
                  component={FinancialAccountScreen}
                />
                <Route
                  exact
                  path="/finance/accounts/create"
                  authorize={['financial.accounts.store']}
                  component={FinancialAccountCreateScreen}
                />
                <Route
                  exact
                  path="/finance/accounts/:id/show"
                  authorize={['financial.accounts.show']}
                  component={FinancialAccountShowScreen}
                />
                <Route
                  exact
                  path="/finance/accounts/:id/edit"
                  authorize={['financial.accounts.update']}
                  component={FinancialAccountEditScreen}
                />
                <Route
                  exact
                  path="/finance/categories"
                  authorize={['financial.sub.categories.index']}
                  component={FinancialCategoryScreen}
                />
                <Route
                  exact
                  path="/finance/categories/:id/show"
                  authorize={['financial.sub.categories.show']}
                  component={FinancialCategoryShowScreen}
                />
                <Route
                  exact
                  path="/finance/categories/create"
                  authorize={['financial.sub.categories.store']}
                  component={FinancialCategoryCreateScreen}
                />
                <Route
                  exact
                  path="/finance/categories/:id/edit"
                  authorize={['financial.sub.categories.update']}
                  component={FinancialCategoryEditScreen}
                />
              </div>
            </div>
          </MenuBand>
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert, companies } = state;
  return {
    company: companies.item || null,
    alert,
  };
}

App.propTypes = {
  dispatch: PropTypes.dispatch,
  company: PropTypes.any,
  alert: PropTypes.any,
};

const connectedApp = withRouter(connect(mapStateToProps)(App));
export { connectedApp as App };
