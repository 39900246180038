import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { projectActions } from '../../actions/project.actions';
import { activityActions } from '../../actions/activity.actions';

class ActivityTypeEditScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      client_id: null,
      status_id: null,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(activityActions.getTypeById(match.params.id)).then(() => {
      const { type } = this.props;

      dispatch(projectActions.getStatusProjects());

      this.setState({
        name: type.name,
        active: type.active,
        status_id: type.status_id,
      });
    });
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, active, status_id } = this.state;
    const { dispatch, match } = this.props;
    if (name && status_id) {
      dispatch(
        activityActions.updateType(
          match.params.id,
          {
            name,
            active,
            status_id,
          },
          this.props.history,
        ),
      );
    }
  }

  render() {
    const { name, active, status_id, submitted } = this.state;
    const { status, loading } = this.props;
    let activeOptions,
      statusOptions = [];

    activeOptions = [
      { key: 1, value: 1, text: 'Ativo' },
      { key: 2, value: 0, text: 'Inativo' },
    ];

    if (status && status.data !== undefined) {
      statusOptions = status.data.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      });
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <h1>Alterar Tipo de Atividade</h1>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Field>
            <label>Nome do Tipo</label>
            <input
              name="name"
              placeholder="Nome do Projeto"
              value={name}
              onChange={this.handleChange}
            />
            {submitted && !name && (
              <Label basic color="red" pointing>
                Nome é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Estado"
              fluid
              search
              selection
              name="status_id"
              options={statusOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={status_id}
            />
            {submitted && !status_id && (
              <Label basic color="red" pointing>
                Estado é requerido
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              search
              selection
              name="active"
              options={activeOptions}
              onChange={(event, data) => this.handleChange(true, data)}
              value={active}
            />
          </Form.Field>
          <Button primary type="submit">
            Atualizar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { projects, activities } = state;
  return {
    status: projects.status,
    type: activities.itemType || null,
  };
}

const connectedActivityTypeEditScreen = connect(mapStateToProps)(ActivityTypeEditScreen);
export { connectedActivityTypeEditScreen as ActivityTypeEditScreen };
